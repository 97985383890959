import { AxiosError } from "axios";
import { useMutation } from "react-query";
import { axiosSubmitPrice } from "../api/axiosPostRequest";

export const useSubmitPriceQuery = () =>
  useMutation(axiosSubmitPrice, {
    onError: (err: AxiosError, val) => {
      console.error(`from ${val.apiName} ERROR`, err);
    },
  });
