import { useUserMessageRecordQuery } from "../query/useUserMessageRecordQuery";
import { IcopyMessageList } from "../types/state.type";
export const useUserMessageList = (id?: number, token?: string) => {
  const { data, status } = useUserMessageRecordQuery(
    `message/trade/${id}/content/`,
    {
      apiName: "User message list",
      authorization: token,
    },
  );

  return {
    userMessageData: data ? (data as IcopyMessageList[]) : [],
    userMessageStatus: status,
  };
};
