interface signature {
  [key: string | number]: string | object;
}

export const SUBMIT_SELL_AND_BUY_ERROR_TYPE: signature = {
  400: "잘못된 금액을 작성하셨습니다.",
  401: "잘못된 인증 정보입니다.",
  404: "잘못된 주소로 인해 등록하셨습니다.",
};

export const SUBMIT_USER_INFO_CHANGE_ERROR_TYPE: signature = {
  400: "잘못된 정보를 작성하셨습니다.",
  401: "잘못된 인증 정보입니다.",
  404: "잘못된 주소로 인해 등록하셨습니다.",
};

export const SUBMIT_USER_PASSWORD_CHECK_ERROR_TYPE: signature = {
  400: "비밀번호를 잘못 작성하셨습니다.",
  401: "잘못된 인증 정보입니다.",
  404: "잘못된 주소로 인해 등록하셨습니다.",
};
