import {
  IsignupInfo,
  IreceiveCheck,
} from "../../pages/SignUp/types/state.type";

interface Istate {
  input: IsignupInfo;
  benefit: boolean;
  receiveCheck: IreceiveCheck;
}

interface Ifunc {
  setInput: (value: IsignupInfo) => void;
  handleToggleAll: () => void;
  handleToggleSingle: (e: string) => void;
}

export const SignupCheckBox = ({
  state,
  func,
}: {
  state: Istate;
  func: Ifunc;
}) => {
  return (
    <div>
      <div className="flex items-center mb-8 border-b-[1px] border-b-main-color-primary pb-[12px]">
        <h1 className="text-listFirst font-bold text-main-color-primary max-sm:font-medium">
          약관 동의
        </h1>
      </div>
      <div className="mt-[40px] mb-[16px] max-md:mb-[20px]">
        <article className="flex max-md:flex-col">
          <h1 className="flex mr-[6px] max-md:mr-[0px] max-sm:text-footer max-xs:text-smallExplanation">
            <input
              className="mr-[10px] max-sm:max-h-[15px] max-sm:max-w-[15px] max-sm:mr-[4px]"
              type="checkbox"
              checked={state.input.agreement}
              onChange={() =>
                func.setInput({
                  ...state.input,
                  agreement: !state.input.agreement,
                })
              }
            />
            본인은 만 14세 이상이며,
            <a
              className="border-b-[1px] ml-1 cursor-pointer"
              target="_blank"
              href="/privacy/service"
            >
              이용약관
            </a>
            ,
            <a
              className="border-b-[1px] ml-1 cursor-pointer"
              target="_blank"
              href="/privacy/policy"
            >
              개인정보 처리 방침
            </a>
            을
          </h1>
          <h1 className="flex items-center gap-x-[8px] max-md:ml-[28px] max-md:mt-[10px] max-sm:text-footer max-xs:text-smallExplanation">
            확인하였으며 동의합니다.
            <span className="text-general-red text-smallExplanation">필수</span>
          </h1>
        </article>
      </div>
      <div>
        <article className="flex max-md:flex-col">
          <h1 className="flex mr-[6px] max-sm:text-footer max-xs:text-smallExplanation">
            <input
              className="mr-[10px] max-sm:max-h-[15px] max-sm:max-w-[15px] max-sm:mr-[4px]"
              type="checkbox"
              checked={state.benefit}
              onChange={func.handleToggleAll}
            />
            <a
              className="border-b-[1px]"
              target="_blank"
              href="/privacy/marketing"
            >
              마케팅수신 동의 약관
            </a>
            에 동의하며, 신규기능 업데이트
          </h1>
          <h1 className="flex items-center gap-x-[8px] max-md:ml-[28px] max-md:mt-[10px] max-sm:text-footer max-xs:text-smallExplanation">
            및 필요한 정보를 받고 싶습니다.
            <span className="text-text-color-secondary text-smallExplanation">
              선택
            </span>
          </h1>
        </article>
      </div>

      <div className="flex mt-3 ml-6 text-default max-sm:text-footer max-xs:text-smallExplanation">
        <div className="flex items-center">
          <input
            className="max-sm:max-h-[15px] max-sm:max-w-[15px]"
            id="email"
            type="checkbox"
            value="email"
            checked={state.receiveCheck.email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              func.handleToggleSingle(e.target.value)
            }
          />
          <label htmlFor="email">
            <span className="ml-[6px] mr-4 cursor-pointer">이메일</span>
          </label>
        </div>
        <div className="flex items-center">
          <input
            className="max-sm:max-h-[15px] max-sm:max-w-[15px]"
            id="sms"
            type="checkbox"
            value="sms"
            checked={state.receiveCheck.sms}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              func.handleToggleSingle(e.target.value)
            }
          />
          <label htmlFor="sms">
            <span className="ml-[6px] mr-4 cursor-pointer">SMS</span>
          </label>
        </div>
        <div className="flex items-center">
          <input
            className="max-sm:max-h-[15px] max-sm:max-w-[15px]"
            id="kakao"
            type="checkbox"
            value="kakao"
            checked={state.receiveCheck.kakao}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              func.handleToggleSingle(e.target.value)
            }
          />
          <label htmlFor="kakao">
            <span className="ml-[6px] mr-4 cursor-pointer">카카오톡</span>
          </label>
        </div>
      </div>
    </div>
  );
};
