import { useState } from "react";
import { MainPlantListBox } from "./MainPlantListBox";
import { CapacityRadioList } from "../../UI/Input_Radio/CapacityRadioList";
import { IfilterItem } from "../../../pages/Mypage/types/state.type";

interface ImainPlantListCapacityFilter {
  filter: IfilterItem;
  filterType: string;
  selectFilter: (type: string, value: string) => void;
}

export const MainPlantListCapacityFilter = ({
  filter,
  filterType,
  selectFilter,
}: ImainPlantListCapacityFilter) => {
  const [toggle, setToggle] = useState<boolean>(true);

  return (
    <section>
      <MainPlantListBox
        state={{
          toggle: toggle,
          filter: filter,
        }}
        func={{
          setToggle: setToggle,
        }}
        options={{
          filterType: filterType,
          component: (
            <CapacityRadioList
              state={{
                toggle: toggle,
                filter: filter,
              }}
              func={{
                selectFilter: selectFilter,
              }}
            />
          ),
        }}
      />
    </section>
  );
};
