import { AxiosError } from "axios";
import { useMutation } from "react-query";
import { axiosAddFavorite } from "../api/axiosPostRequest";
import { axiosRemoveFavorite } from "../api/axiosDeleteRequest";

export const useAddFavorite = () =>
  useMutation(axiosAddFavorite, {
    onError: (err: AxiosError, val) => {
      console.error(`from ${val.apiName} ERROR`, err);
    },
  });

export const useRemoveFavorite = () =>
  useMutation(axiosRemoveFavorite, {
    onError: (err: AxiosError, val) => {
      console.error(`from ${val.apiName} ERROR`, err);
    },
  });
