import { useEffect, useState } from "react";

declare const window: typeof globalThis & {
  naver: any;
};

export const useCadastralMap = (latitude: number, longitude: number) => {
  const { naver } = window;

  // naver map 중복 로드 차단
  const [brake, setBrake] = useState(false);

  useEffect(() => {
    function mapSync() {
      // 중복 렌더링 방지
      if (!latitude || !longitude || !naver?.maps || brake) return;

      var map = new naver.maps.Map("cadastral", {
        center: new naver.maps.LatLng(latitude, longitude),
        // mapTypeControl: true,
        logoControl: false,
      });

      var cadastralLayer = new naver.maps.CadastralLayer();

      cadastralLayer.setMap(map);

      const location = new naver.maps.LatLng(latitude, longitude);
      // 지도에 표시할 위치의 위도와 경도 설정
      new naver.maps.Marker({
        map: map,
        position: location,
      });
    }

    if (latitude && longitude && naver?.maps?.Map) {
      mapSync();
      setBrake(true);
    }
  }, [latitude, longitude, naver?.maps?.Map]);
};
