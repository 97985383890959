export const getTodayDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month =
    date.getMonth() >= 10 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1);
  const day = date.getDate() >= 10 ? date.getDate() : "0" + date.getDate();

  const hour = date.getHours() >= 10 ? date.getHours() : "0" + date.getHours();
  const minute =
    date.getMinutes() >= 10 ? date.getMinutes() : "0" + date.getMinutes();

  return (
    String(year) +
    "." +
    String(month) +
    "." +
    String(day) +
    " " +
    hour +
    ":" +
    minute
  );
};

export const getDayIncodeDate = (getDay: string) => {
  if (!getDay) return "";
  const date = new Date(getDay);

  const year = date.getFullYear();
  const month =
    date.getMonth() >= 10 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1);
  const day = date.getDate() >= 10 ? date.getDate() : "0" + date.getDate();

  return String(year) + "." + String(month) + "." + String(day);
};

export const getRecordDate = (res: string) => {
  if (!res) return;
  const currentDate = new Date().getTime();
  const chatDate = new Date(res).getTime();

  const milliSeconds = currentDate - chatDate;

  const seconds = milliSeconds / 1000;
  const minutes = seconds / 60;
  const hours = minutes / 60;

  if (seconds < 60) {
    return `방금 전`;
  } else if (minutes < 60) {
    return `${Math.floor(minutes)}분 전`;
  } else if (hours < 24) {
    return `${Math.floor(hours)}시간 전`;
  } else {
    const time = new Date(res);
    const year = time.getFullYear();
    const month =
      time.getMonth() >= 10 ? time.getMonth() + 1 : "0" + (time.getMonth() + 1);
    const day = time.getDate() >= 10 ? time.getDate() : "0" + time.getDate();

    return String(year) + "." + String(month) + "." + String(day);
  }
};
