export function inputFileType(file: string) {
  if (
    file === "image/png" ||
    file === "image/gif" ||
    file === "image/jpeg" ||
    file === "application/pdf"
  ) {
    return true;
  } else {
    return false;
  }
}
