import { Route, Routes } from "react-router-dom";
import { MypageMainFetch } from "../../pages/Mypage/module_fetch/MypageMain.fetch";
import { MypageInquiryFetch } from "../../pages/Mypage/module_fetch/MypageInquiry.fetch";
import { MypageCertificationFetch } from "../../pages/Mypage/module_fetch/MypageCertification.fetch";
import { MypagePlantSellFetch } from "../../pages/Mypage/module_fetch/MypagePlantSell.fetch";
import { MypagePlantBuyFetch } from "../../pages/Mypage/module_fetch/MypagePlantBuy.fetch";
import { MypageOwnerSubmitFetch } from "../../pages/Mypage/module_fetch/MypageOwnerSubmit.fetch";
import { MypageOwnerInfoFetch } from "../../pages/Mypage/module_fetch/MypageOwnerInfo.fetch";
import { MypageFavoriteFetch } from "../../pages/Mypage/module_fetch/MypageFavorite.fetch";
import { MypageUserInfoFetch } from "../../pages/Mypage/module_fetch/MypageUserInfo.fetch";
import { MypageMyPlantFetch } from "../../pages/Mypage/module_fetch/MypageMyPlant.fetch";
import { MypageRegistrationFetch } from "../../pages/Mypage/module_fetch/MypageRegistration.fetch";

interface Iitem {
  username: string;
}

export const MypageRight = ({ item }: { item: Iitem }) => {
  return (
    <section className="max-w-[1222px] w-full mt-14 max-md:mb-14 max-sm:mb-0 ">
      <Routes>
        <Route path="" element={<MypageMainFetch />} />
        <Route path="inquiry" element={<MypageInquiryFetch />} />
        <Route path="myPlant" element={<MypageMyPlantFetch />} />
        <Route
          path="myPlant/certification"
          element={<MypageCertificationFetch />}
        />
        <Route
          path="myPlant/registration"
          element={<MypageRegistrationFetch />}
        />
        <Route path="trade/plantSell" element={<MypagePlantSellFetch />} />
        <Route path="trade/plantBuy" element={<MypagePlantBuyFetch />} />
        <Route
          path="myPlant/certification/information/:id"
          element={<MypageOwnerInfoFetch />}
        />
        <Route
          path="myPlant/submit"
          element={
            <MypageOwnerSubmitFetch item={{ username: item.username }} />
          }
        />
        <Route path="favorite" element={<MypageFavoriteFetch />} />
        <Route path="updateInfo" element={<MypageUserInfoFetch />} />
      </Routes>
    </section>
  );
};
