import classNames from "classnames";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { notifyMessage } from "./atoms/notify.atoms";

interface Ifunc {
  setMenuHide: (bool: boolean) => void;
  selectLogout: () => void;
  setSearchHide: (bool: boolean) => void;
}

interface Istate {
  menuHide: boolean;
}

export const HeaderMobileMenu = ({
  state,
  func,
}: {
  state: Istate;
  func: Ifunc;
}) => {
  const [cookie] = useCookies(["60hz-user"]);

  const [notify, setNotify] = useRecoilState(notifyMessage);
  const navigate = useNavigate();

  const { pathname, search } = useLocation();

  return (
    <div
      className={classNames(
        "w-full h-[205px] fixed left-0 bg-general-white border-[1px] border-border-color-dividers pt-[20px] pb-[40px] px-[20px] rounded-t-3xl transition-all duration-300",
        { "bottom-0": state.menuHide },
        { "bottom-[-250px]": !state.menuHide },
        "md:hidden",
      )}
    >
      <ul
        className={classNames(
          "grid gap-y-[25px]",
          { "grid-cols-2": cookie["60hz-user"] },
          { "grid-cols-3 mt-[45px]": !cookie["60hz-user"] },
        )}
      >
        {cookie["60hz-user"] ? (
          <>
            <li className="flex justify-center">
              <div
                className="flex flex-col gap-y-[10px] items-center justify-center cursor-pointer hover:text-main-color-primary"
                onClick={func.selectLogout}
              >
                <img
                  className="w-[25px] h-[25px]"
                  src="/icon_remodel/logout.svg"
                />
                로그아웃
              </div>
            </li>
            <li className="flex justify-center">
              <div
                className="flex flex-col gap-y-[10px] items-center justify-center cursor-pointer hover:text-main-color-primary"
                onClick={() => {
                  if (pathname !== "/mypage") {
                    func.setMenuHide(false);
                    func.setSearchHide(false);
                    navigate("/mypage");
                  }
                }}
              >
                <img
                  className="w-[24px] h-[25px]"
                  src="/icon_remodel/user.svg"
                />
                내 정보
              </div>
            </li>
            <li className="flex justify-center">
              <div
                className="flex flex-col gap-y-[10px] items-center justify-center cursor-pointer hover:text-main-color-primary"
                onClick={() => {
                  if (pathname !== "/message") {
                    func.setMenuHide(false);
                    func.setSearchHide(false);
                    setNotify(false);
                    navigate("/message");
                  }
                }}
              >
                <img
                  className="w-[25px] h-[25px]"
                  src="/icon_remodel/letter-box.svg"
                />
                <div className="relative">
                  메세지함
                  {notify && (
                    <div className="absolute top-[5px] left-[60px]">
                      <span className="relative flex h-[10px] w-[10px]">
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-general-red opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-[10px] w-[10px] bg-general-red"></span>
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </li>
          </>
        ) : (
          <>
            <li className="flex justify-center">
              <div
                className="flex flex-col gap-y-[10px] items-center justify-center cursor-pointer hover:text-main-color-primary"
                onClick={() => {
                  if (pathname !== "/login") {
                    func.setMenuHide(false);
                    func.setSearchHide(false);
                    navigate("/login");
                  }
                }}
              >
                <img
                  className="w-[25px] h-[25px]"
                  src="/icon_remodel/login.svg"
                />
                로그인
              </div>
            </li>
            <li className="flex justify-center">
              <div
                className="flex flex-col gap-y-[10px] items-center justify-center cursor-pointer hover:text-main-color-primary"
                onClick={() => {
                  if (pathname !== "/signup") {
                    func.setMenuHide(false);
                    func.setSearchHide(false);
                    navigate("/signup");
                  }
                }}
              >
                <img
                  className="w-[29px] h-[23px]"
                  src="/icon_remodel/signup.svg"
                />
                회원가입
              </div>
            </li>
          </>
        )}

        <li className="flex justify-center">
          <div
            className="flex flex-col gap-y-[10px] items-center justify-center cursor-pointer hover:text-main-color-primary"
            onClick={() => {
              if (pathname !== "/qna") {
                func.setMenuHide(false);
                func.setSearchHide(false);
                navigate("/qna?questions", {
                  state: { previousPath: pathname, queryStringPath: search },
                });
              }
            }}
          >
            <img className="w-[30px] h-[30px]" src="/icon_remodel/talk.svg" />
            고객센터
          </div>
        </li>
      </ul>
      <div
        className="w-[20px] h-[20px] flex absolute right-[30px] bottom-[18px] cursor-pointer"
        onClick={() => func.setMenuHide(false)}
      >
        <img src="/icon_remodel/close.svg" />
      </div>
    </div>
  );
};
