import { IplantList } from "../../../../pages/Main/types/plantList.type";
import {
  SelectedLocalType,
  SelectedCapacityType,
} from "../../../../pages/Mypage/types/state.type";
import MainPageNation from "../../../UI/Paging/MainPageNation";
import { MypageMyPlantSearch } from "./MypageMyPlantSearch";
import { MypageMyPlantSearchList } from "./MypageMyPlantSearchList";

interface Istate {
  pageNumber: number;
  searchText: string;
  localCurrent: SelectedLocalType;
  capacityCurrent: SelectedCapacityType;
}

interface Iitem {
  plantList: IplantList[] | null;
  plantListTotal: number;
}

interface IapiResponse {
  loading: boolean;
}

interface Ifunc {
  setSearchText: (text: string) => void;
  searchPlant: (search: string) => void;
  pageChangeHandler: (page: number) => void;
  setLocalCurrent: (local: SelectedLocalType) => void;
  setCapacityCurrent: (capacity: SelectedCapacityType) => void;
}

export const MypageMyPlant = ({
  state,
  item,
  func,
  apiResponse,
}: {
  state: Istate;
  item: Iitem;
  func: Ifunc;
  apiResponse: IapiResponse;
}) => {
  return (
    <section>
      <div className="flex items-center mb-4">
        <h1 className="text-listFirst font-bold max-sm:font-medium">
          내 발전소 찾기
        </h1>
      </div>
      <MypageMyPlantSearch
        state={{
          searchText: state.searchText,
          localCurrent: state.localCurrent,
          capacityCurrent: state.capacityCurrent,
        }}
        func={{
          searchPlant: func.searchPlant,
          setSearchText: func.setSearchText,
          setLocalCurrent: func.setLocalCurrent,
          setCapacityCurrent: func.setCapacityCurrent,
        }}
      />

      <MypageMyPlantSearchList
        item={{
          plantList: item.plantList,
          plantListTotal: item.plantListTotal,
        }}
        apiResponse={{ loading: apiResponse.loading }}
      />

      {!apiResponse.loading && !!item.plantList && !!item.plantList.length && (
        <div className="max-w-[800px] w-full">
          <MainPageNation
            state={{
              total: item.plantListTotal,
              current: state.pageNumber,
            }}
            func={{
              pageChangeHandler: func.pageChangeHandler,
            }}
          />
        </div>
      )}
    </section>
  );
};
