import { annualPowerGenerationUnit } from "../../../utils_public/Unit";

interface IdetailEnergeMain {
  capacity: number | string;
  local: string;
  hourDaily: number;
}

export const DetailEnergeMain = ({
  capacity,
  local,
  hourDaily,
}: IdetailEnergeMain) => {
  return (
    <div>
      <h1 className="text-subTitle font-bold mb-8 max-sm:font-medium max-sm:text-subValue">
        이 발전소에서 생산하는 에너지
      </h1>
      <div className="w-[95%] flex">
        <div>
          <h2 className="text-subTitle text-main-color-primary mb-[6px]">
            {annualPowerGenerationUnit(hourDaily, capacity)}
          </h2>
          <span className="text-listSecond">연간 발전량</span>
        </div>
      </div>
    </div>
  );
};
