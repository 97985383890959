import { AxiosError } from "axios";
import { useMutation } from "react-query";
import { axiosSendTalking } from "../api/axiosPostRequest";

interface errorData {
  code: string;
  message: string;
}

export const useSendTalkingQuery = () =>
  useMutation(axiosSendTalking, {
    onError: (err: AxiosError<errorData>, val) => {
      console.error(`from ${val.apiName} ERROR`, err);
    },
  });
