import { useCookies } from "react-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import { useEarlyFavorite } from "../hooks/useFavorite";
import { DetailInformationButton } from "../../../components/Detail/Button/DetailInformationButton";
import {
  DetailClipBoardToast,
  DetailFavoriteToast,
} from "../../../components/Detail/Toast/DetailToast";
import {
  useClipBoardToastTimeOut,
  useFavoriteToastTimeOut,
} from "../hooks/useToastTimeOut";
import { ModalConfirmation } from "../../../components/UI/Modal/ModalConfirmation";

type ImodalSellBuy = {
  open: boolean;
  type: string;
  plantId: number;
};

interface Ifunc {
  setModalTalkChoice: (bool: boolean) => void;
  setModalConfirmation: (bool: boolean) => void;
  setModalSellBuy: (value: ImodalSellBuy) => void;
  setFavoriteIcon: (bool: boolean) => void;
  setFavoriteToast: (bool: boolean) => void;
  setClipBoardToast: (bool: boolean) => void;
  setZindex: (bool: boolean) => void;
  likeSubmitFromServer: () => void;
}

interface Istate {
  zIndex: boolean;
  favoriteIcon: boolean;
  favoriteToast: boolean;
  clipBoardToast: boolean;
  modalConfirmation: boolean;
}

interface Iitem {
  favoriteCount: number;
}

interface IapiResponse {
  favoriteResponse: boolean;
  currentFavoriteStatus: string;
}

export const DetailInformationButtonModify = ({
  state,
  item,
  func,
  apiResponse,
}: {
  state: Istate;
  item: Iitem;
  func: Ifunc;
  apiResponse: IapiResponse;
}) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [cookie] = useCookies(["60hz-user"]);

  // 찜하기 조회(get) 응답 결과 처리 hooks
  const currentFavorite = useEarlyFavorite(
    {
      status: apiResponse.currentFavoriteStatus,
      favoriteResponse: apiResponse.favoriteResponse,
    },
    func.setFavoriteIcon,
  );

  // 비로그인 접근 방지 함수
  const preventNotLogin = () => {
    return window.confirm(
      "로그인이 필요합니다. 로그인 페이지로 이동하겠습니까?",
    )
      ? navigate("/login", {
          state: { previousPath: pathname, queryStringPath: search },
        })
      : null;
  };

  // 찜하기 아이콘 클릭
  const clickFavoriteIcon = () => {
    if (!cookie["60hz-user"]) return preventNotLogin();
    // 좋아요 누른 최대 갯수(16개) 이상일 경우 차단 및 초과 상태 모달 On
    if (item.favoriteCount >= 16) {
      return func.setModalConfirmation(true);
    }

    func.setFavoriteToast(true);
    func.setFavoriteIcon(!state.favoriteIcon);
    func.likeSubmitFromServer();
  };

  // 토스트 UI On/Off Hooks
  const clipBoardToastTimeOut = useClipBoardToastTimeOut(
    state.clipBoardToast,
    func.setClipBoardToast,
  );

  const favoriteToastTimeOut = useFavoriteToastTimeOut(
    {
      favoriteToast: state.favoriteToast,
      clipBoardToast: state.clipBoardToast,
    },
    {
      setZindex: func.setZindex,
      setFavoriteToast: func.setFavoriteToast,
    },
  );

  return (
    <>
      <DetailInformationButton
        state={{
          favoriteIcon: state.favoriteIcon,
        }}
        func={{
          setModalTalkChoice: func.setModalTalkChoice,
          setClipBoardToast: func.setClipBoardToast,
          clickFavoriteIcon: clickFavoriteIcon,
          setModalSellBuy: func.setModalSellBuy,
        }}
      />
      {/* ModalConfirmation */}

      {state.modalConfirmation && (
        <ModalConfirmation
          contentsTop={`찜한 발전소 제한 개수(16개)가`}
          contentsBottom={`초과되었습니다.`}
          setModal={func.setModalConfirmation}
        />
      )}

      <DetailClipBoardToast clipBoardToast={state.clipBoardToast} />
      <DetailFavoriteToast
        state={{
          zIndex: state.zIndex,
          favoriteToast: state.favoriteToast,
          favoriteIcon: state.favoriteIcon,
        }}
      />
    </>
  );
};
