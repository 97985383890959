import axios from "axios";

export const axiosPlantRoom = async ({ queryKey }: any) => {
  const response = await axios.get(
    process.env.REACT_APP_SERVER_URL + queryKey[1],
    {
      withCredentials: true,
      headers: {
        Authorization: " Token " + queryKey[3],
      },
    },
  );

  return response.data;
};

export const axiosUserMessageRecord = async ({ queryKey }: any) => {
  const response = await axios.get(
    process.env.REACT_APP_SERVER_URL + queryKey[1],
    {
      withCredentials: true,
      headers: {
        Authorization: " Token " + queryKey[2],
      },
    },
  );

  return response.data;
};

export const axiosUserMessageList = async ({ queryKey }: any) => {
  const response = await axios.get(
    process.env.REACT_APP_SERVER_URL + queryKey[1],
    {
      withCredentials: true,
      headers: {
        Authorization: " Token " + queryKey[2],
      },
    },
  );

  return response.data;
};
