interface Ifunc {
  setModalTalkChoice: (bool: boolean) => void;
}

export const DetailInformationSupport = ({ func }: { func: Ifunc }) => {
  return (
    <div>
      <button
        className="border-[2px] border-main-color-primary text-main-color-primary rounded-xl px-[13px] py-[5px] duration-300 hover:bg-main-color-primary hover:text-general-white"
        onClick={() => {
          func.setModalTalkChoice(true);
        }}
      >
        메세지 보내기
      </button>
    </div>
  );
};
