interface signature {
  [key: string | number]: string | object;
}

export const PLANT_LIST_FILTER__ERROR_TYPE: signature = {
  400: "잘못된 정보를 검색하셨습니다.",
  401: "잘못된 인증 정보입니다.",
  404: "잘못된 주소를 등록하셨습니다.",
};

export const SUBMIT_HOPE_ERROR_TYPE: signature = {
  400: "잘못된 정보를 작성하셨습니다.",
  401: "잘못된 인증 정보입니다.",
  404: "잘못된 주소를 등록하셨습니다.",
};
