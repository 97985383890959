export const DetailClipBoardToast = ({
  clipBoardToast,
}: {
  clipBoardToast: boolean;
}) => {
  return (
    <div
      className={`w-full felx justify-center fixed ${
        clipBoardToast
          ? "bottom-[5%] max-md:top-[20px]"
          : "bottom-[-150px] max-md:top-[-150px]"
      } transition-all duration-700 ease-in-out z-[170] max-md:bottom-auto`}
    >
      <div className="flex min-h-full justify-center items-center">
        <div
          className="w-[320px] h-[100px] flex justify-center items-center bg-general-white
            rounded-lg border-main-color-primary border-[1px] max-md:w-[500px] max-md:h-[45px] max-md:justify-start max-md:pl-[30px]"
        >
          <img className="mr-2" src="/icon_remodel/clip.svg" />
          <span>링크 복사 완료</span>
        </div>
      </div>
    </div>
  );
};

interface IstateFavorite {
  zIndex: boolean;
  favoriteToast: boolean;
  favoriteIcon: boolean;
}

export const DetailFavoriteToast = ({ state }: { state: IstateFavorite }) => {
  return (
    <div
      className={`w-full felx justify-center fixed ${
        state.favoriteToast
          ? "bottom-[5%] max-md:top-[20px]"
          : "bottom-[-150px] max-md:top-[-150px]"
      } transition-all duration-700 ease-in-out ${
        state.zIndex ? "z-[200]" : "z-[150]"
      } max-md:bottom-auto`}
    >
      <div className="flex min-h-full justify-center items-center">
        <div
          className="w-[320px] h-[100px] flex justify-center items-center bg-general-white 
            rounded-md border-main-color-primary border-[1px] max-md:w-[500px] max-md:h-[45px] max-md:justify-start max-md:pl-[30px]"
        >
          <img
            className="mr-2 w-[20px] h-[18px]"
            src={
              state.favoriteIcon
                ? "/icon_remodel/heart_full.svg"
                : "/icon_remodel/heart.svg"
            }
          />
          <span>{state.favoriteIcon ? "찜하기 완료" : "찜하기 취소"}</span>
        </div>
      </div>
    </div>
  );
};
