import { useState } from "react";
import { QnAInquiry } from "../../../components/QnA/QnAInquiry";

interface Istate {
  inquiryText: string;
  selectInquiryList: string;
}

interface Ifunc {
  setInquiryText: (text: string) => void;
  setSelectInquiryList: (text: string) => void;
  submitInquiry: () => void;
}

interface IapiResponse {
  success: boolean;
  loading: boolean;
  axiosCode: string;
  error: boolean;
  errorStatus: number;
}

export const QnAInquiryModify = ({
  state,
  func,
  apiResponse,
}: {
  state: Istate;
  func: Ifunc;
  apiResponse: IapiResponse;
}) => {
  const [toggle, setToggle] = useState(false);

  const clickInquiryListItem = (selected: string) => {
    func.setSelectInquiryList(selected);
    setToggle(false);
  };

  return (
    <QnAInquiry
      state={{
        toggle: toggle,
        inquiryText: state.inquiryText,
        selectInquiryList: state.selectInquiryList,
      }}
      func={{
        setToggle: setToggle,
        setSelectInquiryList: func.setSelectInquiryList,
        setInquiryText: func.setInquiryText,
        clickInquiryListItem: clickInquiryListItem,
        submitInquiry: func.submitInquiry,
      }}
      apiResponse={{
        ...apiResponse,
      }}
    />
  );
};
