import { atom } from "recoil";

export const passwordLength = atom({
  key: "passwordLength",
  default: false,
});

export const passwordNumber = atom({
  key: "passwordNumber",
  default: false,
});

export const passwordSpecial = atom({
  key: "passwordSpecial",
  default: false,
});

export const passwordSame = atom({
  key: "passwordSame",
  default: false,
});
