import { useQuery } from "react-query";
import { axiosSMP } from "../api/axiosGetRequest";

interface IuseSMPQuery {
  apiName: string;
}

export const useSMPQuery = (url: string, info: IuseSMPQuery) =>
  useQuery(["SMPFromDetail", url], axiosSMP, {
    onError: (err) => {
      console.error(`from ${info.apiName} ERROR`, err);
    },
  });
