import { useEffect, useState } from "react";

interface Ifunc {
  setFavorite: (bool: boolean) => void;
  setModal: (bool: boolean) => void;
}

export const useFavorite = (is_like: boolean) => {
  const [favorite, setFavorite] = useState(false);

  useEffect(() => {
    return is_like ? setFavorite(true) : setFavorite(false);
  }, [is_like]);

  return { favorite: favorite, setFavorite: setFavorite };
};

export const useFavoriteError = (func: Ifunc, status?: number | null) => {
  if (!status) return;
  useEffect(() => {
    if (status === 403) {
      func.setFavorite(false);
      func.setModal(true);
    }
  }, [status]);
};
