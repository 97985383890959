import { capacityUnit } from "../../../utils_public/Unit";
import { ItalkModalItem } from "../../../pages/Detail/types/item.type";

export const DetailModalInformation = ({ item }: { item: ItalkModalItem }) => {
  return (
    <article className="my-[24px]">
      <h1 className="mb-[6px] font-bold max-sm:text-default">
        {item.plantName}
      </h1>
      <div className="flex text-footer">
        <span>
          {item.addr_lv1 + " " + item.addr_lv2 + " " + item.addr_lv3 + " "}
        </span>
        <span className="px-4">|</span>
        <span>용량 {capacityUnit(item.capacity)}</span>
        {item.landName !== null && (
          <>
            <span className="px-4">|</span>
            <span>{item.landName}</span>
          </>
        )}
      </div>
    </article>
  );
};
