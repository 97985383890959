interface signature {
  [key: string | number]: string | object;
}

export const SUBMIT_TALK_ERROR_TYPE: signature = {
  400: {
    MSC001: "자기 자신에게 보낼 수 없습니다.",
    MSC002: "텍스트 형식이 올바르지 않습니다.",
  },
  401: "잘못된 인증 정보입니다.",
  404: "잘못된 주소를 요청하셨습니다.",
};
