import { DetailEnergeMarket } from "../../../components/Detail/Energe/DetailEnergeMarket";
import { useGetSMP } from "../hooks/useGetSMP";
import { useGetREC } from "../hooks/useGetREC";
import { ISMP, IREC } from "../types/RECorSMP.type";

export const DetailEnergeMarketFetch = () => {
  // React-Query 매일 업데이트된 SMP 요청
  const smpData: ISMP[] = useGetSMP();

  // React-Query 화,목 업데이트된 SMP 요청
  const recData: IREC[] = useGetREC();

  return <DetailEnergeMarket smpData={smpData} recData={recData} />;
};
