import {
  Container as MapDiv,
  NaverMap,
  Marker,
  useNavermaps,
} from "react-naver-maps";
import { DetailVariousMapSelect } from "./DetailVariousMapSelect";

import { SubmitLoading } from "../../UI/Loading/Loading";
import { ReactElement } from "react";

interface signature {
  [key: string]: ReactElement<HTMLDivElement> | ReactElement;
}

interface Istate {
  latitude: number;
  longitude: number;
  curretNaverValue: string;
  notFound: boolean;
  typeNaverMap: string;
}

interface IapiResponse {
  status: string;
}

interface Ifunc {
  changeNaverMap: (currentMap: string) => void;
}

export const DetailVariousMapNaver = ({
  state,
  apiResponse,
  func,
}: {
  state: Istate;
  apiResponse: IapiResponse;
  func: Ifunc;
}) => {
  // 네이버 맵 상태 처리
  const NAVER_MAP_STATUS: signature = {
    success: NaverMapBox(state.latitude, state.longitude, state.typeNaverMap),
    loading: (
      <div className="h-full flex justify-center items-center">
        <SubmitLoading width={100} height={100} />
      </div>
    ),
    error: (
      <div className="h-full flex justify-center items-center">
        <span>서버 에러가 발생하였습니다.</span>
      </div>
    ),
  };

  return (
    <div className="relative">
      <MapDiv
        className="h-[338px] absolute mb-2 rounded-xl"
        innerStyle={{
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          position: "absolute",
          zIndex: state.curretNaverValue !== "pano" ? 20 : 0,
          borderRadius: "0.75rem",
        }}
      >
        {NAVER_MAP_STATUS[apiResponse.status]}
      </MapDiv>

      <div
        id="pano"
        style={{
          position: "absolute",
          width: `${state.curretNaverValue === "pano" ? "100%" : "0px"}`,
        }}
        className="h-[338px] absolute top-0 rounded-xl"
      />
      {state.notFound && (
        <div className="w-full h-[338px] flex items-center justify-center absolute top-0 bg-main-color-sub z-10 rounded-xl">
          <p className="text-listTitle ">해당 로드뷰는 촬영 기록이 없습니다.</p>
        </div>
      )}

      <div className="flex justify-between items-center">
        <p className="text-footer text-text-color-secondary">
          출처: 네이버지도, 최근 업데이트: 2023
        </p>
        <DetailVariousMapSelect
          changeNaverMap={func.changeNaverMap}
          curretNaverValue={state.curretNaverValue}
        />
      </div>
    </div>
  );
};

const NaverMapBox = (
  latitude: number,
  longitude: number,
  typeNaverMap: string,
) => {
  const navermaps = useNavermaps();

  return (
    <NaverMap
      defaultCenter={new navermaps.LatLng(latitude, longitude)}
      defaultZoom={17}
      mapTypeId={typeNaverMap}
    >
      <Marker
        defaultPosition={{
          lat: latitude,
          lng: longitude,
        }}
      />
    </NaverMap>
  );
};
