import { useEffect } from "react";
import {
  IplantTotalInfo,
  Iinverter,
  Imodule,
} from "../types/plantTotalInfo.type";

import { Ifile, IimageFile, IthumbnailList } from "../types/state.type";

interface Ifunc {
  setInverter: (inverter: Iinverter) => void;
  setModule: (module: Imodule) => void;
  setRpsFile: (file: Ifile) => void;
  setEnergeFile: (file: Ifile) => void;
  setImageFile: (file: IimageFile) => void;
  setHaveRpsFile: (bool: boolean) => void;
  setHaveEnergeFile: (bool: boolean) => void;
  setThumbnail: (image: IthumbnailList) => void;
}

export const useSavePlantTotalInfo = (
  status: string,
  plantTotalInfo: IplantTotalInfo,
  func: Ifunc,
) => {
  useEffect(() => {
    if (status === "success") {
      func.setInverter({
        inverterManufactureCompany: plantTotalInfo.inverterManufactureCompany,
        inverterName: plantTotalInfo.inverterName,
        inverterCapacity: plantTotalInfo.inverterCapacity,
        inverterNums: plantTotalInfo.inverterNums,
        change: false,
      });
      func.setModule({
        moduleManufactureCompany: plantTotalInfo.moduleManufactureCompany,
        moduleName: plantTotalInfo.moduleName,
        moduleCapacity: plantTotalInfo.moduleCapacity,
        moduleNums: plantTotalInfo.moduleNums,
        change: false,
      });
      func.setRpsFile({
        name: plantTotalInfo.rpsPowerGenImageName,
        file: plantTotalInfo.rpsPowerGenImage,
        change: false,
      });
      func.setEnergeFile({
        name: plantTotalInfo.renewableCertificateImageName,
        file: plantTotalInfo.renewableCertificateImage,
        change: false,
      });

      if (
        plantTotalInfo.rpsPowerGenImageName &&
        plantTotalInfo.rpsPowerGenImage
      ) {
        func.setHaveRpsFile(true);
      }

      if (
        plantTotalInfo.renewableCertificateImageName &&
        plantTotalInfo.renewableCertificateImage
      ) {
        func.setHaveEnergeFile(true);
      }

      if (
        plantTotalInfo.images.length > 0 &&
        plantTotalInfo.imagesConverter !== null
      ) {
        func.setImageFile({
          image: plantTotalInfo.imagesConverter,
          change: false,
        });
        func.setThumbnail({ image: [...plantTotalInfo.images], default: true });
      }
    }
  }, [status]);
};
