import { HeaderMenu } from "./HeaderMenu";
import { HeaderLogo } from "./HeaderLogo";
import { HeaderSearch } from "./HeaderSearch";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { HeaderMobileMenu } from "./HeaderMobileMenu";
import { HeaderMobileSearch } from "./HeaderMobileSearch";

interface Istate {
  searchText: string;
}

interface Ifunc {
  searchPlant: (search: string) => void;
  selectLogout: () => void;
  setSearchText: (value: string) => void;
}

interface Ioptions {
  cookie: string;
}

export const HeaderPresenter = ({
  state,
  func,
  options,
}: {
  state: Istate;
  func: Ifunc;
  options: Ioptions;
}) => {
  const { pathname } = useLocation();
  const currentPath = pathname.split("/")[1];

  const [menuHide, setMenuHide] = useState(false);
  const [searchHide, setSearchHide] = useState(false);

  return (
    <header className="w-full fixed z-[3] bg-general-white top-0 max-md:bottom-0 max-md:top-auto max-md:border-t-[1px] max-md:border-t-border-color-dividers">
      <div className="max-w-[1630px] h-[56px] flex mx-auto justify-between items-center px-[50px] max-md:px-[24px] max-sm:px-[16px]">
        <HeaderLogo setSearchHide={setSearchHide} />
        {currentPath !== "privacy" && (
          <HeaderSearch
            state={{
              searchText: state.searchText,
            }}
            func={{
              setSearchText: func.setSearchText,
              searchPlant: func.searchPlant,
            }}
          />
        )}

        <HeaderMenu
          options={{ cookie: options.cookie }}
          func={{
            selectLogout: func.selectLogout,
          }}
        />

        {searchHide && (
          <HeaderMobileSearch
            state={{
              searchText: state.searchText,
            }}
            func={{
              setSearchText: func.setSearchText,
              searchPlant: func.searchPlant,
              setSearchHide: setSearchHide,
            }}
          />
        )}

        <div className="h-[45px] flex items-center gap-x-[30px] md:hidden">
          <div
            className="h-full flex flex-col items-center justify-between cursor-pointer"
            onClick={() => setSearchHide(true)}
          >
            <img className="w-[21px] h-[25px]" src="/icon_remodel/search.svg" />
            <h1 className="text-default">검색</h1>
          </div>
          <div
            className="h-full flex flex-col items-center justify-between cursor-pointer"
            onClick={() => setMenuHide(!menuHide)}
          >
            <img className="w-[25px]" src="/icon_remodel/menu.svg" />
            <h1 className="text-default">메뉴</h1>
          </div>
        </div>
      </div>

      <HeaderMobileMenu
        state={{ menuHide: menuHide }}
        func={{
          setMenuHide: setMenuHide,
          selectLogout: func.selectLogout,
          setSearchHide: setSearchHide,
        }}
      />
    </header>
  );
};
