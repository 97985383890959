import { useState } from "react";
import { MypageRegistrationModify } from "../module_modify/MypageRegistration.modify";
import { Ifile, IplantInputInfo } from "../types/state.type";

export const MypageRegistrationFetch = () => {
  const [plantInputInfo, setPlantInputInfo] = useState<IplantInputInfo>({
    plantName: "",
    address: "",
    completionYear: "",
    landCategory: "",
    capacity: "",
    capacityType: "kW",
    startConstruction: "",
  });

  const [businessFile, setBusinessFile] = useState<Ifile>({
    name: "",
    file: null,
    change: false,
  });

  const [actionFile, setActionFile] = useState<Ifile>({
    name: "",
    file: null,
    change: false,
  });

  return (
    <MypageRegistrationModify
      state={{
        plantInputInfo: plantInputInfo,
        businessFile: businessFile,
        actionFile: actionFile,
      }}
      func={{
        setPlantInputInfo: setPlantInputInfo,
        setBusinessFile: setBusinessFile,
        setActionFile: setActionFile,
      }}
    />
  );
};
