import {
  passwordLength,
  passwordNumber,
  passwordSpecial,
  passwordSame,
} from "../atoms/validation.atoms";
import { useRecoilValue } from "recoil";

type IndexSignature = {
  [key: string]: boolean;
};

export const passwordStateCheck = (type: string) => {
  const cur_length = useRecoilValue(passwordLength);
  const cur_number = useRecoilValue(passwordNumber);
  const cur_special = useRecoilValue(passwordSpecial);
  const cur_same = useRecoilValue(passwordSame);

  const CURRENT_PASSWORD_VALIDATION: IndexSignature = {
    length: cur_length,
    number: cur_number,
    special: cur_special,
    same: cur_same,
  };

  return CURRENT_PASSWORD_VALIDATION[type];
};
