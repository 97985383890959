import { ImodalPlantList } from "../../../pages/Mypage/types/state.type";
import { IregistrationList } from "../../../pages/Mypage/types/item.type";
import { capacityUnit } from "../../../utils_public/Unit";
import { useBodyScrollLock } from "../../../utils_public/useBodyScrollLock";
import { DefaultLoading } from "../Loading/Loading";
import { useNavigate } from "react-router-dom";
import { nullToEmptyString } from "../../../utils_public/nullToEmptyString";

export const ModalRecordBuySell = ({
  modalPlantList,
  setModalPlantList,
  registrationList,
  isSuccess,
  isLoading,
}: {
  modalPlantList: ImodalPlantList;
  setModalPlantList: (plantList: ImodalPlantList) => void;
  registrationList: IregistrationList[];
  isSuccess: boolean;
  isLoading: boolean;
}) => {
  useBodyScrollLock();
  const navigate = useNavigate();
  return (
    <div className="relative z-[999]">
      <div className="fixed inset-0 bg-[#272727] bg-opacity-40 transition-opacity"></div>
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full justify-center items-center">
          <div
            className="w-[550px] h-[300px] flex flex-col pt-[30px] relative bg-general-white p-8
          rounded-2xl border-main-color-primary border-[1px] drop-shadow-[2px_2px_4px_rgba(0,0,0,0.25)] max-md:w-[450px] max-sm:w-[340px] max-sm:p-5"
          >
            <div
              className="absolute right-[25px] top-[15px] cursor-pointer"
              onClick={() => setModalPlantList(INITIAL_MODAL_PLANT_MAP)}
            >
              <img src="/icon_remodel/close.svg" />
            </div>
            <div className="flex text-listTitle items-center justify-center mb-[36px]">
              <h1 className=" text-main-color-primary font-bold max-sm:font-medium">
                {modalPlantList.username}
              </h1>
              <p className="text-listFirst">
                님이 {modalPlantList.type === "seller" ? "판매" : "구매"} 등록한
                발전소 리스트
              </p>
            </div>

            <div className="max-w-full w-full flex border-b-[1px] border-b-border-color-dividers pb-[10px] px-[12px]">
              <div className="max-w-[180px] w-full">발전소명</div>
              <div className="max-w-[180px] w-full">주소</div>
              <div className="max-w-[100px] w-full ">용량</div>
            </div>

            {isLoading && (
              <div className="w-[70px] mx-auto my-[20px]">
                <DefaultLoading />
              </div>
            )}

            {!isLoading && !!registrationList.length && (
              <ul className="h-[90px] mb-[20px] overflow-y-scroll">
                {registrationList.map((el, idx) => (
                  <li key={idx} className="flex px-[12px] py-[8px] ">
                    <div className="max-w-[180px] w-full">
                      <span
                        className="border-b-[1px] border-b-main-color-primary text-main-color-primary cursor-pointer hover:border-b-[#b1159f] hover:text-[#b1159f]"
                        onClick={() => navigate(`/detail/${el.plant_id}`)}
                      >
                        {el.plant_name}
                      </span>
                    </div>
                    <div className="max-w-[180px] w-full">
                      {`${nullToEmptyString(el.addr_lv1)} 
                      ${nullToEmptyString(el.addr_lv2)} 
                      ${nullToEmptyString(el.addr_lv3)}`}
                    </div>
                    <div className="max-w-[80px] w-full ">
                      {capacityUnit(el.capacity)}
                    </div>
                  </li>
                ))}
              </ul>
            )}

            {!isLoading && !registrationList.length && (
              <div className="h-[90px] mb-[20px] flex justify-center items-center">
                등록된 이력이 없습니다.
              </div>
            )}

            <div className="w-full flex justify-end">
              <button
                className="w-[72px] h-[36px] text-center bg-main-color-primary text-general-white rounded-lg"
                onClick={() => setModalPlantList(INITIAL_MODAL_PLANT_MAP)}
              >
                닫기
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const INITIAL_MODAL_PLANT_MAP = {
  open: false,
  type: "seller",
  userId: 0,
  username: "",
};
