import realCapacity from "../../../data/capacity_price.json";
import expectedCapacity from "../../../data/capacity_expected_price.json";
import squarePrice from "../../../data/square_price.json";

export function Plant_capacity_obj(value) {
  const capacity = {
    0: "1MW~", // 1M 초과
    1: "500kW~1MW", // 500KW 초과
    2: "200~500kW", // 200KW 초과
    3: "150~200kW", // 150KW 초과
    4: "100~150kW", // 100KW 초과
    5: "50~100kW", // 50KW 초과
    6: "20~50kW", // 20KW 초과
    7: "10~20kW", // 10KW 초과
    8: "0~10kW", // 나머지
  };

  const capacity_arr = [1, 0.5, 0.2, 0.15, 0.1, 0.05, 0.02, 0.01, 0];

  // 발전소가 0이면 반복문 과정 없이 그냥 바로 처리
  if (value === 0) {
    return "0~10KW";
  }

  let num = 0;

  for (let i = 0; i < capacity_arr.length; i++) {
    if (capacity_arr[i] <= value) {
      num = capacity[i];
      break;
    }
  }

  return num;
}

export function Plant_Capacity(db, range) {
  // 맨 아래 그래프
  // 용량별 지역 실거래가
  db.localInfo = { local: "", max: 0, min: 0, avg: 0 };
  // 전국 실거래가
  db.allInfo = { local: "", max: 0, min: 0, avg: 0 };
  // 지역 예상매매가
  db.expected_allInfo = { local: "", max: 0, min: 0, avg: 0 };
  // 전국 예상매매가
  db.expected_localInfo = { local: "", max: 0, min: 0, avg: 0 };

  let local = realCapacity.find((el) => {
    return el.지역 === db.priceInfo.지역 ? el.지역 : null;
  });

  db.localInfo.local = local.지역;
  db.allInfo.local = realCapacity[0].지역;

  let max = realCapacity.find((el) => {
    return el.지역 === db.priceInfo.지역 && el.기준 === "최고" ? el : null;
  });

  db.localInfo.max = max[range];
  db.allInfo.max = realCapacity[1][range];

  let min = realCapacity.find((el) => {
    return el.지역 === db.priceInfo.지역 && el.기준 === "최저" ? el : null;
  });

  db.localInfo.min = min[range];
  db.allInfo.min = realCapacity[0][range];

  let avg = realCapacity.find((el) => {
    return el.지역 === db.priceInfo.지역 && el.기준 === "평균" ? el : null;
  });

  db.localInfo.avg = avg[range];
  db.allInfo.avg = realCapacity[2][range];

  // 예상 매매가
  expectedCapacity.find((el) => {
    return el.지역 === "전국" && range === el.용량구간
      ? ((db.expected_allInfo.local = el.지역),
        (db.expected_allInfo.max = el.최고가),
        (db.expected_allInfo.min = el.최저가),
        (db.expected_allInfo.avg = el.평균가))
      : null;
  });

  expectedCapacity.find((el) => {
    return el.지역 === db.priceInfo.지역 && range === el.용량구간
      ? ((db.expected_localInfo.local = el.지역),
        (db.expected_localInfo.max = el.최고가),
        (db.expected_localInfo.min = el.최저가),
        (db.expected_localInfo.avg = el.평균가))
      : null;
  });
}

export function Plant_square(value) {
  if (!value?.addr_lv1) return "-";
  const addr_lv1 = value.addr_lv1;
  return squarePrice[addr_lv1];
}

export function Plant_square_price(square, price) {
  return (square * price * 0.00000001).toFixed(2);
}

export function Plant_land(land) {
  const land_arr = ["전", "답", "과수원"];
  return land_arr.filter((el) => el === land);
}

export function Plant_Total_Price(thisprice, tax) {
  return thisprice + tax;
}

export function Plant_land_percent(type, year) {
  if (!type) {
    return "4.6%";
  } else {
    if (year) return "1.6%";
    return "3.4%";
  }
}

export function farm_caculate(value) {
  return Math.floor(0.034 * value);
}

export function farm_caculate_more(value) {
  return Math.floor(0.016 * value);
}

export function etc_caculate(value) {
  return Math.floor(0.046 * value);
}

export const power_duration = [
  { area: "강원", time: (3.37687535).toFixed(1) },
  { area: "경기", time: (3.085395507).toFixed(1) },
  { area: "경남", time: (3.491137299).toFixed(1) },
  { area: "경북", time: (3.476645729).toFixed(1) },
  { area: "광주", time: (3.335761855).toFixed(1) },
  { area: "대구", time: (3.421224822).toFixed(1) },
  { area: "대전", time: (3.095963088).toFixed(1) },
  { area: "부산", time: (3.589807994).toFixed(1) },
  { area: "서울", time: (3.007746999).toFixed(1) },
  { area: "세종", time: (3.306932787).toFixed(1) },
  { area: "울산", time: (3.859730816).toFixed(1) },
  { area: "인천", time: (3.456852055).toFixed(1) },
  { area: "전남", time: (3.5420757).toFixed(1) },
  { area: "전북", time: (3.365522483).toFixed(1) },
  { area: "제주", time: (3.208464537).toFixed(1) },
  { area: "충남", time: (3.479890814).toFixed(1) },
  { area: "충북", time: (3.2969197).toFixed(1) },
];
