import { DetailPresnter } from "./Detail.presenter";
import { IplantInfo } from "./types/plantInfo.type";
import { Iinquiry } from "./types/state.type";
import { IsellerList, IactualPriceList } from "./types/item.type";
// hooks
import { useState } from "react";

interface Iitem {
  plantInfo: IplantInfo;
  favoriteCount: number;
  sellerList: IsellerList[];
  actualPriceList: IactualPriceList[];
}

interface IapiResponse {
  plantLoading: string;
  favoriteCountLoading: string;
  sellerListLoading: string;
}

export const DetailModify = ({
  item,
  apiResponse,
}: {
  item: Iitem;
  apiResponse: IapiResponse;
}) => {
  return (
    <DetailPresnter
      item={{
        plantInfo: item.plantInfo,
        favoriteCount: item.favoriteCount,
        sellerList: item.sellerList,
        actualPriceList: item.actualPriceList,
      }}
      apiResponse={{
        plantLoading: apiResponse.plantLoading,
        favoriteCountLoading: apiResponse.favoriteCountLoading,
        sellerListLoading: apiResponse.sellerListLoading,
      }}
    />
  );
};
