import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CAPACITY_LIST,
  LOCAL_LIST,
} from "../../../../pages/Mypage/constants/myPlant.constant";
import {
  SelectedLocalType,
  SelectedCapacityType,
} from "../../../../pages/Mypage/types/state.type";
import { MypageMyPlantSearchDropDownBox } from "./MypageMyPlantSearchDropDownBox";

interface Ifunc {
  searchPlant: (search: string) => void;
  setLocalCurrent: (local: SelectedLocalType) => void;
  setCapacityCurrent: (capacity: SelectedCapacityType) => void;
  setSearchText: (value: string) => void;
}

interface Istate {
  searchText: string;
  localCurrent: SelectedLocalType;
  capacityCurrent: SelectedCapacityType;
}

export const MypageMyPlantSearch = ({
  state,
  func,
}: {
  state: Istate;
  func: Ifunc;
}) => {
  const navigate = useNavigate();
  const [isLocalDropdown, setIsLocalDropdown] = useState(false);
  const [isCapacityDropdown, setIsCapacityDropdown] = useState(false);

  return (
    <main className="max-w-full w-full mb-[80px]">
      <div className="mb-[28px]">
        <h1>발전소 이름 혹은 주소를 검색해주세요.</h1>
      </div>
      <div className="flex gap-x-[40px] mb-[24px]">
        <MypageMyPlantSearchDropDownBox
          isDropdown={isLocalDropdown}
          setIsDropdown={setIsLocalDropdown}
          currentDropdownValue={state.localCurrent}
          setCurrentDropdownValue={(value: string) =>
            func.setLocalCurrent(value as SelectedLocalType)
          }
          dropDownValueList={LOCAL_LIST}
        />
        <MypageMyPlantSearchDropDownBox
          isDropdown={isCapacityDropdown}
          setIsDropdown={setIsCapacityDropdown}
          currentDropdownValue={state.capacityCurrent}
          setCurrentDropdownValue={(value: string) =>
            func.setCapacityCurrent(value as SelectedCapacityType)
          }
          dropDownValueList={CAPACITY_LIST}
        />
      </div>
      <input
        className="max-w-[800px] w-full border-[1px] border-border-color-dividers p-[8px] outline-none"
        placeholder="발전소 검색"
        type={"text"}
        value={state.searchText}
        onChange={(value: React.ChangeEvent<HTMLInputElement>) =>
          func.setSearchText(value.target.value)
        }
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            state.searchText !== ""
              ? func.searchPlant(state.searchText)
              : alert("검색 입력창이 공백 상태입니다.");
          }
        }}
      />
      <div
        className="w-[370px] h-[80px] flex flex-col justify-center px-[12px] gap-y-[4px] mt-[20px] bg-main-color-primary text-general-white rounded-lg cursor-pointer"
        onClick={() => navigate("/mypage/myPlant/registration")}
      >
        <h1>발전소가 등록되지 않으셨다구요?</h1>
        <p className="flex items-center gap-x-[6px]">
          지금 바로 신규 발전소를 등록 해보세요!
          <span className="bg-text-color-primary p-[6px] animate-twinkle rounded-xl text-default">
            Click Here !
          </span>
        </p>
      </div>
    </main>
  );
};
