import { useQuery } from "react-query";
import { axiosFavorite, axiosFavoriteLimitCount } from "../api/axiosGetRequest";

interface IfavoriteAccess {
  apiName: string;
  authorization?: string;
}

export const useFavoriteQuery = (url: string, info: IfavoriteAccess) =>
  useQuery(["favoriteFromDetail", url, info.authorization], axiosFavorite, {
    onError: (err) => {
      console.error(`from ${info.apiName} ERROR`, err);
    },
  });

export const useFavoriteLimitCountQuery = (
  url: string,
  info: IfavoriteAccess,
) =>
  useQuery(
    ["favoriteCountFromDetail", url, info.authorization],
    axiosFavoriteLimitCount,
    {
      onError: (err) => {
        console.error(`from ${info.apiName} ERROR`, err);
      },
    },
  );
