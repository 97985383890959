import classNames from "classnames";
import { DefaultLoading } from "../../UI/Loading/Loading";
import { useBodyScrollLock } from "../../../utils_public/useBodyScrollLock";

type ImodalTalk = {
  open: boolean;
  type: string;
  userId: number;
  username: string;
};

type Ilist = {
  created_datetime: string;
  id: number;
  is_me: boolean;
  user_id: number;
  username: string;
};

interface Ifunc {
  setModalSell: (bool: boolean) => void;
  setModalTalk: (bool: ImodalTalk) => void;
}

interface IapiResponse {
  status: string;
}

interface Iitem {
  list: Ilist[];
}

export const DetailSellListModal = ({
  func,
  apiResponse,
  item,
}: {
  func: Ifunc;
  apiResponse: IapiResponse;
  item: Iitem;
}) => {
  useBodyScrollLock();

  return (
    <div className="relative z-[999]">
      <div className="fixed inset-0 bg-[#272727] bg-opacity-40 transition-opacity"></div>
      <div className="fixed inset-0 overflow-y-auto ">
        <div className="flex min-h-full justify-center items-center">
          <div
            className="w-[707px] h-[350px] pt-[30px] relative bg-general-white p-8
            rounded-2xl border-main-color-primary border-[1px] drop-shadow-[2px_2px_4px_rgba(0,0,0,0.25)] max-md:w-[450px] max-sm:w-[340px] max-sm:p-5 max-sm:h-[300px]"
          >
            <img
              className="absolute top-[15px] right-[35px] cursor-pointer"
              src="/icon_remodel/close.svg"
              onClick={() => {
                func.setModalSell(false);
              }}
            />
            <h1 className="text-subValue text-main-color-primary mb-[20px] max-sm:text-listSecond">
              구매 희망자 리스트
            </h1>
            <dl className="flex justify-end">
              <dt className="py-[8px] w-[50%] text-center border-b-[1px] border-t-[1px] border-b-border-color-dividers border-t-border-color-dividers max-sm:text-default max-sm:pr-[30px]">
                등록 일자
              </dt>
              <dt className="py-[8px] w-[50%] text-center border-b-[1px] border-t-[1px] border-b-border-color-dividers border-t-border-color-dividers max-sm:text-default">
                아이디(닉네임)
              </dt>
            </dl>
            <ul className="h-[150px] mt-[10px] overflow-y-auto max-sm:h-[100px]">
              {apiResponse.status === "loading" ? (
                <div className="max-w-[12%] w-full mx-auto mt-[50px]">
                  <DefaultLoading />
                </div>
              ) : item.list.length ? (
                item.list.map((el, idx) => (
                  <li
                    className={classNames("flex", { "pt-[8px]": idx })}
                    key={el.user_id}
                  >
                    <div className="w-[50%] text-center max-sm:text-footer max-sm:w-[40%]">
                      {el.created_datetime.slice(0, 10)}
                    </div>
                    <div className="w-[50%] flex gap-x-[10px] max-sm:text-footer">
                      <div className="pl-[115px] max-md:pl-[50px] max-sm:pl-[65px]">
                        <div
                          className="flex gap-x-[10px] border-b-[1px] cursor-pointer border-b-main-color-primary text-main-color-primary hover:border-b-[#b1159f] hover:text-[#b1159f]"
                          onClick={() => {
                            if (item.list[idx].is_me) {
                              return alert(
                                "자기 자신에게 메세지를 보낼 수 없습니다.",
                              );
                            }
                            func.setModalSell(false);
                            func.setModalTalk({
                              open: true,
                              type: "seller",
                              userId: el.user_id,
                              username: el.username,
                            });
                          }}
                        >
                          <p className="max-w-[100px] whitespace-nowrap overflow-hidden text-ellipsis max-sm:max-w-[80px]">
                            {el.username}
                          </p>
                          <img
                            className="w-[15px] max-sm:w-[10px]"
                            src="/icon_remodel/mail.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </li>
                ))
              ) : (
                <div className="flex justify-center pt-[20px] text-listTitle max-md:text-listSecond max-sm:text-default">
                  해당 발전소의 구매 희망자가 현재 없는 상태입니다.
                </div>
              )}
            </ul>
            {apiResponse.status !== "loading" && (
              <div className="flex justify-end mt-[15px]">
                <button
                  className="w-[88px] py-[12px] bg-main-color-primary text-general-white rounded-lg max-sm:w-[70px] max-sm:text-default"
                  onClick={() => {
                    func.setModalSell(false);
                  }}
                >
                  닫기
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
