import { useRecoilState } from "recoil";

interface Istate {
  searchText: string;
}

interface Ifunc {
  setSearchHide: (bool: boolean) => void;
  searchPlant: (search: string) => void;
  setSearchText: (value: string) => void;
}

export const HeaderMobileSearch = ({
  state,
  func,
}: {
  state: Istate;
  func: Ifunc;
}) => {
  return (
    <main className="w-full h-[70px] flex fixed top-0 right-0 bg-general-white border-b-[1px] border-b-text-color-disabled md:hidden">
      <div
        className="w-[70px] flex justify-center cursor-pointer"
        onClick={() => func.setSearchHide(false)}
      >
        <img className="w-[30px] rotate-90" src="/icon_remodel/arrow.svg" />
      </div>
      <div className="max-w-full w-full relative">
        <input
          className="max-w-full pr-[50px] w-full h-full !outline-none text-listTitle"
          placeholder={"발전소 이름 또는 주소로 검색"}
          value={state.searchText}
          onChange={(value: React.ChangeEvent<HTMLInputElement>) =>
            func.setSearchText(value.target.value)
          }
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              state.searchText !== ""
                ? func.searchPlant(state.searchText)
                : alert("검색 입력창이 공백 상태입니다.");
            }
          }}
        />

        {state.searchText.length > 0 && (
          <img
            className="absolute right-5 top-[26px] cursor-pointer"
            src="/icon_remodel/close.svg"
            onClick={() => func.setSearchText("")}
          />
        )}
      </div>
    </main>
  );
};
