import classNames from "classnames";
import { Italk } from "../../../pages/Detail/types/state.type";
import { ButtonLoading } from "../../UI/Loading/Loading";
import { SUBMIT_TALK_ERROR_TYPE } from "../../../pages/Detail/error/error.object";
import { ErrorHandling } from "../../../utils_public/ErrorHandling";
import { useBodyScrollLock } from "../../../utils_public/useBodyScrollLock";

interface Istate {
  talkText: Italk;
}

interface Ifunc {
  modalClose: () => void;
  submitTalk: (type: string) => void;
  setModalTalkSellList: (bool: boolean) => void;
  setModalTalkBuyList: (bool: boolean) => void;
}

interface Ioptions {
  type: string;
  sellerListCount: number;
  currentPagePositon: string;
  ownerVerification: boolean;
}

interface IapiResponse {
  loading: boolean;
  axiosCode: string;
  error: boolean;
  errorStatus: number;
  errorCode: string;
}

export const DetailTalkModalRegist = ({
  state,
  func,
  options,
  apiResponse,
}: {
  state: Istate;
  func: Ifunc;
  options: Ioptions;
  apiResponse: IapiResponse;
}) => {
  const VALIDATION = state.talkText.content.length < 5;

  return (
    <div className="w-full flex justify-end items-center gap-x-2">
      <h1 className="text-general-red mr-[20px]">
        {ErrorHandling(
          {
            errorType: SUBMIT_TALK_ERROR_TYPE,
            errorCode: apiResponse.axiosCode,
            errorStatus: apiResponse.errorStatus,
            isError: apiResponse.error,
          },
          apiResponse.errorCode,
        )}
      </h1>
      <div
        className="w-[88px] py-[11px] text-center border-[1px] cursor-pointer rounded-lg border-border-color-dividers"
        onClick={() => {
          return options.type === "buyer"
            ? options.sellerListCount > 0
              ? (func.setModalTalkBuyList(true), func.modalClose())
              : func.modalClose()
            : (func.setModalTalkSellList(true), func.modalClose());
        }}
      >
        <span>
          {options.type === "buyer"
            ? options.currentPagePositon === "mypage"
              ? "닫기"
              : options.sellerListCount > 0
              ? "뒤로가기"
              : "닫기"
            : options.currentPagePositon === "mypage"
            ? "닫기"
            : "뒤로가기"}
        </span>
      </div>
      <button
        className={`${classNames(
          "w-[88px] py-[12px] text-center rounded-lg",
          { "bg-text-color-disabled cursor-not-allowed": VALIDATION },
          {
            "bg-main-color-primary text-general-white cursor-pointer":
              !VALIDATION,
          },
        )} `}
        onClick={() => {
          // 중복 클릭 방지
          if (VALIDATION) return;

          if (options.ownerVerification) {
            func.submitTalk("to-user");
          } else {
            func.submitTalk("to-plant");
          }
        }}
      >
        {apiResponse.loading ? (
          <div className="w-[19px] h-[19px] mx-auto">
            <ButtonLoading />
          </div>
        ) : (
          "등록하기"
        )}
      </button>
    </div>
  );
};
