import axios from "axios";
import { HandleFormOwner } from "../utils/handleFormData.function";

export const axiosPatchInquiryList = async (queryKey: any) => {
  const response = await axios.patch(
    process.env.REACT_APP_SERVER_URL + queryKey.url,
    {
      question: queryKey.contents,
    },
    {
      withCredentials: true,
      headers: {
        Authorization: " Token " + queryKey.cookie,
      },
    },
  );

  return response.data;
};

export const axiosPatchOwnerInfo = async (queryKey: any) => {
  const formData = HandleFormOwner(
    queryKey.businessFile.file,
    queryKey.businessFile.change,
    queryKey.commerceFile.file,
    queryKey.commerceFile.change,
  );

  const response = await axios.patch(
    process.env.REACT_APP_SERVER_URL + queryKey.url,
    formData,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: " Token " + queryKey.cookie,
      },
    },
  );

  return response.data;
};

export const axiosPatchUserInfo = async (queryKey: any) => {
  if (!queryKey.pwButton) {
    const onlyInfoResponse = await axios.patch(
      process.env.REACT_APP_SERVER_URL + queryKey.url,
      {
        username: queryKey.userInfo.userName,
        nickname: queryKey.userInfo.nickname,
        phone: queryKey.userInfo.phoneNumber,
        company_name: queryKey.userInfo.company,
        company_type: queryKey.userInfo.companyType,
        receive_email: queryKey.receive.email,
        receive_sms: queryKey.receive.sms,
        receive_kakaotalk: queryKey.receive.kakao,
      },
      {
        withCredentials: true,
        headers: {
          Authorization: " Token " + queryKey.cookie,
        },
      },
    );
    return onlyInfoResponse.data;
  } else {
    const response = await axios.patch(
      process.env.REACT_APP_SERVER_URL + queryKey.url,
      {
        username: queryKey.userInfo.userName,
        phone: queryKey.userInfo.phoneNumber,
        updated_password: queryKey.userInfo.password,
        company_name: queryKey.userInfo.company,
        company_type: queryKey.userInfo.companyType,
        receive_email: queryKey.receive.email,
        receive_sms: queryKey.receive.sms,
        receive_kakaotalk: queryKey.receive.kakao,
      },
      {
        withCredentials: true,
        headers: {
          Authorization: " Token " + queryKey.cookie,
        },
      },
    );
    return response.data;
  }
};
