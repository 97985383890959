import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const useGoLoginPage = (status: string) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (status === "success") {
      navigate("/login?success");
    }
  }, [status]);
};
