import { usePlantRoomListQuery } from "../query/usePlantRoomQuery";
import { IplantRoomList } from "../types/item.type";

export const usePlantRoomList = (plantName: string, token?: string) => {
  const { data, status } = usePlantRoomListQuery(
    `message/plant-room/?plant_name=${plantName}`,
    plantName,
    {
      apiName: "Plant room list from talk",
      authorization: token,
    },
  );

  return {
    plantRoomListData: data ? (data as IplantRoomList[]) : [],
    plantRoomStatus: status,
  };
};
