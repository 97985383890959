import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MypageMyPlant } from "../../../components/Mypage/MypageMyPlant/MyPlant/MypageMyPlant";
import { IplantList } from "../../Main/types/plantList.type";
import { checkHasDecode } from "../../Search/utils/stringDecode";
import { SelectedLocalType, SelectedCapacityType } from "../types/state.type";

interface Istate {
  pageNumber: number;
  localCurrent: SelectedLocalType;
  capacityCurrent: SelectedCapacityType;
}

interface Ifunc {
  setPageNumber: (page: number) => void;
  setLocalCurrent: (local: SelectedLocalType) => void;
  setCapacityCurrent: (capacity: SelectedCapacityType) => void;
}

interface Iitem {
  plantList: IplantList[] | null;
  plantListTotal: number;
}

interface IapiResponse {
  loading: boolean;
}

export const MypageMyPlantModify = ({
  state,
  func,
  item,
  apiResponse,
}: {
  state: Istate;
  func: Ifunc;
  item: Iitem;
  apiResponse: IapiResponse;
}) => {
  const pathName = useLocation();
  const navigate = useNavigate();

  const [searchText, setSearchText] = useState("");

  const searchPlant = (searchPlant: string) => {
    const currentPath = pathName.search.slice(6);
    // 중복된 검색 문자 차단(필터 발전소 API는 문제없지만, url 게속 히스토리 쌓여있음)
    if (searchPlant === checkHasDecode(currentPath)) return;

    navigate(`/mypage/myPlant/?word=${searchPlant}`);
  };

  const pageChangeHandler = (pageNum: number) => {
    window.scrollTo(0, 0);
    func.setPageNumber(pageNum);
  };

  useEffect(() => {
    const current_search = checkHasDecode(pathName.search).slice(6);
    setSearchText(current_search);
    func.setPageNumber(1);
  }, [pathName]);

  return (
    <MypageMyPlant
      state={{
        pageNumber: state.pageNumber,
        searchText: searchText,
        localCurrent: state.localCurrent,
        capacityCurrent: state.capacityCurrent,
      }}
      item={{ plantList: item.plantList, plantListTotal: item.plantListTotal }}
      func={{
        setSearchText: setSearchText,
        searchPlant: searchPlant,
        pageChangeHandler: pageChangeHandler,
        setLocalCurrent: func.setLocalCurrent,
        setCapacityCurrent: func.setCapacityCurrent,
      }}
      apiResponse={{ loading: apiResponse.loading }}
    />
  );
};
