import {
  Ifile,
  IplantInputInfo,
} from "../../../../pages/Mypage/types/state.type";
import { MypageRegistrationDropDown } from "./MypageRegistrationDropDown";
import { MypageRegistrationFile } from "./MypageRegistrationFile";
import {
  PLANT_INFORMATION,
  LAND_LIST,
  START_CONSTRUCTION_LIST,
} from "../../../../pages/Mypage/constants/registration.constant";
import { MypageRegistrationRadioItem } from "./MypageRegistrationRadioItem";

interface Istate {
  plantInputInfo: IplantInputInfo;
  businessFile: Ifile;
  actionFile: Ifile;
}

interface Ifunc {
  setPlantInputInfo: (info: IplantInputInfo) => void;
  setBusinessFile: (file: Ifile) => void;
  setActionFile: (file: Ifile) => void;
  handleInput: (value: string, type: string) => void;
}

export const MypageRegistration = ({
  state,
  func,
}: {
  state: Istate;
  func: Ifunc;
}) => {
  return (
    <section className="max-w-[768px] w-full">
      <div className="flex items-center mb-4">
        <h1 className="text-listFirst font-bold max-sm:font-medium">
          새 발전소 등록하기
        </h1>
      </div>

      <div>
        <h1 className="text-listTitle mb-[17px]">발전소 기본 정보</h1>
        <ul className=" w-full flex flex-col mb-[80px] ">
          {PLANT_INFORMATION.map((el, idx) => (
            <li
              key={idx}
              className="flex border-t-[1px] border-y-border-color-dividers"
            >
              <div className="max-w-[169px] w-full flex items-center pl-5 py-3 bg-main-color-sub max-sm:max-w-[150px] max-sm:pl-3">
                {el.name}
              </div>
              <div className="max-w-full w-full flex pl-5 py-3 gap-x-[16px] max-sm:pl-3">
                {el.name !== "지목" && el.name !== "착공단계" ? (
                  <input
                    className="max-w-[300px] w-full border-[1px] border-border-color-dividers outline-none p-[8px] rounded-lg"
                    type={el.type}
                    placeholder={el.placeHolder}
                    value={state.plantInputInfo[el.state]}
                    maxLength={el.maxLength}
                    onChange={(e) => func.handleInput(e.target.value, el.state)}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength,
                        );
                    }}
                  />
                ) : (
                  <MypageRegistrationDropDown
                    options={{
                      name: el.name,
                      list:
                        el.name === "지목"
                          ? LAND_LIST
                          : START_CONSTRUCTION_LIST,
                    }}
                  />
                )}

                {el.name === "용량" && (
                  <div className="flex gap-x-[12px]">
                    {SIGNUP_RADIO_LIST.map((el, idx) => (
                      <div key={idx}>
                        <MypageRegistrationRadioItem
                          state={{ plantInputInfo: state.plantInputInfo }}
                          func={{ setPlantInputInfo: func.setPlantInputInfo }}
                          options={{ type: el }}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>

      <main className="w-full flex flex-col gap-y-[50px] mb-[50px]">
        <MypageRegistrationFile
          state={{ file: state.businessFile }}
          func={{ setFile: func.setBusinessFile }}
          options={{
            title: "발전사업허가증",
            input_id: "input-file-upload_A",
          }}
        />
        <MypageRegistrationFile
          state={{ file: state.actionFile }}
          func={{ setFile: func.setActionFile }}
          options={{
            title: "개발행위허가증",
            input_id: "input-file-upload_B",
          }}
        />
      </main>
      <div>
        <button className="max-w-full w-full bg-main-color-primary text-general-white rounded-lg py-[12px]">
          등록하기
        </button>
      </div>
    </section>
  );
};

const SIGNUP_RADIO_LIST = ["kW", "MW"];
