import { MypageUserRadioItem } from "./MypageUserRadioItem";

interface signature {
  [key: string]: string;
}

interface Istate {
  input: signature;
}

interface Ifunc {
  setInput: (input: signature) => void;
}

export const MypageUserRadioList = ({
  state,
  func,
}: {
  state: Istate;
  func: Ifunc;
}) => {
  return (
    <div className="h-[145px] flex max-md:flex-col max-md:h-full max-md:gap-y-[10px]">
      <span className="w-[77px] h-full text-default">구분</span>
      <ul className="grid grid-cols-[1fr_1fr] auto-rows-max gap-y-[20px] gap-x-[45px] ml-[63px] max-md:ml-0 max-sm:gap-x-[44px]">
        {MYPAGE_USER_RADIO_LIST.map((el, idx) => (
          <MypageUserRadioItem
            key={idx}
            state={{
              input: state.input,
            }}
            func={{
              setInput: func.setInput,
            }}
            item={{
              inputName: el,
            }}
          />
        ))}
      </ul>
    </div>
  );
};

const MYPAGE_USER_RADIO_LIST = [
  "발전소 소유주(개인/법인)",
  "에너지 관련 회사",
  "정부/공공기관",
  "기타",
];
