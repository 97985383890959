import classNames from "classnames";
import { IsellerList } from "../../../pages/Detail/types/item.type";
import { HandleComma } from "../../../utils_public/Comma";
import { getDayIncodeDate } from "../../../utils_public/dateFormat";

interface Iitem {
  sellerList: IsellerList[];
}

export const DetailAskingPrice = ({ item }: { item: Iitem }) => {
  const sortedDescending = item.sellerList
    .map((el) => el)
    .sort((a, b) => b.price - a.price);

  return (
    <section className="w-[47%] z-0 max-xl:w-full">
      <h1 className="mt-[72px] mb-[36px] text-subValue font-semibold max-sm:text-listTitle">
        이 발전소의 판매가격 리스트
      </h1>
      <main className="max-xl:flex max-xl:flex-col max-xl:items-start">
        <dl className="max-w-full w-full flex py-[12px] px-[8px] border-y-[1px] bg-main-color-sub border-y-border-color-dividers max-sm:text-default max-xl:max-w-full">
          <dt className="max-w-[170px] min-w-[170px] w-full text-right">
            판매 가격
          </dt>
          <dt className="max-w-[270px] w-full text-center">등록 일자</dt>
          <dt className="max-w-full w-full flex-grow-[2] text-center">
            닉네임
          </dt>
        </dl>
        <ul className="max-w-full w-full flex flex-col max-xl:max-w-full max-sm:text-default">
          {item.sellerList.length ? (
            sortedDescending.map((el, idx) => (
              <li
                className={classNames(
                  "flex items-center py-[12px] px-[8px] border-b-[1px] border-b-border-color-dividers",
                )}
                key={idx}
              >
                <div className="max-w-[170px] min-w-[170px] w-full text-right">
                  <p>{HandleComma(el.price)} 원</p>
                </div>
                <div className="max-w-[270px] w-full text-center">
                  <p>{getDayIncodeDate(el.created_datetime)}</p>
                </div>
                <div className="max-w-full w-full flex justify-center gap-x-[8px] items-center">
                  {el.is_owner && (
                    <span className="w-[45px] text-center bg-general-yellow text-footer py-[4px] rounded-md max-sm:text-smallExplanation max-sm:w-[40px] whitespace-nowrap">
                      소유주
                    </span>
                  )}

                  <p>{el.username}</p>
                </div>
              </li>
            ))
          ) : (
            <li className="text-center mt-[20px] text-subValue max-md:text-listTitle max-sm:text-listSecond">
              해당 발전소는 판매 가격이 등록 되지 않은 상태입니다.
            </li>
          )}
        </ul>
      </main>
    </section>
  );
};
