import axios from "axios";
import {
  handleCapacity__gte,
  handleCapacity__lt,
} from "../utils/handleCapacity.funtion";
import { checkHasDecode } from "../../../pages/Search/utils/stringDecode";

interface signature {
  [key: string]: string | number;
}

export const axiosHopeBuySellUserInfo = async ({ queryKey }: any) => {
  const response = await axios.get(
    process.env.REACT_APP_SERVER_URL + queryKey[1],
    {
      withCredentials: true,
      headers: {
        Authorization: " Token " + queryKey[2],
      },
    },
  );

  return response.data;
};

export const axiosPlantFilterList = async ({ queryKey }: any) => {
  const SEARCH_TYPE: signature = {
    search: queryKey[3] ? checkHasDecode(queryKey[3]).slice(6) : "",
  };

  // 지목 기타일 경우
  const LAND_ETC = {
    search: SEARCH_TYPE["search"],
    addr_lv1: queryKey[2].local === "전국" ? null : queryKey[2].local,
    land_category__not__in: "1,2,3,4,5,6,7,11,18",
    capacity__gte:
      queryKey[2].capacity === "전체"
        ? null
        : handleCapacity__gte(queryKey[2].capacity),
    capacity__lt:
      queryKey[2].capacity === "전체"
        ? null
        : handleCapacity__lt(queryKey[2].capacity),
    ordering: queryKey[2].sort,
    page: queryKey[2].page,
  };

  // 지목 기타가 아닐 경우
  const LANT_NOT_ECT = {
    search: SEARCH_TYPE["search"],
    addr_lv1: queryKey[2].local === "전국" ? null : queryKey[2].local,

    land_category:
      queryKey[2].land === "전체" ? null : LAND_ID[queryKey[2].land],
    capacity__gte:
      queryKey[2].capacity === "전체"
        ? null
        : handleCapacity__gte(queryKey[2].capacity),
    capacity__lt:
      queryKey[2].capacity === "전체"
        ? null
        : handleCapacity__lt(queryKey[2].capacity),
    ordering: queryKey[2].sort,
    page: queryKey[2].page,
  };

  const response = await axios.get(
    process.env.REACT_APP_SERVER_URL + queryKey[1],

    {
      withCredentials: true,
      params: queryKey[2].land === "기타" ? LAND_ETC : LANT_NOT_ECT,

      headers: {
        Authorization: queryKey[4] ? "Token " + queryKey[4] : "",
      },
    },
  );

  return response.data;
};

const LAND_ID: signature = {
  잡종지: 1,
  대지: 2,
  공장: 3,
  임야: 4,
  답: 5,
  목장용지: 6,
  전: 7,
  과수원: 11,
  주차장: 18,
  기타: "1,2,3,4,5,6,7,11,18",
};
