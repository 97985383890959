import { useEffect } from "react";
import { IinquiryListDeleteModal } from "../types/state.type";

interface IstateUpdate {
  inquiryContent: string;
  status: string;
  updatedId: number;
  inquiry_id: number;
}

interface IsetStateUpdate {
  setInquiryContent: (text: string) => void;
  setContentCopy: (text: string) => void;
  setModify: (bool: boolean) => void;
  setAlreadyInquiry: (bool: boolean) => void;
}

interface IstateDelete {
  status: string;
  deleteInquiryList: number[];
  deleteModal: IinquiryListDeleteModal;
}

interface IsetStateDelete {
  setAlreadyInquiry: (bool: boolean) => void;
  setDeleteInquiryList: (array: number[]) => void;
  setDeleteModal: (value: IinquiryListDeleteModal) => void;
}

export const useInquiryUpdateResult = (
  state: IstateUpdate,
  func: IsetStateUpdate,
) => {
  useEffect(() => {
    if (state.status === "success" && state.updatedId === state.inquiry_id) {
      func.setInquiryContent(state.inquiryContent);
      func.setContentCopy(state.inquiryContent);
      func.setModify(true);
    } else if (state.status === "error") {
      func.setAlreadyInquiry(true);
    }
  }, [state.status]);
};

export const useInquiryDeleteResult = (
  state: IstateDelete,
  func: IsetStateDelete,
) => {
  useEffect(() => {
    if (state.status === "success") {
      func.setDeleteInquiryList([
        ...state.deleteInquiryList,
        state.deleteModal.inquiry_id as number,
      ]);
      func.setDeleteModal({ modal: false, inquiry_id: null });
    } else if (state.status === "error") {
      func.setAlreadyInquiry(true);
      func.setDeleteModal({ modal: false, inquiry_id: null });
    }
  }, [state.status]);
};
