import { useCookies } from "react-cookie";
import { ModalRecordBuySell } from "../../../components/UI/Modal/ModalRecordBuySell";
import { useGetRecordBuySell } from "../hooks/useGetRecordBuySell";

import { ImodalPlantList } from "../types/state.type";

export const MyPageRecordBuySellFetch = ({
  modalPlantList,
  setModalPlantList,
}: {
  modalPlantList: ImodalPlantList;
  setModalPlantList: (plantList: ImodalPlantList) => void;
}) => {
  const [cookie] = useCookies(["60hz-user"]);
  const { registrationList, isSuccess, isLoading } = useGetRecordBuySell(
    cookie["60hz-user"],
    modalPlantList.userId,
    modalPlantList.type,
  );

  return (
    <ModalRecordBuySell
      modalPlantList={modalPlantList}
      setModalPlantList={setModalPlantList}
      registrationList={registrationList}
      isSuccess={isSuccess}
      isLoading={isLoading}
    />
  );
};
