export const MainBanner = () => {
  return (
    <>
      <section className="max-w-[1924px] mx-auto mb-[80px] px-[50px] max-md:hidden">
        <div className="w-full h-full flex items-end bg-general-lightGray rounded-3xl px-[147px] max-2xl:px-[50px]">
          <div className="max-w-[549px] w-full max-h-full h-full text-[48px] font-medium pb-[30px] z-[1] max-2xl:text-mainTitle max-xl:text-subTitle max-lg:text-subValue max-md:text-listTitle ">
            <p className="whitespace-nowrap">국내 태양광 발전소 정보가</p>
            <p className="whitespace-nowrap">모두 모여있는 곳</p>
          </div>
          <div className="flex  justify-end items-center">
            <img
              src="/image_remodel/banner_gray.png"
              className="w-[90%] h-[90%]"
            />
          </div>
        </div>
      </section>
    </>
  );
};
