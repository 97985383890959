import { useEffect } from "react";
import { useCookies } from "react-cookie";

interface Iresponse {
  Token: string;
}

export const useMakeCookie = (response: Iresponse) => {
  const [, setCookie] = useCookies(["60hz-user"]);
  useEffect(() => {
    if (!response) return;
    setCookie("60hz-user", response.Token, {
      path: "/",
      secure: true,
    });
  }, [response]);
};
