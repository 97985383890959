import classNames from "classnames";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

export const QnAMenu = ({
  selectMenu,
  setSelectMenu,
}: {
  selectMenu: string;
  setSelectMenu: (text: string) => void;
}) => {
  const [cookie] = useCookies(["60hz-user"]);

  const navigation = useNavigate();

  return (
    <menu className="max-w-[1630px] w-full mx-auto px-[50px] flex justify-center mt-[50px] gap-x-[15px] max-md:px-[24px] max-md:mt-0">
      <div
        className={classNames(
          "max-w-full w-full text-listTitle  border-b-[1.5px] cursor-pointer text-center pb-[10px] transition-all duration-300",
          { "border-b-main-color-primary": selectMenu === "questions" },
          { "border-b-border-color-dividers": selectMenu !== "questions" },
        )}
        onClick={() => setSelectMenu("questions")}
      >
        <span>자주 묻는 질문</span>
      </div>
      <div
        className={classNames(
          "max-w-full w-full text-listTitle  border-b-[1.5px] cursor-pointer text-center pb-[10px] transition-all duration-300",
          { "border-b-main-color-primary": selectMenu === "inquiry" },
          { "border-b-border-color-dividers": selectMenu !== "inquiry" },
        )}
        onClick={() => {
          if (!cookie["60hz-user"]) {
            alert("로그인 후 이용 문의를 주시길 바랍니다.");
            return navigation("/login");
          }
          setSelectMenu("inquiry");
        }}
      >
        <span>이용 문의</span>
      </div>
    </menu>
  );
};
