import { atom } from "recoil";
import { IfilterItem } from "../../Mypage/types/state.type";

export const filterCategory = atom<IfilterItem>({
  key: "searchCategory",
  default: {
    local: "전국",
    land: "전체",
    capacity: "전체",
    sort: "-capacity",
    page: 1,
  },
});
