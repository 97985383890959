import { ReactElement, useState } from "react";
import { useLocation } from "react-router-dom";
import { LoginInputBox } from "../../components/Login/LoginInputBox";
import { LoginSelect } from "../../components/Login/LoginSelect";
import { IloginInput } from "../../pages/Login/types/loginInput.type";
import { LoginModalFetch } from "./module_fetch/LoginModal.fetch";

interface signature {
  [key: string]: ReactElement<HTMLDivElement>;
}

interface Istate {
  loginInput: IloginInput;
  emailResult: boolean;
}

interface Ifunc {
  changeLoginInput: (type: string, text: string) => void;
  submitLogin: () => void;
}

interface IapiResponse {
  loading: boolean;
  axiosCode: string;
  error: boolean;
  errorStatus: number;
}

export const LoginPresenter = ({
  state,
  func,
  apiResponse,
}: {
  state: Istate;
  func: Ifunc;
  apiResponse: IapiResponse;
}) => {
  const [currentModal, setCurrentModal] = useState("");
  const { search } = useLocation();

  const SELECT_MODAL: signature = {
    email: (
      <LoginModalFetch
        func={{
          setCurrentModal: setCurrentModal,
        }}
        options={{
          modalType: "email",
          textType: { username: "", phone: "" },
        }}
      />
    ),
    password: (
      <LoginModalFetch
        func={{
          setCurrentModal: setCurrentModal,
        }}
        options={{
          modalType: "password",
          textType: { username: "", email: "", phone: "" },
        }}
      />
    ),
    verification: (
      <LoginModalFetch
        func={{
          setCurrentModal: setCurrentModal,
        }}
        options={{
          modalType: "verification",
          textType: { email: "" },
        }}
      />
    ),
  };
  return (
    <>
      <form className="max-w-[738px] mb-[200px] mx-auto pt-[180px] flex flex-col items-center max-md:px-[25px] max-sm:pt-[100px]">
        <figure className="max-w-[432px] w-full mb-16 ">
          <img
            className="h-[40px] mx-auto"
            src="./image_remodel/title_logo.svg"
          />
          {/* 회원가입 성공 후 뜨는 문구(url 쿼리문 /?success) */}
          {search.slice(1) === "success" && (
            <div className="flex flex-col gap-y-[8px] justify-center text-listFirst text-center mt-[76px]">
              <p> 회원가입이 완료되었습니다. </p>
              <p> 가입된 이메일을 통해 인증 메일을 확인하시길 바랍니다. </p>
            </div>
          )}

          {search.slice(1) === "is_certi" && (
            <p className="flex justify-center text-listFirst text-center mt-10">
              인증 메일을 확인하였습니다. <br />
              로그인 후 전국 태양광 발전소의 더 많은 정보를 확인하세요!
            </p>
          )}

          {search.slice(1) === "access" && (
            <p className="flex justify-center text-listFirst text-center mt-10">
              로그인 후 접근이 필요한 페이지 입니다.
            </p>
          )}
        </figure>

        {search.slice(1) !== "success" && (
          <>
            <LoginInputBox
              func={{
                changeLoginInput: func.changeLoginInput,
                setCurrentModal: setCurrentModal,
              }}
              apiResponse={{
                axiosCode: apiResponse.axiosCode,
                error: apiResponse.error,
                errorStatus: apiResponse.errorStatus,
              }}
            />
            <LoginSelect
              state={{
                loginInput: state.loginInput,
                emailResult: state.emailResult,
              }}
              func={{
                submitLogin: func.submitLogin,
              }}
              apiResponse={{
                loading: apiResponse.loading,
              }}
            />
            <div>
              <p
                className="leading-7 border-b-[1px] border-text-color-secondary text-text-color-secondary text-default cursor-pointer"
                onClick={() => setCurrentModal("verification")}
              >
                인증메일 재요청
              </p>
            </div>
          </>
        )}
      </form>
      {SELECT_MODAL[currentModal]}
    </>
  );
};
