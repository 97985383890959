import { useCookies } from "react-cookie";
import { MypageCertificationModify } from "../module_modify/MypageCertification.modify";
import { useGetMyPlantList } from "../hooks/useGetMyPlant";

export const MypageCertificationFetch = () => {
  const [cookie] = useCookies(["60hz-user"]);

  const { data, status } = useGetMyPlantList(cookie["60hz-user"]);

  return <MypageCertificationModify plantList={data} status={status} />;
};
