import { IreceiveCheck } from "../../../pages/SignUp/types/state.type";

interface Istate {
  receiveCheck: IreceiveCheck;
}

interface Ifunc {
  handleToggleSingle: (e: string) => void;
}

export const MypageUserCheckBox = ({
  state,
  func,
}: {
  state: Istate;
  func: Ifunc;
}) => {
  return (
    <div className="flex mt-4 text-default">
      <div className="flex items-center">
        <input
          id="email"
          type="checkbox"
          value="email"
          checked={state.receiveCheck.email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            func.handleToggleSingle(e.target.value);
          }}
        />
        <label htmlFor="email">
          <span className="ml-[6px] mr-4 cursor-pointer">이메일</span>
        </label>
      </div>
      <div className="flex items-center">
        <input
          id="sms"
          type="checkbox"
          value="sms"
          checked={state.receiveCheck.sms}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            func.handleToggleSingle(e.target.value);
          }}
        />
        <label htmlFor="sms">
          <span className="ml-[6px] mr-4 cursor-pointer">SMS</span>
        </label>
      </div>
      <div className="flex items-center">
        <input
          id="kakao"
          type="checkbox"
          value="kakao"
          checked={state.receiveCheck.kakao}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            func.handleToggleSingle(e.target.value);
          }}
        />
        <label htmlFor="kakao">
          <span className="ml-[6px] mr-4 cursor-pointer">카카오톡</span>
        </label>
      </div>
    </div>
  );
};
