import { useBodyScrollLock } from "../../../utils_public/useBodyScrollLock";

interface Ifunc {
  setModalTalkChoice: (bool: boolean) => void;
  setModalSell: (bool: boolean) => void;
  setModalBuy: (bool: boolean) => void;
}

export const ModalTalkTypeChoice = ({ func }: { func: Ifunc }) => {
  // 메세지 버튼 클릭
  const clickTalkBox = (select: string) => {
    if (select === "buyer") {
      func.setModalBuy(true);
    } else {
      func.setModalSell(true);
    }
  };

  useBodyScrollLock();

  return (
    <div className="relative z-[999]">
      <div className="fixed inset-0 bg-[#272727] bg-opacity-40 transition-opacity"></div>
      <div className="fixed inset-0 overflow-y-auto ">
        <div className="flex min-h-full justify-center items-center">
          <div
            className="w-[550px] h-[300px] flex flex-col items-center justify-center gap-y-[30px] pt-[30px] relative bg-general-white p-8
          rounded-2xl border-main-color-primary border-[1px] drop-shadow-[2px_2px_4px_rgba(0,0,0,0.25)] max-md:w-[450px] max-sm:w-[340px] max-sm:p-5"
          >
            <img
              className="absolute top-[15px] right-[35px] cursor-pointer max-sm:right-[20px]"
              src="/icon_remodel/close.svg"
              onClick={() => {
                func.setModalTalkChoice(false);
              }}
            />
            <h1 className="text-subValue text-main-color-primary mb-[20px] text-center max-sm:text-listSecond">
              누구에게 메시지를 보내기 희망하시나요?
            </h1>

            <div className="w-full flex justify-center gap-x-[20px]">
              <button
                className="max-w-[180px] w-full py-[12px] bg-main-color-primary text-general-white rounded-lg"
                onClick={() => {
                  func.setModalTalkChoice(false);
                  clickTalkBox("buyer");
                }}
              >
                판매자에게 보내기
              </button>
              <button
                className="max-w-[180px] w-full py-[12px] bg-main-color-primary text-general-white rounded-lg"
                onClick={() => {
                  func.setModalTalkChoice(false);
                  clickTalkBox("seller");
                }}
              >
                구매자에게 보내기
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
