import { useQuery } from "react-query";
import { axiosSentinelMap } from "../api/axiosGetRequest";

interface IuseSentinelMap {
  apiName: string;
}

export const useSentinelMapQuery = (url: string, info: IuseSentinelMap) =>
  useQuery(["SentinelMapFromDetail", url], axiosSentinelMap, {
    onError: (err) => {
      console.error(`from ${info.apiName} ERROR`, err);
    },
  });
