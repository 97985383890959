import {
  Iinverter,
  Imodule,
} from "../../../pages/Mypage/types/plantTotalInfo.type";

interface Istate {
  inverter: Iinverter;
  module: Imodule;
}

interface Ifunc {
  setInverter: (inverter: Iinverter) => void;
  setModule: (module: Imodule) => void;
}

interface Ioptions {
  title: string;
}

export const MypageEquipment = ({
  state,
  func,
  options,
}: {
  state: Istate;
  func: Ifunc;
  options: Ioptions;
}) => {
  const diffrence = options.title === "모듈";
  return (
    <section className="mb-[60px]">
      <h1 className="mb-4 text-listFirst font-bold">{options.title}</h1>
      <div className="flex items-center mb-8">
        <span className="w-[141px] text-default">제조회사</span>
        <input
          className="w-[637px] h-[43px] px-4 !outline-none border-[0.5px] border-border-color-dividers rounded-lg"
          placeholder="제조회사를 입력해주세요"
          value={
            diffrence
              ? state.module.moduleManufactureCompany
                ? state.module.moduleManufactureCompany
                : ""
              : state.inverter.inverterManufactureCompany
              ? state.inverter.inverterManufactureCompany
              : ""
          }
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (options.title === "모듈") {
              func.setModule({
                ...state.module,
                moduleManufactureCompany: e.target.value,
                change: true,
              });
            } else {
              func.setInverter({
                ...state.inverter,
                inverterManufactureCompany: e.target.value,
                change: true,
              });
            }
          }}
        />
      </div>
      <div className="flex items-center mb-8">
        <span className="w-[141px] text-default">모델명</span>
        <input
          className="w-[637px] h-[43px] px-4 !outline-none border-[0.5px] border-border-color-dividers rounded-lg"
          placeholder="모델명을 입력해주세요"
          value={
            diffrence
              ? state.module.moduleName
                ? state.module.moduleName
                : ""
              : state.inverter.inverterName
              ? state.inverter.inverterName
              : ""
          }
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (options.title === "모듈") {
              func.setModule({
                ...state.module,
                moduleName: e.target.value,
                change: true,
              });
            } else {
              func.setInverter({
                ...state.inverter,
                inverterName: e.target.value,
                change: true,
              });
            }
          }}
        />
      </div>
      <div className="flex items-center mb-8 relative">
        <span className="w-[141px] text-default">용량</span>
        <div className="w-[637px] relative">
          <input
            className="w-full h-[43px] px-4 !outline-none border-[0.5px] border-border-color-dividers rounded-lg"
            type="number"
            min="0"
            onWheel={(e) => e.currentTarget.blur()}
            placeholder="숫자만 입력해주세요"
            value={
              diffrence
                ? state.module.moduleCapacity
                  ? state.module.moduleCapacity
                  : ""
                : state.inverter.inverterCapacity
                ? state.inverter.inverterCapacity
                : ""
            }
            onKeyDown={(e) => {
              if (e.code === "Minus") {
                e.preventDefault();
              }
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (options.title === "모듈") {
                func.setModule({
                  ...state.module,
                  moduleCapacity: e.target.value,
                  change: true,
                });
              } else {
                func.setInverter({
                  ...state.inverter,
                  inverterCapacity: e.target.value,
                  change: true,
                });
              }
            }}
          />
          <span className="absolute right-2 top-[12px]">kW</span>
        </div>
      </div>
      <div className="flex items-center">
        <span className="w-[141px] text-default">수량</span>
        <div className="w-[637px] relative">
          <input
            className="w-full h-[43px] px-4 !outline-none border-[0.5px] border-border-color-dividers rounded-lg"
            type="number"
            min="0"
            onWheel={(e) => e.currentTarget.blur()}
            onKeyDown={(e) => {
              if (e.code === "Minus") {
                e.preventDefault();
              }
            }}
            placeholder="숫자만 입력해주세요"
            value={
              diffrence
                ? state.module.moduleNums
                  ? state.module.moduleNums
                  : ""
                : state.inverter.inverterNums
                ? state.inverter.inverterNums
                : ""
            }
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (options.title === "모듈") {
                func.setModule({
                  ...state.module,
                  moduleNums: e.target.value,
                  change: true,
                });
              } else {
                func.setInverter({
                  ...state.inverter,
                  inverterNums: e.target.value,
                  change: true,
                });
              }
            }}
          />
          <span className="absolute right-3 top-[12px]">개</span>
        </div>
      </div>
    </section>
  );
};
