import { useState } from "react";
import { PlantListItemFetch } from "../../pages/Main/module_fetch/PlantListItem.fetch";
import { SkeletonListContainer } from "../UI/PlantList/SkeletonList";
import { IplantList } from "../../pages/Main/types/plantList.type";
import { ModalConfirmation } from "../UI/Modal/ModalConfirmation";
import { ModalHopeSellBuyFetch } from "../../pages/Main/module_fetch/ModalHopeSellBuy.fetch";
import { ErrorHandling } from "../../utils_public/ErrorHandling";
import { PLANT_LIST_FILTER__ERROR_TYPE } from "../../pages/Main/error/error.object";

interface Istate {
  plantList: IplantList[];
}

interface IapiResponse {
  isLoading: boolean;
  error: boolean;
  axiosCode: string;
  errorStatus: number;
}

interface Ifunc {
  setBlurModal: (bool: boolean) => void;
}

export const MainPlantList = ({
  state,
  apiResponse,
  func,
}: {
  state: Istate;
  apiResponse: IapiResponse;
  func: Ifunc;
}) => {
  const [modalFavorite, setModalFavorite] = useState(false);
  const [modalSellBuy, setModalSellBuy] = useState({
    open: false,
    type: "",
    plantId: 0,
  });

  return (
    <>
      {apiResponse.isLoading ? (
        <ul className="grid grid-cols-4 gap-8 max-xl:grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1">
          <SkeletonListContainer count={28} />
        </ul>
      ) : apiResponse.error ? (
        <h1 className="w-full flex justify-center my-28 text-subValue">
          {ErrorHandling(
            {
              errorType: PLANT_LIST_FILTER__ERROR_TYPE,
              errorCode: apiResponse.axiosCode,
              errorStatus: apiResponse.errorStatus,
              isError: apiResponse.error,
            },
            "",
          )}
        </h1>
      ) : state.plantList.length ? (
        <ul className="grid grid-cols-4 gap-8 max-xl:grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1">
          {state.plantList.map((el: IplantList) => {
            return (
              <PlantListItemFetch
                item={{ ...el }}
                key={el.id}
                func={{
                  setModalFavorite: setModalFavorite,
                  setModalSellBuy: setModalSellBuy,
                  setBlurModal: func.setBlurModal,
                }}
              />
            );
          })}
        </ul>
      ) : (
        <div className="w-full flex justify-center mt-28 text-subValue">
          조건에 맞는 발전소가 없습니다.
        </div>
      )}
      {modalFavorite && (
        <ModalConfirmation
          contentsTop={`찜한 발전소 제한 개수(16개)가`}
          contentsBottom={`초과되었습니다.`}
          setModal={setModalFavorite}
        />
      )}

      {modalSellBuy.open && (
        <ModalHopeSellBuyFetch
          state={{
            type: modalSellBuy.type,
            plantId: modalSellBuy.plantId,
          }}
          func={{
            setModalSellBuy: setModalSellBuy,
          }}
        />
      )}
    </>
  );
};
