import { MypageUserInput } from "../../UI/Input_Text/Mypage/MypageUserInput";
import { MypageUserRadioList } from "../../UI/Input_Radio/MypageUserRadioList";
import { MypageUserBenefit } from "./MypageUserBenefit";
import { MypageUserPassword } from "./MypageUserPassword";
import {
  IreceiveCheck,
  IuserPasswordValidation,
} from "../../../pages/Mypage/types/state.type";
import { ButtonLoading } from "../../UI/Loading/Loading";
import { ErrorHandling } from "../../../utils_public/ErrorHandling";
import { SUBMIT_USER_INFO_CHANGE_ERROR_TYPE } from "../../../pages/Mypage/error/error.object";
import { Ipass } from "../../../pages/SignUp/types/state.type";

interface signature {
  [key: string]: string;
}

interface IserverError {
  code: string;
  message: string;
}

interface signatureRef {
  [key: string]: React.RefObject<HTMLInputElement>;
}

interface Istate {
  toggle: boolean;
  pwButton: boolean;
  receiveCheck: IreceiveCheck;
  userModify: signature;
  passwordValidation: IuserPasswordValidation;
  passwordCheck: boolean;
  passwordVerifyCheck: boolean;
  nicknameCheck: boolean;
  usernameCheck: boolean;
  phoneNumberCheck: boolean;
  telephoneNumberCheck: boolean;
  companyCheck: boolean;
}

interface Ifunc {
  setPwButton: (bool: boolean) => void;
  setUserModify: (userModify: signature) => void;
  submitUserInfo: () => void;
  handleToggleSingle: (value: string) => void;
  handleToggleAll: () => void;
  blurInput: (name: string) => void;
  submitValidation: () => void;
}

interface Iitem {
  nicknameOriginal: string;
  phoneNumberOriginal: string;
}

interface IapiResponse {
  serverError?: IserverError;
  submitLoading: boolean;
  submitError: boolean;
  submiteCode: string;
  submiteStatus: number;
  phoneDupStatus: Ipass;
  phoneDupLoading: boolean;
  nicknameDupStatus: Ipass;
  nicknameDupLoading: boolean;
}

interface Ioptions {
  passwordRef: React.RefObject<HTMLInputElement>;
  passwordVerifyRef: React.RefObject<HTMLInputElement>;
  nicknameRef: React.RefObject<HTMLInputElement>;
  nameRef: React.RefObject<HTMLInputElement>;
  phoneRef: React.RefObject<HTMLInputElement>;
  telephoneRef: React.RefObject<HTMLInputElement>;
  companyRef: React.RefObject<HTMLInputElement>;
}

export const MypageUserChange = ({
  state,
  func,
  item,
  apiResponse,
  options,
}: {
  state: Istate;
  func: Ifunc;
  item: Iitem;
  apiResponse: IapiResponse;
  options: Ioptions;
}) => {
  const INPUT_REF_TYPE: signatureRef = {
    nickname: options.nicknameRef,
    userName: options.nameRef,
    phoneNumber: options.phoneRef,
    telephoneNumber: options.telephoneRef,
    company: options.companyRef,
  };

  return (
    <div>
      {INPUT_TOP.map((el, idx) => (
        <MypageUserInput
          key={idx}
          state={{
            input: state.userModify,
            currentValue: state.userModify[el.name],
            nicknameCheck: state.nicknameCheck,
            usernameCheck: state.usernameCheck,
            phoneNumberCheck: state.phoneNumberCheck,
            telephoneNumberCheck: state.telephoneNumberCheck,
            companyCheck: state.companyCheck,
          }}
          func={{
            setInput: func.setUserModify,
            blurInput: func.blurInput,
          }}
          item={{
            nicknameOriginal: item.nicknameOriginal,
            phoneNumberOriginal: item.phoneNumberOriginal,
          }}
          apiResponse={{
            serverError: apiResponse.serverError,
            phoneDupStatus: apiResponse.phoneDupStatus,
            phoneDupLoading: apiResponse.phoneDupLoading,
            nicknameDupStatus: apiResponse.nicknameDupStatus,
            nicknameDupLoading: apiResponse.nicknameDupLoading,
          }}
          options={{ ...el, ref: INPUT_REF_TYPE[el.name] }}
        />
      ))}
      <div className="flex mb-8 gap-x-[65px] items-center max-md:mt-[15px]">
        <span className="w-[77px] text-default">이메일</span>
        <p className="max-w-[523px] w-full text-text-color-disabled">
          {state.userModify["email"]}
        </p>
      </div>
      <div className="flex mb-8 gap-x-[75px] items-center max-md:flex-col max-md:items-start max-md:gap-y-[15px]">
        <span className="w-[77px] text-default">비밀번호</span>
        <MypageUserPassword
          state={{
            pwButton: state.pwButton,
            passwordValidation: state.passwordValidation,
            input: state.userModify,
            passwordCheck: state.passwordCheck,
            passwordVerifyCheck: state.passwordVerifyCheck,
          }}
          func={{
            passwordOpen: func.setPwButton,
            setInput: func.setUserModify,
            blurInput: func.blurInput,
          }}
          options={{
            passwordRef: options.passwordRef,
            passwordVerifyRef: options.passwordVerifyRef,
          }}
          apiResponse={{
            serverError: apiResponse.serverError,
          }}
        />
      </div>

      {INPUT_BOTTOM.map((el, idx) => (
        <MypageUserInput
          key={idx + 2}
          state={{
            input: state.userModify,
            currentValue: state.userModify[el.name],
            nicknameCheck: state.nicknameCheck,
            usernameCheck: state.usernameCheck,
            phoneNumberCheck: state.phoneNumberCheck,
            telephoneNumberCheck: state.telephoneNumberCheck,
            companyCheck: state.companyCheck,
          }}
          item={{
            nicknameOriginal: item.nicknameOriginal,
            phoneNumberOriginal: item.phoneNumberOriginal,
          }}
          func={{
            setInput: func.setUserModify,
            blurInput: func.blurInput,
          }}
          apiResponse={{
            phoneDupStatus: apiResponse.phoneDupStatus,
            phoneDupLoading: apiResponse.phoneDupLoading,
            nicknameDupStatus: apiResponse.nicknameDupStatus,
            nicknameDupLoading: apiResponse.nicknameDupLoading,
          }}
          options={{
            ...el,
            ref: INPUT_REF_TYPE[el.name],
          }}
        />
      ))}
      <MypageUserRadioList
        state={{
          input: state.userModify,
        }}
        func={{
          setInput: func.setUserModify,
        }}
      />
      <MypageUserBenefit
        state={{
          toggle: state.toggle,
          receiveCheck: state.receiveCheck,
        }}
        func={{
          handleToggleSingle: func.handleToggleSingle,
          handleToggleAll: func.handleToggleAll,
        }}
      />
      <div className="max-w-[660px] w-full h-[47px] mt-10 flex justify-end">
        <h1 className="w-[70%]  flex justify-center items-center text-general-red">
          {ErrorHandling(
            {
              errorType: SUBMIT_USER_INFO_CHANGE_ERROR_TYPE,
              errorCode: apiResponse.submiteCode,
              errorStatus: apiResponse.submiteStatus,
              isError: apiResponse.submitError,
            },
            "",
          )}
        </h1>
        <button
          className={`w-[160px] bg-main-color-primary text-general-white rounded-lg`}
          onClick={() => {
            func.submitValidation();
          }}
        >
          {apiResponse.submitLoading ? (
            <div className="w-[40px] mx-auto">
              <ButtonLoading />{" "}
            </div>
          ) : (
            "저장하기"
          )}
        </button>
      </div>
    </div>
  );
};

const INPUT_TOP = [
  {
    type: "text",
    title: "이름",
    name: "userName",
    maxLength: 20,
    placeHolder: "이름을 입력해주세요",
  },
  {
    type: "text",
    title: "닉네임",
    name: "nickname",
    maxLength: 8,
    placeHolder: "닉네임을 입력해주세요",
  },
];

const INPUT_BOTTOM = [
  {
    type: "tel",
    title: "휴대폰 번호",
    name: "phoneNumber",
    maxLength: 13,
    placeHolder: "휴대폰 번호를 입력해주세요",
  },
  {
    type: "tel",
    title: "전화번호",
    name: "telephoneNumber",
    maxLength: 12,
    placeHolder: "전화번호를 입력해주세요",
  },
  {
    type: "text",
    title: "소속",
    name: "company",
    maxLength: 20,
    placeHolder: "소속을 입력해주세요",
  },
];
