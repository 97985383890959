import { IfilterItem } from "../../../pages/Mypage/types/state.type";
import { SortDropDownCurrent } from "./SortDropDownCurrent";
import { SortDropDownListBox } from "./SortDropDownListBox";

type signature = {
  [key: string]: string;
};

interface IsortList {
  name: string;
  text: string;
}

interface Istate {
  filter: IfilterItem;
  toggle: boolean;
}

interface Ifunc {
  selectFilter: (type: string, value: string) => void;
  setToggle: (bool: boolean) => void;
}

export const SortDropDown = ({
  state,
  func,
}: {
  state: Istate;
  func: Ifunc;
}) => {
  return (
    <div className="w-[170px] relative flex justify-end mr-1 items-center rounded-lg max-md:text-default">
      <SortDropDownCurrent
        state={{
          current: HIGH_TO_LOW_CURRENT_LIST[state.filter["sort"]],
          toggle: state.toggle,
        }}
        func={{
          setToggle: func.setToggle,
        }}
      />
      <SortDropDownListBox
        state={{
          list: HIGH_TO_LOW_LIST,
          toggle: state.toggle,
        }}
        func={{
          selectFilter: func.selectFilter,
        }}
      />
    </div>
  );
};

export const HIGH_TO_LOW_LIST: IsortList[] = [
  { name: "-capacity", text: "용량 높은 순" },
  { name: "capacity", text: "용량 낮은 순" },
  { name: "-createAt", text: "준공년도 이른 순" },
  { name: "createAt", text: "준공년도 낮은 순" },
];

export const HIGH_TO_LOW_CURRENT_LIST: signature = {
  "-capacity": "용량 높은 순",
  capacity: "용량 낮은 순",
  "-createAt": "준공년도 이른 순",
  createAt: "준공년도 낮은 순",
};
