interface Istate {
  searchText: string;
}

interface Ifunc {
  setSearchText: (value: string) => void;
  searchPlant: (search: string) => void;
}

export const HeaderSearch = ({
  state,
  func,
}: {
  state: Istate;
  func: Ifunc;
}) => {
  return (
    <div className="w-[434px] h-[40px] flex relative flex-col max-md:max-w-[324px] max-md:w-full max-md:mx-[16px] max-md:hidden">
      <input
        className="w-full h-full !outline-none pl-4 pr-10 border-main-color-primary 
        border-[1.5px] rounded-full text-default max-md:whitespace-nowrap max-md:overflow-hidden max-md:text-ellipsis"
        placeholder={"발전소 이름 또는 주소로 검색"}
        type={"text"}
        value={state.searchText}
        onChange={(value: React.ChangeEvent<HTMLInputElement>) =>
          func.setSearchText(value.target.value)
        }
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            state.searchText !== ""
              ? func.searchPlant(state.searchText)
              : alert("검색 입력창이 공백 상태입니다.");
          }
        }}
      />

      <div
        className="w-[18px] h-[18px] absolute right-4 top-[12px] cursor-pointer"
        onClick={() => {
          if (!state.searchText || state.searchText === "")
            return alert("검색 입력창이 공백 상태입니다.");
          func.searchPlant(state.searchText);
        }}
      >
        <img src="/icon_remodel/search.svg" />
      </div>
    </div>
  );
};
