import { IthumbnailList } from "../../../pages/Mypage/types/state.type";

export const MypageThumbnail = ({
  thumbnail,
  setThumbnail,
}: {
  thumbnail: IthumbnailList;
  setThumbnail: (file: IthumbnailList) => void;
}) => {
  return (
    <section>
      <div className="flex gap-x-1 items-center mb-4">
        <div className="flex items-center gap-x-[8px]">
          <h1 className="text-listFirst font-bold">발전소 사진</h1>
          <span className="font-normal">(최대 5장)</span>
          <span
            className="text-smallExplanation text-text-color-secondary border-b-[1px] 
          border-b-text-color-secondary cursor-pointer"
            onClick={() =>
              setThumbnail({ image: thumbnail.image, default: false })
            }
          >
            첨부파일 수정하기
          </span>
        </div>
      </div>
      <aside className="flex flex-wrap gap-x-[16px] gap-y-[30px]">
        {thumbnail.image.map((el) => (
          <a
            className="cursor-pointer"
            key={el.id}
            href={el.file}
            target={"_blank"}
          >
            <img
              className="w-[130px] h-[130px] object-cover rounded-lg"
              src={el.file}
            />
          </a>
        ))}
      </aside>
    </section>
  );
};
