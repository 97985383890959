import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

type ImodalSellBuy = {
  open: boolean;
  type: string;
  plantId: number;
};

interface Istate {
  success: boolean;
  type: string;
}

export const useSubmitPrice = (
  state: Istate,
  setModalSellBuy: (object: ImodalSellBuy) => void,
) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (state.success) {
      confirm(
        `${
          state.type === "sell" ? "판매가격" : "구매가격"
        }이 성공적으로 등록되었습니다. \n${
          state.type === "sell"
            ? "관리자 확인 후 마이페이지에서 확인 가능합니다."
            : "자세한 내용은 마이페이지에서 확인 가능합니다."
        } `,
      )
        ? navigate(
            state.type === "sell"
              ? "/mypage/trade/plantSell"
              : "/mypage/trade/plantBuy",
          )
        : null;
      setModalSellBuy({ open: false, type: "", plantId: 0 });
    }
  }, [state.success]);
};
