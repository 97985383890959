import { Imessage } from "../../../pages/Chat/types/item.type";
import {
  IpingList,
  IcurrentMessageList,
} from "../../../pages/Chat/types/state.type";
import { ChatTabletUserListItem } from "./ChatTabletUserListItem";

type IselectOption = {
  plant: string;
  currentMessageId: number;
  user: string;
};

interface Istate {
  selectOption: IselectOption;
  selectPlant: Imessage[];
  userPingList: IpingList;
  curretMessageList: IcurrentMessageList;
}

interface Ifunc {
  setSelectOption: (value: IselectOption) => void;
  setUserPingList: (value: IpingList) => void;
  setSendMessageError: (error: boolean) => void;
}

export const ChatTabletUserList = ({
  state,
  func,
}: {
  state: Istate;
  func: Ifunc;
}) => {
  return (
    <section className="max-w-[50%] w-full pl-[20px] max-md:pl-[15px]">
      {state.selectOption.plant === "" ? (
        <div className="max-w-full w-full h-full bg-general-lightGray flex justify-center pt-[50px] text-listTitle whitespace-pre max-md:text-listFirst max-sm:text-default">
          발전소를 선택해주세요!
        </div>
      ) : (
        <>
          <ul className="h-[680px] flex flex-col overflow-y-scroll scrollbar-hide">
            {state.selectPlant.map((el, idx) => (
              <ChatTabletUserListItem
                item={{ ...el }}
                key={idx}
                state={{
                  selectOption: state.selectOption,
                  userPingList: state.userPingList,
                  curretMessageList: state.curretMessageList,
                }}
                func={{
                  setSelectOption: func.setSelectOption,
                  setUserPingList: func.setUserPingList,
                  setSendMessageError: func.setSendMessageError,
                }}
              />
            ))}
          </ul>
        </>
      )}
    </section>
  );
};
