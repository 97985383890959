import { useEffect, useState } from "react";
import { IactualPriceList } from "../../../pages/Detail/types/item.type";
import { IcapacityRange } from "../../../pages/Detail/types/state.type";
import { DetailActualPriceFilter } from "./DetailActualPriceFilter";
import { DetailActualPriceGraph } from "./DetailActualPriceGraph";

type IsellInfo = {
  created_datetime: string;
  price: number;
};

interface Iitem {
  local: string;
  sellInfo: IsellInfo;
  capacity: number;
  actualPriceList: IactualPriceList[];
}

export const DetailActualPrice = ({ item }: { item: Iitem }) => {
  const [currentLocal, setCurrentLocal] = useState("전국");
  const [currentCapacity, setCurretCapacity] =
    useState<IcapacityRange>("~100kW 미만");

  useEffect(() => {
    setCurrentLocal(item.local);
  }, [item.local]);
  useEffect(() => {
    if (item.capacity < 0.1) {
      setCurretCapacity("~100kW 미만");
    } else if (item.capacity < 0.2) {
      setCurretCapacity("100kW 이상 ~ 200kW 미만");
    } else if (item.capacity < 0.5) {
      setCurretCapacity("200kW 이상 ~ 500kW 미만");
    } else if (item.capacity < 1) {
      setCurretCapacity("500kW 이상 ~ 1MW 미만");
    } else {
      setCurretCapacity("1MW 이상~");
    }
  }, [item.capacity]);

  return (
    <>
      <div className="w-[47%] z-0 max-xl:w-full">
        <h1 className="text-subTitle font-bold max-sm:font-medium max-sm:text-subValue">
          이 발전소의 거래 가격
        </h1>
        <DetailActualPriceFilter
          state={{
            currentLocal: currentLocal,
            currentCapacity: currentCapacity,
          }}
          func={{
            setCurrentLocal: setCurrentLocal,
            setCurretCapacity: (value: string) =>
              setCurretCapacity(value as IcapacityRange),
          }}
          item={{
            sellInfo: item.sellInfo,
            capacity: item.capacity,
          }}
        />
        <DetailActualPriceGraph
          state={{
            currentLocal: currentLocal,
            currentCapacity: currentCapacity,
          }}
          item={{
            sellInfo: item.sellInfo,
            capacity: item.capacity,
            actualPriceList: item.actualPriceList,
          }}
        />
      </div>
    </>
  );
};
