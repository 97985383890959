import { Ifile } from "../../../../pages/Mypage/types/state.type";
import { DragDropFileContainer } from "../../../UI/DragAndDrop/DragDropFile";

interface Istate {
  file: Ifile;
}

interface Ifunc {
  setFile: (file: Ifile) => void;
}

interface Ioptions {
  title: string;
  input_id: string;
}

export const MypageRegistrationFile = ({
  state,
  func,
  options,
}: {
  state: Istate;
  func: Ifunc;
  options: Ioptions;
}) => {
  return (
    <div>
      <div className="flex items-center text-listSecond relative mb-[12px]">
        <h1 className="font-bold max-sm:font-medium">{options.title}</h1>
        <span className="ml-1 text-smallExplanation text-general-red">
          필수
        </span>
      </div>
      <div className="max-w-full w-full h-[178px]">
        <DragDropFileContainer
          file={state.file}
          setFile={func.setFile}
          title={options.title}
          input_id={options.input_id}
          content={""}
        />
      </div>
    </div>
  );
};
