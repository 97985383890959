import { AxiosError } from "axios";
import { useQuery, useMutation } from "react-query";
import { axiosCheckPassword } from "../api/axiosPostRequest";
import { axiosPatchUserInfo } from "../api/axiosPatchRequest";
import { axiosUserInfo } from "../api/axiosGetRequest";

interface IserverError {
  code: string;
  message: string;
}

export const useGetUserInfoQuery = (
  url: string,
  info: { cookie?: string; apiName: string },
) =>
  useQuery([info.apiName, url, info.cookie], axiosUserInfo, {
    onError: (err) => {
      console.error(`from ${info.apiName} ERROR`, err);
    },
    cacheTime: 0,
    retry: false,
  });

export const useCheckPasswordQuery = () =>
  useMutation(axiosCheckPassword, {
    onError: (err: AxiosError, val) => {
      console.error(`from ${val.apiName} ERROR`, err);
    },
  });

export const usePatchUserInfoQuery = () =>
  useMutation(axiosPatchUserInfo, {
    onSuccess: () => {
      alert("회원 정보가 변경 되었습니다.");
      window.location.reload();
    },
    onError: (err: AxiosError<IserverError>, val) => {
      console.error(`from ${val.apiName} ERROR`, err);
    },
  });
