import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { getDayIncodeDate } from "../../../../utils_public/dateFormat";
import {
  InputComma,
  HandleInputTextComma,
  HandleComma,
} from "../../../../utils_public/Comma";
import { DefaultLoading } from "../../../UI/Loading/Loading";
import { newBadgeSetting } from "../../../../pages/Mypage/utils/newBadgeSetting.function";
import {
  ImodalPlantList,
  priceBoxType,
} from "../../../../pages/Mypage/types/state.type";
import { UserBox } from "../../../UI/Box/UserBox";
import { useState } from "react";
import { MypagePriceHistoryFetch } from "../../../../pages/Mypage/module_fetch/MypagePriceHistory.fetch";

type IbuyerList = {
  created_datetime: string;
  price: number;
  user_id: number;
  username: string;
};

interface Istate {
  selectPlantId: number;
  updateInput: boolean;
  saveCreateDate: string;
  copyPrice: string;
  selectedPriceHistory: priceBoxType;
}

interface Iitem {
  buyer_list: IbuyerList[];
  plant_id: number;
  plant_name: string;
  sell_created_datetime: string;
  sell_price: number;
}

type ImodalSellBuy = {
  open: boolean;
  type: string;
  userId: number;
  username: string;
};

interface Ifunc {
  setModalTalk: (value: ImodalSellBuy) => void;
  setSelectPlantId: (num: number) => void;
  setCurrentMessagePlantId: (value: number) => void;
  setModalPlantList: (plantList: ImodalPlantList) => void;
  setCopyPrice: (value: string) => void;
  submitPrice: (num: number, price: number | string) => void;
  setUpdateInput: (bool: boolean) => void;
  onClickPriceHistory: (currentPlantId: number) => void;
}

interface IapiResponse {
  submitLoading: boolean;
  submitSuccess: boolean;
}

interface Ioptions {
  id: number;
  length: number;
}

export const MypagePlantSellItem = ({
  state,
  item,
  func,
  apiResponse,
  options,
}: {
  state: Istate;
  item: Iitem;
  func: Ifunc;
  apiResponse: IapiResponse;
  options: Ioptions;
}) => {
  const navigate = useNavigate();
  const [curretUserId, setCurretUserId] = useState(0);
  const [currentPlantId, setCurrentPlantId] = useState(0);

  const INITIAL_CURRENT_USER_ID = 0;

  return (
    <li className="flex px-5 mt-[15px] pb-[15px] border-b-[1px] border-b-border-color-dividers">
      <div className="max-w-[280px] w-full flex items-center">
        <span
          className="h-[20px] text-main-color-primary border-b-main-color-primary border-b-[1px] hover:border-b-[#b1159f] hover:text-[#b1159f] cursor-pointer"
          onClick={() => navigate(`/detail/${item.plant_id}`)}
        >
          {item.plant_name}
        </span>
      </div>
      <div className="max-w-[250px] w-full flex flex-col items-center justify-center gap-y-[12px]">
        <div className="w-full flex items-center justify-end gap-x-[15px] whitespace-pre">
          <div className="relative">
            <input
              className={classNames(
                "w-[145px] h-[21px] text-right text-general-red",
                { "outline-none": state.updateInput },
                {
                  "border-[1px] border-border-color-dividers rounded-lg px-[5px]":
                    !state.updateInput,
                },
              )}
              type={"text"}
              readOnly={state.updateInput}
              maxLength={15}
              value={
                state.updateInput
                  ? HandleInputTextComma(state.copyPrice) + " 원"
                  : HandleInputTextComma(state.copyPrice)
              }
              onChange={(e) => func.setCopyPrice(InputComma(e))}
            />
          </div>
          <button
            className="text-text-color-disabled relative"
            onClick={() => {
              if (state.updateInput) {
                func.setUpdateInput(!state.updateInput);
                func.setSelectPlantId(item.plant_id);
              } else {
                if (!state.copyPrice) {
                  return alert("금액을 작성해주시길 바랍니다.");
                }
                return confirm(
                  `가격을 ${HandleComma(
                    state.copyPrice,
                  )} 원으로 변경하시겠습니까? \n변경하신 후에는 구매자들에게 알림 메일이 발송됩니다.`,
                )
                  ? func.submitPrice(item.plant_id, state.copyPrice)
                  : null;
              }
            }}
          >
            {apiResponse.submitLoading &&
            item.plant_id === state.selectPlantId ? (
              <div className="absolute w-[16px] top-[-8px]">
                <DefaultLoading />
              </div>
            ) : state.updateInput ? (
              <span className="border-b-[1px] border-b-border-color-dividers">
                수정
              </span>
            ) : (
              // TODO:변경 취소하기 및 서버의 추가적인 처리가 필요한 부분 check 합니다.
              <span className="border-b-[1px] border-b-border-color-dividers">
                변경하기
              </span>
            )}
          </button>
          <button className="text-text-color-disabled relative">
            <span
              className="border-b-[1px] border-b-border-color-dividers"
              onClick={() => func.onClickPriceHistory(item.plant_id)}
            >
              변경 이력
            </span>
            {state.selectedPriceHistory.plantId === item.plant_id && (
              <MypagePriceHistoryFetch
                plantId={state.selectedPriceHistory.plantId}
                type={state.selectedPriceHistory.type}
              />
            )}
          </button>
        </div>
        <div className="w-full text-right text-default text-text-color-disabled">
          {state.saveCreateDate}
        </div>
      </div>
      <dl className="max-w-[280px] w-full flex flex-col gap-y-[12px] text-right">
        {item.buyer_list.map((el, idx) => (
          <dt className="flex justify-center flex-col  gap-y-[12px]" key={idx}>
            <div key={idx}>{HandleComma(el.price)} 원</div>
            <div className="text-default text-text-color-disabled">
              {getDayIncodeDate(el.created_datetime)}
            </div>
          </dt>
        ))}
      </dl>
      <dl className="max-w-[240px] w-full flex flex-col gap-y-[15px] justify-center">
        {item.buyer_list.length ? (
          item.buyer_list.map((el, idx) => (
            <dt className="relative" key={idx}>
              <div className="h-[45px] flex gap-x-[10px] items-start justify-end">
                <div
                  className="flex border-b-[1px] gap-x-[7px] text-main-color-primary border-b-main-color-primary cursor-pointer hover:border-b-[#b1159f] hover:text-[#b1159f]"
                  onClick={() => {
                    setCurretUserId(
                      currentPlantId === item.plant_id &&
                        el.user_id === curretUserId
                        ? INITIAL_CURRENT_USER_ID
                        : el.user_id,
                    );
                    setCurrentPlantId(item.plant_id);
                    func.setCurrentMessagePlantId(options.id);
                  }}
                >
                  <h1 className="max-w-[170px] whitespace-nowrap overflow-hidden text-ellipsis ">
                    {el.username}

                    {currentPlantId === item.plant_id &&
                      curretUserId === el.user_id && (
                        <div className="absolute top-[25px] z-[1]">
                          <UserBox
                            func={{
                              setModalTalk: func.setModalTalk,
                              setModalPlantList: func.setModalPlantList,
                            }}
                            option={{
                              type: "seller",
                              userId: el.user_id,
                              username: el.username,
                            }}
                          />
                        </div>
                      )}
                  </h1>
                </div>
              </div>
            </dt>
          ))
        ) : (
          <dt className="text-text-color-disabled font-bold">
            구매 희망자 없음
          </dt>
        )}
      </dl>
      <dl className="w-[50px] flex flex-col gap-y-[16px] justify-center items-end">
        {item.buyer_list.map((el, idx) => (
          <dt key={idx} className="h-[44px]">
            {newBadgeSetting(
              {
                type: "sellerList",
                currentTime: el.created_datetime,
              },
              {
                parentIndex: options.id,
                parentLenght: options.length,
                childIndex: idx,
                childLength: item.buyer_list.length,
              },
            ) && (
              <span className="text-general-red animate-twinkle text-default font-semibold">
                NEW
              </span>
            )}
          </dt>
        ))}
      </dl>
    </li>
  );
};
