import { useEffect, useRef } from "react";
import { ChatMessage } from "../../../components/Chat/ChatMessage";
import { IcopyMessageList } from "../types/state.type";
import { ImessageList } from "../types/item.type";

type IselectOption = {
  plant: string;
  currentMessageId: number;
  user: string;
};

interface Istate {
  copyMessageList: IcopyMessageList[];
  selectOption: IselectOption;
  text: string;
}

interface Ifunc {
  setCopyMessageList: (value: IcopyMessageList[]) => void;
  setText: (text: string) => void;
  handleKeyDown: (
    key: string,
    shiftKey: boolean,
    e: React.KeyboardEvent<HTMLFormElement>,
  ) => void;
  handleSubmit: () => void;
}

interface IapiResponse {
  userMessageStatus: string;
  userSendMessageStatus: string;
  userSendMessageError: boolean;
  userSendMessageErrorCode: string;
  userSendMessageErrorStatus: number;
}

interface Iitem {
  userMessageData: ImessageList[];
}

export const ChatMessageModify = ({
  state,
  func,
  item,
  apiResponse,
}: {
  state: Istate;
  func: Ifunc;
  item: Iitem;
  apiResponse: IapiResponse;
}) => {
  const scrollRef = useRef<any>(null);

  useEffect(() => {
    if (state.selectOption.user) {
      scrollRef.current.scrollTop = scrollRef.current?.scrollHeight;
    }
  }, [state.copyMessageList, apiResponse.userSendMessageStatus]);

  return (
    <ChatMessage
      state={{
        selectOption: state.selectOption,
        copyMessageList: state.copyMessageList,
        text: state.text,
      }}
      func={{
        setCopyMessageList: func.setCopyMessageList,
        setText: func.setText,
        handleKeyDown: func.handleKeyDown,
        handleSubmit: func.handleSubmit,
      }}
      item={{
        ...item,
      }}
      apiResoponse={{
        ...apiResponse,
      }}
      options={{
        scrollRef: scrollRef,
      }}
    />
  );
};
