import { AxiosError } from "axios";
import { useMutation } from "react-query";
import { axiosSendMessage } from "../api/axiosPostRequest";

export const useSendMessageQuery = () =>
  useMutation(axiosSendMessage, {
    onError: (err: AxiosError, val) => {
      console.error(`from ${val.apiName} ERROR`, err);
    },
  });
