import { HandleComma } from "../../../utils_public/Comma";

export const woodCalculate = (capacity: number | string) => {
  if (typeof capacity === "string" || !capacity) return "-";
  // 서버에서 받아온 용량 재정의
  const REDEFINE_CAPACITY = capacity * 1000;
  // 탄소 배출량
  const CARBON_VALUE = 0.4747;
  // 나무 1그루가 흡수하는 탄소량
  const CARBON_ABSORPTION_FROM_WOOD = 0.81;
  // 이번달 지구내 탄소감축 기여 예상량
  const CARBON_FOOTPRINT = REDEFINE_CAPACITY * CARBON_VALUE;

  return HandleComma(CARBON_FOOTPRINT * CARBON_ABSORPTION_FROM_WOOD);
};

export const electricityUsageCalculate = (
  capacity: number | string,
  hourDaily: number,
) => {
  if (typeof capacity === "string" || !capacity || !hourDaily) return "-";

  // 서버에서 받아온 용량 재정의
  const REDEFINE_CAPACITY = capacity * 1000;

  // 4인가구 전력 예측량
  const HOUSEHOLD_ELECTRICITY = 351.4;

  return HandleComma((REDEFINE_CAPACITY * hourDaily) / HOUSEHOLD_ELECTRICITY);
};
