import { useState } from "react";
import { Ifile } from "../../../pages/Mypage/types/state.type";
import { DragDropFileContainer } from "../../UI/DragAndDrop/DragDropFile";

export const MypageSubmit = ({
  energeFile,
  setEnergeFile,
}: {
  energeFile: Ifile;
  setEnergeFile: (file: Ifile) => void;
}) => {
  return (
    <section className="mb-[60px]">
      <h1 className="mb-4 text-listFirst font-bold">신재생에너지설비 인증서</h1>
      <div className="max-w-full w-full h-[178px]">
        <DragDropFileContainer
          file={energeFile}
          setFile={setEnergeFile}
          title={"신재생에너지 설비 인증서"}
          input_id="input-file-upload-cert"
          content={""}
        />
      </div>
    </section>
  );
};
