import { HandleComma } from "../../../utils_public/Comma";
import { DefaultLoading } from "../Loading/Loading";
import { getDayIncodeDate } from "../../../utils_public/dateFormat";
import { priceHistoryListType } from "../../../pages/Mypage/types/item.type";

export const PriceHistoryBox = ({
  priceHistoryList,
  isLoading,
}: {
  priceHistoryList: priceHistoryListType[] | undefined;
  isLoading: boolean;
}) => {
  return (
    <ul className="flex flex-col gap-[8px] bg-general-white p-[12px] text-text-color-primary absolute shadow-lg border-[1px] border-border-color-dividers rounded-md z-10">
      {isLoading && (
        <div>
          <DefaultLoading />
        </div>
      )}

      {!isLoading &&
        !!priceHistoryList?.length &&
        priceHistoryList.map((el, idx) => (
          <PriceHistoryBoxItem
            key={idx}
            price={el.price}
            date={el.created_datetime}
          />
        ))}
    </ul>
  );
};

const PriceHistoryBoxItem = ({
  price,
  date,
}: {
  price: number;
  date: string;
}) => {
  return (
    <li className="flex items-center  justify-between gap-x-[12px] gap-y-[4px]">
      <span className="text-default">{HandleComma(price)} 원</span>
      <span className="text-smallExplanation text-text-color-secondary">
        {getDayIncodeDate(date)}
      </span>
    </li>
  );
};
