import { useNavigate } from "react-router-dom";
import { DefaultLoading } from "../../UI/Loading/Loading";

interface Iitem {
  ownerComplete: number;
  forSailComplete: number;
  favoriteCount: number;
}

interface IapiResponse {
  countStatus: string;
}

export const MypageAlonePlantCurrent = ({
  item,
  apiResponse,
}: {
  item: Iitem;
  apiResponse: IapiResponse;
}) => {
  const navigate = useNavigate();
  return (
    <section className="w-[100%]">
      <h1 className="text-listFirst font-bold mb-4 max-sm:font-medium">
        발전소 현황
      </h1>
      <div className="w-full h-[176px] px-[20px] flex items-center gap-x-[82px] justify-center bg-general-lightGray rounded-xl max-2xl:gap-x-[32px] max-md:flex-col max-md:h-full max-sm:h-[210px]">
        <main className="max-w-[407px] w-full flex gap-x-[60px] max-xl:gap-x-[0px] max-md:mt-[30px]">
          <div className="flex flex-col items-center gap-y-[8px] max-xl:w-full">
            <h1
              className={`text-[44px] ${
                item.ownerComplete
                  ? "text-main-color-primary"
                  : "text-text-color-disabled"
              } max-sm:text-[38px] max-sm:mb-0`}
            >
              {apiResponse.countStatus === "loading" ? (
                <div className="h-[52.5px]">
                  <DefaultLoading />
                </div>
              ) : (
                item.ownerComplete
              )}
            </h1>
            <span className="text-listSecond max-2xl:text-default max-sm:text-footer">
              소유주 등록 완료
            </span>
          </div>
          <div className="flex flex-col items-center gap-y-[8px] max-xl:w-full">
            <h1
              className={`text-[44px] ${
                item.forSailComplete
                  ? "text-main-color-primary"
                  : "text-text-color-disabled"
              } max-sm:text-[38px] max-sm:mb-0`}
            >
              {apiResponse.countStatus === "loading" ? (
                <div className="h-[52.5px]">
                  <DefaultLoading />
                </div>
              ) : (
                item.forSailComplete
              )}
            </h1>
            <span className="text-listSecond max-2xl:text-default max-sm:text-footer">
              매물 등록 완료
            </span>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer gap-y-[8px] max-xl:w-full"
            onClick={() => navigate("favorite")}
          >
            <h1
              className={`text-[44px] ${
                item.favoriteCount
                  ? "text-main-color-primary"
                  : "text-text-color-disabled"
              } max-sm:text-[38px] max-sm:mb-0`}
            >
              {apiResponse.countStatus === "loading" ? (
                <div className="h-[52.5px]">
                  <DefaultLoading />
                </div>
              ) : (
                item.favoriteCount
              )}
            </h1>
            <span className="text-listSecond max-2xl:text-default max-sm:text-footer">
              찜한 발전소 수
            </span>
          </div>
        </main>
        <div className=" h-1/2 border-x-[1px] border-border-color-dividers max-md:border-x-[0px]"></div>
        <div
          className="max-w-[407px] group flex justify-center items-center gap-x-[16px] py-[30px]
          text-main-color-primary text-listFirst cursor-pointer transition-all
          hover:text-text-color-primary max-sm:text-listSecond max-sm:max-w-full"
          onClick={() => navigate("myPlant")}
        >
          <h1 className="whitespace-nowrap">발전소 등록하기</h1>
          <svg
            width="100%"
            height="8"
            viewBox="0 0 227 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-main-color-primary group-hover:fill-text-color-primary"
          >
            <path d="M226.354 4.35355C226.549 4.15829 226.549 3.84171 226.354 3.64645L223.172 0.464466C222.976 0.269204 222.66 0.269204 222.464 0.464466C222.269 0.659728 222.269 0.976311 222.464 1.17157L225.293 4L222.464 6.82843C222.269 7.02369 222.269 7.34027 222.464 7.53553C222.66 7.7308 222.976 7.7308 223.172 7.53553L226.354 4.35355ZM0 4.5H226V3.5H0V4.5Z" />
          </svg>
        </div>
      </div>
    </section>
  );
};
