export const SkeletonListContainer = ({ count }: { count: number }) => {
  const elements = Array.from(Array(count)).map((el, idx) => (
    <SkeletonListItem key={idx} />
  ));

  return <>{elements.map((el) => el)}</>;
};

export const SkeletonListItem = () => {
  return (
    <li className="relative cursor-pointer animate-pulse ">
      <div className="w-full h-[200px] bg-[#D9D9D9] rounded-xl max-2xl:h-[160px]"></div>
      <div className="h-[21px] flex items-center text-lg mt-4">
        <div className="w-[18px] h-[18px] bg-[#D9D9D9] rounded-xl"></div>
        <div className="w-[85%] h-[21px] ml-2 bg-[#D9D9D9] rounded-xl"></div>
      </div>
      <div className="w-full flex mt-3">
        <div className="w-[70px] h-[15px] mr-3 bg-[#D9D9D9] rounded-xl"></div>
        <div className="w-[60px] h-[15px]  mr-3  bg-[#D9D9D9] rounded-xl "></div>
        <div className="w-[70px] h-[15px] bg-[#D9D9D9] rounded-xl"></div>
      </div>
      <div className="w-full flex mt-2">
        <div className="w-[70px] h-[15px] mr-3 bg-[#D9D9D9] rounded-xl"></div>
        <div className="w-[60px] h-[15px]  mr-3  bg-[#D9D9D9] rounded-xl"></div>
      </div>
    </li>
  );
};
