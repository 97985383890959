import { MypageUserPwInput } from "../../UI/Input_Text/Mypage/MypageUserPwInput";
import { IuserPasswordValidation } from "../../../pages/Mypage/types/state.type";

interface signature {
  [key: string]: string;
}

interface signatureRef {
  [key: string]: React.RefObject<HTMLInputElement>;
}

interface IserverError {
  code: string;
  message: string;
}

interface Istate {
  pwButton: boolean;
  input: signature;
  passwordValidation: IuserPasswordValidation;
  passwordCheck: boolean;
  passwordVerifyCheck: boolean;
}

interface Ifunc {
  passwordOpen: (bool: boolean) => void;
  setInput: (userModify: signature) => void;
  blurInput: (name: string) => void;
}

interface IapiResponse {
  serverError?: IserverError;
}

interface Ioptions {
  passwordRef: React.RefObject<HTMLInputElement>;
  passwordVerifyRef: React.RefObject<HTMLInputElement>;
}

export const MypageUserPassword = ({
  state,
  func,
  apiResponse,
  options,
}: {
  state: Istate;
  func: Ifunc;
  apiResponse: IapiResponse;
  options: Ioptions;
}) => {
  return (
    <div className="w-full">
      {state.pwButton ? (
        <UserPasswordContainer
          passwordValidation={state.passwordValidation}
          input={state.input}
          passwordCheck={state.passwordCheck}
          passwordVerifyCheck={state.passwordVerifyCheck}
          setInput={func.setInput}
          blurInput={func.blurInput}
          options={{ ...options }}
          apiResponse={{
            serverError: apiResponse.serverError,
          }}
        />
      ) : (
        <div className="max-w-[523px] w-full flex justify-between max-md:justify-start">
          <input type="password" value="@@@@@@@@@@" disabled />
          <button
            className="py-2 px-3 text-default border-[1px] border-main-color-primary rounded-lg"
            onClick={() => func.passwordOpen(true)}
          >
            비밀번호 변경
          </button>
        </div>
      )}
    </div>
  );
};

const UserPasswordContainer = ({
  input,
  setInput,
  passwordValidation,
  passwordCheck,
  passwordVerifyCheck,
  blurInput,
  options,
  apiResponse,
}: {
  input: signature;
  setInput: (userModify: signature) => void;
  passwordValidation: IuserPasswordValidation;
  passwordCheck: boolean;
  passwordVerifyCheck: boolean;
  blurInput: (name: string) => void;
  options: Ioptions;
  apiResponse: IapiResponse;
}) => {
  const INPUT_REF_TYPE: signatureRef = {
    password: options.passwordRef,
    passwordVerify: options.passwordVerifyRef,
  };
  return (
    <>
      {PASSWORD_OPTIONS.map((el, idx) => (
        <MypageUserPwInput
          key={idx}
          state={{
            input: input,
            passwordValidation: passwordValidation,
            passwordCheck: passwordCheck,
            passwordVerifyCheck: passwordVerifyCheck,
          }}
          func={{
            setInput: setInput,
            blurInput: blurInput,
          }}
          options={{
            ...el,
            ref: INPUT_REF_TYPE[el.name],
          }}
          apiResponse={{
            serverError: apiResponse.serverError,
          }}
        />
      ))}
    </>
  );
};

const PASSWORD_MODIFY_VALIDATION = [
  {
    type: "length",
    text: "8~30자 이내",
  },
  {
    type: "number",
    text: "숫자 포함",
  },
  {
    type: "special",
    text: "특수문자 포함",
  },
];
const PASSWORD_CHECK_VALIDATION = [
  {
    type: "same",
    text: "비밀번호 일치",
  },
];

const PASSWORD_OPTIONS = [
  {
    title: "변경 비밀번호",
    name: "password",
    passwordValiText: PASSWORD_MODIFY_VALIDATION,
  },
  {
    title: "비밀번호 확인",
    name: "passwordVerify",
    passwordValiText: PASSWORD_CHECK_VALIDATION,
  },
];
