import { usePlantInfoQuery } from "../query/usePlantInfoQuery";

import { IplantInfo } from "../types/plantInfo.type";

export const useGetPlantInfo = (id?: string, token?: string) => {
  const { data, status } = usePlantInfoQuery(`api/plant/${id}/`, {
    apiName: "Plant Information",
    authorization: token,
  });

  return data
    ? {
        plantData: {
          id: data.id ? data.id : 0,
          plant_name: data.plant_name ? data.plant_name : "-",
          addr_lv1: data.addr_lv1 ? data.addr_lv1 : "-",
          addr_lv2: data.addr_lv2 ? data.addr_lv2 : "",
          addr_lv3: data.addr_lv3 ? data.addr_lv3 : "",
          capacity: data.capacity ? data.capacity : 0,
          completion_date: data.completion_date ? data.completion_date : "",
          expected_price: data.expected_price ? data.expected_price : 0,
          latitude: data.latitude ? data.latitude : 0,
          longitude: data.longitude ? data.longitude : 0,
          is_im_owner: data.is_im_owner ? data.is_im_owner : false,
          is_like: data.is_like ? data.is_like : false,
          is_owner: data.is_owner ? data.is_owner : false,
          land_area: data.land_area ? data.land_area : "-",
          land_name: data.land_name ? data.land_name : "-",
          land_price: data.land_price ? data.land_price : 0,
          member_type: data.member_type ? data.member_type : "-",
          gen: {
            gen_hourly_today:
              data.gen && data.gen.gen_hourly_today
                ? data.gen.gen_hourly_today
                : [],
            gen_hourly_tomorrow:
              data.gen && data.gen.gen_hourly_tomorrow
                ? data.gen.gen_hourly_tomorrow
                : [],
            gen_monthly_last_year:
              data.gen && data.gen.gen_monthly_last_year
                ? data.gen.gen_monthly_last_year
                : [],
            sum_gen_hour_daily:
              data.gen && data.gen.sum_gen_hour_daily
                ? data.gen.sum_gen_hour_daily
                : 0,
          },
          sell_info: {
            created_datetime:
              data.sell_info && data.sell_info.created_datetime
                ? data.sell_info.created_datetime
                : "",
            price:
              data.sell_info && data.sell_info.price ? data.sell_info.price : 0,
          },
          trade_info: {
            area:
              data.trade_info && data.trade_info.area
                ? data.trade_info.area
                : 0,
            price:
              data.trade_info && data.trade_info.price
                ? data.trade_info.price
                : 0,
          },
        },
        plantStatus: status,
      }
    : { plantData: DEFAULT_PLANTINFO, plantStatus: status };
};

const DEFAULT_PLANTINFO: IplantInfo = {
  id: 0,
  plant_name: "-",
  addr_lv1: "-",
  addr_lv2: "",
  addr_lv3: "",
  capacity: 0,
  completion_date: "",
  expected_price: "-",
  latitude: 0,
  longitude: 0,
  is_im_owner: false,
  is_like: false,
  is_owner: false,
  land_area: "-",
  land_name: "-",
  land_price: 0,
  member_type: "-",
  gen: {
    gen_hourly_today: [],
    gen_hourly_tomorrow: [],
    gen_monthly_last_year: [],
    sum_gen_hour_daily: 0,
  },
  sell_info: {
    created_datetime: "",
    price: 0,
  },
  trade_info: {
    area: 0,
    price: 0,
  },
};
