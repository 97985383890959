import { useCookies } from "react-cookie";
import { useGetMenuUserInfo } from "./hooks/useGetMenuUserInfo";
import { useGetBuySellRecently } from "./hooks/useGetBuySellRecently";
import { MypageModify } from "./Mypage.modify";

export const MypageFetch = () => {
  const [cookie] = useCookies(["60hz-user"]);

  const { userInfo, isLoading } = useGetMenuUserInfo(cookie["60hz-user"]);
  const { recently } = useGetBuySellRecently(cookie["60hz-user"]);

  return (
    <MypageModify
      item={{
        userInfo: userInfo,
        recently: recently,
      }}
      apiResponse={{
        userInfoLoading: isLoading,
      }}
    />
  );
};
