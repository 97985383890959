import { useLocation } from "react-router-dom";
import { FooterDefault } from "./FooterDefault";
import { FooterLogin } from "./FooterLogin";

export const Footer = () => {
  const location = useLocation();
  return location.pathname === "/login" ? (
    <FooterLogin current={location.search} />
  ) : (
    <FooterDefault />
  );
};
