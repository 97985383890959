import { ImyPlantStatusList } from "../../../../pages/Mypage/types/plantInfo.type";
import { MypageCertificationList } from "./MypageCertificationList";
import { MypageCertificationListTablet } from "./MypageCertificationListTablet";

export const MypageCertification = ({
  plantList,
  status,
}: {
  plantList: ImyPlantStatusList[];
  status: string;
}) => {
  return (
    <section>
      <div className="flex items-center mb-4">
        <h1 className="text-listFirst font-bold max-sm:font-medium">
          내 발전소
        </h1>
      </div>

      <MypageCertificationList
        item={{ plantList: plantList }}
        apiResponse={{ status: status }}
      />

      <MypageCertificationListTablet
        item={{ plantList: plantList }}
        apiResponse={{ status: status }}
      />
    </section>
  );
};
