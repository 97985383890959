import { moneyUnit } from "../../../utils_public/Unit";

interface IdetailWorthBottom {
  IndividuallyPrice: number | string;
  landCategory: string;
}

export const DetailWorthBottom = ({
  landCategory,
  IndividuallyPrice,
}: IdetailWorthBottom) => {
  return (
    <div className="h-[92px] flex">
      <div className="w-[270px] max-md:w-[185px] max-sm:w-[170px]">
        <h1 className="text-subValue mb-[6px] max-md:text-listTitle">
          {landCategory}
        </h1>
        <span className="max-md:text-default">지목</span>
      </div>

      <div className="flex flex-col items-center">
        <div className="w-[130px]">
          <h1 className="text-subValue mb-[6px] max-md:text-listTitle">
            {moneyUnit(IndividuallyPrice)}
          </h1>
          <div className="flex items-center">
            <span className="max-md:text-default">개별 공시지가</span>
            <div className="group relative pl-[6px] cursor-pointer mb-[1px]">
              <img src="/icon_remodel/information.svg" />

              <p
                className="w-[427px] px-[16px] py-[10px] bg-main-color-sub absolute left-[-15px] top-7 hidden text-footer rounded-lg group-hover:block
              before:content-[''] before:absolute before:w-0 before:h-0 before:border-[8px] before:border-solid before:border-[transparent]
              before:border-b-main-color-sub before:top-[-15px] before:left-[21px] z-50 leading-[21px] shadow-xl max-md:text-[10px] max-md:w-[335px] max-md:top-[20px] max-md:left-[-240px] max-md:before:top-[-15px] max-md:before:left-[247px]
            "
              >
                ﹣개별 공시지가는 표준지 공시지가를 기준으로 산정한 <br />
                &nbsp;&nbsp;&nbsp; 단위면적당(m<sup>2</sup>) 가격으로 시장,
                군수, 구청장이 조사 및 평가한 가격입니다.
                <br />
                ﹣개별 공시지가는 각종 부동산 세금과 부담금의 산정 기준으로
                활용됩니다.
                <br />
                ﹣본 공시가격은 공공데이터 포털에서 제공하고 있는 주택
                공시가격정보를 <br />
                &nbsp;&nbsp;&nbsp;&nbsp;기반으로 제공하고 있으며, 공공데이터
                포털에서 제공하지 않는 일부 지역의 <br />
                &nbsp;&nbsp;&nbsp;&nbsp;공시가격은 햇빛바람거래소에서 제공하지
                않습니다.
              </p>
            </div>
          </div>
          <p className="mt-[6px] mb-[4px] text-smallExplanation leading-[100%] text-text-color-secondary max-md:text-[11px]">
            출처: 국가공간정보포털
          </p>
          <p className="text-smallExplanation text-text-color-secondary max-md:text-[11px]">
            최근 업데이트: 2023
          </p>
        </div>
      </div>
    </div>
  );
};
