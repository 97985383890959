import { IfilterItem } from "../../../pages/Mypage/types/state.type";

interface Istate {
  toggle: boolean;
  filter: IfilterItem;
}

interface Ifunc {
  selectFilter: (type: string, value: string) => void;
}

export const LandCategoryRadioList = ({
  state,
  func,
}: {
  state: Istate;
  func: Ifunc;
}) => {
  return (
    <div
      className={`grid grid-cols-[1fr_1fr] ${
        state.toggle ? "mb-12" : ""
      }   text-default overflow-hidden transition-all duration-150  ${
        state.toggle ? "max-h-screen " : "max-h-0"
      }`}
    >
      {RADIO_LIST.map((el: string, idx: number) => (
        <div className="flex items-center mb-[16px] radio-buttons" key={idx}>
          <label className="radio-button">
            {el}
            <input
              type="radio"
              onChange={() => func.selectFilter("land", el)}
              checked={state.filter["land"] === el}
            />
            <span className="custom-radio"></span>
          </label>
        </div>
      ))}
    </div>
  );
};

const RADIO_LIST: string[] = [
  "전체",
  "대지",
  "전",
  "공장",
  "답",
  "주차장",
  "과수원",
  "잡종지",
  "목장용지",
  "기타",
  "임야",
];
