import { useBuySellRecentlyQuery } from "../query/useBuySellRecentlyQuery";

export const useGetBuySellRecently = (cookie: string) => {
  const { data } = useBuySellRecentlyQuery("api/v1/my-trade/recently/", {
    cookie: cookie,
    apiName: "BuySellRecently",
  });

  return {
    recently: data
      ? {
          buy_menu_recently_datetime:
            data.buy_trade_stakeholder_sell_update_recently_datetime,
          sell_menu_recently_datetime:
            data.sell_trade_stakeholder_buy_update_recently_datetime,
        }
      : DUMMY_RECENTLY_INFORMATION,
  };
};

const DUMMY_RECENTLY_INFORMATION = {
  buy_menu_recently_datetime: null,
  sell_menu_recently_datetime: null,
};
