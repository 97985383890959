import { useNavigate } from "react-router-dom";
import { ImyPlantStatusList } from "../../../../pages/Mypage/types/plantInfo.type";
import { DefaultLoading } from "../../../UI/Loading/Loading";
import { MypageCertificationItem } from "./MypageCertificationItem";
import { MypageCertificationMenu } from "./MypageCertificationMenu";

interface Iitem {
  plantList: ImyPlantStatusList[];
}

interface IapiResponse {
  status: string;
}

export const MypageCertificationList = ({
  item,
  apiResponse,
}: {
  item: Iitem;
  apiResponse: IapiResponse;
}) => {
  const navigate = useNavigate();
  return (
    <div className="max-lg:hidden">
      <MypageCertificationMenu />

      {apiResponse.status === "loading" ? (
        <div className="flex h-[100px] mt-10">
          <DefaultLoading />
        </div>
      ) : (
        <>
          <ul className="pt-2 mb-[32px] ">
            {item.plantList.length ? (
              item.plantList.map((el) => <MypageCertificationItem {...el} />)
            ) : (
              <h1 className="mt-6 text-center text-text-color-secondary text-listFirst">
                소유주 등록하고 내 발전소를 자랑해보세요!
              </h1>
            )}
          </ul>
          <div className="flex justify-center gap-x-2 cursor-pointer text-main-color-primary">
            <span>+</span>
            <button onClick={() => navigate("/mypage/myPlant/submit")}>
              새로 등록하기
            </button>
          </div>
        </>
      )}
    </div>
  );
};
