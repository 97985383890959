import { useQuery } from "react-query";
import { axiosREC } from "../api/axiosGetRequest";

interface IuseuseRECQuery {
  apiName: string;
}

export const useRECQuery = (url: string, info: IuseuseRECQuery) =>
  useQuery(["RECFromDetail", url], axiosREC, {
    onError: (err) => {
      console.error(`from ${info.apiName} ERROR`, err);
    },
  });
