import { useQuery, useMutation } from "react-query";
import { axiosInquiryList } from "../api/axiosGetRequest";
import { axiosDeleteInquiryList } from "../api/axiosDeleteRequest";
import { axiosPatchInquiryList } from "../api/axiosPatchRequest";
import { AxiosError } from "axios";

export const useGetInquiryListQuery = (
  url: string,
  info: { cookie?: string; apiName: string },
) =>
  useQuery([info.apiName, url, info.cookie], axiosInquiryList, {
    onError: (err) => {
      console.error(`from ${info.apiName} ERROR`, err);
    },
    cacheTime: 0,
    retry: false,
  });

export const useDeleteInquiryListQuery = () =>
  useMutation(axiosDeleteInquiryList, {
    onError: (err: AxiosError, val) => {
      console.error(`from ${val.apiName} ERROR`, err);
    },
  });

export const useUpdateInquiryListQuery = () =>
  useMutation(axiosPatchInquiryList, {
    onError: (err: AxiosError, val) => {
      console.error(`from ${val.apiName} ERROR`, err);
    },
  });
