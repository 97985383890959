import { IfilterItem } from "../../../pages/Mypage/types/state.type";

interface Istate {
  toggle: boolean;
  filter: IfilterItem;
}

interface Ifunc {
  selectFilter: (type: string, value: string) => void;
}

export const LoacalRadioList = ({
  state,
  func,
}: {
  state: Istate;
  func: Ifunc;
}) => {
  return (
    <div
      className={`grid grid-cols-[1fr_1fr] ${
        state.toggle ? "mb-12" : ""
      }  flex-col text-default overflow-hidden transition-all duration-100  ${
        state.toggle ? "max-h-screen " : "max-h-0"
      }`}
    >
      {RADIO_LIST.map((el: string, idx: number) => (
        <div className="flex items-center mb-[16px] radio-buttons" key={idx}>
          <label className="radio-button">
            {el}
            <input
              type="radio"
              onChange={() => func.selectFilter("local", el)}
              checked={state.filter["local"] === el}
            />
            <span className="custom-radio"></span>
          </label>
        </div>
      ))}
    </div>
  );
};

const RADIO_LIST: string[] = [
  "전국",
  "서울",
  "경기",
  "인천",
  "강원",
  "경북",
  "경남",
  "대구",
  "부산",
  "울산",
  "충남",
  "충북",
  "대전",
  "세종",
  "제주",
  "전남",
  "전북",
  "광주",
];
