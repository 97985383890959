import { useQuery } from "react-query";
import { axiosPlantInfo } from "../api/axiosGetRequest";

interface IusePlantInfo {
  apiName: string;
  authorization?: string;
}

export const usePlantInfoQuery = (url: string, info: IusePlantInfo) =>
  useQuery(
    ["plantInformationFromDetail", url, info.authorization],
    axiosPlantInfo,
    {
      onError: (err) => {
        console.error(`from ${info.apiName} ERROR`, err);
      },
      cacheTime: 0,
      retry: false,
    },
  );
