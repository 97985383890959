import { MypagePlantCurrent } from "./MypagePlantCurrent";
import { MypagePlantProfile } from "./MypagePlantProfile";
import { MypageAlonePlantCurrent } from "./MypageAlonePlantCurrent";
import {
  IcurrentPlant,
  ImyPlantProfile,
} from "../../../pages/Mypage/types/main.typs";
import { DefaultLoading } from "../../UI/Loading/Loading";

interface Iitem {
  currentPlant: IcurrentPlant;
  yearsPlant: ImyPlantProfile;
}

interface IapiResponse {
  countStatus: string;
  yearsStatus: string;
  yearsIsError: boolean;
}

export const MypageMain = ({
  item,
  apiResponse,
}: {
  item: Iitem;
  apiResponse: IapiResponse;
}) => {
  return (
    <div
      className={`max-w-[1222px] w-[100%] 
        flex justify-between
       gap-x-8 max-2xl:gap-x-6`}
    >
      {apiResponse.yearsStatus === "loading" ? (
        <div className="w-[100%] h-[100px] mx-auto mt-[120px]">
          <DefaultLoading />
        </div>
      ) : !apiResponse.yearsIsError ? (
        <MypageAlonePlantCurrent
          item={{
            ownerComplete: item.currentPlant.ownerComplete,
            forSailComplete: item.currentPlant.forSailComplete,
            favoriteCount: item.currentPlant.favoriteCount,
          }}
          apiResponse={{
            countStatus: apiResponse.countStatus,
          }}
        />
      ) : (
        <div className="max-w-full w-full flex justify-between gap-x-[12px] max-md:flex-col max-md:gap-y-[32px]">
          <MypagePlantCurrent
            item={{
              ownerComplete: item.currentPlant.ownerComplete,
              forSailComplete: item.currentPlant.forSailComplete,
              favoriteCount: item.currentPlant.favoriteCount,
            }}
            apiResponse={{
              countStatus: apiResponse.countStatus,
            }}
          />
          <MypagePlantProfile
            item={{
              yearsPlant: item.yearsPlant,
            }}
            apiResponse={{
              yearsStatus: apiResponse.yearsStatus,
              yearsIsError: apiResponse.yearsIsError,
            }}
            // yearsPlant={yearsPlant}
            // yearsStatus={yearsStatus}
            // yearsIsError={yearsIsError}
          />
        </div>
      )}
    </div>
  );
};
