import { useFavoritePlantQuery } from "../query/useFavoritePlantQuery";

export const useGetFavoritePlant = (cookie: string) => {
  const { data, status } = useFavoritePlantQuery("liked-plants/", {
    apiName: "favoritePlant",
    cookie: cookie,
  });

  return {
    plantList: data ? data.plant_list : [],
    status: status,
  };
};
