import { useState } from "react";
import { LoginModalModify } from "../mudule_modify/LoginModal.modify";
import {
  emailModal,
  passwordModal,
  verificationdModal,
} from "../types/modal.type";
import { useFindUserInfoQuery } from "../query/useFindUserInfoQuery";

interface Ioptions {
  modalType: string;
  textType: emailModal | passwordModal | verificationdModal;
}

interface Ifunc {
  setCurrentModal: (text: string) => void;
}

export const LoginModalFetch = ({
  options,
  func,
}: {
  options: Ioptions;
  func: Ifunc;
}) => {
  const [inputText, setInputText] = useState(options.textType);

  // 유저 정보 찾기 React-Query POST 설정
  const POST_FIND_USER = useFindUserInfoQuery();

  // 유저 정보 찾기 요청
  const submitFinding = (url: string) => {
    POST_FIND_USER.mutate({
      apiName: `Finding User ${url}`,
      submitInfo: inputText,
      url: url,
    });
  };

  return (
    <>
      <LoginModalModify
        state={{
          inputText: inputText,
        }}
        func={{
          setCurrentModal: func.setCurrentModal,
          setInputText: setInputText,
          submitFinding: submitFinding,
        }}
        item={{
          email: POST_FIND_USER.data && POST_FIND_USER.data["email"],
        }}
        apiResponse={{
          loading: POST_FIND_USER.isLoading,
          success: POST_FIND_USER.isSuccess,
          axiosCode:
            POST_FIND_USER.error && POST_FIND_USER.error.code
              ? POST_FIND_USER.error.code
              : "",
          error: POST_FIND_USER.isError,
          errorStatus:
            POST_FIND_USER.error && POST_FIND_USER.error.response
              ? POST_FIND_USER.error.response.status
              : 0,
        }}
        options={{
          modalType: options.modalType,
        }}
        // status={POST_FIND_USER.status}
        // result={POST_FIND_USER.data}
      />
    </>
  );
};
