import { useMenuUserInfoQuery } from "../query/useMenuUserInfoQuery";
import { ImypageMainInfo } from "../types/item.type";

export const useGetMenuUserInfo = (cookie: string) => {
  const { data, isLoading } = useMenuUserInfoQuery("info/", {
    cookie: cookie,
    apiName: "userInforFromMenu",
  });

  return {
    userInfo: data
      ? {
          username: data.username,
          email: data.email,
          phone: data.phone,
        }
      : DUMMY_MAIN_INFORMATION,
    isLoading: isLoading,
  };
};

const DUMMY_MAIN_INFORMATION: ImypageMainInfo = {
  username: "-",
  email: "-",
  phone: "-",
};
