import { HandleComma } from "../../../utils_public/Comma";

interface Ioptions {
  title: string;
  loading: boolean;
}

interface Iitem {
  plantName: string;
  landCategory: string;
  landSize: number | string;
  memberType: string;
  address: string;
}

export const DetailInformationTable = ({
  item,
  options,
}: {
  item: Iitem;
  options: Ioptions;
}) => {
  return (
    <div>
      <h1 className="text-listTitle mb-[17px]">{options.title}</h1>
      <ul className="flex flex-col ">
        <li className="flex border-t-[1px] border-y-border-color-dividers">
          <div className="max-w-[169px] w-full flex items-center pl-5 py-3 bg-main-color-sub max-sm:max-w-[150px] max-sm:pl-3">
            <span>상호명</span>
          </div>
          <div className="pl-5 py-3 max-sm:pl-3">
            {options.loading ? (
              <div className="animate-pulse">
                <div className="max-w-[150px] h-[19px] bg-[#D9D9D9] rounded-xl"></div>
              </div>
            ) : (
              <span>{item.plantName}</span>
            )}
          </div>
        </li>
        <li className="flex border-t-[1px] border-y-border-color-dividers ">
          <div className="max-w-[169px] w-full flex items-center pl-5 py-3 bg-main-color-sub max-sm:max-w-[150px] max-sm:pl-3">
            <span>주소</span>
          </div>
          <div className="pl-5 py-3 max-sm:pl-3">
            {options.loading ? (
              <div className="animate-pulse">
                <div className="w-[250px] h-[19px] bg-[#D9D9D9] rounded-xl"></div>
              </div>
            ) : (
              <span>{item.address}</span>
            )}
          </div>
        </li>
        <li className="flex border-t-[1px] border-y-border-color-dividers ">
          <div className="max-w-[169px] w-full flex items-center pl-5 py-3 bg-main-color-sub max-sm:max-w-[150px] max-sm:pl-3">
            <span>준공년도</span>
          </div>
          <div className="pl-5 py-3 max-sm:pl-3">
            {options.loading ? (
              <div className="animate-pulse">
                <div className="w-[80px] h-[19px] bg-[#D9D9D9] rounded-xl"></div>
              </div>
            ) : (
              <span>-</span>
            )}
          </div>
        </li>
        <li className="flex border-t-[1px] border-y-border-color-dividers ">
          <div className="max-w-[169px] w-full flex items-center pl-5 py-3 bg-main-color-sub max-sm:max-w-[150px] max-sm:pl-3">
            <span>지목</span>
          </div>
          <div className="pl-5 py-3 max-sm:pl-3">
            {options.loading ? (
              <div className="animate-pulse">
                <div className="w-[80px] h-[19px] bg-[#D9D9D9] rounded-xl"></div>
              </div>
            ) : (
              <span>{item.landCategory}</span>
            )}
          </div>
        </li>
        <li className="flex border-t-[1px] border-y-border-color-dividers ">
          <div className="max-w-[169px] w-full flex items-center pl-5 py-3 bg-main-color-sub max-sm:max-w-[150px] max-sm:pl-3">
            <span>토지면적</span>
          </div>
          <div className="pl-5 py-3 max-sm:pl-3">
            {options.loading ? (
              <div className="animate-pulse">
                <div className="w-[80px] h-[19px] bg-[#D9D9D9] rounded-xl"></div>
              </div>
            ) : (
              <span>
                {HandleComma(item.landSize)} m<sup className="">2</sup>
              </span>
            )}
          </div>
        </li>
        <li className="flex border-y-[1px] border-y-border-color-dividers ">
          <div className="max-w-[169px] w-full flex items-center pl-5 py-3 bg-main-color-sub max-sm:max-w-[150px] max-sm:pl-3">
            <span>전력거래 형태</span>
          </div>
          <div className="pl-5 py-3 max-sm:pl-3">
            {options.loading ? (
              <div className="animate-pulse">
                <div className="w-[60px] h-[19px] bg-[#D9D9D9] rounded-xl"></div>
              </div>
            ) : (
              <span>{item.memberType}</span>
            )}
          </div>
        </li>
      </ul>
    </div>
  );
};
