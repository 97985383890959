import { PriceHistoryBox } from "../../../components/UI/Box/PriceHistoryBox";
import { usePriceHistoryQuery } from "../query/usePriceHistoryQuery";
import { priceBoxValueType } from "../types/state.type";

export const MypagePriceHistoryFetch = ({
  plantId,
  type,
}: {
  plantId: number;
  type: priceBoxValueType;
}) => {
  const { data, isLoading } = usePriceHistoryQuery(plantId, type);

  return <PriceHistoryBox priceHistoryList={data} isLoading={isLoading} />;
};
