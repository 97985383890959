import axios from "axios";

interface signature {
  [key: string]: number;
}

export async function axiosInquiry(queryKey: any) {
  // if (!queryKey.token["60hz-user"]) return;

  console.log(queryKey);

  const modifyRequireData = {
    ...queryKey.submitInfo,
    question_type: QUESTION_TYPE[queryKey.submitInfo.selectInquiryList],
  };

  const response = await axios.post(
    process.env.REACT_APP_SERVER_URL + queryKey.url,
    modifyRequireData,
    {
      withCredentials: true,
      headers: {
        Authorization: " Token " + queryKey.token,
      },
    },
  );

  return response.data;
}

const QUESTION_TYPE: signature = {
  "사이트 이용 방법": 1,
  "버그/데이터 오류 신고": 2,
  "발전량 정보 관련": 3,
  "광고 및 기타 제안": 4,
};

export async function axiosAddFavorite(queryKey: any) {
  const response = await axios.post(
    process.env.REACT_APP_SERVER_URL + queryKey.url,
    {},
    {
      withCredentials: true,
      headers: {
        Authorization: "Token " + queryKey.token,
      },
    },
  );

  return response.data;
}

export async function axiosSendTalking(queryKey: any) {
  const response = await axios.post(
    process.env.REACT_APP_SERVER_URL + queryKey.url,

    {
      content: queryKey.content,
      plant: queryKey.plantId,
      user: queryKey.userId,
    },

    {
      withCredentials: true,
      headers: {
        Authorization: "Token " + queryKey.token,
      },
    },
  );

  return response.data;
}
