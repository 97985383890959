import { HandleComma } from "../../../utils_public/Comma";
import { IREC } from "../../../pages/Detail/types/RECorSMP.type";

export const DetailEnergeREC = ({ recData }: { recData: IREC[] }) => {
  return (
    <div className="flex justify-between mt-4 mb-12 leading-[100%]">
      <div>
        <h1 className="text-listSecond mb-1">REC (현물)</h1>
        <span className="text-smallExplanation">주 2회(화,목) 업데이트</span>
      </div>

      <article>
        <div className="flex justify-between items-center mb-4">
          <span className="text-listSecond">종가</span>
          {currentREC(recData)}
        </div>
      </article>
    </div>
  );
};

// 이전값과 비교 후 css 및 이미지 결정 함수
function colorResult(value: number) {
  if (value > 0) {
    return {
      color: "text-general-red",
      img: "/icon_remodel/plus_triangle.svg",
    };
  } else if (value === 0) {
    return {
      color: "text-text-color-primary",
      img: "/icon_remodel/same_line.svg",
    };
  } else {
    return {
      color: "text-general-blue",
      img: "/icon_remodel/minus_triangle.svg",
    };
  }
}

const currentREC = (recData: IREC[]) => {
  if (!recData[recData.length - 1] || !recData[recData.length - 2]) return;

  const differenceValue: number =
    recData[recData.length - 1].land_trd_rec_value -
    recData[recData.length - 2].land_trd_rec_value;

  return differenceValue ? (
    <SuccessREC differenceValue={differenceValue} recData={recData} />
  ) : (
    <ServerErrorREC />
  );
};

const ServerErrorREC = () => {
  return (
    <>
      <h1 className={`w-[100px] text-listTitle text-center`}>-</h1>
      <span className="w-[11px] ml-3">-</span>
    </>
  );
};

const SuccessREC = ({
  differenceValue,
  recData,
}: {
  differenceValue: number;
  recData: IREC[];
}) => {
  return (
    <>
      <h1
        className={`text-subValue text-right ${
          colorResult(differenceValue).color
        } whitespace-nowrap overflow-hidden text-ellipsis leading-[24px] pl-[16px] `}
      >
        {HandleComma(recData[recData.length - 1].cls_prc)} 원
      </h1>
      <span className="w-[11px] ml-3">
        <img src={colorResult(differenceValue).img} />
      </span>
    </>
  );
};
