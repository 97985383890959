import { IsignupInfo } from "../../../pages/SignUp/types/state.type";

interface SignupRadioItemProps {
  inputName: string;
  input: IsignupInfo;
  setInput: (value: IsignupInfo) => void;
}

export const SignupRadioItem = ({
  inputName,
  input,
  setInput,
}: SignupRadioItemProps) => {
  return (
    <li className="min-w-[210px] w-full flex items-center radio-buttons max-sm:min-w-[150px] whitespace-nowrap">
      <label className="radio-button ">
        {inputName}
        <input
          className="mr-2"
          value={inputName}
          type="radio"
          name="check-signup"
          checked={inputName === input.company_type}
          onChange={() => setInput({ ...input, company_type: inputName })}
        />
        <span className="custom-radio"></span>
      </label>
    </li>
  );
};
