import React, { useState, useRef } from "react";
import { Ifile } from "../../../pages/Mypage/types/state.type";
import { inputFileType } from "../../../pages/Mypage/utils/inputFileType.function";
import { byteUnit } from "../../../utils_public/Unit";
import { DefaultLoading } from "../Loading/Loading";

export const DragDropFileContainer = ({
  file,
  setFile,
  title,
  input_id,
  content,
}: {
  file: Ifile;
  setFile: (file: Ifile) => void;
  title: string;
  input_id: string;
  content: string;
}) => {
  return file.file !== null ? (
    <HaveDragDropFile file={file} setFile={setFile} />
  ) : (
    <DragDropFile
      setFile={setFile}
      title={title}
      input_id={input_id}
      content={content}
    />
  );
};

const HaveDragDropFile = ({
  file,
  setFile,
}: {
  file: Ifile;
  setFile: (value: Ifile) => void;
}) => {
  return (
    <div className="w-full h-full border-[1px] border-border-color-dividers rounded-xl  border-dashed">
      <div className="h-full flex items-center gap-x-8  px-9">
        <div className="flex gap-x-[14px]">
          {(file.file?.size as number) > 2000000 ? (
            <img className="w-[30px]" src="/icon_remodel/none_file.svg" />
          ) : (
            <img className="w-[30px]" src="/icon_remodel/file.svg" />
          )}

          <div className="flex flex-col justify-center gap-y-[2px]">
            <p className="max-w-[250px] w-full whitespace-nowrap overflow-hidden text-ellipsis">
              {file.name}
            </p>
            {(file.file?.size as number) > 2000000 ? (
              <span className="text-general-red text-smallExplanation">
                파일 용량 2MB 초과
              </span>
            ) : (
              <span className="text-smallExplanation text-text-color-disabled">
                {byteUnit(file.file?.size)} / 2MB
              </span>
            )}
          </div>
        </div>
        <div
          className="cursor-pointer"
          onClick={() => setFile({ name: "", file: null, change: true })}
        >
          <img className="w-[10px] h-[10px]" src="/icon_remodel/close.svg" />
        </div>
      </div>
    </div>
  );
};

const DragDropFile = ({
  setFile,
  title,
  input_id,
  content,
}: {
  setFile: (file: Ifile) => void;
  title: string;
  input_id: string;
  content: string;
}) => {
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  // 드래그 이벤트 함수
  const handleDrag = (
    e: React.DragEvent<HTMLFormElement> | React.DragEvent<HTMLDivElement>,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // 드랍 이벤트 함수
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (
      e.dataTransfer.files &&
      e.dataTransfer.files[0] &&
      inputFileType(e.dataTransfer.files[0].type)
    ) {
      handleSaveFile(e.dataTransfer.files[0]);
    }
  };

  // 버튼 클릭 이벤트 함수
  const onButtonClick = () => {
    inputRef.current.click();
  };

  // 첨부 파일 저장 함수
  const handleSaveFile = (e: File) => {
    setFile({
      name: e.name,
      file: e,
      change: true,
    });
  };

  return (
    <form
      className="w-full h-full text-center relative"
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
    >
      <input
        ref={inputRef}
        type="file"
        id={input_id}
        accept="image/png, image/gif, image/jpeg, application/pdf"
        className="hidden"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (e.target.files !== null && e.target.value !== "") {
            handleSaveFile(e.target.files[0]);
          }
        }}
      />
      <div className="h-full flex items-center justify-center border-[1px] border-border-color-dividers rounded-xl  border-dashed">
        <div>
          <h1 className="text-listSecond">{title}</h1>
          <div className="text-text-color-disabled my-4">
            <h1 className="mb-[4px] max-sm:text-default">
              여기로 파일을 끌어놓으세요.
            </h1>
            <p className="text-default mb-[4px] max-sm:text-smallExplanation">
              2MB 이하의 jpg, png, pdf 파일 1개까지 업로드 가능합니다.
            </p>
            <p className="text-default">{content.length >= 0 && content}</p>
          </div>

          <button
            className="text-default text-main-color-primary pb-[4px]  border-b-[1px] border-b-main-color-primary"
            onClick={onButtonClick}
          >
            혹은 기기에서 찾기
          </button>
        </div>
      </div>
      {dragActive && (
        <div
          className="absolute w-full h-full top-0 right-0 left-0 bottom-0 rounded-xl"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
    </form>
  );
};
