import { useState } from "react";
import { useCookies } from "react-cookie";
import { MypageUserInfoModify } from "../module_modify/MypageUserInfo.modify";
import { useCheckPasswordQuery } from "../query/useUserInfoQuery";

export const MypageUserInfoFetch = () => {
  const [password, setPassword] = useState("");
  const [cookie] = useCookies(["60hz-user"]);
  const POST_CHECK_PASSWORD = useCheckPasswordQuery();

  const submitCheckPassword = () => {
    if (password === "") return;
    POST_CHECK_PASSWORD.mutate({
      apiName: "CheckPasswordFromMypage",
      url: "v1/check-password/",
      cookie: cookie["60hz-user"],
      password: password,
    });
  };

  return (
    <MypageUserInfoModify
      state={{
        password: password,
      }}
      func={{
        submitCheckPassword: submitCheckPassword,
        setPassword: setPassword,
      }}
      apiResponse={{
        success: POST_CHECK_PASSWORD.isSuccess,
        loading: POST_CHECK_PASSWORD.isLoading,
        axiosCode:
          POST_CHECK_PASSWORD.error && POST_CHECK_PASSWORD.error.code
            ? POST_CHECK_PASSWORD.error.code
            : "",
        error: POST_CHECK_PASSWORD.isError,
        errorStatus:
          POST_CHECK_PASSWORD.error && POST_CHECK_PASSWORD.error.response
            ? POST_CHECK_PASSWORD.error.response.status
            : 0,
      }}
    />
  );
};
