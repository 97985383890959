import { AxiosError } from "axios";
import { useQuery } from "react-query";
import { axiosPlantFilterList } from "../api/axiosGetRequest";

interface IusePlantList {
  apiName: string;
  cookie?: string;
}

export const usePlantListQuery = (
  url: string,
  filter: object,
  info: IusePlantList,
) =>
  useQuery(
    ["plantListFromMain", url, filter, null, info.cookie],
    axiosPlantFilterList,
    {
      onError: (err: AxiosError) => {
        console.error(`from ${info.apiName} ERROR`, err);
      },
      cacheTime: 0,
      retry: false,
    },
  );
