import { useCookies } from "react-cookie";
import { DetailSellListModal } from "../../../components/Detail/Modal/DetailSellListModal";
import { useGetBuyerList } from "../hooks/useGetBuyerList";

type ImodalTalk = {
  open: boolean;
  type: string;
  userId: number;
  username: string;
};

interface Ifunc {
  setModalSell: (bool: boolean) => void;
  setModalTalk: (bool: ImodalTalk) => void;
}

interface Iitem {
  plantId: number;
}

export const DetailSellListModalFetch = ({
  item,
  func,
}: {
  item: Iitem;
  func: Ifunc;
}) => {
  const [cookie] = useCookies(["60hz-user"]);

  const { data, status } = useGetBuyerList(item.plantId, cookie["60hz-user"]);

  return (
    <DetailSellListModal
      func={{
        setModalSell: func.setModalSell,
        setModalTalk: func.setModalTalk,
      }}
      apiResponse={{
        status: status,
      }}
      item={{
        list: data,
      }}
    />
  );
};
