import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSetRecoilState } from "recoil";

// atoms
import { filterCategory } from "../atoms/filter.atoms";
import { checkHasDecode } from "../utils/stringDecode";

export const useOutSearchURL = (setSearchText: (value: string) => void) => {
  const pathName = useLocation();
  const setFilter = useSetRecoilState(filterCategory);

  const current_search = checkHasDecode(pathName.search).slice(6);

  useEffect(() => {
    const currentPath = pathName.pathname;
    const saveURL = currentPath.split("/");

    if (saveURL[1] !== "search" && saveURL[1] !== "detail") {
      setFilter({
        local: "전국",
        land: "전체",
        capacity: "전체",
        sort: "-capacity",
        page: 1,
      });
      setSearchText("");
    } else if (saveURL[1] === "search") {
      setSearchText(current_search);
    }
  }, [pathName]);
};
