import { useState } from "react";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import { useGetMyPlantInfo } from "../hooks/useGetMyPlant";
import { useAddMyPlantInfoQuery } from "../query/useGetMyPlantQuery";
import { MypageOwnerInfoModify } from "../module_modify/MypageOwnerInfo.modify";
import { Ifile, IimageFile, IthumbnailList } from "../types/state.type";
import { Iinverter, Imodule } from "../types/plantTotalInfo.type";

export const MypageOwnerInfoFetch = () => {
  const { id } = useParams();
  const [cookie] = useCookies(["60hz-user"]);

  const [inverter, setInverter] = useState<Iinverter>({
    inverterManufactureCompany: "",
    inverterName: "",
    inverterCapacity: "",
    inverterNums: "",
    change: false,
  });
  const [module, setModule] = useState<Imodule>({
    moduleManufactureCompany: "",
    moduleName: "",
    moduleCapacity: "",
    moduleNums: "",
    change: false,
  });
  const [rpsFile, setRpsFile] = useState<Ifile>({
    name: "",
    file: null,
    change: false,
  });
  const [haveRpsFile, setHaveRpsFile] = useState(false);
  const [energeFile, setEnergeFile] = useState<Ifile>({
    name: "",
    file: null,
    change: false,
  });
  const [haveEnergeFile, setHaveEnergeFile] = useState(false);
  const [imageFile, setImageFile] = useState<IimageFile>({
    image: [],
    change: false,
  });
  const [thumbnail, setThumbnail] = useState<IthumbnailList>({
    image: [],
    default: false,
  });
  const [modal, setModal] = useState(false);

  const { data, status } = useGetMyPlantInfo(cookie["60hz-user"], id);

  const PUT_MY_PLANT_INFO = useAddMyPlantInfoQuery();

  // 내 발전소 정보 변경 및 새등록 요청 함수
  const submitMyPlantInfo = () => {
    PUT_MY_PLANT_INFO.mutate({
      apiName: "myPlantInfo PUT",
      url: `api/v1/my-plant/${id}/`,
      cookie: cookie["60hz-user"],
      plantInfo: {
        inverter: inverter,
        module: module,
      },
      file: {
        rpsFile: rpsFile,
        energeFile: energeFile,
        imageFile: imageFile.image,
      },
    });

    // 모달 종료
    setModal(false);
  };

  return (
    <MypageOwnerInfoModify
      state={{
        inverter: inverter,
        module: module,
        rpsFile: rpsFile,
        energeFile: energeFile,
        imageFile: imageFile,
        thumbnail: thumbnail,
        haveRpsFile: haveRpsFile,
        haveEnergeFile: haveEnergeFile,
        modal: modal,
      }}
      func={{
        setInverter: setInverter,
        setModule: setModule,
        setRpsFile: setRpsFile,
        setEnergeFile: setEnergeFile,
        setImageFile: setImageFile,
        setThumbnail: setThumbnail,
        setHaveRpsFile: setHaveRpsFile,
        setHaveEnergeFile: setHaveEnergeFile,
        setModal: setModal,
        submitMyPlantInfo: submitMyPlantInfo,
      }}
      item={{
        plantTotalInfo: data,
      }}
      apiResponse={{
        status: status,
        submitStatus: PUT_MY_PLANT_INFO.status,
        submitError:
          PUT_MY_PLANT_INFO.error &&
          PUT_MY_PLANT_INFO.error.response &&
          PUT_MY_PLANT_INFO.error.response.status,
      }}
    />
  );
};
