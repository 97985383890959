import axios from "axios";
import { HandleFormOwner } from "../utils/handleFormData.function";

export async function axiosAddFavorite(queryKey: any) {
  const response = await axios.post(
    process.env.REACT_APP_SERVER_URL + queryKey.url,
    {},
    {
      withCredentials: true,
      headers: {
        Authorization: "Token " + queryKey.token,
      },
    },
  );

  return response.data;
}

export async function axiosAddOwnerInfo(queryKey: any) {
  const formData = HandleFormOwner(
    queryKey.businessFile.file,
    queryKey.businessFile.change,
    queryKey.commerceFile.file,
    queryKey.commerceFile.change,
  );

  const response = await axios.post(
    process.env.REACT_APP_SERVER_URL + queryKey.url,
    formData,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Token " + queryKey.cookie,
      },
    },
  );
  return response.data;
}

export async function axiosCheckPassword(queryKey: any) {
  const response = await axios.post(
    process.env.REACT_APP_SERVER_URL + queryKey.url,
    {
      password: queryKey.password,
    },
    {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Token " + queryKey.cookie,
      },
    },
  );
  return response.data;
}
