import classNames from "classnames";

interface Istate {
  toggle: boolean;
  current: string;
}

interface Iitem {
  selectList: string[];
}

interface Ifunc {
  setToggle: (bool: boolean) => void;
  setCurrent: (local: string) => void;
}

interface Ioptions {
  overflow: boolean;
}

export const DropDownListBox = ({
  state,
  item,
  func,
  options,
}: {
  state: Istate;
  item: Iitem;
  func: Ifunc;
  options: Ioptions;
}) => {
  return (
    <ul
      className={classNames(
        "w-full bg-general-white overflow-y-scroll transition-all duration-100 rounded-lg",
        { "overflow-y-scroll": options.overflow },
        { "overflow-y-hidden scrollbar-hide": !options.overflow },
        { "max-h-[406px] mt-[10px]": state.toggle },
        { "max-h-0 border-[0px] mt-[0px]": !state.toggle },
      )}
    >
      {item.selectList.map((el, idx) => (
        <li
          className="h-[35px] relative mx-[1px] mb-1 px-4 py-1 flex items-center cursor-default hover:bg-main-color-secondary rounded-md"
          key={idx}
          onClick={() => {
            // 중복 클릭 방지
            func.setToggle(false);
            func.setCurrent(el);
          }}
        >
          {el}
          {state.current === el && (
            <img
              className="w-[15px] absolute right-[6px]"
              src="/icon_remodel/check_blue.svg"
            />
          )}
        </li>
      ))}
    </ul>
  );
};
