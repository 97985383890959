import { useSellBuyListQuery } from "../query/useSellBuyListQuery";

export const useGetSellList = (cookie: string) => {
  const { data, status } = useSellBuyListQuery("api/v1/my-trade/sell/", {
    apiName: "sellListGet",
    cookie: cookie,
  });

  return {
    sellList: data ? data : [],
    status: status,
  };
};

export const useGetBuyList = (cookie: string) => {
  const { data, status } = useSellBuyListQuery("api/v1/my-trade/buy/", {
    apiName: "buyListGet",
    cookie: cookie,
  });

  return {
    buyList: data ? data : [],
    status: status,
  };
};
