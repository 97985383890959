import { useQuery, useMutation } from "react-query";
import { axiosAddOwnerInfo } from "../api/axiosPostRequest";
import { axiosOwnerInfo } from "../api/axiosGetRequest";
import { axiosPatchOwnerInfo } from "../api/axiosPatchRequest";

export const useOwnerInfoQuery = (
  url: string,
  info: { cookie: string; apiName: string },
) =>
  useQuery([info.apiName, url, info.cookie], axiosOwnerInfo, {
    onError: (err) => {
      console.error(`from ${info.apiName} ERROR`, err);
    },
    cacheTime: 0,
    retry: false,
  });

export const useAddOwnerInfoQuery = () =>
  useMutation(axiosAddOwnerInfo, {
    onError: (err, val) => {
      console.error(`from ${val.apiName} ERROR`, err);
    },
  });

export const usePatchOwnerInfoQuery = () =>
  useMutation(axiosPatchOwnerInfo, {
    onError: (err, val) => {
      console.error(`from ${val.apiName} ERROR`, err);
    },
  });
