import { useState } from "react";
import { useCookies } from "react-cookie";
import { useInquiryQuery } from "../../Detail/query/useInquiryQuery";
import { QnAInquiryModify } from "../module_modify/QnAInquiry.modify";

export const QnAInquiryFetch = () => {
  const [cookie] = useCookies(["60hz-user"]);
  const [inquiryText, setInquiryText] = useState("");
  const [selectInquiryList, setSelectInquiryList] = useState("");

  const POST_INQUIRY_SUBMIT = useInquiryQuery();

  const submitInquiry = () => {
    POST_INQUIRY_SUBMIT.mutate({
      apiName: "Inquiry Submit",
      submitInfo: {
        question: inquiryText,
        selectInquiryList: selectInquiryList,
      },
      url: "api/ask/",
      token: cookie["60hz-user"],
    });
  };

  return (
    <QnAInquiryModify
      state={{
        inquiryText: inquiryText,
        selectInquiryList: selectInquiryList,
      }}
      func={{
        setInquiryText: setInquiryText,
        setSelectInquiryList: setSelectInquiryList,
        submitInquiry: submitInquiry,
      }}
      apiResponse={{
        success: POST_INQUIRY_SUBMIT.isSuccess,
        loading: POST_INQUIRY_SUBMIT.isLoading,
        axiosCode:
          POST_INQUIRY_SUBMIT.error && POST_INQUIRY_SUBMIT.error.code
            ? POST_INQUIRY_SUBMIT.error.code
            : "",
        error: POST_INQUIRY_SUBMIT.isError,
        errorStatus:
          POST_INQUIRY_SUBMIT.error && POST_INQUIRY_SUBMIT.error.response
            ? POST_INQUIRY_SUBMIT.error.response.status
            : 0,
      }}
    />
  );
};
