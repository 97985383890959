interface Istate {
  toggle: boolean;
}

interface Ifunc {
  handleToggleAll: () => void;
}

export const Toggle = ({ state, func }: { state: Istate; func: Ifunc }) => {
  return (
    <div
      className={`ml-auto pointer-events-auto h-6 w-10 rounded-full 
      cursor-pointer
      p-1  transition duration-200 ease-in-out ${
        state.toggle ? "bg-border-color-dividers" : "bg-main-color-primary"
      } `}
      onClick={() => {
        func.handleToggleAll();
      }}
    >
      <div
        className={`h-4 w-4 rounded-full bg-general-white 
       transition duration-200 ease-in-out ${
         state.toggle ? "translate-x-0" : "translate-x-4"
       } `}
      ></div>
    </div>
  );
};
