import classNames from "classnames";
import { capacityUnit } from "../../../../utils_public/Unit";
import { ImyPlantStatusList } from "../../../../pages/Mypage/types/plantInfo.type";
import { useNavigate } from "react-router-dom";
import { getDayIncodeDate } from "../../../../utils_public/dateFormat";

export const MypageCertificationItem = ({
  id,
  plant,
  status,
  updated_datetime,
}: ImyPlantStatusList) => {
  const navigate = useNavigate();
  return (
    <li className="flex px-5 py-3 items-center">
      <div className="max-w-[294px] w-full">
        <span>{plant ? plant.plant_name : <span>{status}</span>}</span>
      </div>
      <div className="max-w-[159px] w-full">
        <span>{plant ? capacityUnit(plant.capacity) : "-"}</span>
      </div>
      <div className="max-w-[360px] w-full pr-[30px] whitespace-nowrap overflow-hidden text-ellipsis">
        <span>{plant ? plant.updated_address : "-"}</span>
      </div>
      <div className="max-w-[205px] w-full">
        <span
          className={classNames(
            "text-center px-[12px] py-[6px] rounded-[30px] text-footer",

            {
              "bg-main-color-sub": status === "검토 중",
            },
            {
              "bg-main-color-secondary": status === "소유주 등록 완료",
            },
          )}
        >
          {status}
        </span>
      </div>
      <div className="max-w-[164px] w-full flex justify-between">
        <span>{getDayIncodeDate(updated_datetime)}</span>

        {status === "소유주 등록 완료" && (
          <span
            className="text-text-color-disabled border-b-[1px]
           border-b-border-color-dividers cursor-pointer"
            onClick={() => {
              navigate(`information/${id}`);
            }}
          >
            수정
          </span>
        )}
      </div>
    </li>
  );
};
