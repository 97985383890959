import { useState } from "react";
import { IcapacityRange } from "../../../pages/Detail/types/state.type";
import { DetailActualPriceCapacityFilter } from "./DetailActualPriceCapacityFilter";
import { DetailActualPriceLocalFilter } from "./DetailActualPriceLocalFilter";

type IsellInfo = {
  created_datetime: string;
  price: number;
};

interface Istate {
  currentLocal: string;
  currentCapacity: string;
}

interface Ifunc {
  setCurrentLocal: (value: string) => void;
  setCurretCapacity: (value: string) => void;
}

interface Iitem {
  sellInfo: IsellInfo;
  capacity: number;
}

export const DetailActualPriceFilter = ({
  state,
  func,
  item,
}: {
  state: Istate;
  func: Ifunc;
  item: Iitem;
}) => {
  const [localToggle, setLocalToggle] = useState(false);
  const [capacityToggle, setCapacityToggle] = useState(false);

  return (
    <section>
      <div className="text-subValue font-semibold my-[36px] max-sm:text-listTitle">
        <h1>전국 발전소 실거래가</h1>
      </div>
      <main className="flex gap-y-[24px] items-center gap-x-[32px] max-md:gap-x-[24px] max-sm:flex-col max-sm:gap-y-[16px] max-sm:items-start">
        <DetailActualPriceLocalFilter
          state={{ currentLocal: state.currentLocal, localToggle: localToggle }}
          func={{ setToggle: setLocalToggle, setCurrent: func.setCurrentLocal }}
        />
        <DetailActualPriceCapacityFilter
          state={{
            currentCapacity: state.currentCapacity,
            capacityToggle: capacityToggle,
          }}
          func={{
            setToggle: setCapacityToggle,
            setCurrent: func.setCurretCapacity,
          }}
        />
      </main>
    </section>
  );
};
