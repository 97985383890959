import { useEffect } from "react";
import { HandlePhoneNumber } from "../../SignUp/utils/SignFunc";
import {
  emailModal,
  passwordModal,
  verificationdModal,
} from "../types/modal.type";

export const useValidationModal = (
  input: emailModal | passwordModal | verificationdModal,
) => {
  useEffect(() => {
    // console.log(123);
    // console.log(HandlePhoneNumber(input["phone"]));
  }, [input["phone"]]);
};
