import {
  woodCalculate,
  electricityUsageCalculate,
} from "../../../pages/Detail/utils/environmentCalculate.function";

export const DetailEnergeExpectation = ({
  capacity,
  hourDaily,
}: {
  capacity: number | string;
  hourDaily: number;
}) => {
  return (
    <article>
      <h1 className="mb-2 max-sm:text-default">이 발전소 연간 발전량으로...</h1>
      <div className="mb-[6px] max-sm:text-default">
        <span>{`🌳️ ${woodCalculate(
          capacity,
        )}그루의 나무를 심은 것과 같은 효과입니다.`}</span>
      </div>
      <div className="mb-[8px] max-sm:text-default">
        <span>{`🔌 4인 가구가 ${electricityUsageCalculate(
          capacity,
          hourDaily,
        )}년 동안 전기를 사용할 수 있어요.`}</span>
      </div>
      <p className="text-smallExplanation text-text-color-secondary mt-1">
        한국전력거래소 2019년 주택용 가전기기 보급현황 조사 기준
      </p>
    </article>
  );
};
