import { AxiosError } from "axios";
import { useMutation } from "react-query";
import { axiosSignup } from "../api/axiosPostRequest";

interface IserverError {
  phone: string[];
  telephone: string[];
}

export const useSignupQuery = () =>
  useMutation(axiosSignup, {
    onError: (err: AxiosError<IserverError>, val) => {
      console.error(`from ${val.apiName} ERROR`, err);
    },
  });
