import {
  IinquiryListDeleteModal,
  IinquiryListUpdateModal,
} from "../types/state.type";
import { MypageInquiryListItem } from "../../../components/Mypage/MypageInquiry/MypageInquiryListItem";
import { useEffect, useState } from "react";
import { MypageInquirySelectModal } from "../../../components/Mypage/MypageInquiry/MypageInquiryModal";
import { useInquiryUpdateResult } from "../hooks/useInquiryStatusResult";

interface Iitem {
  content: string;
  answer: string;
  answerDate: string;
  question_type: number;
  plantOrder: number;
  inquiry_id: number;
  waiting: string;
  registerDatetime: string;
}

interface Istate {
  deleteModal: IinquiryListDeleteModal;
  updateModal: IinquiryListUpdateModal;
  curretOrderId: number;
  currentListItem: number;
  clickTrigger: boolean;
}

interface IapiResponse {
  updatedStatus: string;
  updatedId: number;
  deletedStatus: string;
}

interface Ifunc {
  setCurretOrderId: (value: number) => void;
  HandleDeleteModal: (value: IinquiryListDeleteModal | string) => void;
  HandleUpdateModal: (value: IinquiryListUpdateModal | string) => void;
  setAlreadyInquiryUpdate: (bool: boolean) => void;
  setCurrentListItem: (id: number) => void;
  setClickTrigger: (bool: boolean) => void;
}

export const MypageInquiryListItemModify = ({
  item,
  state,
  apiResponse,
  func,
}: {
  item: Iitem;
  state: Istate;
  apiResponse: IapiResponse;
  func: Ifunc;
}) => {
  const [inquiryContent, setInquiryContent] = useState(item.content);
  const [contentCopy, setContentCopy] = useState(item.content);
  const [hide, setHide] = useState(false);

  // textarea UI 수정 상태값
  const [modify, setModify] = useState(true);

  // textarea 관리 함수
  const closeModifyBox = (bool: boolean) => {
    // 닫기 누른 경우
    if (bool) {
      setModify(bool);
      setInquiryContent(contentCopy);
    } else {
      setModify(bool);
    }
  };

  // 문의 리스트 아이템 클릭 함수
  const selectInquiryItem = () => {
    setInquiryContent(contentCopy);
    func.setCurrentListItem(item.inquiry_id);
    func.setCurretOrderId(item.plantOrder);
    // 클릭 트리거
    func.setClickTrigger(!state.clickTrigger);
  };

  // 클릭 트리거 effect
  useEffect(() => {
    if (state.currentListItem === item.inquiry_id) {
      setHide(!hide);
    } else {
      setHide(false);
      setModify(true);
    }
  }, [state.currentListItem, state.clickTrigger]);

  useEffect(() => {
    if (!hide && state.currentListItem === item.inquiry_id) {
      func.setCurretOrderId(-1);
    }
  }, [hide]);

  useEffect(() => {
    if (apiResponse.deletedStatus === "success") {
      func.setCurretOrderId(-1);
    }
  }, [apiResponse.deletedStatus]);

  // 문의 수정 관리 함수
  const updateInquiryItem = (mapType: string | IinquiryListUpdateModal) => {
    if (mapType === "update") {
      // 문의 수정 확인 버튼 누른 경우
      func.HandleUpdateModal(mapType);
    } else {
      // 문의 수정 취소 버튼 누른 경우
      func.HandleUpdateModal(mapType);
    }
  };

  // 문의 수정 비동기 과정 관리 hooks
  const updateResult = useInquiryUpdateResult(
    {
      status: apiResponse.updatedStatus,
      inquiryContent: inquiryContent,
      updatedId: apiResponse.updatedId,
      inquiry_id: item.inquiry_id,
    },
    {
      setInquiryContent: setInquiryContent,
      setContentCopy: setContentCopy,
      setModify: setModify,
      setAlreadyInquiry: func.setAlreadyInquiryUpdate,
    },
  );

  return (
    <>
      <MypageInquiryListItem
        item={{
          answer: item.answer,
          answerDate: item.answerDate,
          questionType: item.question_type,
          plantOrder: item.plantOrder,
          inquiry_id: item.inquiry_id,
          waiting: item.waiting,
          registerDatetime: item.registerDatetime,
        }}
        state={{
          contentCopy: contentCopy,
          curretOrderId: state.curretOrderId,
          inquiryContent: inquiryContent,
          hide: hide,
          modify: modify,
        }}
        apiResponse={{
          updatedStatus: apiResponse.updatedStatus,
        }}
        func={{
          closeModifyBox: closeModifyBox,
          selectInquiryItem: selectInquiryItem,
          setInquiryContent: setInquiryContent,
          HandleDeleteModal: func.HandleDeleteModal,
          HandleUpdateModal: func.HandleUpdateModal,
        }}
      />
      {state.deleteModal.modal &&
        state.deleteModal.inquiry_id === item.inquiry_id && (
          <MypageInquirySelectModal
            func={{
              deleteModalFunc: func.HandleDeleteModal,
              updateModalFunc: updateInquiryItem,
            }}
            options={{
              contents: "삭제하시겠습니까?",
              modalType: "delete",
            }}
          />
        )}
      {state.updateModal.modal &&
        state.updateModal.inquiry_id === item.inquiry_id && (
          <MypageInquirySelectModal
            func={{
              deleteModalFunc: func.HandleDeleteModal,
              updateModalFunc: updateInquiryItem,
            }}
            options={{
              contents: "수정하시겠습니까?",
              modalType: "update",
            }}
          />
        )}
    </>
  );
};
