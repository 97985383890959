import axios from "axios";
import { QueryFunctionContext } from "react-query";
import {
  handleCapacity__gte,
  handleCapacity__lt,
} from "../../Main/utils/handleCapacity.funtion";
import { checkHasDecode } from "../../Search/utils/stringDecode";
import { LAND_ID } from "../constants/registration.constant";
import { IfilterItem } from "../types/state.type";

export const axiosFavoritePlant = async ({ queryKey }: any) => {
  const response = await axios.get(
    process.env.REACT_APP_SERVER_URL + queryKey[1],
    {
      withCredentials: true,
      headers: {
        Authorization: "Token " + queryKey[2],
      },
    },
  );

  return response.data;
};

export const axiosMenuUserInfo = async ({ queryKey }: any) => {
  const response = await axios.get(
    process.env.REACT_APP_SERVER_URL + queryKey[1],
    {
      withCredentials: true,
      headers: {
        Authorization: "Token " + queryKey[2],
      },
    },
  );

  return response.data;
};

export const axiosInquiryList = async ({ queryKey }: any) => {
  const response = await axios.get(
    process.env.REACT_APP_SERVER_URL + queryKey[1],
    {
      withCredentials: true,
      headers: {
        Authorization: "Token " + queryKey[2],
      },
    },
  );

  return response.data;
};

export const axiosMyPlantMainCount = async ({ queryKey }: any) => {
  const response = await axios.get(
    process.env.REACT_APP_SERVER_URL + queryKey[1],
    {
      withCredentials: true,
      headers: {
        Authorization: "Token " + queryKey[2],
      },
    },
  );

  return response.data;
};

export const axiosMyPlantMainProfile = async ({ queryKey }: any) => {
  const response = await axios.get(
    process.env.REACT_APP_SERVER_URL + queryKey[1],
    {
      withCredentials: true,
      headers: {
        Authorization: "Token " + queryKey[2],
      },
    },
  );

  return response.data;
};

export const axiosMyPlantList = async ({ queryKey }: any) => {
  const response = await axios.get(
    process.env.REACT_APP_SERVER_URL + queryKey[1],
    {
      withCredentials: true,
      headers: {
        Authorization: "Token " + queryKey[2],
      },
    },
  );

  return response.data;
};

export const axiosMyPlantInfo = async ({ queryKey }: any) => {
  const response = await axios.get(
    process.env.REACT_APP_SERVER_URL + queryKey[1],
    {
      withCredentials: true,
      headers: {
        Authorization: "Token " + queryKey[2],
      },
    },
  );

  return response.data;
};

export const axiosOwnerInfo = async ({ queryKey }: any) => {
  const response = await axios.get(
    process.env.REACT_APP_SERVER_URL + queryKey[1],
    {
      withCredentials: true,
      headers: {
        Authorization: "Token " + queryKey[2],
      },
    },
  );

  return response.data;
};

export const axiosUserInfo = async ({ queryKey }: any) => {
  const response = await axios.get(
    process.env.REACT_APP_SERVER_URL + queryKey[1],
    {
      withCredentials: true,
      headers: {
        Authorization: "Token " + queryKey[2],
      },
    },
  );

  return response.data;
};

export const axiosSellBuyList = async ({ queryKey }: any) => {
  const response = await axios.get(
    process.env.REACT_APP_SERVER_URL + queryKey[1],
    {
      withCredentials: true,
      headers: {
        Authorization: "Token " + queryKey[2],
      },
    },
  );

  return response.data;
};

export const axiosSellBuyRecentlyTime = async ({ queryKey }: any) => {
  const response = await axios.get(
    process.env.REACT_APP_SERVER_URL + queryKey[1],
    {
      withCredentials: true,
      headers: {
        Authorization: "Token " + queryKey[2],
      },
    },
  );

  return response.data;
};

export const axiosRecordBuySell = async ({ queryKey }: any) => {
  const response = await axios.get(
    process.env.REACT_APP_SERVER_URL + queryKey[1],
    {
      withCredentials: true,
      headers: {
        Authorization: "Token " + queryKey[2],
      },
    },
  );

  return response.data;
};

export const axiosPlantFilterList = async ({
  queryKey,
}: QueryFunctionContext<
  [string, string, IfilterItem, string, string | undefined]
>) => {
  const SEARCH_TYPE = {
    search: queryKey[3] ? checkHasDecode(queryKey[3]).slice(6) : "",
  };

  // 쿼리스트링이 없을 경우(페이지 첫 렌더링 상황)
  if (!SEARCH_TYPE.search) {
    return Promise.resolve(null);
  }

  // 지목 기타일 경우
  const LAND_ETC = {
    search: SEARCH_TYPE["search"],
    addr_lv1: queryKey[2].local === "전국" ? null : queryKey[2].local,
    land_category__not__in: "1,2,3,4,5,6,7,11,18",
    capacity__gte:
      queryKey[2].capacity === "전체"
        ? null
        : handleCapacity__gte(queryKey[2].capacity),
    capacity__lt:
      queryKey[2].capacity === "전체"
        ? null
        : handleCapacity__lt(queryKey[2].capacity),
    ordering: queryKey[2].sort,
    page: queryKey[2].page,
  };

  // 지목 기타가 아닐 경우
  const LANT_NOT_ECT = {
    search: SEARCH_TYPE["search"],
    addr_lv1: queryKey[2].local === "전국" ? null : queryKey[2].local,

    land_category:
      queryKey[2].land === "전체" ? null : LAND_ID[queryKey[2].land],
    capacity__gte:
      queryKey[2].capacity === "전체"
        ? null
        : handleCapacity__gte(queryKey[2].capacity),
    capacity__lt:
      queryKey[2].capacity === "전체"
        ? null
        : handleCapacity__lt(queryKey[2].capacity),
    ordering: queryKey[2].sort,
    page: queryKey[2].page,
  };

  const response = await axios.get(
    process.env.REACT_APP_SERVER_URL + queryKey[1],

    {
      withCredentials: true,
      params: queryKey[2].land === "기타" ? LAND_ETC : LANT_NOT_ECT,

      headers: {
        Authorization: queryKey[4] ? "Token " + queryKey[4] : "",
      },
    },
  );

  return response.data;
};
