import React, { useState, useRef } from "react";
import { ImuchFile, IimageFile } from "../../../pages/Mypage/types/state.type";
import { inputFileType } from "../../../pages/Mypage/utils/inputFileType.function";
import { byteUnit } from "../../../utils_public/Unit";

export const DragDropMuchFileContainer = ({
  file,
  setFile,
  title,
  input_id,
  currntIdx,
}: {
  file: IimageFile;
  setFile: (file: IimageFile) => void;
  title: string;
  input_id: string;
  currntIdx: number;
}) => {
  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  return file.image.length > 0 ? (
    <HaveDragDropMuchFile
      file={file.image}
      setFile={setFile}
      inputRef={inputRef}
      currntIdx={currntIdx}
    />
  ) : (
    <DragDropMuchFile
      setFile={setFile}
      title={title}
      input_id={input_id}
      inputRef={inputRef}
    />
  );
};

const HaveDragDropMuchFile = ({
  file,
  setFile,
  inputRef,
  currntIdx,
}: {
  file: ImuchFile[];
  setFile: (value: IimageFile) => void;
  inputRef: React.MutableRefObject<HTMLInputElement>;
  currntIdx: number;
}) => {
  const [dragActive, setDragActive] = useState(false);
  const [fileLimit, setFileLimit] = useState(false);

  // 버튼 클릭 이벤트 함수
  const onButtonClick = () => {
    inputRef.current.click();
  };

  // 드래그 이벤트 함수
  const handleDrag = (
    e: React.DragEvent<HTMLFormElement> | React.DragEvent<HTMLDivElement>,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  // 드랍 이벤트 함수
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (
      e.dataTransfer.files &&
      e.dataTransfer.files[0] &&
      inputFileType(e.dataTransfer.files[0].type)
    ) {
      handleSaveFile(e.dataTransfer.files);
    }
  };

  // 첨부 파일 저장 함수
  const handleSaveFile = (e: ArrayLike<File>) => {
    if (file.length + Object.keys(e).length > 5) {
      return setFileLimit(true);
    } else {
      setFileLimit(false);
      const uploadFile = Array.from(e);
      const copyFileArray: ImuchFile[] = [];

      uploadFile.map((el, idx) =>
        copyFileArray.push({
          id: currntIdx
            ? currntIdx + idx + 1
            : file[file.length - 1].id + idx + 1,
          name: el.name,
          file: el,
          change: true,
        }),
      );
      setFile({ image: [...file, ...copyFileArray], change: true });
    }
  };

  return (
    <form
      className="max-w-full w-full  py-[37px] border-[1px] border-border-color-dividers rounded-xl border-dashed relative"
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
    >
      <input
        ref={inputRef}
        type="file"
        id={"upload-test"}
        accept="image/png, image/gif, image/jpeg"
        className="hidden"
        multiple
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (e.target.files !== null && e.target.value !== "") {
            handleSaveFile(e.target.files);
          }
        }}
      />
      <div className=" grid grid-cols-3 gap-x-3 gap-y-[12px] px-[30px] relative max-md:grid-cols-2 max-md:gap-y-[16px] max-sm:grid-cols-1">
        {file.map((el, idx) => (
          <div
            className="w-full h-[52px] flex items-center
              justify-start gap-x-3 max-md:h-[45px]"
            key={idx}
          >
            <div className="max-w-full w-full flex gap-x-3 items-center justify-start max-sm:justify-center">
              {(el.file?.size as number) > 2000000 ? (
                <img
                  className="w-[30px] max-md:w-[25px]"
                  src="/icon_remodel/none_file.svg"
                />
              ) : (
                <img
                  className="w-[30px] max-md:w-[25px]"
                  src="/icon_remodel/file.svg"
                />
              )}
              <div className="max-w-[115px] w-full flex flex-col justify-center gap-y-[2px] max-md:max-w-[80px]">
                <p className=" whitespace-nowrap overflow-hidden text-ellipsis max-md:text-default ">
                  {el.name}
                </p>

                {(el.file?.size as number) > 2000000 ? (
                  <span className="text-general-red text-smallExplanation">
                    파일 용량 2MB 초과
                  </span>
                ) : (
                  <span className=" text-text-color-disabled text-smallExplanation">
                    {byteUnit(el.file?.size)} / 2MB
                  </span>
                )}
              </div>

              <div
                className="cursor-pointer ml-[20px]"
                onClick={() =>
                  setFile({
                    image: file.filter((item) => item.id !== el.id),
                    change: true,
                  })
                }
              >
                <img
                  className="w-[10px] h-[10px]"
                  src="/icon_remodel/close.svg"
                />
              </div>
            </div>
          </div>
        ))}
        {Array(5 - file.length)
          .fill(0)
          .map((el, idx) => (
            <div
              className="h-[52px] flex items-center justify-center gap-x-3 max-md:h-[45px]"
              key={idx}
            >
              <div className="max-w-[182px] w-full flex gap-x-3"></div>
            </div>
          ))}
        {file.length < 5 && (
          <div className="h-[50px] absolute top-[90px] right-6 flex justify-center items-center text-default gap-x-3 max-md:top-[140px] max-md:right-[44px] max-sm:top-[250px] max-sm:right-[68px]">
            <h1 className="text-text-color-disabled">파일 끌어놓거나</h1>
            <button
              className="text-main-color-primary border-b-[1px] border-b-main-color-primary cursor-pointer"
              onClick={onButtonClick}
            >
              기기에서 찾기
            </button>
          </div>
        )}
      </div>
      {dragActive && (
        <div
          className="absolute w-full h-full top-0 right-0 left-0 bottom-0 rounded-xl"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
    </form>
  );
};

const DragDropMuchFile = ({
  setFile,
  title,
  input_id,
  inputRef,
}: {
  setFile: (file: IimageFile) => void;
  title: string;
  input_id: string;
  inputRef: React.MutableRefObject<HTMLInputElement>;
}) => {
  const [dragActive, setDragActive] = useState(false);
  const [fileLimit, setFileLimit] = useState(false);

  // 드래그 이벤트 함수
  const handleDrag = (
    e: React.DragEvent<HTMLFormElement> | React.DragEvent<HTMLDivElement>,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // 드랍 이벤트 함수
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (
      e.dataTransfer.files &&
      e.dataTransfer.files[0] &&
      inputFileType(e.dataTransfer.files[0].type)
    ) {
      handleSaveFile(e.dataTransfer.files);
    }
  };

  // 버튼 클릭 이벤트 함수
  const onButtonClick = () => {
    inputRef.current.click();
  };

  // 첨부 파일 저장 함수
  const handleSaveFile = (e: ArrayLike<File>) => {
    if (Object.keys(e).length > 5) {
      return setFileLimit(true);
    } else {
      setFileLimit(false);
      const uploadFile = Array.from(e);
      const copyFileArray: ImuchFile[] = [];
      uploadFile.map((el, idx) =>
        copyFileArray.push({ id: idx, name: el.name, file: el, change: true }),
      );
      setFile({ image: copyFileArray, change: true });
    }
  };

  return (
    <form
      className="max-w-full w-full h-[178px] text-center relative"
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
    >
      <input
        ref={inputRef}
        type="file"
        id={input_id}
        accept="image/png, image/gif, image/jpeg"
        className="hidden"
        multiple
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (e.target.files !== null && e.target.value !== "") {
            handleSaveFile(e.target.files);
          }
        }}
      />
      <div className="h-full flex items-center justify-center border-[1px] border-border-color-dividers rounded-xl  border-dashed">
        <div>
          <h1 className="text-listSecond">{title}</h1>
          <div className="text-default text-text-color-disabled my-4">
            <p className="max-sm:text-smallExplanation">
              여기로 파일을 끌어놓으세요. <br />
              2MB 이하의 jpg, png, pdf 파일 5개까지 업로드 가능합니다.
            </p>
          </div>
          {fileLimit && (
            <h1 className="text-default text-general-red">
              최대 5개까지의 파일만 업로드 가능합니다.
            </h1>
          )}

          <button
            className="text-default text-main-color-primary border-b-[1px] border-b-main-color-primary"
            onClick={onButtonClick}
          >
            혹은 기기에서 찾기
          </button>
        </div>
      </div>
      {dragActive && (
        <div
          className="absolute w-full h-full top-0 right-0 left-0 bottom-0 rounded-xl"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
    </form>
  );
};
