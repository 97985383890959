import { LoginFetch } from "./Login.fetch";
import { isLogin } from "../../utils_public/isLogin";
import { Navigate, useLocation } from "react-router-dom";

interface IprevRoutState {
  previousPath: string;
  queryStringPath: string;
}

export const LoginContainer = () => {
  const prevRoute = useLocation();
  // 로그인 후 뒤로가기 이벤트 처리 함수
  const prevPath = handlePath(prevRoute.state as IprevRoutState);

  return isLogin() ? (
    <Navigate to={prevPath as string} />
  ) : (
    <>
      <LoginFetch />
    </>
  );
};

const handlePath = (state: IprevRoutState) => {
  if (!state) return "/";
  const atomicPath = state.previousPath.split("/");
  const curretPath = atomicPath[1];

  if (curretPath === "") {
    return "/";
  } else if (curretPath === "detail") {
    return "/" + curretPath + "/" + atomicPath[2];
  } else if (curretPath === "search") {
    return "/" + curretPath + "/" + state.queryStringPath;
  } else {
    return "/";
  }
};
