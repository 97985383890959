import { useNavigate, useLocation } from "react-router-dom";
import { MypageMainInfo } from "./MypageMainInfo";
import { MypageMenuListModify } from "../../pages/Mypage/module_modify/MypageMenuList.modify";
import { ImypageMainInfo, Irecently } from "../../pages/Mypage/types/item.type";

interface Iitem {
  userInfo: ImypageMainInfo;
  recently: Irecently;
}

interface IapiResponse {
  userInfoLoading: boolean;
}

export const MypageMenu = ({
  item,
  apiResponse,
}: {
  item: Iitem;
  apiResponse: IapiResponse;
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const CURRENT_PATH_NAME = pathname.split("/");

  return (
    <section className="pr-[60px] max-xl:pr-[35px] max-lg:hidden">
      <div className="w-[248px] max-xl:w-[190px]">
        <div>
          <h1
            className="mb-4 font-bold text-subTitle cursor-pointer max-xl:text-subValue"
            onClick={() => {
              if (!CURRENT_PATH_NAME[2]) return;
              navigate("/mypage");
            }}
          >
            마이페이지
          </h1>
        </div>
        <main className="border-[1px] border-main-color-primary p-8 rounded-xl max-xl:px-6">
          <MypageMainInfo item={{ ...item }} apiResponse={{ ...apiResponse }} />
          <div>
            <button
              className="my-8 px-6 py-3 bg-main-color-sub rounded-lg cursor-pointer max-xl:px-4 max-xl:py-2 max-xl:text-default"
              onClick={() => {
                if (
                  CURRENT_PATH_NAME[2] === "myPlant" &&
                  !CURRENT_PATH_NAME[3]
                ) {
                  return;
                }
                navigate("/mypage/myPlant");
              }}
            >
              발전소 찾기
            </button>
            <MypageMenuListModify item={{ recently: item.recently }} />
          </div>
        </main>
      </div>
    </section>
  );
};
