import { IplantInfo } from "../types/plantInfo.type";
import { DetailInformation } from "../../../components/Detail/Information/DetailInformation";

type ImodalSellBuy = {
  open: boolean;
  type: string;
  plantId: number;
};

interface Ifunc {
  setModalTalkChoice: (bool: boolean) => void;
  setModalSellBuy: (value: ImodalSellBuy) => void;
}

interface Iitem {
  plantInfo: IplantInfo;
}

export const DetailInformationModify = ({
  item,
  func,
}: {
  item: Iitem;
  func: Ifunc;
}) => {
  return (
    <>
      <DetailInformation
        func={{
          setModalTalkChoice: func.setModalTalkChoice,
          setModalSellBuy: func.setModalSellBuy,
        }}
        item={{
          plantInfo: item.plantInfo,
        }}
      />
    </>
  );
};
