import { HeaderPresenter } from "./Header.presenter";
import { useCookies } from "react-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { notifyMessage } from "./atoms/notify.atoms";
import { filterCategory } from "../../../pages/Search/atoms/filter.atoms";

// hooks
import { useOutSearchURL } from "../../../pages/Search/hooks/useOutSearchURL";
// utils
import { checkHasDecode } from "../../../pages/Search/utils/stringDecode";
import { useEffect, useState } from "react";

interface Iitem {
  notify: boolean;
}

interface IapiResponse {
  status: string;
}

export const HeaderModify = ({
  item,
  apiResponse,
}: {
  item: Iitem;
  apiResponse: IapiResponse;
}) => {
  const pathName = useLocation();
  const navigate = useNavigate();
  const [cookie, , removeCookie] = useCookies(["60hz-user"]);

  const [searchText, setSearchText] = useState("");

  const setRecoilState = useSetRecoilState(notifyMessage);
  const setFilter = useSetRecoilState(filterCategory);

  // 검색 버튼 클릭 함수
  const searchPlant = (searchPlant: string) => {
    const currentPath = pathName.search.slice(6);
    const pathArr = pathName.pathname.split("/");

    // 중복된 검색 문자 차단(필터 발전소 API는 문제없지만, url 게속 히스토리 쌓여있음)
    // 마이페이지 발전소 검색의 쿼리스트링과 중복되는 현상 방지하기 위해 비교 연산
    if (
      pathArr[1] !== "mypage" &&
      pathArr[2] !== "myPlant" &&
      searchPlant === checkHasDecode(currentPath)
    ) {
      return;
    }

    setFilter({
      local: "전국",
      land: "전체",
      capacity: "전체",
      sort: "-capacity",
      page: 1,
    });
    navigate(`/search/?word=${searchPlant}`);
  };

  // 로그아웃 버튼 클릭 함수
  const selectLogout = () => {
    removeCookie("60hz-user", { path: "/" });
    // 메인페이지 새로고침
    window.location.href = "/";
  };

  useEffect(() => {
    if (apiResponse.status === "success") {
      setRecoilState(!item.notify);
    }
  }, [apiResponse.status, item.notify]);

  const outSearchURL = useOutSearchURL(setSearchText);

  return (
    <>
      <HeaderPresenter
        state={{
          searchText: searchText,
        }}
        func={{
          searchPlant: searchPlant,
          selectLogout: selectLogout,
          setSearchText: setSearchText,
        }}
        options={{
          cookie: cookie["60hz-user"],
        }}
      />
    </>
  );
};
