import { useNavigate } from "react-router-dom";
import { DefaultLoading } from "../../UI/Loading/Loading";

interface Iitem {
  ownerComplete: number;
  forSailComplete: number;
  favoriteCount: number;
}

interface IapiResponse {
  countStatus: string;
}

export const MypagePlantCurrent = ({
  item,
  apiResponse,
}: {
  item: Iitem;
  apiResponse: IapiResponse;
}) => {
  const navigate = useNavigate();
  return (
    <section className="max-w-full w-full">
      <h1 className="text-listFirst font-bold mb-4 max-sm:font-medium">
        발전소 현황
      </h1>
      <div className="h-[259px] flex flex-col items-center justify-center px-[94px] bg-general-lightGray rounded-xl max-2xl:px-[48px]">
        <main className="w-full flex justify-between">
          <div className="flex flex-col items-center">
            <h1
              className={`text-[44px] mb-2 ${
                item.ownerComplete
                  ? "text-main-color-primary"
                  : "text-text-color-disabled"
              }  max-2xl:text-[35px]`}
            >
              {apiResponse.countStatus === "loading" ? (
                <div className="h-[52.5px]">
                  <DefaultLoading />
                </div>
              ) : (
                item.ownerComplete
              )}
            </h1>
            <span className="text-listSecond text-center max-2xl:text-default">
              소유주 등록 완료
            </span>
          </div>
          <div className="flex flex-col items-center">
            <h1
              className={`text-[44px] mb-2 ${
                item.forSailComplete
                  ? "text-main-color-primary"
                  : "text-text-color-disabled"
              } max-2xl:text-[35px]`}
            >
              {apiResponse.countStatus === "loading" ? (
                <div className="h-[52.5px]">
                  <DefaultLoading />
                </div>
              ) : (
                item.forSailComplete
              )}
            </h1>
            <span className="text-listSecond text-center max-2xl:text-default">
              매물 등록 완료
            </span>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => navigate("favorite")}
          >
            <h1
              className={`text-[44px] mb-2 ${
                item.favoriteCount
                  ? "text-main-color-primary"
                  : "text-text-color-disabled"
              }  max-2xl:text-[35px]`}
            >
              {apiResponse.countStatus === "loading" ? (
                <div className="h-[52.5px]">
                  <DefaultLoading />
                </div>
              ) : (
                item.favoriteCount
              )}
            </h1>
            <span className="text-listSecond text-center max-2xl:text-default">
              찜한 발전소 수
            </span>
          </div>
        </main>
        <button
          className="w-full py-4 mt-8 text-listSecond leading-[100%] 
         bg-main-color-primary text-general-white rounded-xl"
          onClick={() => navigate("myPlant")}
        >
          발전소 찾기
        </button>
      </div>
    </section>
  );
};
