interface Isize {
  height: number;
  width: number;
}

export const SubmitLoading = ({ height, width }: Isize) => {
  return (
    <svg
      className={`animate-spin h-[${height}px] w-[${width}px] text-main-color-primary`}
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-[0.03]"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};

export const DefaultLoading = () => {
  return (
    <svg
      className={`animate-spin h-full w-full text-main-color-primary`}
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-[0.03]"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};

export const ButtonLoading = () => {
  return (
    <svg
      className={`animate-spin h-full w-full text-general-white`}
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-[0.03]"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};
