interface ImainPlantResetFilter {
  resetFilter: () => void;
}

export const MainPlantResetFilter = ({
  resetFilter,
}: ImainPlantResetFilter) => {
  return (
    <div className="flex justify-between items-end text-[22px] font-bold text-main-color-secondary mb-8">
      필터
      <span
        className="text-text-color-secondary font-normal text-footer mr-[25px] border-b-text-color-secondary border-b-[1px] cursor-pointer"
        onClick={resetFilter}
      >
        초기화
      </span>
    </div>
  );
};
