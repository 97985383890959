import axios from "axios";
import { HandleFormText } from "../utils/SignFunc";

export async function axiosSignupDuplication(queryKey: any) {
  const response = await axios.post(
    process.env.REACT_APP_SERVER_URL + queryKey.url,
    { [queryKey.type]: queryKey.submitInfo },
    {
      withCredentials: true,
    },
  );

  return response.data;
}

export async function axiosSignup(queryKey: any) {
  const formData = HandleFormText(queryKey.submitInfo, queryKey.benefit);

  const response = await axios.post(
    process.env.REACT_APP_SERVER_URL + queryKey.url,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
      withCredentials: true,
    },
  );
  return response.data;
}
