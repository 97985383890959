import { useBodyScrollLock } from "../../../utils_public/useBodyScrollLock";

type ImodalTalk = {
  open: boolean;
  type: string;
  userId: number;
  username: string;
};

interface Istate {
  modalTalk: ImodalTalk;
}

interface Ifunc {
  modalClose: () => void;
}

interface Ioptions {
  type: string;
}

export const DetailTalkModalTop = ({
  state,
  func,
  options,
}: {
  state: Istate;
  func: Ifunc;
  options: Ioptions;
}) => {
  return (
    <div className="flex justify-between items-center text-subValue text-main-color-primary max-sm:text-listSecond">
      <h1>{`${state.modalTalk.username}님에게 메세지 보내기`}</h1>
      <img
        className="cursor-pointer w-[14px] h-[14px]"
        src="/icon_remodel/close.svg"
        onClick={() => {
          func.modalClose();
        }}
      />
    </div>
  );
};
