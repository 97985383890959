import { ChatMessageFetch } from "../../pages/Chat/module_fetch/ChatMessage.fetch";
import { IcurrentMessageList } from "../../pages/Chat/types/state.type";

type IselectOption = {
  plant: string;
  currentMessageId: number;
  user: string;
};

interface Istate {
  selectOption: IselectOption;
  curretMessageList: IcurrentMessageList;
  sendMessageError: boolean;
}

interface Ifunc {
  setCurretMessageList: (value: IcurrentMessageList) => void;
  setSelectOption: (value: IselectOption) => void;
  setSendMessageError: (error: boolean) => void;
}

export const ChatMessageBox = ({
  state,
  func,
}: {
  state: Istate;
  func: Ifunc;
}) => {
  return (
    <section className="max-w-[960px] min-w-[550px] w-full max-md:min-w-full max-sm:h-[450px]">
      <div className="px-[20px] py-[15px] flex justify-between items-center border-x-[1px] border-x-border-color-dividers max-md:border-t-[1px] max-md:border-t-border-color-dividers max-md:gap-[15px] max-sm:px-[10px]">
        <div className="flex items-center gap-x-[10px] max-md:flex-col max-md:items-start">
          <h1 className="text-main-color-primary text-subValue max-md:text-listFirst">
            {state.selectOption.plant}
          </h1>
          {state.selectOption.user !== "" && (
            <>
              <span className="max-md:hidden">-</span>
              <span className="text-listTitle max-md:text-listSecond">
                {state.selectOption.user + " 님과" + " 대화"}
              </span>
            </>
          )}
        </div>
        <button
          className="w-[155px] h-[30px] bg-main-color-primary text-general-white rounded-lg lg:hidden max-md:text-footer max-md:w-[125px] max-sm:w-[120px] max-sm:text-[11px]"
          onClick={() => {
            func.setSelectOption({ plant: "", user: "", currentMessageId: 0 });
          }}
        >
          다른 메세지 확인하기
        </button>
      </div>
      {state.selectOption.plant === "" ? (
        <div className="max-w-full w-full h-[95%]  bg-general-lightGray"></div>
      ) : state.selectOption.user === "" ? (
        <div className="max-w-full w-full h-[90.5%] flex justify-center pt-[50px] text-listTitle bg-general-lightGray">
          대화할 사용자를 선택해주세요!
        </div>
      ) : (
        <ChatMessageFetch state={{ ...state }} func={{ ...func }} />
      )}
    </section>
  );
};
