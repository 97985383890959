export function HandleComma(num: number | string) {
  if (!num) {
    return 0;
  }

  const parts: string[] = num.toString().split(".");

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return parts[0];
}

export function HandleInputTextComma(str: string) {
  if (!str) {
    return "";
  }

  const parts: string[] = str.toString().split(".");

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return parts[0];
}

export function InputComma(text: React.ChangeEvent<HTMLInputElement>) {
  let str = text.target.value;
  if (str.charAt(0) === "0") {
    let newStr = str.replace(/^0/, "");
    return newStr;
  }

  const comma = (str: string) => {
    str = String(str);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
  };
  const uncomma = (str: string) => {
    str = String(str);
    return str.replace(/[^\d]+/g, "");
  };
  return comma(uncomma(str));
}
