export const SIGNUP_INPUT_LIST = [
  {
    name: "이메일",
    inputInfo: "email",
    type: "text",
    placeHolder: "이메일을 입력해주세요 (* 필수)",
  },
  {
    name: "비밀번호",
    inputInfo: "password",
    type: "password",
    placeHolder: "비밀번호를 입력해주세요 (* 필수)",
    passwordValidation: [
      {
        type: "length",
        text: "8~30자 이내",
      },
      {
        type: "number",
        text: "숫자 포함",
      },
      {
        type: "special",
        text: "특수문자 포함",
      },
    ],
  },
  {
    name: "비밀번호 확인",
    inputInfo: "passwordVerify",
    type: "password",
    placeHolder: "비밀번호를 한번 더 입력해주세요 (* 필수)",
    passwordValidation: [
      {
        type: "same",
        text: "비밀번호 일치",
      },
    ],
  },
  {
    name: "닉네임",
    inputInfo: "nickname",
    type: "text",
    placeHolder: "닉네임을 입력해주세요 (* 필수)",
    validation: [],
  },
  {
    name: "이름",
    inputInfo: "userName",
    type: "text",
    placeHolder: "이름을 입력해주세요 (* 필수)",
    validation: [],
  },
  {
    name: "휴대폰 번호",
    inputInfo: "phoneNumber",
    type: "tel",
    placeHolder: "휴대폰 번호를 입력해주세요 (* 필수)",
  },
  {
    name: "전화번호 (선택)",
    inputInfo: "telephoneNumber",
    type: "tel",
    placeHolder: "전화번호를 입력해주세요",
  },
  {
    name: "소속",
    inputInfo: "company",
    type: "text",
    placeHolder: "소속을 입력해주세요 (* 필수)",
  },
];
