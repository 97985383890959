import classNames from "classnames";
import {
  IplantInfo,
  IinquiryListDeleteModal,
  IinquiryListUpdateModal,
} from "../../../pages/Mypage/types/state.type";
import { InquiryText } from "./InquiryText";

interface signature {
  [key: number]: string;
}

interface Iitem {
  questionType: number;
  answer: string;
  answerDate: string;
  plantOrder: number;
  inquiry_id: number;
  waiting: string;
  registerDatetime: string;
}

interface Istate {
  contentCopy: string;
  curretOrderId: number;
  inquiryContent: string;
  hide: boolean;
  modify: boolean;
}

interface IapiResponse {
  updatedStatus: string;
}

interface Ifunc {
  closeModifyBox: (bool: boolean) => void;
  selectInquiryItem: () => void;
  setInquiryContent: (text: string) => void;
  HandleDeleteModal: (value: IinquiryListDeleteModal) => void;
  HandleUpdateModal: (value: IinquiryListUpdateModal) => void;
}

export const MypageInquiryListItem = ({
  item,
  state,
  apiResponse,
  func,
}: {
  item: Iitem;
  state: Istate;
  apiResponse: IapiResponse;
  func: Ifunc;
}) => {
  const QUESTION_TYPE: signature = {
    1: "사이트 이용 방법",
    2: "버그/데이터 오류 신고",
    3: "발전량 정보 관련",
    4: "광고 및 기타 제안",
  };
  return (
    <li>
      <div
        className={`py-[24px] flex items-center  ${
          item.plantOrder &&
          state.curretOrderId + 1 !== item.plantOrder &&
          "border-t-[1px] border-t-border-color-dividers "
        } px-[30px] cursor-pointer ${
          state.hide &&
          "bg-general-lightGray border-b-[1px] border-b-border-color-dividers"
        } max-lg:px-[15px] max-md:flex max-md:flex-col`}
        onClick={() => {
          func.selectInquiryItem();
        }}
      >
        <div
          className={classNames(
            "max-w-[215px] w-full flex justify-between",
            {
              "text-main-color-primary": state.hide,
            },
            "max-md:max-w-full max-md:justify-start max-md:gap-x-[20px]",
          )}
        >
          <h1 className="w-[170px] whitespace-nowrap overflow-hidden text-ellipsis  max-lg:w-[150px]">
            {QUESTION_TYPE[item.questionType]}
          </h1>
          <span className="text-border-color-dividers">|</span>
        </div>

        <div
          className={classNames(
            "max-w-[765px] w-full px-[112px]",
            {
              "text-main-color-primary": state.hide,
            },
            "max-xl:px-[25px] max-md:px-0 max-md:py-[16px]",
          )}
        >
          <h1 className="w-[430px] whitespace-nowrap overflow-hidden text-ellipsis max-2xl:w-[250px] max-sm:text-default">
            {state.contentCopy}
          </h1>
        </div>
        <div className="max-w-[182px] w-full flex justify-between items-center max-md:max-w-full max-md:justify-start max-md:gap-x-[16px]">
          {item.waiting === "답변 대기" ? WAITING_BADGE : COMPLETE_BADGE}
          <p className="max-sm:text-footer">
            {item.registerDatetime.slice(0, 10).split("-").join(".")}
          </p>
        </div>
      </div>
      {item.waiting === "답변 대기"
        ? state.hide && (
            <InquiryText
              state={{
                inquiryContent: state.inquiryContent,
                modify: state.modify,
                contentCopy: state.contentCopy,
              }}
              apiResponse={{
                updatedStatus: apiResponse.updatedStatus,
              }}
              func={{
                setInquiryContent: func.setInquiryContent,
                closeModifyBox: func.closeModifyBox,
                HandleDeleteModal: func.HandleDeleteModal,
                HandleUpdateModal: func.HandleUpdateModal,
              }}
              item={{
                waiting: item.waiting,
                inquiry_id: item.inquiry_id,
              }}
            />
          )
        : state.hide && (
            <>
              <InquiryText
                state={{
                  inquiryContent: state.inquiryContent,
                  modify: state.modify,
                  contentCopy: state.contentCopy,
                }}
                apiResponse={{
                  updatedStatus: apiResponse.updatedStatus,
                }}
                func={{
                  setInquiryContent: func.setInquiryContent,
                  closeModifyBox: func.closeModifyBox,
                  HandleDeleteModal: func.HandleDeleteModal,
                  HandleUpdateModal: func.HandleUpdateModal,
                }}
                item={{
                  waiting: item.waiting,
                  inquiry_id: item.inquiry_id,
                }}
              />
              <AnswerText answer={item.answer} answerDate={item.answerDate} />
            </>
          )}
    </li>
  );
};

const AnswerText = ({
  answer,
  answerDate,
}: {
  answer: string;
  answerDate: string;
}) => {
  return (
    <div className="py-[28.5px] px-[30px] bg-general-lightGray border-t-[1px] border-t-border-color-dividers">
      <div className="flex justify-between mb-6">
        <span
          className="border-[1px] text-main-color-primary border-main-color-primary w-[72px] 
          text-center py-[6px] rounded-[32px] text-footer "
        >
          답변 완료
        </span>
        <p className="text-main-color-primary max-sm:text-footer">
          {answerDate.slice(0, 10).split("-").join(".")}
        </p>
      </div>
      <div className="w-full bg-general-lightGray rounded-lg whitespace-pre max-sm:text-default">
        {answer}
      </div>
    </div>
  );
};

const WAITING_BADGE = (
  <span
    className="border-[1px] text-text-color-secondary border-border-color-dividers w-[72px] text-center py-[6px] leading-[100%]
        rounded-[32px] text-footer"
  >
    답변 대기
  </span>
);

const COMPLETE_BADGE = (
  <span
    className="border-[1px] text-main-color-primary border-main-color-primary w-[72px] text-center py-[6px] leading-[100%]
        rounded-[32px] text-footer"
  >
    답변 완료
  </span>
);
