import { useState } from "react";
import { useCookies } from "react-cookie";
import { MypageOwnerSubmitModify } from "../module_modify/MypageOwnerSubmit.modify";
import { useAddOwnerInfoQuery } from "../query/useOwnerInfoQuery";
import { Ifile } from "../types/state.type";

interface Iitem {
  username: string;
}

export const MypageOwnerSubmitFetch = ({ item }: { item: Iitem }) => {
  const [cookie] = useCookies(["60hz-user"]);
  const [businessFile, setBusinessFile] = useState<Ifile>({
    name: "",
    file: null,
    change: false,
  });
  const [commerceFile, setCommerceFile] = useState<Ifile>({
    name: "",
    file: null,
    change: false,
  });

  const [submitModal, setSubmitModal] = useState(false);

  const POST_OWNER_FILE = useAddOwnerInfoQuery();

  const submitOwnerFile = () => {
    POST_OWNER_FILE.mutate({
      apiName: "AddOwnerFileFromMypage",
      url: "api/v1/license/",
      cookie: cookie["60hz-user"],
      businessFile: businessFile,
      commerceFile: commerceFile,
    });
    setSubmitModal(false);
  };

  return (
    <MypageOwnerSubmitModify
      state={{
        businessFile: businessFile,
        commerceFile: commerceFile,
        submitModal: submitModal,
      }}
      func={{
        setBusinessFile: setBusinessFile,
        setCommerceFile: setCommerceFile,
        setSubmitModal: setSubmitModal,
        submitOwnerFile: submitOwnerFile,
      }}
      item={{ username: item.username }}
      apiResponse={{
        status: POST_OWNER_FILE.status,
      }}
    />
  );
};
