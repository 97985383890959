import { useState } from "react";
import { ChatUserList } from "../../components/Chat/ChatUserList";
import { ChatPlantList } from "../../components/Chat/ChatPlantList";
import { ChatMessageBox } from "../../components/Chat/ChatMessageBox";
import { IplantRoomList, Imessage } from "./types/item.type";
import { IpingList, IcurrentMessageList } from "./types/state.type";
import { ChatTabletMenu } from "../../components/Chat/ChatTabletMenu/ChatTabletMenu";

type IselectOption = {
  plant: string;
  currentMessageId: number;
  user: string;
};

interface Istate {
  plantPingList: IpingList;
  userPingList: IpingList;
  curretMessageList: IcurrentMessageList;
  selectOption: IselectOption;
  selectPlant: Imessage[];
}

interface Ifunc {
  setPlantPingList: (value: IpingList) => void;
  setUserPingList: (value: IpingList) => void;
  setCurretMessageList: (value: IcurrentMessageList) => void;
  setSelectOption: (value: IselectOption) => void;
  setSelectPlant: (value: Imessage[]) => void;
  searchPlantName: (plantName: string) => void;
  searchPlantReset: () => void;
}

interface IapiResponse {
  plantRoomStatus: string;
}

interface Iitem {
  plantRoomList: IplantRoomList[];
}

export const ChatPresenter = ({
  state,
  func,
  apiResponse,
  item,
}: {
  state: Istate;
  func: Ifunc;
  apiResponse: IapiResponse;
  item: Iitem;
}) => {
  const [sendMessageError, setSendMessageError] = useState(false);

  return (
    <div className="max-w-[1630px] h-[790px] flex mx-auto mt-0 mb-[140px] pt-[108px] px-[50px] justify-between max-md:px-[24px] max-md:pt-[50px] max-sm:h-full">
      <ChatPlantList
        state={{
          selectOption: state.selectOption,
          plantPingList: state.plantPingList,
        }}
        func={{
          setSelectOption: func.setSelectOption,
          setSelectPlant: func.setSelectPlant,
          setPlantPingList: func.setPlantPingList,
          searchPlantName: func.searchPlantName,
          searchPlantReset: func.searchPlantReset,
        }}
        apiResponse={{ ...apiResponse }}
        item={{
          plantRoomList: item.plantRoomList,
        }}
      />
      <ChatUserList
        state={{
          selectOption: state.selectOption,
          selectPlant: state.selectPlant,
          userPingList: state.userPingList,
          curretMessageList: state.curretMessageList,
        }}
        func={{
          setSelectOption: func.setSelectOption,
          setUserPingList: func.setUserPingList,
          setSendMessageError: setSendMessageError,
        }}
      />
      <ChatMessageBox
        state={{
          selectOption: state.selectOption,
          curretMessageList: state.curretMessageList,
          sendMessageError: sendMessageError,
        }}
        func={{
          setCurretMessageList: func.setCurretMessageList,
          setSelectOption: func.setSelectOption,
          setSendMessageError: setSendMessageError,
        }}
      />

      {state.selectOption.user === "" &&
        state.selectOption.currentMessageId === 0 && (
          <ChatTabletMenu
            state={{
              selectOption: state.selectOption,
              selectPlant: state.selectPlant,
              plantPingList: state.plantPingList,
              userPingList: state.userPingList,
              curretMessageList: state.curretMessageList,
            }}
            func={{
              setSelectOption: func.setSelectOption,
              setSelectPlant: func.setSelectPlant,
              setPlantPingList: func.setPlantPingList,
              searchPlantName: func.searchPlantName,
              searchPlantReset: func.searchPlantReset,
              setUserPingList: func.setUserPingList,
              setSendMessageError: setSendMessageError,
            }}
            apiResponse={{ ...apiResponse }}
            item={{
              plantRoomList: item.plantRoomList,
            }}
          />
        )}
    </div>
  );
};
