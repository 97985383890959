import axios from "axios";
import { useQuery } from "react-query";
import { useCookies } from "react-cookie";
import { QUERY_KEY } from "../constants/queryKey.constant";
import { priceHistoryListType } from "../types/item.type";
import { priceBoxValueType } from "../types/state.type";

export const usePriceHistoryQuery = (
  plantId: number,
  tradeType: priceBoxValueType,
) => {
  const [cookie] = useCookies(["60hz-user"]);

  return useQuery<priceHistoryListType[]>(
    QUERY_KEY.priceHistoryStatus(plantId, tradeType),
    async () => {
      const { data } = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          `api/v1/desired-price/history/plant/${plantId}/${tradeType}/`,
        {
          withCredentials: true,
          headers: {
            Authorization: " Token " + cookie["60hz-user"],
          },
        },
      );

      return data.results;
    },
    {
      cacheTime: 0,
      retry: false,
    },
  );
};
