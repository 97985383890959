import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useUserMessageList } from "../hooks/useGetUserMessageList";
import { ChatMessageModify } from "../module_modify/ChatMessage.modify";
import { useSendMessageQuery } from "../query/useSendMessageQuery";
import { IcopyMessageList } from "../types/state.type";
import { getTodayDate } from "../../../utils_public/dateFormat";
import { IcurrentMessageList } from "../types/state.type";

type IselectOption = {
  plant: string;
  currentMessageId: number;
  user: string;
};

interface Istate {
  selectOption: IselectOption;
  curretMessageList: IcurrentMessageList;
  sendMessageError: boolean;
}

interface Ifunc {
  setCurretMessageList: (value: IcurrentMessageList) => void;
  setSendMessageError: (error: boolean) => void;
}

export const ChatMessageFetch = ({
  state,
  func,
}: {
  state: Istate;
  func: Ifunc;
}) => {
  const [cookie] = useCookies(["60hz-user"]);

  const POST_SEND_MESSAGE = useSendMessageQuery();

  const { userMessageData, userMessageStatus } = useUserMessageList(
    state.selectOption.currentMessageId,
    cookie["60hz-user"],
  );

  const [copyMessageList, setCopyMessageList] = useState<IcopyMessageList[]>(
    [],
  );
  const [text, setText] = useState("");

  const handleKeyDown = (
    key: string,
    shiftKey: boolean,
    e: React.KeyboardEvent<HTMLFormElement>,
  ) => {
    if (key === "Enter" && !shiftKey && e.nativeEvent.isComposing === false) {
      e.preventDefault();
      return handleSubmit();
    }
  };

  // 상대방 메세지 요청
  const handleSubmit = () => {
    if (text.length < 5 || text.trim() === "") {
      setText("");
      return alert("최소 5자 이상 메세지를 작성해야만 전송할 수 있습니다.");
    }

    POST_SEND_MESSAGE.mutate({
      apiName: "Submit Message",
      content: text,
      token: cookie["60hz-user"],
      url: `message/trade/${state.selectOption.currentMessageId}/content/`,
    });
  };

  useEffect(() => {
    if (userMessageStatus === "success") {
      setCopyMessageList(userMessageData);
    }
  }, [userMessageStatus]);

  useEffect(() => {
    func.setSendMessageError(true);
  }, [POST_SEND_MESSAGE.isError]);

  useEffect(() => {
    if (POST_SEND_MESSAGE.status === "success") {
      setCopyMessageList([
        ...copyMessageList,
        {
          sender_id: userMessageData[0].is_me
            ? userMessageData[0].sender_id
            : userMessageData[0].receiver_id,
          sender_name: userMessageData[0].is_me
            ? userMessageData[0].sender_name
            : userMessageData[0].receiver_name,
          check_datetime: userMessageData[0].check_datetime,
          receiver_id: userMessageData[0].is_me
            ? userMessageData[0].sender_id
            : userMessageData[0].receiver_id,
          receiver_name: userMessageData[0].is_me
            ? userMessageData[0].sender_name
            : userMessageData[0].receiver_name,
          is_me: true,
          content: text,
          send_datetime: getTodayDate(),
        },
      ]);
      func.setCurretMessageList({
        ...state.curretMessageList,
        [state.selectOption.currentMessageId]: {
          message: text,
          date: new Date().toString(),
        },
      });
      setText("");
    }
  }, [POST_SEND_MESSAGE.status]);

  return (
    <ChatMessageModify
      state={{
        selectOption: state.selectOption,
        copyMessageList: copyMessageList,
        text: text,
      }}
      func={{
        setCopyMessageList: setCopyMessageList,
        setText: setText,
        handleKeyDown: handleKeyDown,
        handleSubmit: handleSubmit,
      }}
      item={{
        userMessageData: userMessageData,
      }}
      apiResponse={{
        userMessageStatus: userMessageStatus,
        userSendMessageStatus: POST_SEND_MESSAGE.status,
        userSendMessageError: state.sendMessageError,
        userSendMessageErrorCode:
          POST_SEND_MESSAGE.error && POST_SEND_MESSAGE.error.code
            ? POST_SEND_MESSAGE.error.code
            : "",
        userSendMessageErrorStatus:
          POST_SEND_MESSAGE.error && POST_SEND_MESSAGE.error.response
            ? POST_SEND_MESSAGE.error.response.status
            : 0,
      }}
    />
  );
};
