import classNames from "classnames";
import { ImyPlantStatusList } from "../../../../pages/Mypage/types/plantInfo.type";
import { getDayIncodeDate } from "../../../../utils_public/dateFormat";
import { capacityUnit } from "../../../../utils_public/Unit";
import { useNavigate } from "react-router-dom";

export const MypageCertificationTabletItem = ({
  id,
  plant,
  status,
  updated_datetime,
}: ImyPlantStatusList) => {
  const navigate = useNavigate();
  return (
    <li className="px-[4px]">
      {plant ? (
        <main className="flex flex-col mb-[60px]">
          <div className="px-[16px] py-[8px] flex justify-between items-center border-y-[1px] border-y-border-color-dividers">
            <span>{plant.plant_name}</span>
            <span
              className="text-border-color-dividers border-b-[1px] border-b-border-color-dividers cursor-pointer"
              onClick={() => {
                navigate(`information/${id}`);
              }}
            >
              수정
            </span>
          </div>
          <article className="px-[16px] py-[12px] flex flex-col gap-y-[12px] max-sm:text-default">
            <div className="flex gap-x-[26px]">
              <h1 className="text-text-color-secondary whitespace-nowrap">
                용량
              </h1>
              <span>{capacityUnit(plant.capacity)}</span>
            </div>
            <div className="flex gap-x-[26px]">
              <h1 className="text-text-color-secondary whitespace-nowrap">
                주소
              </h1>
              <span>{plant.updated_address}</span>
            </div>
            <div className="flex gap-x-[12px] items-center">
              <h1 className="text-text-color-secondary whitespace-nowrap">
                최종 수정일
              </h1>
              <span>{getDayIncodeDate(updated_datetime)}</span>

              <div className="max-w-full">
                <span
                  className={classNames(
                    "text-center px-[12px] py-[6px] rounded-[30px] text-footer max-sm:text-smallExplanation max-sm:py-[4px] max-sm:px-[8px]",
                    {
                      "bg-main-color-sub": status === "검토 중",
                    },
                    {
                      "bg-main-color-secondary": status === "소유주 등록 완료",
                    },
                  )}
                >
                  {status}
                </span>
              </div>
            </div>
          </article>
        </main>
      ) : (
        <main className="flex flex-col mb-[60px]">
          <div className="px-[16px] py-[8px] flex justify-between items-center border-y-[1px] border-y-border-color-dividers text-border-color-dividers">
            <span>-</span>
          </div>
          <article className="px-[16px] py-[12px] flex flex-col gap-y-[12px] max-sm:text-default">
            <p>{status}</p>

            <div className="flex gap-x-[12px]">
              <h1 className="text-text-color-secondary whitespace-nowrap">
                최종 수정일
              </h1>
              <span>{getDayIncodeDate(updated_datetime)}</span>
              <div className="max-w-full">
                <span
                  className={classNames(
                    "text-center px-[12px] py-[6px] rounded-[30px] text-footer max-sm:text-smallExplanation max-sm:py-[4px] max-sm:px-[8px]",
                    {
                      "bg-main-color-sub": status === "검토 중",
                    },
                    {
                      "bg-main-color-secondary": status === "소유주 등록 완료",
                    },
                  )}
                >
                  {status}
                </span>
              </div>
            </div>
          </article>
        </main>
      )}
    </li>
  );
};
