import { Navigate } from "react-router-dom";
import { ChatFetch } from "./Chat.fetch";
import { isLogin } from "../../utils_public/isLogin";

export const ChatContainer = () => {
  return isLogin() ? (
    <>
      <ChatFetch />
    </>
  ) : (
    <Navigate to={"/login?access"} />
  );
};
