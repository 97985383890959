import { useEffect } from "react";
import {
  passwordLength,
  passwordNumber,
  passwordSpecial,
  passwordSame,
} from "../atoms/validation.atoms";
import { IsignupInfo } from "../types/state.type";
import { useSetRecoilState } from "recoil";

export const usePasswordValidation = (signupInput: IsignupInfo) => {
  const setLength = useSetRecoilState(passwordLength);
  const setNumber = useSetRecoilState(passwordNumber);
  const setSpecial = useSetRecoilState(passwordSpecial);
  const setSame = useSetRecoilState(passwordSame);

  useEffect(() => {
    if (signupInput.password.length >= 8 && signupInput.password.length <= 30) {
      setLength(true);
    } else {
      setLength(false);
    }

    if (NUMBER_TEST.test(signupInput.password)) {
      setNumber(true);
    } else {
      setNumber(false);
    }

    if (SPECIAL_TEST.test(signupInput.password)) {
      setSpecial(true);
    } else {
      setSpecial(false);
    }
  }, [signupInput.password]);

  useEffect(() => {
    if (
      signupInput.passwordVerify === signupInput.password &&
      signupInput.passwordVerify !== ""
    ) {
      setSame(true);
    } else {
      setSame(false);
    }
  }, [signupInput.passwordVerify, signupInput.password]);
};

const NUMBER_TEST = /[0-9]/;
const SPECIAL_TEST = /[$&+,:;=?@#|`'<>.^*()%!-]/;
