import { useState } from "react";
import { useCookies } from "react-cookie";
import { MypagePlantSellModify } from "../module_modify/MypagePlantSell.modify";
import { HandleComma } from "../../../utils_public/Comma";
import { useSubmitPriceQuery } from "../../Main/query/useSubmitPriceQuery";
import { useGetSellList } from "../hooks/useGetSellBuyList";

export const MypagePlantSellFetch = () => {
  const [cookie] = useCookies(["60hz-user"]);

  const [selectPlantId, setSelectPlantId] = useState(0);

  const { sellList, status } = useGetSellList(cookie["60hz-user"]);

  const POST_SUBMIT_PRICE = useSubmitPriceQuery();

  const submitPrice = (plantId: number, price: number | string) => {
    POST_SUBMIT_PRICE.mutate({
      apiName: "PostSubmitPrice",
      url: `api/plant/${plantId}/desired-price/sell/`,
      price: typeof price === "number" ? HandleComma(price) : price,
      cookie: cookie["60hz-user"],
    });
  };

  return (
    <MypagePlantSellModify
      state={{
        selectPlantId: selectPlantId,
      }}
      item={{ sellList: sellList }}
      apiResponse={{
        apiStatus: status,
        submitPlantId: POST_SUBMIT_PRICE.data && POST_SUBMIT_PRICE.data.plant,
        submitSuccess: POST_SUBMIT_PRICE.isSuccess,
        submitLoading: POST_SUBMIT_PRICE.isLoading,
        axiosCode:
          POST_SUBMIT_PRICE.error && POST_SUBMIT_PRICE.error.code
            ? POST_SUBMIT_PRICE.error.code
            : "",
        error: POST_SUBMIT_PRICE.isError,
        errorStatus:
          POST_SUBMIT_PRICE.error && POST_SUBMIT_PRICE.error.response
            ? POST_SUBMIT_PRICE.error.response.status
            : 0,
      }}
      func={{
        setSelectPlantId: setSelectPlantId,
        submitPrice: submitPrice,
      }}
    />
  );
};
