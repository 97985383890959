import { DetailInformationTitle } from "./DetailInformationTitle";
import { DetailInformationPirce } from "./DetailInformationPirce";
import { DetailInformationTable } from "./DetailInformationTable";
import { IplantInfo } from "../../../pages/Detail/types/plantInfo.type";

type ImodalSellBuy = {
  open: boolean;
  type: string;
  plantId: number;
};

interface Ifunc {
  setModalTalkChoice: (bool: boolean) => void;
  setModalSellBuy: (value: ImodalSellBuy) => void;
}

interface Istate {
  favoriteIcon: boolean;
}

interface Iitem {
  plantInfo: IplantInfo;
}

export const DetailInformation = ({
  item,
  func,
}: {
  item: Iitem;
  func: Ifunc;
}) => {
  return (
    <section className="w-[47%] max-xl:w-full">
      <DetailInformationTitle
        item={{
          title: item.plantInfo.plant_name,
          address:
            item.plantInfo.addr_lv1 +
            " " +
            item.plantInfo.addr_lv2 +
            " " +
            item.plantInfo.addr_lv3,
          capacity: item.plantInfo.capacity,
        }}
        func={{
          setModalTalkChoice: func.setModalTalkChoice,
        }}
      />
      <DetailInformationPirce
        func={{
          setModalSellBuy: func.setModalSellBuy,
        }}
        item={{
          plantId: item.plantInfo.id,
          capacity: item.plantInfo.capacity,
          local: item.plantInfo.addr_lv1,
          myOwner: item.plantInfo.is_im_owner,
        }}
      />
      <DetailInformationTable
        item={{
          plantName: item.plantInfo.plant_name,
          landCategory: item.plantInfo.land_name,
          landSize: item.plantInfo.land_area,
          memberType: item.plantInfo.member_type,
          address:
            item.plantInfo.addr_lv1 +
            " " +
            item.plantInfo.addr_lv2 +
            " " +
            item.plantInfo.addr_lv3,
        }}
        options={{
          title: "발전소 개요",
          loading: false,
        }}
      />
    </section>
  );
};
