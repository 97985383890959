import { HandleComma } from "./Comma";

export function countUnit(num: number) {
  if (num >= 100000000) {
    if (num >= 1000000000000) {
      return "금액 초과";
    } else if (num >= 100000000000) {
      return String(num).slice(0, 4) + "억";
    } else if (num >= 10000000000) {
      return String(num).slice(0, 3) + "억";
    } else if (num >= 1000000000) {
      return String(num).slice(0, 2) + "억";
    } else {
      return String(num).slice(0, 1) + "억";
    }
  } else if (num >= 10000000) {
    return String(num).slice(0, 4) + "만";
  } else if (num >= 1000000) {
    return String(num).slice(0, 3) + "만";
  } else if (num >= 100000) {
    return String(num).slice(0, 2) + "만";
  } else if (num >= 10000) {
    return String(num).slice(0, 1) + "만";
  } else if (num >= 1000) {
    return String(num).slice(0, 1) + "천";
  } else if (num >= 100) {
    return String(num).slice(0, 1) + "백";
  } else {
    return num >= 0 ? num.toFixed(0) : "-";
  }
}

export function moneyUnit(num: number | string) {
  // 비활성화 값 처리
  num = +num;
  if (num < 0 || !num) return "- 원";

  const changeDot = (str: number, second: number, pos: number) => {
    const numberString = String(str).slice(0, second);
    return [numberString.slice(0, pos), ".", numberString.slice(pos)].join("");
  };

  if (+num >= 100000000) {
    if (+num >= 1000000000000) {
      return "금액 초과";
    } else if (+num >= 100000000000) {
      return (
        <>
          {HandleComma(String(num).slice(0, 4))}
          <span> 억원</span>
        </>
      );
    } else if (+num >= 10000000000) {
      return (
        <>
          {String(num).slice(0, 3)}
          <span> 억원</span>
        </>
      );
    } else if (+num >= 1000000000) {
      return (
        <>
          {changeDot(num, 3, 2)}
          <span> 억원</span>
        </>
      );
    } else {
      return (
        <>
          {changeDot(num, 3, 1)}
          <span> 억원</span>
        </>
      );
    }
  } else if (+num >= 10000000) {
    const handle = HandleComma(String(num).slice(0, 4));
    return (
      <>
        {handle}
        <span> 만원</span>
      </>
    );
  } else if (+num >= 1000000) {
    return (
      <>
        {String(num).slice(0, 3)}
        <span> 만원</span>
      </>
    );
  } else if (+num >= 100000) {
    return (
      <>
        {String(num).slice(0, 2)}
        <span> 만원</span>
      </>
    );
  } else {
    return (
      <>
        {HandleComma(Number(num).toFixed())}
        <span> 원</span>
      </>
    );
  }
}

export function moneyUnit_NotSpan(num: number) {
  // 비활성화 값 처리
  if (num < 0 || typeof num !== "number") return "-원";

  const priceChangeDot = (number: number, second: number, pos: number) => {
    const numberString = String(number).slice(0, second);
    const newStr = [
      numberString.slice(0, pos),
      ".",
      numberString.slice(pos),
    ].join("");

    return newStr[2] === "0" ? newStr.slice(0, 1) : newStr;
  };

  if (num >= 100000000) {
    if (num >= 1000000000000) {
      return "금액 초과";
    } else if (num >= 100000000000) {
      return HandleComma(String(num).slice(0, 4)) + "억원";
    } else if (num >= 10000000000) {
      return String(num).slice(0, 3) + "억원";
    } else if (num >= 1000000000) {
      // type true는 매매가 필터 전용 함수
      return priceChangeDot(num, 3, 2) + "억원";
    } else {
      return priceChangeDot(num, 2, 1) + "억원";
    }
  } else if (num >= 10000000) {
    const handle = String(num).slice(0, 4);
    return handle + "만원";
  } else if (num >= 1000000) {
    const handle = String(num).slice(0, 3);
    return handle + "만원";
  } else {
    return HandleComma((+num).toFixed()) + "원";
  }
}

export function moneyUnit_Pure(num: number) {
  // 비활성화 값 처리
  if (!num) return 0;

  if (+num >= 100000000) {
    if (+num >= 100000000000) {
      // 10억 단위
      return +String(num).slice(0, 4) * 100000000;
    } else if (+num >= 10000000000) {
      // 1억 단위
      return +String(num).slice(0, 3) * 100000000;
    } else if (+num >= 1000000000) {
      // 1억 단위
      return +String(num).slice(0, 2) * 100000000;
    } else {
      // 1천만 단위
      return +String(num).slice(0, 3) * 1000000;
    }
  } else if (+num >= 10000000) {
    // 1천만 단위
    return +String(num).slice(0, 4) * 10000;
  } else if (+num >= 1000000) {
    // 1백만 단위
    return +String(num).slice(0, 3) * 10000;
  } else if (+num >= 100000) {
    return +String(num).slice(0, 2) * 10000;
  } else {
    return num;
  }
}

export function annualPowerGenerationUnit(
  dailyGen: number,
  capacity: string | number,
) {
  if (!dailyGen || !capacity || typeof capacity === "string") return "-";

  const YEARLY_CAPACITY = dailyGen * capacity * 1000;

  if (YEARLY_CAPACITY >= 1000000) {
    return (
      <>
        {YEARLY_CAPACITY.toFixed().slice(0, -6)}
        <span> GWh</span>
      </>
    );
  } else if (YEARLY_CAPACITY >= 1000) {
    return (
      <>
        {YEARLY_CAPACITY.toFixed().slice(0, -3)}
        <span> MWh</span>
      </>
    );
  } else {
    return (
      <>
        {YEARLY_CAPACITY.toFixed(1)}
        <span> kWh</span>
      </>
    );
  }
}

export function capacityUnit(capacity: string | number) {
  if (!capacity || typeof capacity === "string" || capacity <= 0) return "-";

  const capacityRounds = (value: string) => {
    const integerNumber = value.slice(0, -3);
    const decimalNumber = value.slice(-3)[0];

    const combineNumber = integerNumber + "." + decimalNumber;

    return Math.round(Number(combineNumber));
  };

  return capacity >= 1
    ? capacity >= 1000
      ? capacityRounds(String(capacity)) + " GW"
      : Number(capacity).toFixed(0) + " MW"
    : Math.floor(capacity * 1000) + " kW";
}

export function byteUnit(byte?: number) {
  if (!byte) return 0;
  // 1MB
  if (byte >= 1000000) {
    return byte.toFixed().slice(0, 1) + "MB";
  } else if (byte >= 100000) {
    return byte.toFixed().slice(0, 3) + "KB";
  } else if (byte >= 10000) {
    return byte.toFixed().slice(0, 2) + "KB";
  } else if (byte >= 1000) {
    return byte.toFixed().slice(0, 1) + "KB";
  } else {
    return byte + "byte";
  }
}
