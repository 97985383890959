import { useState } from "react";
import { useCookies } from "react-cookie";
import { MypageFavoriteModify } from "../module_modify/MypageFavorite.modify";
import { useGetFavoritePlant } from "../hooks/useGetFavoritePlant";
import { useRemoveFavoritePlantQuery } from "../query/useFavoritePlantQuery";
import { IfavoriteModal } from "../types/state.type";

export const MypageFavoriteFetch = () => {
  const [cookie] = useCookies(["60hz-user"]);
  const { plantList, status } = useGetFavoritePlant(cookie["60hz-user"]);

  const DELETE_LIKE_PLANT = useRemoveFavoritePlantQuery();

  const [deleteList, setDeleteList] = useState<number[]>([]);
  const [deleteModal, setDeleteModal] = useState<IfavoriteModal>({
    modal: false,
    plant_id: null,
  });

  const deleteFavoritePlant = () => {
    if (!deleteModal.plant_id) {
      return;
    }
    DELETE_LIKE_PLANT.mutate({
      apiName: "favoritePlant REMOVE",
      url: `api/like/${deleteModal.plant_id}`,
      cookie: cookie["60hz-user"],
      plant_id: deleteModal.plant_id,
    });
    // 삭제된 리스트 id 저장
    setDeleteList([...deleteList, deleteModal.plant_id]);
    // 삭제 모달 초기화
    setDeleteModal({ modal: false, plant_id: null });
  };

  return (
    <>
      <MypageFavoriteModify
        plantList={plantList}
        deleteList={deleteList}
        status={status}
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        deleteFavoritePlant={deleteFavoritePlant}
      />
    </>
  );
};
