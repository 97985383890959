import { useParams } from "react-router-dom";
import { useGetSentinelMap } from "../hooks/useGetSentinelMap";
import { DetailVariousMapModify } from "../module_modify/DetailVariousMap.modify";

interface Istate {
  latitude: number;
  longitude: number;
}

interface IapiResponse {
  status: string;
}

export const DetailVariousMapFetch = ({
  state,
  apiResponse,
}: {
  state: Istate;
  apiResponse: IapiResponse;
}) => {
  const params = useParams();
  // React-Query 해당 발전소 센티널 이미지 요청
  const getSentinel = useGetSentinelMap(params.id);

  return (
    <>
      <DetailVariousMapModify
        state={{
          latitude: state.latitude,
          longitude: state.longitude,
        }}
        apiResponse={{
          status: apiResponse.status,
        }}
      />
    </>
  );
};
