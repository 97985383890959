import {
  useBuyerListQuery,
  useSellerListQuery,
} from "../query/useBuyerListQuery";

export const useGetBuyerList = (id?: number, token?: string) => {
  const { data, status } = useBuyerListQuery(
    `api/plant/${id}/desired-price/buy/`,
    {
      apiName: "Buyer List",
      authorization: token,
    },
  );

  return {
    data: data ? data : [],
    status: status,
  };
};

export const useGetSellerList = (id?: string, token?: string) => {
  const { data, status } = useSellerListQuery(
    `api/plant/${id}/desired-price/sell/`,
    {
      apiName: "Seller List",
      authorization: token,
    },
  );

  return {
    sellerList: data ? data : [],
    sellerListStatus: status,
  };
};
