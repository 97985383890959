import { ImessageList } from "../../pages/Chat/types/item.type";
import { IcopyMessageList } from "../../pages/Chat/types/state.type";
import { DefaultLoading } from "../UI/Loading/Loading";
import { ChatMessageItem } from "./ChatMessageItem";
import { SEND_MESSAGE_ERROR_TYPE } from "../../pages/Chat/error/error.object";
import { ErrorHandling } from "../../utils_public/ErrorHandling";

type IselectOption = {
  plant: string;
  currentMessageId: number;
  user: string;
};

interface Istate {
  selectOption: IselectOption;
  copyMessageList: IcopyMessageList[];
  text: string;
}

interface Ifunc {
  setCopyMessageList: (value: IcopyMessageList[]) => void;
  setText: (text: string) => void;
  handleKeyDown: (
    key: string,
    shiftKey: boolean,
    e: React.KeyboardEvent<HTMLFormElement>,
  ) => void;
  handleSubmit: () => void;
}

interface Iitem {
  userMessageData: ImessageList[];
}

interface IapiResponse {
  userMessageStatus: string;
  userSendMessageStatus: string;
  userSendMessageError: boolean;
  userSendMessageErrorCode: string;
  userSendMessageErrorStatus: number;
}

interface Ioptions {
  scrollRef: any;
}

export const ChatMessage = ({
  state,
  func,
  item,
  apiResoponse,
  options,
}: {
  state: Istate;
  func: Ifunc;
  item: Iitem;
  apiResoponse: IapiResponse;
  options: Ioptions;
}) => {
  return (
    <>
      <div
        ref={options.scrollRef}
        className="max-h-full h-[78%] flex flex-col bg-general-lightGray border-x-[1px] border-x-border-color-dividers px-[100px] py-[50px] overflow-y-scroll transition-all duration-200 max-md:px-[25px] max-sm:py-[25px]"
      >
        {apiResoponse.userMessageStatus === "loading" ? (
          <div className="w-[50%] h-[50%] mx-auto mt-[100px]">
            <DefaultLoading />
          </div>
        ) : (
          <article>
            {state.copyMessageList.map((el, idx) => (
              <ChatMessageItem
                item={{ ...el }}
                options={{ order: idx }}
                key={idx}
              />
            ))}
            {apiResoponse.userSendMessageStatus === "loading" && (
              <div className="w-[30px] h-[30px] mx-auto mt-[30px]">
                <DefaultLoading />
              </div>
            )}

            {apiResoponse.userSendMessageError && (
              <h1 className="text-default text-center text-general-red mt-[30px]">
                {ErrorHandling(
                  {
                    errorType: SEND_MESSAGE_ERROR_TYPE,
                    errorCode: apiResoponse.userSendMessageErrorCode,
                    errorStatus: apiResoponse.userSendMessageErrorStatus,
                    isError: apiResoponse.userSendMessageError,
                  },
                  "",
                )}
              </h1>
            )}
          </article>
        )}
      </div>
      <form
        className="h-[92px] flex items-center border-[1px] border-border-color-dividers"
        onSubmit={(e) => {
          e.preventDefault();
          func.handleSubmit();
        }}
        onKeyDown={(e: React.KeyboardEvent<HTMLFormElement>) =>
          func.handleKeyDown(e.key, e.shiftKey, e)
        }
      >
        <textarea
          className="h-full py-[30px] px-[20px] flex-grow outline-none resize-none scrollbar-hide max-md:text-default max-sm:px-[10px]"
          value={state.text}
          onChange={(e) => func.setText(e.target.value)}
          maxLength={100}
          placeholder={"메세지를 입력해주세요. (최소 5자 ~ 100자 이내)"}
        />
        <button className="h-full bg-main-color-primary text-general-white px-[30px] max-md:text-default max-sm:px-[25px]">
          전송하기
        </button>
      </form>
    </>
  );
};
