import classNames from "classnames";
import { IcapacityRange } from "../../../pages/Detail/types/state.type";
import { DropDownListBox } from "../../UI/DropDown/DropDownListBox";

interface Istate {
  currentCapacity: string;
  capacityToggle: boolean;
}

interface Ifunc {
  setToggle: (bool: boolean) => void;
  setCurrent: (value: string) => void;
}

export const DetailActualPriceCapacityFilter = ({
  state,
  func,
}: {
  state: Istate;
  func: Ifunc;
}) => {
  return (
    <div className="flex-grow h-[150px] flex flex-col justify-center text-listFirst gap-y-[10px] relative border-[1px] border-border-color-dividers rounded-xl px-[20px]  max-md:text-listSecond max-sm:text-default max-sm:max-w-[250px]">
      <h1>발전소 용량 정보를 선택해주세요.</h1>
      <div className="w-full h-[35px] relative z-[1]">
        <div className="w-full absolute border-[1px] py-[6px] text-default border-border-color-dividers rounded-lg">
          <div
            className="text-listSecond pl-[14px] relative cursor-pointer max-md:pr-[20px] max-md:text-default"
            onClick={() => func.setToggle(!state.capacityToggle)}
          >
            {state.currentCapacity}
            <img
              className={classNames(
                "absolute top-[8px] right-[8px]",
                {
                  "rotate-180": state.capacityToggle,
                },
                {
                  "rotate-0": !state.capacityToggle,
                },
              )}
              src="/icon_remodel/arrow.svg"
            />
          </div>
          <DropDownListBox
            state={{
              toggle: state.capacityToggle,
              current: state.currentCapacity,
            }}
            item={{
              selectList: CAPACITY_LIST,
            }}
            func={{
              setToggle: func.setToggle,
              setCurrent: func.setCurrent,
            }}
            options={{
              overflow: false,
            }}
          />
        </div>
      </div>
    </div>
  );
};

const CAPACITY_LIST: IcapacityRange[] = [
  "~100kW 미만",
  "100kW 이상 ~ 200kW 미만",
  "200kW 이상 ~ 500kW 미만",
  "500kW 이상 ~ 1MW 미만",
  "1MW 이상~",
];
