import { SignupRadioItem } from "./SignupRadioItem";
import { IsignupInfo } from "../../../pages/SignUp/types/state.type";
export const SignupRadioList = ({
  input,
  setInput,
}: {
  input: IsignupInfo;
  setInput: (value: IsignupInfo) => void;
}) => {
  return (
    <div className="h-[40px] flex mb-10 max-md:flex-col max-md:gap-y-[16px] max-md:h-[70px]">
      <span className="min-w-[77px] h-full text-default">구분</span>
      <ul className="grid grid-cols-[1fr_1fr] auto-rows-max gap-y-[20px] gap-x-[45px] ml-[75px] max-md:ml-[0px] max-sm:text-default max-xs:text-footer">
        {SIGNUP_RADIO_LIST.map((el, idx) => (
          <SignupRadioItem
            inputName={el}
            key={idx}
            input={input}
            setInput={setInput}
          />
        ))}
      </ul>
    </div>
  );
};

const SIGNUP_RADIO_LIST = [
  "발전소 소유주(개인/법인)",
  "에너지 관련 회사",
  "정부/공공기관",
  "기타",
];
