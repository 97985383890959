import {
  useFavoriteQuery,
  useFavoriteLimitCountQuery,
} from "../query/useFavoriteQuery";

export const useGetFavorite = (id?: string, token?: string) => {
  const { data, status } = useFavoriteQuery(`api/like/${id}`, {
    apiName: "Favorite Information",
    authorization: token,
  });

  return {
    data: data ? data : false,
    status: status,
  };
};

export const useGetFavoriteLimitCount = (token?: string) => {
  const { data, status } = useFavoriteLimitCountQuery(`liked-count/`, {
    apiName: "Plant Information",
    authorization: token,
  });

  return {
    favoriteCountData: data ? data : 0,
    favoriteCountStatus: status,
  };
};
