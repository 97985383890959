import { DetailWorthTop } from "./DetailWorthTop";
import { DetailWorthBottom } from "./DetailWorthBottom";
import { IplantInfo } from "../../../pages/Detail/types/plantInfo.type";

interface IdetailWorth {
  landCategory: string;
  landPrice: string;
  landFlatlandPrice: string;
  IndividuallyPrice: string;
}

export const DetailWorth = ({ plantInfo }: { plantInfo: IplantInfo }) => {
  return (
    <section className="w-[47%] max-xl:w-full">
      <div className="mb-8">
        <h1 className="text-subTitle font-bold max-sm:font-medium max-sm:text-subValue">
          이 발전소가 있는 토지의 가치는?
        </h1>
        <p className="text-smallExplanation text-text-color-secondary mt-[6px]">
          실거래가와 상이할 수 있습니다.
        </p>
        <p className="text-smallExplanation text-text-color-secondary mt-[3px]">
          공공데이터가 제공되지 않는 경우, 하이픈으로 표시됩니다.
        </p>
      </div>
      <DetailWorthTop
        landSize={plantInfo.land_area}
        tradeInfoArea={plantInfo.trade_info.area}
        tradeInforPrice={plantInfo.trade_info.price * 10000}
      />
      <DetailWorthBottom
        landCategory={plantInfo.land_name}
        IndividuallyPrice={plantInfo.land_price}
      />
    </section>
  );
};

const DUMMY_WORTH: IdetailWorth = {
  landCategory: "종교용지",
  landPrice: "2 억원",
  landFlatlandPrice: "65 만원",
  IndividuallyPrice: "10 만원",
};
