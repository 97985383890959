import { useEffect } from "react";

type ImodalTalk = {
  open: boolean;
  type: string;
  userId: number;
  username: string;
};

interface Istate {
  success: boolean;
  modalTalk: ImodalTalk;
  owner: boolean;
  talkText: number;
}

export const useSubmitTalkSuccess = (
  state: Istate,
  setModalTalk: (bool: ImodalTalk) => void,
) => {
  useEffect(() => {
    if (state.success) {
      if (state.owner) {
        confirm(
          `${
            state.modalTalk.type === "seller" ? "구매자" : "판매자"
          }에게 쪽지 전송을 성공하였습니다. 대화 페이지에서 확인하시겠습니까?`,
        )
          ? window.open(`/message?plant=${state.talkText}`)
          : null;
      } else {
        state.modalTalk.type === "buyer"
          ? alert("발전소가 매물로 등록되면 전달드리겠습니다.")
          : null;
      }

      return setModalTalk({ open: false, type: "", userId: 0, username: "" });
    }
  }, [state.success]);
};
