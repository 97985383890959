export const MypagePlantSellMenu = () => {
  return (
    <nav className="flex px-5 text-default text-text-color-secondary py-2 border-y-[1px] border-y-text-color-disabled">
      <div className="max-w-[280px] w-full">
        <span>발전소명</span>
      </div>
      <div className="max-w-[250px] w-full text-right">
        <span>나의 판매 희망가</span>
      </div>
      <div className="max-w-[280px] w-full text-right">
        <span>구매자의 희망가</span>
      </div>
      <div className="max-w-[240px] w-full text-right">
        <span>닉네임</span>
      </div>
      <div className="w-[50px]"></div>
    </nav>
  );
};
