import { ReactElement, useEffect } from "react";
import {
  LoginEmailModal,
  LoginPasswordModal,
  LoginVerificationModal,
} from "../../../components/Login/Modal/LoginModal";
import {
  emailModal,
  passwordModal,
  verificationdModal,
} from "../types/modal.type";
import { useValidationModal } from "../hooks/useValidationModal";

interface signature {
  [key: string]: ReactElement<HTMLDivElement>;
}

interface Istate {
  inputText: emailModal | passwordModal | verificationdModal;
}

interface Ifunc {
  setInputText: (
    value: emailModal | passwordModal | verificationdModal,
  ) => void;
  setCurrentModal: (text: string) => void;
  submitFinding: (url: string) => void;
}

interface Iitem {
  email: string;
}

interface IapiResponse {
  loading: boolean;
  success: boolean;
  axiosCode: string;
  error: boolean;
  errorStatus: number;
}

interface Ioptions {
  modalType: string;
}

export const LoginModalModify = ({
  state,
  func,
  item,
  apiResponse,
  options,
}: {
  state: Istate;
  func: Ifunc;
  item: Iitem;
  apiResponse: IapiResponse;
  options: Ioptions;
}) => {
  const CURRENT_MODAL: signature = {
    email: (
      <LoginEmailModal
        state={{
          inputText: state.inputText,
        }}
        item={{
          email: item.email,
        }}
        func={{
          setCurrentModal: func.setCurrentModal,
          setInputText: func.setInputText,
          submitFinding: func.submitFinding,
        }}
        apiResponse={{ ...apiResponse }}
      />
    ),
    password: (
      <LoginPasswordModal
        state={{
          inputText: state.inputText,
        }}
        func={{
          setCurrentModal: func.setCurrentModal,
          setInputText: func.setInputText,
          submitFinding: func.submitFinding,
        }}
        apiResponse={{ ...apiResponse }}
      />
    ),
    verification: (
      <LoginVerificationModal
        state={{
          inputText: state.inputText,
        }}
        func={{
          setCurrentModal: func.setCurrentModal,
          setInputText: func.setInputText,
          submitFinding: func.submitFinding,
        }}
        apiResponse={{ ...apiResponse }}
      />
    ),
  };

  useEffect(() => {
    if (
      (options.modalType === "password" ||
        options.modalType === "verification") &&
      apiResponse.success
    ) {
      alert("요청하신 정보를 메일 전송 완료하였습니다.");
      func.setCurrentModal("");
    }
  }, [apiResponse.success]);

  const validation = useValidationModal(state.inputText);

  return <>{CURRENT_MODAL[options.modalType]}</>;
};
