import { ImypageMainInfo } from "../../pages/Mypage/types/item.type";

interface Iitem {
  userInfo: ImypageMainInfo;
}

interface IapiResponse {
  userInfoLoading: boolean;
}

export const MypageMainInfo = ({
  item,
  apiResponse,
}: {
  item: Iitem;
  apiResponse: IapiResponse;
}) => {
  return apiResponse.userInfoLoading ? (
    <div className="animate-pulse">
      <h1 className="max-w-[140px] w-full h-[20px] font-bold text-listFirst mb-3 bg-[#D9D9D9] rounded-xl max-xl:text-listSecond"></h1>
      <div className="flex flex-col text-default gap-y-[6px]">
        <span className="max-w-[100px] w-full h-[15px] bg-[#D9D9D9] rounded-xl"></span>
        <span className="max-w-[100px] w-full h-[15px] bg-[#D9D9D9] rounded-xl"></span>
      </div>
    </div>
  ) : (
    <div>
      <h1 className="font-bold text-listFirst mb-3 ">
        {item.userInfo.username}
      </h1>
      <div className="flex flex-col text-default gap-y-[6px] max-xl:text-footer">
        <span>{item.userInfo.email}</span>
        <span>{item.userInfo.phone}</span>
      </div>
    </div>
  );
};
