import { MypageMain } from "../../../components/Mypage/MypageMain/MypageMain";
import { IcurrentPlant, ImyPlantProfile } from "../types/main.typs";

interface Iitem {
  currentPlant: IcurrentPlant;
  yearsPlant: ImyPlantProfile;
}

interface IapiResponse {
  countStatus: string;
  yearsStatus: string;
  yearsIsError: boolean;
}

export const MypageMainModify = ({
  item,
  apiResponse,
}: {
  item: Iitem;
  apiResponse: IapiResponse;
}) => {
  return (
    <MypageMain
      item={{
        currentPlant: item.currentPlant,
        yearsPlant: item.yearsPlant,
      }}
      apiResponse={{
        countStatus: apiResponse.countStatus,
        yearsStatus: apiResponse.yearsStatus,
        yearsIsError: apiResponse.yearsIsError,
      }}
    />
  );
};
