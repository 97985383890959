import { useEffect } from "react";
import { MypagePlantBuy } from "../../../components/Mypage/MypageMyPlant/PlantBuy/MypagePlantBuy";
import { ErrorHandling } from "../../../utils_public/ErrorHandling";
import { SUBMIT_SELL_AND_BUY_ERROR_TYPE } from "../error/error.object";
import { Ibuylist } from "../types/item.type";

interface Istate {
  selectPlantId: number;
}

interface Item {
  buyList: Ibuylist[];
}

interface IapiResponse {
  apiStatus: string;
  submitPlantId: number;
  submitSuccess: boolean;
  submitLoading: boolean;
  axiosCode: string;
  error: boolean;
  errorStatus: number;
}

interface Ifunc {
  setSelectPlantId: (num: number) => void;
  submitPrice: (num: number, price: number | string) => void;
}

export const MypagePlantBuyModify = ({
  state,
  item,
  apiResponse,
  func,
}: {
  state: Istate;
  item: Item;
  apiResponse: IapiResponse;
  func: Ifunc;
}) => {
  useEffect(() => {
    if (apiResponse.error) {
      return alert(
        ErrorHandling(
          {
            errorType: SUBMIT_SELL_AND_BUY_ERROR_TYPE,
            errorCode: apiResponse.axiosCode,
            errorStatus: apiResponse.errorStatus,
            isError: apiResponse.error,
          },
          "",
        ),
      );
    }
  }, [apiResponse.error]);

  return (
    <MypagePlantBuy
      state={{
        selectPlantId: state.selectPlantId,
      }}
      item={{ buyList: item.buyList }}
      apiResponse={{
        apiStatus: apiResponse.apiStatus,
        submitPlantId: apiResponse.submitPlantId,
        submitSuccess: apiResponse.submitSuccess,
        submitLoading: apiResponse.submitLoading,
        axiosCode: apiResponse.axiosCode,

        error: apiResponse.error,
        errorStatus: apiResponse.errorStatus,
      }}
      func={{
        setSelectPlantId: func.setSelectPlantId,
        submitPrice: func.submitPrice,
      }}
    />
  );
};
