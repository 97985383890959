import classNames from "classnames";
import { ReactElement, useEffect } from "react";
import { SUBMIT_FIND_USER_INFO_ERROR_TYPE } from "../../../pages/Login/error/error.object";
import {
  emailModal,
  passwordModal,
  verificationdModal,
} from "../../../pages/Login/types/modal.type";
import {
  HandlePhoneNumber,
  HandleEmail,
  HandleHyphen,
} from "../../../pages/SignUp/utils/SignFunc";
import { ErrorHandling } from "../../../utils_public/ErrorHandling";
import { ButtonLoading } from "../../UI/Loading/Loading";

interface Istate {
  inputText: emailModal | passwordModal | verificationdModal;
}

interface Iitem {
  email: string;
}

interface Ifunc {
  setCurrentModal: (value: string) => void;
  setInputText: (
    value: emailModal | passwordModal | verificationdModal,
  ) => void;
  submitFinding: (url: string) => void;
}

interface IapiResponse {
  loading: boolean;
  success: boolean;
  axiosCode: string;
  error: boolean;
  errorStatus: number;
}

export const LoginEmailModal = ({
  state,
  item,
  func,
  apiResponse,
}: {
  state: Istate;
  item: Iitem;
  func: Ifunc;
  apiResponse: IapiResponse;
}) => {
  const clickCheck =
    state.inputText.username === "" ||
    !HandlePhoneNumber(state.inputText.phone);

  return (
    <div className={`relative z-[999]`}>
      <div className="fixed inset-0 bg-[#272727] bg-opacity-50 transition-opacity"></div>
      <div className="fixed inset-0 overflow-y-auto ">
        <div className="flex min-h-full justify-center items-center">
          <form
            className="w-[350px] relative bg-general-white p-8
          rounded-lg border-main-color-primary border-[1px] drop-shadow-[2px_2px_4px_rgba(0,0,0,0.25)]"
          >
            <div className="flex justify-between items-center mb-8">
              <h1 className="text-listTitle">이메일 찾기</h1>
              <img
                className="w-[13px] h-[13px] cursor-pointer"
                src="/icon_remodel/close.svg"
                onClick={() => func.setCurrentModal("")}
              />
            </div>
            <main>
              <div className="mb-5">
                <input
                  className="w-[100%] pl-1 pb-1 !outline-none border-b-[1px] border-text-color-primary"
                  placeholder="이름을 작성해주세요"
                  maxLength={30}
                  disabled={apiResponse.success}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    func.setInputText({
                      ...state.inputText,
                      username: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <input
                  className="w-[100%] pl-1 pb-1 !outline-none border-b-[1px] border-text-color-primary"
                  type="text"
                  placeholder="전화번호를 작성해주세요"
                  maxLength={13}
                  onInput={HandleHyphen}
                  disabled={apiResponse.success}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    func.setInputText({
                      ...state.inputText,
                      phone: e.target.value,
                    })
                  }
                />
              </div>
            </main>

            {
              <div
                className={classNames(
                  "h-[40px] px-3 text-default flex items-center justify-center flex-col",
                  { "my-[7px]": apiResponse.success },
                )}
              >
                {apiResponse.error && (
                  <h1 className="text-general-red">
                    {ErrorHandling(
                      {
                        errorType: SUBMIT_FIND_USER_INFO_ERROR_TYPE,
                        errorCode: apiResponse.axiosCode,
                        errorStatus: apiResponse.errorStatus,
                        isError: apiResponse.error,
                      },
                      "",
                    )}
                  </h1>
                )}

                {apiResponse.success && (
                  <>
                    <h1>가입하신 이메일 정보는 아래와 같습니다.</h1>
                    <span className="text-main-color-primary">
                      {item.email}
                    </span>
                  </>
                )}
              </div>
            }
            <div>
              <button
                className={`w-full h-[40px] cursor-pointer bg-main-color-primary rounded-lg text-general-white  ${
                  clickCheck &&
                  "cursor-not-allowed bg-border-color-dividers text-text-color-primary"
                }`}
                disabled={apiResponse.success}
                onClick={(e) => {
                  e.preventDefault();
                  // 유효성 불통과일 경우 submit 클릭 방지
                  if (clickCheck) return;
                  func.submitFinding("find-email/");
                }}
              >
                {apiResponse.loading ? (
                  <div className="w-[30px] mx-auto">
                    <ButtonLoading />
                  </div>
                ) : (
                  "이메일 확인하기"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export const LoginPasswordModal = ({
  state,
  apiResponse,
  func,
}: {
  state: Istate;
  apiResponse: IapiResponse;
  func: Ifunc;
}) => {
  const clickCheck =
    state.inputText.username === "" ||
    !HandlePhoneNumber(state.inputText.phone) ||
    !HandleEmail(state.inputText.email);

  useEffect(() => {
    if (apiResponse.success) {
    }
  }, [apiResponse.success]);
  return (
    <div className={`relative z-[999]`}>
      <div className="fixed inset-0 bg-[#272727] bg-opacity-50 transition-opacity"></div>

      <div className="fixed inset-0 overflow-y-auto ">
        <div className="flex min-h-full justify-center items-center">
          <form
            className="w-[350px] relative bg-general-white p-8
        rounded-lg border-main-color-primary border-[1px] drop-shadow-[2px_2px_4px_rgba(0,0,0,0.25)]"
          >
            <div className="flex justify-between items-center mb-8">
              <h1 className="text-listTitle">비밀번호 찾기</h1>
              <img
                className="w-[13px] h-[13px] cursor-pointer"
                src="/icon_remodel/close.svg"
                onClick={() => func.setCurrentModal("")}
              />
            </div>
            <main>
              <div className="mb-5">
                <input
                  className="w-[100%] pl-1 pb-1 !outline-none border-b-[1px] border-text-color-primary"
                  placeholder="이름을 작성해주세요"
                  maxLength={30}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    func.setInputText({
                      ...state.inputText,
                      username: e.target.value,
                    })
                  }
                />
              </div>
              <div className="mb-5">
                <input
                  className="w-[100%] pl-1 pb-1 !outline-none border-b-[1px] border-text-color-primary"
                  placeholder="이메일을 작성해주세요"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    func.setInputText({
                      ...state.inputText,
                      email: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <input
                  className="w-[100%] pl-1 pb-1 !outline-none border-b-[1px] border-text-color-primary"
                  placeholder="전화번호를 작성해주세요"
                  maxLength={13}
                  onInput={HandleHyphen}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    func.setInputText({
                      ...state.inputText,
                      phone: e.target.value,
                    })
                  }
                />
              </div>
            </main>
            <article className="h-[35px] flex justify-center items-center">
              {apiResponse.error && (
                <div className="text-general-red text-default">
                  {ErrorHandling(
                    {
                      errorType: SUBMIT_FIND_USER_INFO_ERROR_TYPE,
                      errorCode: apiResponse.axiosCode,
                      errorStatus: apiResponse.errorStatus,
                      isError: apiResponse.error,
                    },
                    "",
                  )}
                </div>
              )}
            </article>
            <div>
              <button
                className={`w-full h-[40px] cursor-pointer bg-main-color-primary rounded-lg text-general-white ${
                  clickCheck &&
                  "cursor-not-allowed bg-border-color-dividers text-text-color-primary"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  // 유효성 불통과일 경우 submit 클릭 방지
                  if (clickCheck) return;
                  func.submitFinding("find-pwd/");
                }}
              >
                {apiResponse.loading ? (
                  <div className="w-[30px] mx-auto">
                    <ButtonLoading />
                  </div>
                ) : (
                  "이메일 전송하기"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export const LoginVerificationModal = ({
  state,
  func,
  apiResponse,
}: {
  state: Istate;
  func: Ifunc;
  apiResponse: IapiResponse;
}) => {
  const clickCheck =
    state.inputText.username === "" || !HandleEmail(state.inputText.email);
  return (
    <div className={`relative z-[999]`}>
      <div className="fixed inset-0 bg-[#272727] bg-opacity-50 transition-opacity"></div>

      <div className="fixed inset-0 overflow-y-auto ">
        <div className="flex min-h-full justify-center items-center">
          <form
            className="w-[350px] relative bg-general-white p-8
          rounded-lg border-main-color-primary border-[1px] drop-shadow-[2px_2px_4px_rgba(0,0,0,0.25)]"
          >
            <div className="flex justify-between items-center mb-8">
              <h1 className="text-listTitle">인증메일을 받지 못하셨나요?</h1>
              <img
                className="w-[13px] h-[13px] cursor-pointer"
                src="/icon_remodel/close.svg"
                onClick={() => func.setCurrentModal("")}
              />
            </div>
            <main>
              <div>
                <input
                  className="w-[100%] pl-1 pb-1 !outline-none border-b-[1px] border-text-color-primary"
                  placeholder="이메일을 작성해주세요"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    func.setInputText({
                      ...state.inputText,
                      email: e.target.value,
                    })
                  }
                />
              </div>
            </main>
            <article className="h-[35px] flex justify-center items-center">
              {apiResponse.error && (
                <div className="text-general-red text-default">
                  {ErrorHandling(
                    {
                      errorType: SUBMIT_FIND_USER_INFO_ERROR_TYPE,
                      errorCode: apiResponse.axiosCode,
                      errorStatus: apiResponse.errorStatus,
                      isError: apiResponse.error,
                    },
                    "",
                  )}
                </div>
              )}
            </article>
            <div>
              <button
                className={`w-full h-[40px] cursor-pointer bg-main-color-primary rounded-lg text-general-white ${
                  clickCheck &&
                  "cursor-not-allowed bg-border-color-dividers text-text-color-primary"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  // 유효성 불통과일 경우 submit 클릭 방지
                  if (clickCheck) return;
                  func.submitFinding("email-send/");
                }}
              >
                {apiResponse.loading ? (
                  <div className="w-[30px] mx-auto">
                    <ButtonLoading />
                  </div>
                ) : (
                  "이메일 전송하기"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
