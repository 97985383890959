import { MypagePlantBuyModify } from "../module_modify/MypagePlantBuy.modify";
import { useCookies } from "react-cookie";
import { useGetBuyList } from "../hooks/useGetSellBuyList";
import { useSubmitPriceQuery } from "../../Main/query/useSubmitPriceQuery";
import { HandleComma } from "../../../utils_public/Comma";
import { useState } from "react";

export const MypagePlantBuyFetch = () => {
  const [cookie] = useCookies(["60hz-user"]);
  const { buyList, status } = useGetBuyList(cookie["60hz-user"]);

  const [selectPlantId, setSelectPlantId] = useState(-1);

  const POST_SUBMIT_PRICE = useSubmitPriceQuery();

  const submitPrice = (plantId: number, price: number | string) => {
    POST_SUBMIT_PRICE.mutate({
      apiName: "PostSubmitPrice",
      url: `api/plant/${plantId}/desired-price/buy/`,
      price: typeof price === "number" ? HandleComma(price) : price,
      cookie: cookie["60hz-user"],
    });
  };

  return (
    <MypagePlantBuyModify
      state={{
        selectPlantId: selectPlantId,
      }}
      item={{ buyList: buyList }}
      apiResponse={{
        apiStatus: status,
        submitPlantId: POST_SUBMIT_PRICE.data && POST_SUBMIT_PRICE.data.plant,
        submitSuccess: POST_SUBMIT_PRICE.isSuccess,
        submitLoading: POST_SUBMIT_PRICE.isLoading,
        axiosCode:
          POST_SUBMIT_PRICE.error && POST_SUBMIT_PRICE.error.code
            ? POST_SUBMIT_PRICE.error.code
            : "",
        error: POST_SUBMIT_PRICE.isError,
        errorStatus:
          POST_SUBMIT_PRICE.error && POST_SUBMIT_PRICE.error.response
            ? POST_SUBMIT_PRICE.error.response.status
            : 0,
      }}
      func={{
        setSelectPlantId: setSelectPlantId,
        submitPrice: submitPrice,
      }}
    />
  );
};
