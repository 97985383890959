import { useHopeUserInfoQuery } from "../query/useHopeUserInfoQuery";

interface IgetHopeUserInfo {
  phone: string;
  username: string;
}

export const useGetHopeUserInfo = (token?: string) => {
  const { data, status } = useHopeUserInfoQuery(`trader-info/`, {
    apiName: "Hope User Information",
    authorization: token,
  });

  return {
    data: data ? (data as IgetHopeUserInfo) : { phone: "", username: "" },
    status: status,
  };
};
