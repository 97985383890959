import { DetailCapacityGraph } from "./DetailCapacityGraph";
import { IplantInfo } from "../../../pages/Detail/types/plantInfo.type";

export const DetailCapacity = ({ plantInfo }: { plantInfo: IplantInfo }) => {
  return (
    <section className="w-[47%] mt-20 max-xl:w-full">
      <div className="flex justify-between mb-10">
        <div className="flex items-center">
          <span>발전량 자세히 보기</span>
          <a
            className="text-smallExplanation text-text-color-secondary border-b-[0.5px] 
        border-b-text-color-secondary ml-3 cursor-pointer"
            href="/qna?inquiry"
            target={"_blank"}
          >
            발전량이 정확하지 않나요?
          </a>
        </div>
      </div>
      <DetailCapacityGraph dateCapacity={plantInfo.gen} />
    </section>
  );
};
