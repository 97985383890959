export function HandleEmail(submitInfo) {
  const pattern = /^[a-z0-9A-Z_+.-]+@([a-z0-9A-Z]+\.)+[a-z0-9]{2,4}$/;
  const EMOJI_TEST =
    /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/;

  const res = pattern.test(submitInfo);
  const emogiValidation = EMOJI_TEST.test(submitInfo);

  return res && !emogiValidation;
}

export function HandlePhoneNumber(value) {
  const personal = ["010", "011", "016", "017", "019"];

  const number = value;

  const personal_num = personal.find((el) => el === number.slice(0, 3));

  // // 숫자만 오직 타이핑 가능한 정규 표현식
  value = value.replace(/[^0-9]/g, "");

  const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;

  // 01[] 번호 합격 해야하고 && 휴대폰 검증 합격 해야하고 && 제한 숫자 합격 해야한다.
  if (personal_num && regPhone.test(number) && number.length === 13) {
    //  휴대 전화번호 합격
    return true;
  } else {
    //  번호에서 검증 탈락
    return false;
  }
}

export function HandleTelephoneNumber(value) {
  const local = [
    "02",
    "031",
    "032",
    "033",
    "041",
    "043",
    "042",
    "044",
    "051",
    "052",
    "053",
    "054",
    "055",
    "061",
    "062",
    "063",
    "064",
    "070",
  ];
  const number = value;
  const local_num = local.find((el) => el === number.slice(0, 3));
  const seoul_num = local.find((el) => el === number.slice(0, 2));

  // // 숫자만 오직 타이핑 가능한 정규 표현식
  value = value.replace(/[^0-9]/g, "");

  const regSeoul = /^\d{2}-?([0-9]{3,4})-?([0-9]{4})$/;
  const regLocal = /^\d{3}-?([0-9]{3,4})-?([0-9]{4})$/;

  if (seoul_num && regSeoul.test(number) && number.length < 13) {
    //  지역 전화번호 합격
    return true;
  } else if (local_num && regLocal.test(number) && number.length < 14) {
    //  서울 전화번호 합격
    return true;
  } else {
    //  번호에서 검증 탈락
    return false;
  }
}

export function HandleHyphen(value) {
  value.target.value = value.target.value
    .replace(/[^0-9]/g, "")
    .replace(
      /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,
      "$1-$2-$3",
    )
    .replace("--", "-");
}

export function HandleCompany(e) {
  e.target.value = e.target.value.replace(/[^ㄱ-ㅎ|가-힣|a-z|A-Z]/gi, "");
}

export function HandleNickname(submitInfo) {
  if (!submitInfo) {
    return false;
  }
  const SPECIAL_TEST = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/g;
  const EMOJI_TEST =
    /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g;
  const SPACE_TEST = /\s/g;

  const specailValidation = SPECIAL_TEST.test(submitInfo);
  const emogiValidation = EMOJI_TEST.test(submitInfo);
  const spaceValidation = SPACE_TEST.test(submitInfo);

  return !specailValidation && !emogiValidation && !spaceValidation;
}

export function HandleOnlyTextInpt(text) {
  text.target.value = text.target.value
    .replace(/[^A-Za-zㄱ-ㅎㅏ-ㅣ가-힣]/g, "")
    .replace(/\s/g, "");
}

export function HandleFormText(submitInfo, benefit, file) {
  const formData = new FormData();

  formData.append("email", submitInfo.email);
  formData.append("nickname", submitInfo.nickname);
  formData.append("username", submitInfo.userName);
  formData.append("telephone", submitInfo.telephoneNumber);
  formData.append("phone", submitInfo.phoneNumber);
  formData.append("password", submitInfo.password);
  formData.append("company_type", submitInfo.company_type);
  formData.append("company_name", submitInfo.company);
  formData.append("receive_email", benefit.email);
  formData.append("receive_sms", benefit.sms);
  formData.append("receive_kakaotalk", benefit.kakao);

  return formData;
}
