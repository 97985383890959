export const DetailModalContents = ({
  onChangeText,
}: {
  onChangeText: (text: string) => void;
}) => {
  return (
    <div className="h-[208px] mb-[16px] max-sm:h-[180px]">
      <h1 className=" mb-2">내용</h1>
      <textarea
        className="w-full h-[181px] py-3 px-4 text-default !outline-none border-[1px] border-border-color-dividers rounded-lg max-sm:h-[150px]"
        placeholder="메세지를 입력해주세요. (최소 5자 ~ 100자 이내)"
        maxLength={100}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          onChangeText(e.target.value)
        }
      ></textarea>
    </div>
  );
};
