import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";
import { usePlantListQuery } from "../../Search/query/usePlantListQuery";
import { Ifilter } from "../../Search/types/filter.type";

export const useGetPlantList = (filter: Ifilter) => {
  const [cookie] = useCookies(["60hz-user"]);
  const pathName = useLocation();

  const { data, isLoading, isSuccess, isError, error } = usePlantListQuery(
    "api/plant/",
    filter,
    pathName.search,
    {
      apiName: "Search List Information",
      cookie: cookie["60hz-user"],
    },
  );

  return {
    plantList: isSuccess && data ? data.results : null,
    plantListTotal: isSuccess && data ? data.count : 0,
    loading: isLoading,
    isError: isError,
    error: error,
  };
};
