import classNames from "classnames";
import { IuserPasswordValidation } from "../../../../pages/Mypage/types/state.type";
import { passwordStateCheck } from "../../../../pages/Mypage/utils/passwordStateCheck";

interface signature {
  [key: string]: string;
}

interface IserverError {
  code: string;
  message: string;
}

type validationObj = {
  type: string;
  text: string;
};

interface Istate {
  input: signature;
  passwordValidation: IuserPasswordValidation;
  passwordCheck: boolean;
  passwordVerifyCheck: boolean;
}

interface Ifunc {
  setInput: (userModify: signature) => void;
  blurInput: (name: string) => void;
}

interface Ioptions {
  title: string;
  name: string;
  passwordValiText: validationObj[];
  ref: React.RefObject<HTMLInputElement>;
}

interface IapiResponse {
  serverError?: IserverError;
}

export const MypageUserPwInput = ({
  state,
  func,
  options,
  apiResponse,
}: {
  state: Istate;
  func: Ifunc;
  options: Ioptions;
  apiResponse: IapiResponse;
}) => {
  const inputErrorColor =
    options.name === "passwordOriginal" &&
    apiResponse.serverError?.code === "UIR002";

  return (
    <div>
      <div
        className={`flex items-center ${
          options.name === "passwordOriginal" && "mb-4"
        }`}
      >
        <span className="max-w-[109px] w-full text-default max-sm:max-w-[95px]">
          {options.title}
        </span>
        <input
          ref={options.ref}
          className={classNames(
            "max-w-[410px] w-full h-[43px] px-4 !outline-none border-[0.5px] border-border-color-dividers rounded-lg",

            {
              "border-general-red":
                options.name === "password" && state.passwordCheck,
            },

            {
              "border-general-red":
                options.name === "passwordVerify" && state.passwordVerifyCheck,
            },
          )}
          type="password"
          onBlur={() => func.blurInput(options.name)}
          onChange={(e) => {
            func.setInput({ ...state.input, [options.name]: e.target.value });
          }}
        />
      </div>

      {options.passwordValiText.length > 0 && (
        <div
          className={`flex mt-[6px] ${
            options.passwordValiText[0].type !== "same" && "mb-4"
          }  ml-[115px] max-sm:ml-[100px]`}
        >
          {options.passwordValiText.map((el, idx) => (
            <span
              className={`flex text-smallExplanation ${
                passwordStateCheck(el.type, state.passwordValidation)
                  ? "text-main-color-primary"
                  : "text-text-color-secondary"
              }`}
              key={idx}
            >
              {el.text}
              <img
                className="ml-[6px] mr-[17px] max-sm:mr-[12px]"
                src={`${
                  passwordStateCheck(el.type, state.passwordValidation)
                    ? "/icon_remodel/check_blue.svg"
                    : "/icon_remodel/check.svg"
                }`}
              />
            </span>
          ))}
        </div>
      )}
    </div>
  );
};
