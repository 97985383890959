import { useState } from "react";
import { useGetPlantList } from "../hooks/useGetPlantList";
import { MypageMyPlantModify } from "../module_modify/MypageMyPlant.modify";
import { SelectedLocalType, SelectedCapacityType } from "../types/state.type";

export const MypageMyPlantFetch = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [localCurrent, setLocalCurrent] = useState<SelectedLocalType>("전국");
  const [capacityCurrent, setCapacityCurrent] =
    useState<SelectedCapacityType>("전체");

  const { plantList, loading, plantListTotal, isError, error } =
    useGetPlantList({
      local: localCurrent,
      land: "전체",
      capacity: capacityCurrent,
      sort: "-capacity",
      page: pageNumber,
    });

  return (
    <MypageMyPlantModify
      state={{
        pageNumber: pageNumber,
        localCurrent: localCurrent,
        capacityCurrent: capacityCurrent,
      }}
      func={{
        setPageNumber: setPageNumber,
        setLocalCurrent: setLocalCurrent,
        setCapacityCurrent: setCapacityCurrent,
      }}
      item={{ plantList: plantList, plantListTotal: plantListTotal }}
      apiResponse={{ loading: loading }}
    />
  );
};
