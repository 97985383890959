import classNames from "classnames";
import "./animated.css";
import { MainPlantListCapacityFilter } from "../FilterCategory/MainPlantListCapacityFilter";
import { MainPlantListLandCategoryFilter } from "../FilterCategory/MainPlantListLandCategoryFilter";
import { MainPlantListLocalFilter } from "../FilterCategory/MainPlantListLocalFilter";
import { IfilterItem } from "../../../pages/Mypage/types/state.type";

interface Istate {
  filter: IfilterItem;
  mobileFilterOpen: boolean;
}

interface Ifunc {
  selectFilter: (type: string, value: string) => void;
  resetFilter: () => void;
  setMobileFilterOpen: (bool: boolean) => void;
}

export const MobileMainFilter = ({
  state,
  func,
}: {
  state: Istate;
  func: Ifunc;
}) => {
  return (
    <main>
      <div
        className={classNames(
          "w-full h-full fixed py-[30px]  top-0 right-0 bottom-0 left-0 overflow-auto bg-text-color-primary animated duration-700 lg:hidden ",
          { "bg-opacity-0 z-0": !state.mobileFilterOpen },
          { "bg-opacity-40 z-[110]": state.mobileFilterOpen },
        )}
      ></div>

      <div
        className={classNames(
          "max-w-full z-[110] w-full max-h-full h-full animated duration-500 shadow-inner fixed top-0 right-0 bottom-0 left-0 mx-auto my-0 lg:hidden",
          { "top-full": !state.mobileFilterOpen },
        )}
      >
        <div
          className={classNames(
            "min-h-full flex flex-col relative justify-center items-center duration-700",
            { "opacity-0": !state.mobileFilterOpen },
          )}
        >
          <div className=" bg-general-white rounded-lg">
            <div className="sticky top-0 flex justify-center items-center text-listTitle border-b-[1px] border-b-border-color-dividers py-[10px] bg-general-white z-[500]">
              <span>필터</span>
              <img
                className="absolute right-[15px] cursor-pointer"
                src="/icon_remodel/close.svg"
                onClick={() => {
                  func.setMobileFilterOpen(false);
                }}
              />
            </div>
            <div className="max-h-[700px] pt-[50px] px-[45px] overflow-y-auto">
              <MainPlantListLocalFilter
                filter={state.filter}
                filterType={"지역"}
                selectFilter={func.selectFilter}
              />
              <MainPlantListCapacityFilter
                filter={state.filter}
                filterType={"용량"}
                selectFilter={func.selectFilter}
              />
              <MainPlantListLandCategoryFilter
                filter={state.filter}
                filterType={"지목"}
                selectFilter={func.selectFilter}
              />
              <div className="sticky bottom-[0px] h-[130px] pt-[40px] flex justify-center gap-x-[40px] bg-general-white">
                <button
                  className="w-[120px] h-[40px] border-[1px] border-main-color-primary rounded-lg"
                  onClick={func.resetFilter}
                >
                  초기화
                </button>
                <button
                  className="w-[120px] h-[40px] border-[1px] bg-main-color-primary text-general-white rounded-lg"
                  onClick={() => {
                    func.setMobileFilterOpen(false);
                  }}
                >
                  완료
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
