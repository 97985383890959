import { useNavigate, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { notifyMessage } from "./atoms/notify.atoms";

interface Ioptions {
  cookie: string;
}

interface Ifunc {
  selectLogout: () => void;
}

export const HeaderMenu = ({
  options,
  func,
}: {
  options: Ioptions;
  func: Ifunc;
}) => {
  // 쿠키 유무 판별
  const typeCheck = typeof options.cookie;
  const { pathname } = useLocation();

  const privacyURL = pathname.slice(1, 8);

  return (
    <>
      {privacyURL === "privacy" ? (
        <HeaderPrivacy />
      ) : typeCheck === "string" ? (
        <HeaderLogOut
          func={{
            selectLogout: func.selectLogout,
          }}
        />
      ) : (
        <HeaderLogin />
      )}
    </>
  );
};

const HeaderLogin = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  return (
    <menu className="max-w-[340px] w-full pl-[20px] flex justify-end gap-x-[15px] max-md:hidden">
      <span
        className={`cursor-pointer ${
          pathname.slice(1) === "qna" &&
          search.slice(1) === "questions" &&
          "text-main-color-primary"
        } `}
        onClick={() => {
          if (pathname !== "/qna") {
            navigate("/qna?questions", {
              state: { previousPath: pathname, queryStringPath: search },
            });
          }
        }}
      >
        고객센터
      </span>
      <span
        className={`cursor-pointer ${
          (pathname.slice(1) === "login" || pathname.slice(1) === "signup") &&
          "text-main-color-primary"
        }`}
        onClick={() => {
          if (pathname !== "/login") {
            navigate("/login", {
              state: { previousPath: pathname, queryStringPath: search },
            });
          }
        }}
      >
        로그인 / 회원가입
      </span>
    </menu>
  );
};

const HeaderLogOut = ({ func }: { func: Ifunc }) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const [notify, setNotify] = useRecoilState(notifyMessage);

  const currentPath = pathname.slice(1, 7);
  return (
    <menu className="max-w-[380px] w-full pl-[10px] flex justify-end gap-x-[25px] max-md:hidden max-lg:gap-x-[10px] max-lg:text-default max-lg:max-w-[340px]">
      <a
        className={`relative cursor-pointer ${
          pathname.slice(1) === "message" && "text-main-color-primary"
        }
        `}
        href="/message"
        target={"_blank"}
        onClick={() => setNotify(false)}
      >
        메세지함
        {notify && (
          <div className="absolute top-[-5px] right-[-13px] max-lg:right-[50px]">
            <span className="relative flex h-[10px] w-[10px]">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-general-red opacity-75"></span>
              <span className="relative inline-flex rounded-full h-[10px] w-[10px] bg-general-red"></span>
            </span>
          </div>
        )}
      </a>
      <a
        className={`cursor-pointer ${
          pathname.slice(1) === "qna" &&
          search.slice(1) === "questions" &&
          "text-main-color-primary"
        }`}
        onClick={() => {
          if (pathname !== "/qna") {
            navigate("/qna?questions", {
              state: { previousPath: pathname, queryStringPath: search },
            });
          }
        }}
      >
        고객센터
      </a>
      <span
        className={`cursor-pointer ${
          currentPath === "mypage" && "text-main-color-primary"
        }`}
        onClick={() => {
          if (pathname !== "/mypage") {
            navigate("/mypage");
          }
        }}
      >
        마이페이지
      </span>

      <span className="cursor-pointer" onClick={func.selectLogout}>
        로그아웃
      </span>
    </menu>
  );
};

const HeaderPrivacy = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const curretPath = pathname.slice(9);

  return (
    <menu className="text-default">
      <span
        className={`cursor-pointer mr-5 ${
          curretPath === "service" && "text-main-color-primary"
        }`}
        onClick={() => {
          if (curretPath !== "service") navigate("privacy/service");
        }}
      >
        이용약관
      </span>
      <span
        className={`cursor-pointer mr-5 ${
          curretPath === "policy" && "text-main-color-primary"
        }`}
        onClick={() => {
          if (curretPath !== "policy") navigate("privacy/policy");
        }}
      >
        개인정보 처리 방침
      </span>
      <span
        className={`cursor-pointer ${
          curretPath === "marketing" && "text-main-color-primary"
        }`}
        onClick={() => {
          if (curretPath !== "marketing") navigate("privacy/marketing");
        }}
      >
        마케팅 수신
      </span>
    </menu>
  );
};
