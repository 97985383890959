import { useState } from "react";
import { useCookies } from "react-cookie";
import { DetailTalkModalModify } from "../module_modify/DetailTalkModal.modify";
import { Italk } from "../types/state.type";
import { ItalkModalItem } from "../types/item.type";
import { useSendTalkingQuery } from "../query/useSendTalkingQuery";

type ImodalTalk = {
  open: boolean;
  type: string;
  userId: number;
  username: string;
};

interface Istate {
  modalTalk: ImodalTalk;
}

interface Ifunc {
  setModalTalk: (bool: ImodalTalk) => void;
  setModalTalkSellList: (bool: boolean) => void;
  setModalTalkBuyList: (bool: boolean) => void;
}

interface Ioptions {
  sellerListCount: number;
  currentPagePositon: string;
}

export const DetailTalkModalFetch = ({
  state,
  item,
  func,
  options,
}: {
  state: Istate;
  item: ItalkModalItem;
  func: Ifunc;
  options: Ioptions;
}) => {
  const [cookie] = useCookies(["60hz-user"]);
  const [talkText, setTalkText] = useState<Italk>({
    plant: item.plantId,
    content: "",
  });

  // React-Query 문의하기 API POST 설정
  const POST_SEND_TALKING = useSendTalkingQuery();

  // 발전소 문의 요청
  const submitTalk = (type: string) => {
    POST_SEND_TALKING.mutate({
      apiName: "Submit Talk",
      // type: type,
      content: talkText.content,
      userId: state.modalTalk.userId,
      plantId: item.plantId,
      token: cookie["60hz-user"],
      url: `message/trade/${type}/`,
    });
  };

  return (
    <>
      <DetailTalkModalModify
        state={{
          modalTalk: state.modalTalk,
          talkText: talkText,
        }}
        item={{ ...item }}
        func={{
          setModalTalk: func.setModalTalk,
          submitTalk: submitTalk,
          setTalkText: setTalkText,
          setModalTalkSellList: func.setModalTalkSellList,
          setModalTalkBuyList: func.setModalTalkBuyList,
        }}
        options={{
          sellerListCount: options.sellerListCount,
          currentPagePositon: options.currentPagePositon,
        }}
        apiResponse={{
          success: POST_SEND_TALKING.isSuccess,
          loading: POST_SEND_TALKING.isLoading,
          axiosCode:
            POST_SEND_TALKING.error && POST_SEND_TALKING.error.code
              ? POST_SEND_TALKING.error.code
              : "",
          error: POST_SEND_TALKING.isError,
          errorStatus:
            POST_SEND_TALKING.error && POST_SEND_TALKING.error.response
              ? POST_SEND_TALKING.error.response.status
              : 0,
          errorCode:
            POST_SEND_TALKING.error && POST_SEND_TALKING.error.response
              ? POST_SEND_TALKING.error.response.data.code
              : "",
        }}
      />
    </>
  );
};
