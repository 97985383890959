import { ButtonLoading } from "../Loading/Loading";
import { ErrorHandling } from "../../../utils_public/ErrorHandling";
import { SUBMIT_HOPE_ERROR_TYPE } from "../../../pages/Main/error/error.object";
import classNames from "classnames";
import { InputComma } from "../../../utils_public/Comma";
import { moneyUnit } from "../../../utils_public/Unit";
import { useBodyScrollLock } from "../../../utils_public/useBodyScrollLock";

type ImodalSellBuy = {
  open: boolean;
  type: string;
  plantId: number;
};

type Itext = {
  username: string;
  phoneNumber: string;
  price: string;
  email: string;
};

type Iuserinfo = {
  phone: string;
  username: string;
};

interface Istate {
  type: string;
  text: Itext;
}

interface Iitem {
  userInfo: Iuserinfo;
}

interface IapiResponse {
  loading: string;
  submitLoading: boolean;
  axiosCode: string;
  error: boolean;
  errorStatus: number;
}

interface Ifunc {
  setModalSellBuy: (object: ImodalSellBuy) => void;
  setText: (text: Itext) => void;
  submitPrice: () => void;
}

export const ModalHopeSellBuy = ({
  state,
  item,
  func,
  apiResponse,
}: {
  state: Istate;
  item: Iitem;
  func: Ifunc;
  apiResponse: IapiResponse;
}) => {
  const currentType = state.type === "sell";
  const submitCheck = !state.text.price;

  useBodyScrollLock();

  return (
    <>
      <div className="w-full h-full fixed bottom-0 left-0 top-0 right-0 z-[105] justify-start">
        <div className="w-full h-full absolute  bg-opacity-40 bg-text-color-primary"></div>

        <div className="flex relative min-h-full justify-center items-center">
          <div
            className="w-[500px] h-[350px] px-[50px] py-[30px] relative flex flex-col justify-center items-center bg-general-white
          rounded-xl border-main-color-primary border-[1px] drop-shadow-[2px_2px_4px_rgba(0,0,0,0.25)] max-md:w-[400px] max-sm:w-[300px] max-sm:px-[30px] max-sm:h-[300px]"
          >
            <img
              className="absolute right-4 top-4 cursor-pointer"
              src="/icon_remodel/close.svg"
              onClick={() => {
                func.setModalSellBuy({ open: false, type: "", plantId: 0 });
              }}
            />
            <article className="max-w-[270px] w-full mb-[40px] text-listFirst text-center max-sm:text-default max-sm:mb-[20px]">
              <h1 className="flex justify-center items-center text-main-color-primary">
                {apiResponse.loading === "loading" ? (
                  <div className="max-w-[80px] w-full animate-pulse">
                    <h1 className=" h-[20px] font-bold text-listFirst mr-[5px] bg-[#D9D9D9] rounded-xl"></h1>
                  </div>
                ) : (
                  <div className=" whitespace-nowrap overflow-hidden text-ellipsis">
                    {item.userInfo.username}
                  </div>
                )}

                <p className="text-text-color-primary">님,</p>
              </h1>

              <p className="flex justify-center">
                {currentType ? " 판매" : " 구매"}를 희망하시나요?
              </p>
              <h1 className="flex justify-center items-center">
                {apiResponse.loading === "loading" ? (
                  <div className="max-w-[100px] w-full animate-pulse">
                    <p className="h-[20px] mr-[5px] bg-[#D9D9D9] rounded-xl"></p>
                  </div>
                ) : (
                  <span className="text-default">{item.userInfo.phone} </span>
                )}
                {currentType ? "로 관리자가 " : "로 판매자의 판매 의사가"}
              </h1>
              <div>
                {currentType
                  ? "곧 연락 드리겠습니다."
                  : "확인되면 연락 드리겠습니다."}
              </div>
            </article>
            <div>
              <div className="flex flex-col gap-y-[10px]">
                <div className="flex items-center">
                  <h1 className="max-w-[120px] w-full max-sm:text-default max-sm:max-w-[100px]">
                    {currentType ? "판매 가격" : "구매 가격"}(원)
                    <span className="text-general-red ml-[5px]">*</span>
                  </h1>
                  <input
                    type={"text"}
                    className="w-full px-[10px] py-[10px] border-[1px] border-main-color-primary rounded-lg outline-none max-sm:text-default"
                    placeholder="금액을 입력해주세요"
                    maxLength={15}
                    value={state.text.price}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      func.setText({
                        ...state.text,
                        price: InputComma(e),
                      })
                    }
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                      InputComma(e)
                    }
                  />
                </div>

                <p className="text-right max-sm:text-default">
                  상세 금액 :{" "}
                  <span className="text-main-color-primary">
                    {moneyUnit(state.text.price.replace(/,/g, ""))}
                  </span>
                </p>
              </div>

              <div className="mt-[15px] flex justify-end items-center">
                <h1 className="text-general-red mr-[25px]">
                  {ErrorHandling(
                    {
                      errorType: SUBMIT_HOPE_ERROR_TYPE,
                      errorCode: apiResponse.axiosCode,
                      errorStatus: apiResponse.errorStatus,
                      isError: apiResponse.error,
                    },
                    "",
                  )}
                </h1>
                <button
                  className={classNames(
                    "w-[88px] py-[12px] rounded-lg",
                    {
                      "bg-main-color-primary text-general-white cursor-pointer":
                        !submitCheck,
                    },
                    {
                      "bg-text-color-disabled text-general-white cursor-not-allowed":
                        submitCheck,
                    },
                    "max-sm:text-default max-sm:w-[70px] max-sm:py-[10px]",
                  )}
                  onClick={() => {
                    if (submitCheck) return;

                    func.submitPrice();
                  }}
                >
                  {apiResponse.submitLoading ? (
                    <div className="w-[19px] h-[19px] mx-auto">
                      <ButtonLoading />
                    </div>
                  ) : (
                    "등록하기"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
