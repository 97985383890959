import { SignupInput } from "../../components/UI/Input_Text/SignupInput";
import { SignupRadioList } from "../../components/UI/Input_Radio/SignupRadioList";
import { SignupCheckBox } from "../../components/SignUp/SignupCheckBox";
import { IsignupInfo, IreceiveCheck, Ipass } from "./types//state.type";
import { SIGNUP_INPUT_LIST } from "./constants/signupInputList.constant";

import { ButtonLoading } from "../../components/UI/Loading/Loading";

interface signature {
  [key: string]: React.RefObject<HTMLInputElement>;
}

interface IserverError {
  phone: string[];
  telephone: string[];
}

interface Istate {
  input: IsignupInfo;
  benefit: boolean;
  receiveCheck: IreceiveCheck;
  emailCheck: boolean;
  passwordCheck: boolean;
  passwordVerifyCheck: boolean;
  nicknameCheck: boolean;
  usernameCheck: boolean;
  phoneNumberCheck: boolean;
  telephoneNumberCheck: boolean;
  companyCheck: boolean;
}

interface Ifunc {
  setInput: (input: IsignupInfo) => void;
  submitValidation: () => void;
  blurInput: (type: string) => void;
  handleToggleAll: () => void;
  handleToggleSingle: (value: string) => void;
}

interface IapiResponse {
  serverError?: IserverError;
  signupStatus: string;
  emailDupStatus: Ipass;
  emailDupLoading: boolean;
  phoneDupStatus: Ipass;
  phoneDupLoading: boolean;
  nicknameDupStatus: Ipass;
  nicknameDupLoading: boolean;
}

interface Ioptions {
  emailRef: React.RefObject<HTMLInputElement>;
  passwordRef: React.RefObject<HTMLInputElement>;
  passwordVerifyRef: React.RefObject<HTMLInputElement>;
  nicknameRef: React.RefObject<HTMLInputElement>;
  nameRef: React.RefObject<HTMLInputElement>;
  phoneRef: React.RefObject<HTMLInputElement>;
  telephoneRef: React.RefObject<HTMLInputElement>;
  companyRef: React.RefObject<HTMLInputElement>;
}

export const SignupPresenter = ({
  state,
  func,
  apiResponse,
  options,
}: {
  state: Istate;
  func: Ifunc;
  apiResponse: IapiResponse;
  options: Ioptions;
}) => {
  const INPUT_REF_TYPE: signature = {
    email: options.emailRef,
    password: options.passwordRef,
    passwordVerify: options.passwordVerifyRef,
    nickname: options.nicknameRef,
    userName: options.nameRef,
    phoneNumber: options.phoneRef,
    telephoneNumber: options.telephoneRef,
    company: options.companyRef,
  };

  return (
    <div className="max-w-[801px] flex flex-col items-center mx-auto justify-center mb-[140px] pt-[142px] px-[50px] max-md:px-[24px] max-md:pt-[50px] max-md:mb-[90px]">
      <h1 className="mb-12 text-subTitle font-bold max-sm:font-medium">
        회원가입
      </h1>
      <div className="w-full flex flex-col">
        <div className="flex items-center mb-8 border-b-[1px] border-b-main-color-primary pb-[12px]">
          <h1 className="text-listFirst font-bold text-main-color-primary max-sm:font-medium">
            기본정보
          </h1>
        </div>
        <form className="mb-[40px]">
          {SIGNUP_INPUT_LIST.map((el, idx) => (
            <SignupInput
              {...el}
              key={idx}
              state={{
                input: state.input,
                emailCheck: state.emailCheck,
                passwordCheck: state.passwordCheck,
                passwordVerifyCheck: state.passwordVerifyCheck,
                nicknameCheck: state.nicknameCheck,
                usernameCheck: state.usernameCheck,
                phoneNumberCheck: state.phoneNumberCheck,
                telephoneNumberCheck: state.telephoneNumberCheck,
                companyCheck: state.companyCheck,
              }}
              func={{
                setInput: func.setInput,
                blurInput: func.blurInput,
              }}
              apiResponse={{
                serverError: apiResponse.serverError,
                emailDupStatus: apiResponse.emailDupStatus,
                emailDupLoading: apiResponse.emailDupLoading,
                phoneDupStatus: apiResponse.phoneDupStatus,
                phoneDupLoading: apiResponse.phoneDupLoading,
                nicknameDupStatus: apiResponse.nicknameDupStatus,
                nicknameDupLoading: apiResponse.nicknameDupLoading,
              }}
              options={{
                ref: INPUT_REF_TYPE[el.inputInfo],
              }}
            />
          ))}
          <SignupRadioList input={state.input} setInput={func.setInput} />
        </form>
        <SignupCheckBox
          state={{
            input: state.input,
            benefit: state.benefit,
            receiveCheck: state.receiveCheck,
          }}
          func={{
            setInput: func.setInput,
            handleToggleAll: func.handleToggleAll,
            handleToggleSingle: func.handleToggleSingle,
          }}
        />
      </div>
      <div className="w-full mt-[30px]">
        <button
          className={`max-w-[677px] w-full h-[45px] 
          bg-main-color-primary text-general-white rounded-lg`}
          onClick={() => {
            func.submitValidation();
          }}
        >
          {apiResponse.signupStatus === "loading" ? (
            <div className="w-[35px] mx-auto">
              <ButtonLoading />
            </div>
          ) : (
            "회원가입 완료"
          )}
        </button>
      </div>
    </div>
  );
};
