import { useState } from "react";
import { QnAPresenter } from "./QnA.presenter";

export const QnAModify = () => {
  const [current, setCurrent] = useState(0);
  const [selectKeyword, setSelectKeyword] = useState<string[]>([]);

  const QnAKeywordClick = (target: string[]) => {
    setSelectKeyword(target);
    setCurrent(0);
  };

  return (
    <QnAPresenter
      state={{
        current: current,
        selectKeyword: selectKeyword,
      }}
      func={{
        setCurrent: setCurrent,
        setSelectKeyword: setSelectKeyword,
        QnAKeywordClick: QnAKeywordClick,
      }}
    />
  );
};
