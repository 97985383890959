import { useState } from "react";
import { DetailEnergeSMP } from "./DetailEnergeSMP";
import { DetailEnergeREC } from "./DetailEnergeREC";
import { DetailEnergeWeeklyGraph } from "./DetailEnergeWeeklyGraph";
import { IREC, ISMP } from "../../../pages/Detail/types/RECorSMP.type";

export const DetailEnergeMarket = ({
  recData,
  smpData,
}: {
  recData: IREC[];
  smpData: ISMP[];
}) => {
  const [hide, setHide] = useState(false);

  return (
    <main
      className={`w-full transition-all duration-500 ${
        hide ? "max-h-screen" : "max-h-[51px]"
      } my-8 border-[1px] border-main-color-primary rounded-xl overflow-y-hidden overflow-x-hidden`}
    >
      <div
        className="flex justify-between mb-[16px] items-center cursor-pointer px-8 py-[16px] max-md:px-[16px]"
        onClick={() => setHide(!hide)}
      >
        <h1 className="text-main-color-primary ">
          {hide ? "시장동향 접어두기" : "펼쳐서 시장동향을 확인하세요."}
        </h1>
        <div className="w-[18px] h-[18px] relative">
          <img
            style={{
              filter:
                "invert(41%) sepia(87%) saturate(517%) hue-rotate(186deg) brightness(94%) contrast(92%)",
            }}
            className={`${
              hide ? "rotate-180" : "rotate-0"
            } absolute top-[50%] left-[70%] translate-x-[-50%] translate-y-[-50%] `}
            src="/icon_remodel/arrow.svg"
          />
        </div>
      </div>

      <div className="px-8 pt-[16px] max-md:px-[16px]">
        <DetailEnergeSMP smpData={smpData} />
        <DetailEnergeREC recData={recData} />
        <DetailEnergeWeeklyGraph recData={recData} />
      </div>
    </main>
  );
};
