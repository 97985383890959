import { useSMPQuery } from "../query/useSMPQuery";
import { ISMP } from "../types/RECorSMP.type";

export const useGetSMP = () => {
  const { data } = useSMPQuery("api/smp/", {
    apiName: "SMP Information",
  });

  return data ? data : INITIAL_DATA;
};

const INITIAL_DATA: ISMP[] = [
  { jeju_smp: 0, land_smp: 0, trade_day: "" },
  { jeju_smp: 0, land_smp: 0, trade_day: "" },
];
