import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { usePlantListQuery } from "../query/usePlantListQuery";
import { filterCategory } from "../atoms/filter.atoms";

export const useGetPlantList = () => {
  const [cookie] = useCookies(["60hz-user"]);
  const pathName = useLocation();

  const filter = useRecoilValue(filterCategory);

  const { data, isLoading, isSuccess, isError, error } = usePlantListQuery(
    "api/plant/",
    filter,
    pathName.search,
    {
      apiName: "Search List Information",
      cookie: cookie["60hz-user"],
    },
  );

  return {
    plantList: isSuccess ? data && data.results : INITIAL_DATA,
    plantListTotal: isSuccess ? data && data.count : 0,
    loading: isLoading,
    isError: isError,
    error: error,
  };
};

const INITIAL_DATA = [
  {
    addr_lv1: "",
    addr_lv2: "",
    addr_lv3: "",
    capacity: 0,
    completion_date: null,
    id: 0,
    is_im_owner: false,
    is_like: false,
    is_owner: false,
    land_name: null,
    latitude: 0,
    longitude: 0,
    plant_name: "",
    power_type: "",
  },
];
