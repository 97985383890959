import { useNavigate, useLocation } from "react-router-dom";

export const HeaderLogo = ({
  setSearchHide,
}: {
  setSearchHide: (bool: boolean) => void;
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="max-w-[300px] w-full h-[21px] max-xl:max-w-[250px] max-lg:max-w-[190px]">
      <img
        className="cursor-pointer "
        src="/image_remodel/title_logo.svg"
        onClick={() => {
          if (location.pathname !== "/") {
            navigate("/");
            setSearchHide(false);
          }
        }}
      />
    </div>
  );
};
