import { useNavigate } from "react-router-dom";
import { ImyPlantProfile } from "../../../pages/Mypage/types/main.typs";

interface Iitem {
  yearsPlant: ImyPlantProfile;
}
interface IapiResponse {
  yearsStatus: string;
  yearsIsError: boolean;
}

export const MypagePlantProfile = ({
  item,
  apiResponse,
}: {
  item: Iitem;
  apiResponse: IapiResponse;
}) => {
  const thisYears = new Date().getFullYear();
  const navigate = useNavigate();
  return (
    <section className="max-w-full w-full">
      <h1 className="text-listFirst font-bold mb-4">발전소 프로필</h1>
      <div
        className=" h-[259px] flex flex-col items-center justify-center px-[50px] 
      bg-general-lightGray rounded-xl max-2xl:px-[48px]"
      >
        {apiResponse.yearsIsError ? (
          <>
            <h1 className="font-bold">등록 하신 발전소 정보가 없습니다.</h1>
          </>
        ) : (
          <>
            <h1 className="mb-2 font-bold max-2xl:text-default">
              {Number(item.yearsPlant.completionDate)}년에 태어난 발전소를{" "}
              {thisYears - Number(item.yearsPlant.completionDate)}년 째 운영 중
            </h1>
            <p className="max-2xl:text-default">
              발전소 정보를 업데이트할 수록 예상 매매가가 정확해집니다.
            </p>
          </>
        )}
        <div>
          <h1
            className="pb-1 mt-8 text-listSecond text-main-color-primary border-b-[1px] border-b-main-color-primary cursor-pointer"
            onClick={() => {
              if (apiResponse.yearsIsError) {
                confirm(
                  "등록하신 발전소가 없습니다. 지금 바로 등록하시겠습니까?",
                )
                  ? navigate("myPlant/submit")
                  : null;
              } else {
                navigate(`myPlant/information/${item.yearsPlant.id}`);
              }
            }}
          >
            내 발전소 확인하기
          </h1>
        </div>
      </div>
    </section>
  );
};
