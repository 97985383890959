import { SortDropDown } from "../UI/DropDown/SortDropDown";
import { HandleComma } from "../../utils_public/Comma";
import { DefaultLoading } from "../UI/Loading/Loading";
import { IfilterItem } from "../../pages/Mypage/types/state.type";

interface Istate {
  filter: IfilterItem;
  total: number;
  toggle: boolean;
}

interface Ifunc {
  selectFilter: (type: string, value: string) => void;
  setToggle: (bool: boolean) => void;
  setMobileFilterOpen: (bool: boolean) => void;
}

interface IapiResponse {
  loading: boolean;
}

export const MainPlantListHeader = ({
  state,
  func,
  apiResponse,
}: {
  state: Istate;
  func: Ifunc;
  apiResponse: IapiResponse;
}) => {
  return (
    <div className="flex mb-[32px] justify-between">
      <div className="w-full flex gap-x-[20px]">
        <div
          className="max-w-[80px] w-full flex items-center gap-x-[5px] cursor-pointer lg:hidden"
          onClick={() => {
            func.setMobileFilterOpen(true);
          }}
        >
          <span>필터</span>
          <img className="w-[20px] h-[20px]" src="/icon_remodel/filter.svg" />
        </div>
        <h1 className="flex items-center gap-x-[10px]">
          총
          {apiResponse.loading ? (
            <div className="w-[18px]">
              <DefaultLoading />{" "}
            </div>
          ) : (
            <span className="text-main-color-primary font-bold max-sm:font-medium">
              {HandleComma(state.total)}{" "}
              <span className="text-text-color-primary font-normal">건</span>
            </span>
          )}
        </h1>
      </div>

      <SortDropDown
        state={{
          filter: state.filter,
          toggle: state.toggle,
        }}
        func={{
          selectFilter: func.selectFilter,
          setToggle: func.setToggle,
        }}
      />
    </div>
  );
};
