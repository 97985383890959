import { HTMLInputTypeAttribute } from "react";

type InputState =
  | "plantName"
  | "address"
  | "completionYear"
  | "landCategory"
  | "capacity"
  | "capacityType"
  | "startConstruction";

interface IplantInformation {
  name: string;
  state: InputState;
  type?: HTMLInputTypeAttribute;
  placeHolder?: string;
  maxLength?: number;
}

interface IlandId {
  잡종지: number;
  대지: number;
  공장: number;
  임야: number;
  답: number;
  목장용지: number;
  전: number;
  과수원: number;
  주차장: number;
  기타: string;
}

export const PLANT_INFORMATION: IplantInformation[] = [
  {
    name: "상호명",
    state: "plantName",
    type: "text",
    placeHolder: "상호명을 입력해주세요.",
    maxLength: 10,
  },
  {
    name: "주소",
    state: "address",
    type: "text",
    placeHolder: "주소를 입력해주세요.",
    maxLength: 30,
  },
  {
    name: "준공년도",
    state: "completionYear",
    type: "text",
    placeHolder: "준공년도를 입력해주세요.",
    maxLength: 4,
  },
  { name: "지목", state: "landCategory" },
  {
    name: "용량",
    state: "capacity",
    type: "text",
    placeHolder: "용량을 입력해주세요.",
    maxLength: 4,
  },
  { name: "착공단계", state: "startConstruction" },
];

export const LAND_LIST = [
  "잡종지",
  "대지",
  "공장",
  "임야",
  "답",
  "목장용지",
  "전",
  "과수원",
  "주차장",
  "기타",
];

export const START_CONSTRUCTION_LIST = [
  "입지선정",
  "투자승인",
  "단지개발",
  "금융조달",
];

export const LAND_ID: IlandId = {
  잡종지: 1,
  대지: 2,
  공장: 3,
  임야: 4,
  답: 5,
  목장용지: 6,
  전: 7,
  과수원: 11,
  주차장: 18,
  기타: "1,2,3,4,5,6,7,11,18",
};
