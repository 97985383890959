import { DetailVariousMap } from "../../../components/Detail/VariousMap/DetailVariousMap";
import { useRoadView } from "../hooks/useRoadView";
import { useCadastralMap } from "../hooks/useCadastralMap";
import { useState } from "react";

interface Istate {
  latitude: number;
  longitude: number;
}

interface IapiResponse {
  status: string;
}

export const DetailVariousMapModify = ({
  state,
  apiResponse,
}: {
  state: Istate;
  apiResponse: IapiResponse;
}) => {
  // 현재 선택된 지도 토글 state
  const [curretNaverValue, setCurretNaverValue] = useState("satellite");
  // 촬영되지 않은 로드뷰 좌표 접근 시 차단 state
  const [notFound, setNotFound] = useState(false);
  // 현재 선택된 지도 관리 state
  const [typeNaverMap, setTypeNaverMap] = useState("satellite");

  // 네이버 맵 로드뷰 셋팅
  const settingRoadView = useRoadView(
    state.latitude,
    state.longitude,
    curretNaverValue,
    setNotFound,
  );

  // 네이버 지적도 셋팅
  const settingCadastralMap = useCadastralMap(state.latitude, state.longitude);

  // 선택한 토글 형태에 변화하는 네이버 맵
  const changeNaverMap = (value: string) => {
    // pano가 아닐 경우, setTypeNaverMap 상태 업데이트하여 react-naver-map 속성을 체크
    if (value !== "pano") {
      setCurretNaverValue(value);
      setTypeNaverMap(value);
    } else {
      setCurretNaverValue(value);
    }
  };

  return (
    <>
      <DetailVariousMap
        state={{
          latitude: state.latitude,
          longitude: state.longitude,
          curretNaverValue: curretNaverValue,
          notFound: notFound,
          typeNaverMap: typeNaverMap,
        }}
        apiResponse={{
          status: apiResponse.status,
        }}
        func={{
          changeNaverMap: changeNaverMap,
        }}
      />
    </>
  );
};
