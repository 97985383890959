import { MypageInquiryList } from "./MypageInquiryList";

import {
  IinquiryList,
  IinquiryListDeleteModal,
  IinquiryListUpdateModal,
} from "../../../pages/Mypage/types/state.type";
import { DefaultLoading } from "../../UI/Loading/Loading";

interface Istate {
  deleteInquiryList: number[];
  alreadyInquiryDelete: boolean;
  deleteModal: IinquiryListDeleteModal;
  updateModal: IinquiryListUpdateModal;
  selectInquiryList: string;
  toggle: boolean;
  inquiryText: string;
}

interface Iitem {
  inquiryList: IinquiryList[];
}

interface IapiResponse {
  status: string;
  updatedStatus: string;
  updatedError?: null | number;
  updatedId: number;
  deletedStatus: string;
  deletedError?: null | number;
}

interface Ifunc {
  setAlreadyInquiryDelete: (bool: boolean) => void;
  HandleDeleteModal: (value: IinquiryListDeleteModal | string) => void;
  HandleUpdateModal: (value: IinquiryListUpdateModal | string) => void;
  submitInquiry: () => void;
  setToggle: (bool: boolean) => void;
  clickInquiryListItem: (selected: string) => void;
  setInquiryText: (text: string) => void;
}

export const MypageInquiry = ({
  state,
  item,
  apiResponse,
  func,
}: {
  state: Istate;
  item: Iitem;
  apiResponse: IapiResponse;
  func: Ifunc;
}) => {
  return (
    <section>
      <h1 className="text-listFirst font-bold mb-4">문의 내역</h1>
      {apiResponse.status == "loading" ? (
        <div className="w-[100%] h-[200px] flex justify-center items-center mt-28">
          <DefaultLoading />
        </div>
      ) : (
        <>
          <ul className="h-[470px] w-full mt-2 overflow-y-scroll">
            <MypageInquiryList
              state={{
                deleteInquiryList: state.deleteInquiryList,
                deleteModal: state.deleteModal,
                updateModal: state.updateModal,
                alreadyInquiryDelete: state.alreadyInquiryDelete,
              }}
              item={{
                inquiryList: item.inquiryList,
              }}
              apiResponse={{
                updatedError: apiResponse.updatedError,
                updatedId: apiResponse.updatedId,
                deletedStatus: apiResponse.deletedStatus,
                deletedError: apiResponse.deletedError,
                updatedStatus: apiResponse.updatedStatus,
              }}
              func={{
                setAlreadyInquiryDelete: func.setAlreadyInquiryDelete,
                HandleDeleteModal: func.HandleDeleteModal,
                HandleUpdateModal: func.HandleUpdateModal,
              }}
            />
          </ul>
        </>
      )}
    </section>
  );
};
