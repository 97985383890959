import { Ifile, ImuchFile } from "../types/state.type";

interface IplantInfo {
  inverter: {
    inverterCapacity: string;
    inverterManufactureCompany: string;
    inverterName: string;
    inverterNums: string;
  };
  module: {
    moduleCapacity: string;
    moduleManufactureCompany: string;
    moduleName: string;
    moduleNums: string;
  };
}

interface IfileCollection {
  rpsFile: Ifile;
  energeFile: Ifile;
  imageFile: ImuchFile[];
}

export function HandleFormOwner(
  businessFile: File,
  businessCheck: boolean,
  commerceFile: File,
  commerceCheck: boolean,
) {
  const formData: FormData = new FormData();

  businessCheck ? formData.append("plant_license", businessFile) : null;
  commerceCheck
    ? formData.append("commercial_operation_license", commerceFile)
    : null;

  return formData;
}

export function HandleFormPlantInfo(
  plantInfo: IplantInfo,
  fileCollection: IfileCollection,
) {
  const formData: FormData = new FormData();

  // 인버터
  (plantInfo.inverter.inverterCapacity ||
    plantInfo.inverter.inverterCapacity === "") &&
    formData.append(
      "inverter_capacity",
      String(plantInfo.inverter.inverterCapacity),
    );
  (plantInfo.inverter.inverterManufactureCompany ||
    plantInfo.inverter.inverterManufactureCompany === "") &&
    formData.append(
      "inverter_manufacture_company",
      String(plantInfo.inverter.inverterManufactureCompany),
    );
  (plantInfo.inverter.inverterName || plantInfo.inverter.inverterName === "") &&
    formData.append("inverter_name", String(plantInfo.inverter.inverterName));
  (plantInfo.inverter.inverterNums || plantInfo.inverter.inverterNums === "") &&
    formData.append("inverter_nums", String(plantInfo.inverter.inverterNums));

  // 모듈
  (plantInfo.module.moduleCapacity || plantInfo.module.moduleCapacity === "") &&
    formData.append("module_capacity", String(plantInfo.module.moduleCapacity));
  (plantInfo.module.moduleManufactureCompany ||
    plantInfo.module.moduleManufactureCompany === "") &&
    formData.append(
      "module_manufacture_company",
      String(plantInfo.module.moduleManufactureCompany),
    );
  (plantInfo.module.moduleName || plantInfo.module.moduleName === "") &&
    formData.append("module_name", String(plantInfo.module.moduleName));
  (plantInfo.module.moduleNums || plantInfo.module.moduleNums === "") &&
    formData.append("module_nums", String(plantInfo.module.moduleNums));

  // rps 파일
  fileCollection.rpsFile.change &&
    (fileCollection.rpsFile.file
      ? formData.append("rps_power_gen_image", fileCollection.rpsFile.file)
      : formData.append("rps_power_gen_image", ""));
  fileCollection.rpsFile.change &&
    fileCollection.rpsFile.name &&
    formData.append(
      "rps_power_gen_image_origin_name",
      fileCollection.rpsFile.name,
    );

  // energe 파일
  fileCollection.energeFile.change &&
    (fileCollection.energeFile.file
      ? formData.append(
          "renewable_certificate_image",
          fileCollection.energeFile.file,
        )
      : formData.append("renewable_certificate_image", ""));
  fileCollection.energeFile.change &&
    fileCollection.energeFile.name &&
    formData.append(
      "renewable_certificate_image_origin_name",
      fileCollection.energeFile.name,
    );

  // image 파일
  fileCollection.imageFile.length > 0
    ? fileCollection.imageFile.map((el) =>
        formData.append("images", el.file as File),
      )
    : formData.append("images", "");

  return formData;
}
