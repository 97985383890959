import ApexCharts from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { moneyUnit_NotSpan } from "../../../utils_public/Unit";
import { makeTimeSumTotal } from "../../../pages/Detail/utils/actualPrice.function";
import { IactualPriceList } from "../../../pages/Detail/types/item.type";
import { useMemo } from "react";
import { IcapacityRange } from "../../../pages/Detail/types/state.type";

type Iseries = {
  name: string;
  data: any;
};

type IsellInfo = {
  created_datetime: string;
  price: number;
};

interface Istate {
  currentLocal: string;
  currentCapacity: IcapacityRange;
}

interface Iitem {
  sellInfo: IsellInfo;
  capacity: number;
  actualPriceList: IactualPriceList[];
}

export const DetailActualPriceGraph = ({
  state,
  item,
}: {
  state: Istate;
  item: Iitem;
}) => {
  // 시작 날짜와 종료 날짜 설정
  const startDate = new Date("2020-01-01");
  const endDate = new Date("2023-12-31");

  // 소유주 판매 등록 금액
  const THIS_PLANT_PRICE = item.sellInfo.price;

  const FILTERING_DATA =
    state.currentLocal === "전국"
      ? item.actualPriceList.filter(
          (parent) =>
            parent.capacity_group ===
            CAPACITY_NAME_CHANGE[state.currentCapacity],
        )
      : item.actualPriceList.filter(
          (parent) =>
            parent.sido === state.currentLocal &&
            parent.capacity_group ===
              CAPACITY_NAME_CHANGE[state.currentCapacity],
        );

  const TOTAL = FILTERING_DATA.map((el) => el["price"]).reduce(
    (acc, cur) => acc + cur,
    0,
  );

  const MAX_PRICE = Math.max(...FILTERING_DATA.map((el) => el.price));

  const ACTUAL_PRICE_OPTIONS: ApexOptions = useMemo(() => {
    return {
      chart: {
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },

      noData: {
        text: "해당 용량의 지역 발전소 실거래가의 정보가 없습니다.",
      },

      annotations: {
        yaxis: [
          {
            y:
              TOTAL && !!item.sellInfo.price
                ? MAX_PRICE <= THIS_PLANT_PRICE
                  ? undefined
                  : THIS_PLANT_PRICE
                : -TOTAL,
            borderColor: "#FF4560",
            label: {
              borderColor: "#FF4560",
              style: {
                color: "#fff",
                background: "#FF4560",
                fontSize: "14px",
              },
              text: `소유주 판매가 : ${
                MAX_PRICE <= THIS_PLANT_PRICE
                  ? `평균 초과(${moneyUnit_NotSpan(THIS_PLANT_PRICE)})`
                  : `${moneyUnit_NotSpan(THIS_PLANT_PRICE)}`
              }`,
            },
          },
        ],
      },

      dataLabels: {
        enabled: false,
      },

      legend: {
        show: true,
        offsetY: 7,
      },

      tooltip: {
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
          const year = new Date(
            w.config.series[seriesIndex].data[dataPointIndex].x,
          ).getFullYear();
          const month = new Date(
            w.config.series[seriesIndex].data[dataPointIndex].x,
          ).getMonth();
          const price = w.config.series[seriesIndex].data[dataPointIndex].y;
          const capacity = Math.floor(
            w.config.series[seriesIndex].data[dataPointIndex].capacity,
          );

          return (
            '<main class="rounded-lg text-center" >' +
            '<div class="py-1 bg-border-color-dividers">' +
            year +
            "년 " +
            (month + 1) +
            "월" +
            "</div>" +
            '<div class=" bg-general-white  px-4 pt-3 ">' +
            '<div class="flex ">' +
            '<span class="text-main-color-primary mr-2" >' +
            "금액 :" +
            "</span>" +
            "<span>" +
            moneyUnit_NotSpan(price) +
            "</span>" +
            "</div>" +
            "</div>" +
            '<div class=" bg-general-white  px-4 py-3 ">' +
            '<div class="flex ">' +
            '<span class="text-main-color-primary mr-2" >' +
            "용량 :" +
            "</span>" +
            "<span>" +
            capacity +
            " kW" +
            "</span>" +
            "</div>" +
            "</div>" +
            "</main>"
          );
        },
      },

      xaxis: {
        type: "datetime",
        categories: [
          new Date("2020-01"),
          new Date("2021-01"),
          new Date("2022-01"),
          new Date("2023-01"),
        ],
        labels: {
          style: {
            colors: "#111111",
            fontWeight: 700,
          },
          // rotate: 0,
          datetimeFormatter: {
            year: "yyyy",
            month: "",
          },
        },

        tooltip: {
          enabled: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        type: "numeric",
        labels: {
          formatter: function (value: number, opts: any) {
            return moneyUnit_NotSpan(value) as string;
          },
          style: {
            fontFamily: "PretendardVariable",
          },
        },
        show: true,
        opposite: false,
      },
    };
  }, [item.actualPriceList]);

  const CURRENT_SERIES: Iseries[] = [
    {
      name: state.currentCapacity,
      data: makeTimeSumTotal(item.actualPriceList, {
        startDate: startDate,
        endDate: endDate,
        local: state.currentLocal,
        capacity: CAPACITY_NAME_CHANGE[state.currentCapacity],
      }),
    },
  ];
  return (
    <div className="w-full h-[250px] my-[36px]">
      <ApexCharts
        options={ACTUAL_PRICE_OPTIONS}
        series={CURRENT_SERIES}
        height={"100%"}
        type={"scatter"}
        width={"100%"}
      />
      <div className="mt-[20px] text-footer text-text-color-secondary">
        <span>
          출처: 정보공개포털 (전국 지방자치단체 종합민원실), 최근 업데이트: 2023
        </span>
      </div>
    </div>
  );
};

const CAPACITY_NAME_CHANGE: { [key in IcapacityRange]: string } = {
  "~100kW 미만": "~100kW",
  "100kW 이상 ~ 200kW 미만": "100kW-200kW",
  "200kW 이상 ~ 500kW 미만": "200kW-500kW",
  "500kW 이상 ~ 1MW 미만": "500kW-1MW",
  "1MW 이상~": "1MW~",
};
