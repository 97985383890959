import classNames from "classnames";

interface Istate {
  selectKeyword: string[];
  current: number;
}

interface Ifunc {
  setCurrent: (count: number) => void;
}

export const QnAList = ({ state, func }: { state: Istate; func: Ifunc }) => {
  return (
    <ul className="max-w-[1630px] w-full h-full mb-[50px]  mx-auto px-[50px] max-md:px-[24px]">
      {state.selectKeyword.length
        ? INITIAL_DATA.filter((current) =>
            state.selectKeyword.some(
              (check) => check === current.type.find((el) => el === check),
            ),
          ).map((el, idx) => (
            <li
              key={el.id}
              className="pt-[25px] cursor-pointer"
              onClick={() => {
                if (state.current === idx + 1) return func.setCurrent(0);
                func.setCurrent(idx + 1);
              }}
            >
              <h1 className="pb-[25px] flex justify-between items-center border-b-[1px] border-b-border-color-dividers">
                {el.title}
                <img
                  className={classNames(
                    "w-[20px] transition-all duration-500 )",
                    { "rotate-180": state.current === idx + 1 },
                    { "rotate-0": state.current !== idx + 1 },
                  )}
                  src="/icon_remodel/arrow.svg"
                />
              </h1>
              <p
                className={classNames(
                  "transition-all duration-150 overflow-hidden text-text-color-secondary px-[20px]",
                  {
                    "max-h-screen h-full py-[20px]": state.current === idx + 1,
                  },
                  { "max-h-0": state.current !== idx + 1 },
                )}
              >
                {el.answer.split("\n").map((line, idx) => {
                  return (
                    <span key={idx}>
                      {line}
                      <br />
                    </span>
                  );
                })}
              </p>
            </li>
          ))
        : INITIAL_DATA.map((el, idx) => (
            <li
              key={el.id}
              className="pt-[25px] cursor-pointer"
              onClick={() => {
                if (state.current === idx + 1) return func.setCurrent(0);
                func.setCurrent(idx + 1);
              }}
            >
              <h1 className="pb-[25px] flex justify-between items-center border-b-[1px] border-b-border-color-dividers">
                {el.title}
                <img
                  className={classNames(
                    "w-[20px] transition-all duration-500 )",
                    { "rotate-180": state.current === idx + 1 },
                    { "rotate-0": state.current !== idx + 1 },
                  )}
                  src="/icon_remodel/arrow.svg"
                />
              </h1>
              <p
                className={classNames(
                  "transition-all duration-150 overflow-hidden text-text-color-secondary px-[20px]",
                  {
                    "max-h-screen h-full py-[20px]": state.current === idx + 1,
                  },
                  { "max-h-0": state.current !== idx + 1 },
                )}
              >
                {el.answer.split("\n").map((line, idx) => {
                  return (
                    <span key={idx}>
                      {line}
                      <br />
                    </span>
                  );
                })}
              </p>
            </li>
          ))}
    </ul>
  );
};

const INITIAL_DATA = [
  {
    id: 1,
    type: ["거래", "발전소"],
    title: "햇빛바람거래소가 제공하는 서비스는 무엇인가요?",
    answer:
      "햇빛바람거래소에서는 전국의 모든 태양광 발전소에 대한 정보를 쉽게 확인할 수 있습니다. \n 소규모 태양광 발전소 거래 과정에서 투명성과 신뢰를 제공함으로서 더 많은 이용자 분들이 손쉽게 발전소를 판매/구매할 수 있는 플랫폼 환경을 제공하고 있습니다.",
  },
  {
    id: 2,
    type: ["판매", "인증"],
    title: "발전소를 판매 할 수 있는 자격 요건과 방법이 있나요?",
    answer:
      "발전소를 판매하기 위해서 해당 발전소의 소유주 인증이 필요합니다. 이후 관리자 확인 절차를 거쳐 판매자가 소유주임이 입증되면, \n 매물 여부를 확인한 뒤 소유주 본인이 사이트 내에서 직접 거래 할 수 있습니다. 발전소를 판매하기 위해서는 홈페이지 상의 ‘팔기’ 버튼을 통해 이름과 연락처, 희망판매 가격을 등록하셔야 합니다. \n 이후 관리자가 직접 소유주 인증 확인 절차를 진행합니다. ( 발전사업등록증, 상업운전개시 확인서 및 본인인증 필요) 소유주 인증이 완료되면 홈페이지 상에 판매 가격이 등록되고, \n 마이 페이지의 판매 관리 탭을 통해 실시간으로 발전소 구매 희망자들의 희망 구매가격을 조회할 수 있습니다. 메세지 보내기 기능을 통해 판매자와 구매자 간 직접 거래가 가능합니다.",
  },
  {
    id: 3,
    type: ["소유주", "인증"],
    title: "소유주 인증 유효 기간이 있나요?",
    answer:
      "햇빛바람거래소에서 인정하는 소유주 인증 기간은 2년입니다. 이후 추가 소유주 인증을 통해 기한을 연장할 수 있습니다.",
  },
  {
    id: 4,
    type: ["소유주", "인증"],
    title: "소유주와 가족관계라도 발전소를 판매 할 수 있나요?",
    answer:
      "소유주 인증을 위해서는 발전사업등록증과 상업운전개시 확인서가 필요하며 별도의 본인 인증을 거쳐야 합니다. \n 만약 소유주와 가족 관계인 경우 가족관계증명서를 첨부하고 발전소 판매에 대한 소유주 본인 동의가 입증되는 경우, 판매 등록이 가능합니다. ",
  },
  {
    id: 5,
    type: ["중개"],
    title: "중개 수수료가 있나요?",
    answer:
      "판매/구매에 대한 중개 수수료는 없습니다. 단, 이용약관에 따라 서비스 이용 요금은 부과될 수 있으나, 현재 무료 서비스로 제공되고 있습니다.",
  },
  {
    id: 6,
    type: ["소유주", "발전소"],
    title: "소유주 인증이 되지 않은 발전소는 어떻게 구매 할 수 있나요?",
    answer:
      "소유주 인증이 되지 않은 발전소를 구매하는 것은 불가합니다. 다만, 이후에 해당 발전소의 소유주 인증이 완료된 후, \n 판매 대상으로 등록되는 경우 직접 소유주와 협의를 통해 구매를 진행하실 수 있습니다.",
  },
  {
    id: 7,
    type: ["거래", "판매", "구매"],
    title: "판매자와 구매자의 거래는 어떻게 이루어지나요?",
    answer:
      "태양광 발전소의 소유주로 등록하시는 경우 본인의 발전소를 판매물로 등록하실 수 있습니다. 판매물로 등록된 발전소에 대해 희망 판매 가격을 공지할 수 있으며, \n 이후 구매 희망가격과 비교하여 거래를 진행하게 됩니다. 거래는 발전소 소유주와 구매 희망자 간 1:1 직접 거래의 형태로 이루어지며 \n 이 과정에서 발생하는 모든 필요한 서류 절차 등은 거래 당사자간 협의에 의해 이루어집니다. ",
  },
  {
    id: 8,
    type: ["발전소", "발전량"],
    title: "태양광 발전소 발전량 정보는 어디서 가져오나요?",
    answer:
      "햇빛바람거래소 내 모든 발전소의 발전량 정보는 햇빛바람지도(햇빛바람 지도) 의 데이터를 기반으로 하고 있습니다. \n 햇빛바람지도는 발전소의 설비용량과 위치(위경도) 정보를 사용하여 해당 위경도의 날씨데이터를 이용해서 \n 발전량 예측을 수행하고 있습니다",
  },
];
