export const DetailCadastralMap = () => {
  return (
    <section className="w-[47%] max-xl:w-full z-0">
      <div id="cadastral" className="w-full h-[350px] mt-16 rounded-xl" />
      <p className="mt-2 text-footer text-text-color-secondary">
        출처: 네이버지도, 최근 업데이트: 2023
      </p>
    </section>
  );
};
