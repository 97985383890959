import { IuserPasswordValidation } from "../types/state.type";

type IndexSignature = {
  [key: string]: boolean;
};

export const passwordStateCheck = (
  type: string,
  validation: IuserPasswordValidation,
) => {
  const CURRENT_PASSWORD_VALIDATION: IndexSignature = {
    length: validation.passwordLength,
    number: validation.passwordNumber,
    special: validation.passwordSpecial,
    same: validation.passwordSame,
  };

  return CURRENT_PASSWORD_VALIDATION[type];
};
