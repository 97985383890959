import { IplantList } from "../../../../pages/Main/types/plantList.type";
import { HandleComma } from "../../../../utils_public/Comma";
import { DefaultLoading } from "../../../UI/Loading/Loading";
import { MypageMyPlantSearchListItem } from "./MypageMyPlantSearchListItem";

interface Iitem {
  plantList: IplantList[] | null;
  plantListTotal: number;
}

interface IapiResponse {
  loading: boolean;
}

export const MypageMyPlantSearchList = ({
  item,

  apiResponse,
}: {
  item: Iitem;

  apiResponse: IapiResponse;
}) => {
  return (
    <div className="max-w-[800px] w-full">
      <div className="mb-[28px] flex justify-between">
        <h1>발전소 목록</h1>
        <h1 className="flex gap-x-[8px]">
          총
          {apiResponse.loading ? (
            <div className="w-[18px] h-[18px]">
              <DefaultLoading />
            </div>
          ) : (
            <span className="text-main-color-primary font-bold max-sm:font-medium">
              {HandleComma(item.plantListTotal)}
            </span>
          )}
          건
        </h1>
      </div>

      <dl className="flex px-[20px] text-listFirst border-b-[1px] border-b-border-color-dividers pb-[16px]">
        <dt className="max-w-[300px] w-full">
          <span>발전소명</span>
        </dt>
        <dt className="max-w-[200px] w-full">
          <span>주소</span>
        </dt>
        <dt className="max-w-[140px] w-full">
          <span>용량</span>
        </dt>
        <dt className="max-w-[60px] w-full"></dt>
      </dl>

      {apiResponse.loading && (
        <div className="h-[120px] mt-[80px] mx-auto">
          <DefaultLoading />
        </div>
      )}

      {!apiResponse.loading && !!item.plantList && (
        <MypageMyPlantSearchListItem plantList={item.plantList} />
      )}

      {!apiResponse.loading && !item.plantList && (
        <div className="text-center mt-[40px] text-listTitle">
          <span className=" text-text-color-secondary border-b-[1px] border-text-color-secondary pb-[2px]">
            검색된 발전소가 없습니다.
          </span>
        </div>
      )}
    </div>
  );
};
