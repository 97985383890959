import classNames from "classnames";
import { useState } from "react";
import useScroll from "../../../utils_public/useScroll";

export const ScrollControlButton = () => {
  const [scrollTopFlag, setScrollTopFlag] = useState(false);
  const [scrollBottomFlag, setScrollBottomFlag] = useState(false);

  const updateScroll = () => {
    const { scrollY } = window;
    const isTopScrolled = scrollY <= 0;
    const isBottomScrolled = scrollY + 842 < document.body.scrollHeight;

    setScrollTopFlag(isTopScrolled);
    setScrollBottomFlag(isBottomScrolled);
  };

  useScroll(updateScroll);

  return (
    <ul className="fixed right-[0px] bottom-[70px] border-[1px] border-border-color-dividers bg-general-white z-[1]">
      <li
        className={classNames(
          "w-[50px] h-[50px] border-b-[1px] border-b-border-color-dividers flex items-center justify-center max-md:w-[40px] max-md:h-[40px]",
          { "cursor-pointer": !scrollTopFlag },
          { "cursor-default": scrollTopFlag },
        )}
        onClick={() => {
          if (scrollTopFlag) return;
          window.scrollTo({ top: 0 });
        }}
      >
        <img
          className="w-[45%] rotate-180"
          src={
            scrollTopFlag
              ? "/icon_remodel/scroll_hide.svg"
              : "/icon_remodel/scroll.svg"
          }
        />
      </li>
      <li
        className={classNames(
          "w-[50px] h-[50px] flex items-center justify-center cursor-pointer max-md:w-[40px] max-md:h-[40px]",
          { "cursor-pointer": scrollBottomFlag },
          { "cursor-default": !scrollBottomFlag },
        )}
        onClick={() => {
          if (!scrollBottomFlag) return;
          window.scrollTo({
            top: document.body.scrollHeight,
            behavior: "smooth",
          });
        }}
      >
        <img
          className="w-[50%]"
          src={
            !scrollBottomFlag
              ? "/icon_remodel/scroll_bottom_hide.svg"
              : "/icon_remodel/scroll_bottom.svg"
          }
        />
      </li>
    </ul>
  );
};
