import classNames from "classnames";
import { useNavigate, useLocation } from "react-router-dom";
import { ImypageMainInfo, Irecently } from "../../pages/Mypage/types/item.type";
import { buySellMenuPing } from "../../pages/Mypage/utils/buySellMenuPing.function";

interface Iitem {
  userInfo: ImypageMainInfo;
  recently: Irecently;
}

export const MypageMenuTablet = ({ item }: { item: Iitem }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const CURRENT_PATH_NAME = pathname.split("/");

  return (
    <>
      <section className="lg:hidden">
        <div className="mb-[20px]">
          <span
            className="text-subTitle font-semibold cursor-pointer max-sm:font-medium"
            onClick={() => {
              if (!CURRENT_PATH_NAME[2]) return;
              navigate("/mypage");
            }}
          >
            마이페이지
          </span>
        </div>
        <ul className="grid grid-cols-4 border-y-[1px] border-border-color-dividers py-[10px] justify-items-center gap-y-[20px]">
          {MENU_LIST.map((el, idx) => (
            <li className="relative" key={idx}>
              <span
                className={classNames(
                  "cursor-pointer hover:text-main-color-primary max-sm:text-default",
                  {
                    "text-main-color-primary font-bold max-sm:font-medium":
                      !CURRENT_PATH_NAME[2]
                        ? el.name === "내 정보"
                        : CURRENT_PATH_NAME[2] === el.url.split("/")[0] &&
                          CURRENT_PATH_NAME[3] === el.url.split("/")[1],
                  },
                )}
                onClick={() => {
                  if (
                    CURRENT_PATH_NAME[2] === "trade"
                      ? CURRENT_PATH_NAME[3] === el.url.split("/")[1]
                      : el.name === "내 정보"
                      ? !CURRENT_PATH_NAME[2]
                      : CURRENT_PATH_NAME[2] === el.url.split("/")[0]
                  )
                    return;
                  navigate(el.url);
                }}
              >
                {el.name}
              </span>
              {el.name === "판매 관리" &&
                buySellMenuPing(
                  "60hz-sellerList-local",
                  item.recently.sell_menu_recently_datetime,
                ) && (
                  <div className="absolute top-[4px] left-[65px]">
                    <span className="relative flex h-[10px] w-[10px]">
                      <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-general-red opacity-75"></span>
                      <span className="relative inline-flex rounded-full h-[10px] w-[10px] bg-general-red"></span>
                    </span>
                  </div>
                )}

              {el.name === "구매 관리" &&
                buySellMenuPing(
                  "60hz-buyerList-local",
                  item.recently.buy_menu_recently_datetime,
                ) && (
                  <div className="absolute top-[4px] left-[65px]">
                    <span className="relative flex h-[10px] w-[10px]">
                      <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-general-red opacity-75"></span>
                      <span className="relative inline-flex rounded-full h-[10px] w-[10px] bg-general-red"></span>
                    </span>
                  </div>
                )}
            </li>
          ))}
        </ul>
        {!CURRENT_PATH_NAME[2] && (
          <article className="max-w-full w-full flex justify-between items-center p-[32px] border-b-[1px] border-b-main-color-primary">
            <aside>
              <h1 className="text-subValue font-semibold mb-[8px] max-sm:font-medium">
                {item.userInfo.username}
              </h1>
              <div className="flex flex-col gap-y-[8px] text-default">
                <span>{item.userInfo.email}</span>
                <span>{item.userInfo.phone}</span>
              </div>
            </aside>
            <button
              className="h-[43px] px-6 bg-main-color-sub rounded-lg cursor-pointer max-xl:px-4 max-xl:py-2 max-xl:text-default"
              onClick={() => navigate("/mypage/myPlant")}
            >
              발전소 찾기
            </button>
          </article>
        )}
      </section>
    </>
  );
};

const MENU_LIST = [
  { name: "내 정보", url: "" },
  { name: "메세지함", url: "/message" },
  { name: "내 발전소", url: "myPlant/certification" },
  { name: "판매 관리", url: "trade/plantSell" },
  { name: "구매 관리", url: "trade/plantBuy" },
  { name: "찜한 발전소", url: "favorite" },
  { name: "내 정보 수정", url: "updateInfo" },
  { name: "문의 내역", url: "inquiry" },
];
