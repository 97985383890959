import { power_duration } from "../../../pages/Detail/utils/DetailFunctional";
import { capacityUnit } from "../../../utils_public/Unit";

type ImodalSellBuy = {
  open: boolean;
  type: string;
  plantId: number;
};

interface Ifunc {
  setModalSellBuy: (select: ImodalSellBuy) => void;
}

interface Iitem {
  plantId: number;
  capacity: number | string;
  local: string;
  myOwner: boolean;
}

export const DetailInformationPirce = ({
  func,
  item,
}: {
  func: Ifunc;
  item: Iitem;
}) => {
  return (
    <div className="flex items-center max-md:flex-col">
      <div className="w-full flex gap-x-[60px] mt-8 mb-12 max-md:mb-8">
        <div className="w-[130px]">
          <h1 className="text-subTitle text-main-color-primary mb-[6px]">
            {capacityUnit(item.capacity)}
          </h1>
          <span className="flex items-center  text-listSecond">용량</span>
        </div>
        <div className="w-[150px]">
          <h1 className="text-subTitle text-main-color-primary mb-[6px]">
            {power_duration.filter((word) => word.area === item.local)[0]?.time}{" "}
            시간
          </h1>
          <span className="flex items-center text-listSecond">
            {item.local} 연평균 발전시간
          </span>
        </div>
      </div>

      <div className="max-w-[280px] w-full flex flex-col gap-y-[10px] items-end max-md:flex-row max-md:max-w-full max-md:gap-x-[40px] max-md:mb-[32px]">
        <button
          className="w-full h-[55px] flex items-center justify-center text-listTitle py-[25px] rounded-lg bg-general-lightRed text-general-white hover:bg-[#ef8478] duration-75
            
          "
          onClick={() => {
            func.setModalSellBuy({
              open: true,
              type: "sell",
              plantId: item.plantId,
            });
          }}
        >
          팔기
        </button>
        <button
          className="w-full h-[55px] flex items-center justify-center text-listTitle py-[25px] rounded-lg bg-general-lightGreen text-general-white hover:bg-[#6bcf9a] duration-75"
          onClick={() => {
            if (item.myOwner) {
              return alert("본인이 소유한 발전소를 구매할 수 없습니다.");
            }

            func.setModalSellBuy({
              open: true,
              type: "buy",
              plantId: item.plantId,
            });
          }}
        >
          사기
        </button>
      </div>
    </div>
  );
};
