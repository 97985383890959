import { useCookies } from "react-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import { IplantList } from "../../../pages/Main/types/plantList.type";
import { capacityUnit } from "../../../utils_public/Unit";

interface Istate {
  currentFavorite: boolean;
}

interface Ifunc {
  likeSubmitFromServer: (id?: number) => void;
}

export const FavoritePlantListItem = ({
  item,
  func,
  state,
}: {
  item: IplantList;
  state: Istate;
  func: Ifunc;
}) => {
  const [cookie] = useCookies(["60hz-user"]);

  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  return (
    <li className="relative">
      <div
        className="cursor-pointer"
        onClick={() => {
          navigate(`/detail/${item.id}`);
        }}
      >
        <figure className="h-[200px] relative rounded-xl max-2xl:h-[160px] max-sm:h-[250px]">
          <img
            className="w-full h-full object-cover rounded-xl"
            src={`https://naveropenapi.apigw.ntruss.com/map-static/v2/raster-cors?w=300&h=300&maptype=satellite&level=17&center=${item.longitude},${item.latitude}&X-NCP-APIGW-API-KEY-ID=${process.env.REACT_APP_NAVER_MAP_ID}`}
          />
        </figure>
        <div className="flex items-center text-lg mt-4">
          <img className="w-[18px] h-[18px]" src="/icon_remodel/sun.svg" />
          <h1
            className="ml-2 text-main-color-primary text-listFirst leading-[100%] font-semibold whitespace-nowrap overflow-hidden text-ellipsis max-sm:font-medium"
            title={item.plant_name as string}
          >
            {item.plant_name}
          </h1>
        </div>
      </div>

      <div className="text-default text-text-color-secondary mt-[4px]">
        <div className="flex items-center leading-[22px] flex-wrap">
          {item.addr_lv1 ? item.addr_lv1 + " " : ""}
          {item.addr_lv2 ? item.addr_lv2 + " " : ""}
          {item.addr_lv3 ? item.addr_lv3 + " " : ""}
          <span className="px-2">|</span>
          {capacityUnit(item.capacity)}
          {item.land_name && <span className="px-2">|</span>}
          {item.land_name && <>{item.land_name}</>}
        </div>
      </div>
      <div className="w-[30px] h-[30px] top-[5px] right-[5px] absolute">
        <div
          className="w-full h-full flex justify-center items-center"
          onClick={(e) => {
            e.stopPropagation();
            if (!cookie["60hz-user"]) {
              return window.confirm(
                "로그인이 필요합니다. 로그인 페이지로 이동하겠습니까?",
              )
                ? navigate("/login", {
                    state: { previousPath: pathname, queryStringPath: search },
                  })
                : null;
            }
            func.likeSubmitFromServer(item.id);
          }}
        >
          <img
            className="w-[20px] h-[18px] cursor-pointer"
            src={
              state.currentFavorite
                ? "/icon_remodel/heart_white_full.svg"
                : "/icon_remodel/heart_white.svg"
            }
          />
        </div>

        <ul className="mt-[8px] flex flex-col gap-y-[18px] absolute right-[-13px] max-2xl:mt-[2px] max-2xl:gap-y-[10px]">
          {item.is_owner && (
            <p
              className="w-[35px] h-[35px] flex justify-center items-center flex-col bg-general-yellow relative rounded-l-md font-bold
               before:absolute before:border-[8px] before:border-solid before:border-[transparent] before:rotate-[-45deg]
              before:border-b-[#b8b01f] before:bottom-[-8px] before:right-[3px] z-[1]  max-2xl:h-[30px]"
            >
              <span className="text-[10px] max-2xl:text-[8px]">소유주</span>
              <span className="text-[10px] max-2xl:text-[8px]">인증</span>
            </p>
          )}
        </ul>
      </div>
    </li>
  );
};
