import classNames from "classnames";
import { DropDownListBox } from "../../UI/DropDown/DropDownListBox";

interface Istate {
  currentLocal: string;
  localToggle: boolean;
}

interface Ifunc {
  setToggle: (bool: boolean) => void;
  setCurrent: (value: string) => void;
}

export const DetailActualPriceLocalFilter = ({
  state,
  func,
}: {
  state: Istate;
  func: Ifunc;
}) => {
  return (
    <div className=" flex-grow h-[150px] flex text-listFirst flex-col justify-center gap-x-[10px] gap-y-[10px] relative border-[1px] border-border-color-dividers rounded-xl px-[20px] max-md:text-listSecond max-sm:text-default max-sm:max-w-[250px] max-sm:w-full">
      <h1>원하시는 지역 정보를 선택해주세요.</h1>
      <div className=" flex items-center gap-x-[10px] max-md:flex-col max-md:items-start max-md:gap-y-[8px]">
        <div className="w-[90px] h-[35px] z-[2]">
          <div className="relative border-[1px] py-[6px] text-default border-border-color-dividers rounded-lg">
            <div
              className="w-[90px] text-listSecond pl-[14px] relative cursor-pointer"
              onClick={() => func.setToggle(!state.localToggle)}
            >
              {state.currentLocal}
              <img
                className={classNames(
                  "absolute top-[8px] right-[10px]",
                  {
                    "rotate-180": state.localToggle,
                  },
                  {
                    "rotate-0": !state.localToggle,
                  },
                )}
                src="/icon_remodel/arrow.svg"
              />
            </div>
            <DropDownListBox
              state={{
                toggle: state.localToggle,
                current: state.currentLocal,
              }}
              item={{
                selectList: LOCAL_LIST,
              }}
              func={{
                setToggle: func.setToggle,
                setCurrent: func.setCurrent,
              }}
              options={{
                overflow: true,
              }}
            />
          </div>
        </div>
        지역의 평균 거래값
      </div>
    </div>
  );
};

const LOCAL_LIST = [
  "전국",
  "서울",
  "경기",
  "인천",
  "강원",
  "경북",
  "경남",
  "대구",
  "부산",
  "울산",
  "충남",
  "충북",
  "대전",
  "세종",
  "제주",
  "전남",
  "전북",
  "광주",
];
