import { useGetInquiryListQuery } from "../query/useInquiryListQuery";
import { IinquiryList } from "../types/state.type";

export const useGetInquiryList = (cookie?: string) => {
  const { data, status } = useGetInquiryListQuery("api/ask/", {
    apiName: "InquiryListFromMypage",
    cookie: cookie,
  });

  return {
    data: data ? data.results : DUMMY_DATA_LIST,
    status: status,
  };
};

const DUMMY_DATA: IinquiryList = {
  answer: "-",
  answer_datetime: "-",
  id: 1,
  question: "-",
  question_type: 1,
  register_datetime: "-",
  status: "-",
};

const DUMMY_DATA_LIST: IinquiryList[] = Array.from(
  { length: 3 },
  () => DUMMY_DATA,
);
