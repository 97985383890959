import classNames from "classnames";
import { Ipass } from "../../../../pages/SignUp/types/state.type";
import { HandleHyphen } from "../../../../pages/SignUp/utils/SignFunc";
import { SubmitLoading } from "../../Loading/Loading";

interface signature {
  [key: string]: string;
}

interface IserverError {
  code: string;
  message: string;
}

interface Istate {
  input: signature;
  currentValue: string;
  nicknameCheck: boolean;
  usernameCheck: boolean;
  phoneNumberCheck: boolean;
  telephoneNumberCheck: boolean;
  companyCheck: boolean;
}

interface Ifunc {
  setInput: (input: signature) => void;
  blurInput: (name: string) => void;
}

interface Iitem {
  nicknameOriginal: string;
  phoneNumberOriginal: string;
}

interface IapiResponse {
  serverError?: IserverError;
  phoneDupStatus: Ipass;
  phoneDupLoading: boolean;
  nicknameDupStatus: Ipass;
  nicknameDupLoading: boolean;
}

interface Ioptions {
  type: string;
  title: string;
  name: string;
  maxLength: number;
  placeHolder: string;
  ref: React.RefObject<HTMLInputElement>;
}

export const MypageUserInput = ({
  state,
  func,
  item,
  apiResponse,
  options,
}: {
  state: Istate;
  func: Ifunc;
  item: Iitem;
  apiResponse: IapiResponse;
  options: Ioptions;
}) => {
  return (
    <div className="h-[80px] max-md:h-[95px]">
      <div className="flex items-center max-md:flex-col max-md:items-start max-md:gap-y-[10px]">
        <span className="w-[77px] text-default">{options.title}</span>
        <div className="max-w-[523px] w-full ml-16 relative max-md:ml-0">
          <input
            ref={options.ref}
            className={classNames(
              "w-full h-[43px] px-4 !outline-none border-[0.5px] border-border-color-dividers rounded-lg",

              {
                "border-general-red":
                  options.name === "nickname" &&
                  (state.nicknameCheck || apiResponse.nicknameDupStatus.fail) &&
                  item.nicknameOriginal !== state.input.nickname,
              },
              {
                "border-general-red":
                  options.name === "userName" && state.usernameCheck,
              },
              {
                "border-general-red":
                  options.name === "phoneNumber" &&
                  (state.phoneNumberCheck || apiResponse.phoneDupStatus.fail) &&
                  item.phoneNumberOriginal !== state.input.phoneNumber,
              },
              {
                "border-general-red":
                  options.name === "telephoneNumber" &&
                  state.telephoneNumberCheck,
              },
              {
                "border-general-red":
                  options.name === "company" && state.companyCheck,
              },
            )}
            type={options.type}
            value={state.currentValue}
            maxLength={options.maxLength}
            placeholder={options.placeHolder}
            onBlur={() => func.blurInput(options.name)}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
              options.name === "phoneNumber" ||
              options.name === "telephoneNumber"
                ? HandleHyphen(e)
                : null
            }
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              func.setInput({ ...state.input, [options.name]: e.target.value });
            }}
          />

          {/* 휴대폰번호 중복 비동기 로딩 애니메이션 */}
          {options.name === "phoneNumber" && apiResponse.phoneDupLoading && (
            <div className="absolute top-[10px] right-5">
              <SubmitLoading width={23} height={23} />
            </div>
          )}
          {/* 닉네임 중복 비동기 로딩 애니메이션 */}
          {options.name === "nickname" && apiResponse.nicknameDupLoading && (
            <div className="absolute top-[10px] right-5">
              <SubmitLoading width={23} height={23} />
            </div>
          )}
        </div>
      </div>

      {/* 유효성 및 중복 닉네임 체크 */}
      {item.nicknameOriginal !== state.input["nickname"] &&
        options.name === "nickname" &&
        (apiResponse.nicknameDupStatus.init && state.nicknameCheck ? (
          <h1 className="mt-[6px] ml-[148px] text-smallExplanation text-general-red max-md:ml-0">
            닉네임 형식이 올바르지 않습니다. (공백, 특수문자 및 이모티콘 사용
            불가)
          </h1>
        ) : (
          (apiResponse.nicknameDupStatus.success && (
            <h1 className="mt-[6px] ml-[148px] text-smallExplanation text-main-color-primary max-md:ml-0">
              사용 가능한 닉네임입니다.
            </h1>
          )) ||
          (apiResponse.nicknameDupStatus.fail && (
            <h1 className="mt-[6px] ml-[148px] text-smallExplanation text-general-red max-md:ml-0">
              중복된 닉네임입니다.
            </h1>
          ))
        ))}

      {/* 유효성 및 중복 휴대폰 번호 체크 */}
      {item.phoneNumberOriginal !== state.input["phoneNumber"] &&
        options.name === "phoneNumber" &&
        (apiResponse.serverError?.code ? (
          <h1 className="mt-[6px] ml-[155px] text-smallExplanation text-general-red max-md:ml-0">
            등록할 수 없는 휴대폰 번호 형식입니다.
          </h1>
        ) : apiResponse.phoneDupStatus.init && state.phoneNumberCheck ? (
          <h1 className="mt-[6px] ml-[155px] text-smallExplanation text-general-red max-md:ml-0">
            휴대폰 번호 형식이 올바르지 않습니다.
          </h1>
        ) : (
          (apiResponse.phoneDupStatus.success && (
            <h1 className="mt-[6px] ml-[155px] text-smallExplanation text-main-color-primary max-md:ml-0">
              사용 가능한 휴대폰 번호입니다.
            </h1>
          )) ||
          (apiResponse.phoneDupStatus.fail && (
            <h1 className="mt-[6px] ml-[155px] text-smallExplanation text-general-red max-md:ml-0">
              중복된 휴대폰 번호입니다.
            </h1>
          ))
        ))}

      {/* 유효성 전화번호 체크 */}
      {state.input.telephoneNumber.length > 0 &&
        options.name === "telephoneNumber" &&
        state.telephoneNumberCheck && (
          <h1 className="mt-[6px] ml-[148px] text-smallExplanation text-general-red max-md:ml-0">
            전화번호 형식이 올바르지 않습니다.
          </h1>
        )}
    </div>
  );
};
