import { useEffect } from "react";
import { MypagePlantSell } from "../../../components/Mypage/MypageMyPlant/PlantBuy/MypagePlantSell";
import { ErrorHandling } from "../../../utils_public/ErrorHandling";
import { SUBMIT_SELL_AND_BUY_ERROR_TYPE } from "../error/error.object";

type IbuyerList = {
  created_datetime: string;
  price: number;
  user_id: number;
  username: string;
};

interface IsellList {
  plant_addr_lv1: string;
  plant_addr_lv2: string;
  plant_addr_lv3: string;
  plant_capacity: number;
  plant_land_name: string;
  buyer_list: IbuyerList[];
  plant_id: number;
  plant_name: string;
  sell_created_datetime: string;
  sell_price: number;
}

interface Istate {
  selectPlantId: number;
}

interface Iitem {
  sellList: IsellList[];
}

interface IapiResponse {
  apiStatus: string;
  submitPlantId: number;
  submitSuccess: boolean;
  submitLoading: boolean;
  axiosCode: string;
  error: boolean;
  errorStatus: number;
}

interface Ifunc {
  setSelectPlantId: (num: number) => void;
  submitPrice: (num: number, price: number | string) => void;
}

export const MypagePlantSellModify = ({
  state,
  item,
  apiResponse,
  func,
}: {
  state: Istate;
  item: Iitem;
  apiResponse: IapiResponse;
  func: Ifunc;
}) => {
  useEffect(() => {
    if (apiResponse.error) {
      alert(
        ErrorHandling(
          {
            errorType: SUBMIT_SELL_AND_BUY_ERROR_TYPE,
            errorCode: apiResponse.axiosCode,
            errorStatus: apiResponse.errorStatus,
            isError: apiResponse.error,
          },
          "",
        ),
      );
    }
  }, [apiResponse.error]);

  return (
    <MypagePlantSell
      state={{
        selectPlantId: state.selectPlantId,
      }}
      item={{ sellList: item.sellList }}
      apiResponse={{
        apiStatus: apiResponse.apiStatus,
        submitPlantId: apiResponse.submitPlantId,
        submitSuccess: apiResponse.submitSuccess,
        submitLoading: apiResponse.submitLoading,
        axiosCode: apiResponse.axiosCode,
        error: apiResponse.error,
        errorStatus: apiResponse.errorStatus,
      }}
      func={{
        setSelectPlantId: func.setSelectPlantId,
        submitPrice: func.submitPrice,
      }}
    />
  );
};
