export const imageURLtoFile = async (url: string, fileName: string) => {
  if (!url) return null;
  const response = await fetch(url);
  const data = await response.blob();
  const ext = url.split(".").pop();
  const metadata = { type: `image/${ext}` };
  const newFile: File = new File([data], fileName, metadata);

  return newFile;
};
