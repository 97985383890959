import { Ifile } from "../../../pages/Mypage/types/state.type";
import { DragDropFileContainer } from "../../UI/DragAndDrop/DragDropFile";

export const MypageRpsSystem = ({
  rpsFile,
  setRpsFile,
}: {
  rpsFile: Ifile;
  setRpsFile: (file: Ifile) => void;
}) => {
  return (
    <section className="mb-[60px]">
      <h1 className="mb-4 text-listFirst font-bold">
        RPS 종합시스템상 발전량 화면 캡쳐본
      </h1>
      <div className="max-w-full w-full h-[178px]">
        <DragDropFileContainer
          file={rpsFile}
          setFile={setRpsFile}
          title={"RPS 종합시스템상 발전량 화면 캡쳐본"}
          input_id="input-file-upload-rps"
          content={""}
        />
      </div>
    </section>
  );
};
