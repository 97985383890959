import {
  passwordLength,
  passwordNumber,
  passwordSpecial,
  passwordSame,
} from "./atoms/validation.atoms";
import { useRecoilValue } from "recoil";
import { useRef, useState } from "react";
import { SignupPresenter } from "./Signup.presenter";
// hooks
import { usePasswordValidation } from "./hooks/useValidation";
import { useGoLoginPage } from "./hooks/useGoLoginPage";
import {
  useEmailPass,
  usePhonePass,
  useNicknamePass,
} from "./hooks/useDuplicationPass";
import { IsignupInfo, IreceiveCheck } from "./types/state.type";
import {
  HandleEmail,
  HandleNickname,
  HandlePhoneNumber,
  HandleTelephoneNumber,
} from "./utils/SignFunc";

interface IserverError {
  phone: string[];
  telephone: string[];
}

interface Istate {
  input: IsignupInfo;
  benefit: boolean;
  receiveCheck: IreceiveCheck;
}

interface IapiResponse {
  serverError?: IserverError;
  signupStatus: string;
  emailDupSuccess: boolean;
  emailDupFail: boolean;
  emailDupLoading: boolean;
  phoneDupSuccess: boolean;
  phoneDupFail: boolean;
  phoneDupLoading: boolean;
  nicknameDupSuccess: boolean;
  nicknameDupFail: boolean;
  nicknameDupLoading: boolean;
}

interface Ifunc {
  setInput: (input: IsignupInfo) => void;
  setBenefit: (bool: boolean) => void;
  setReceiveCheck: (bool: IreceiveCheck) => void;
  submitPhoneDuplication: () => void;
  submitEmailDuplication: () => void;
  submitNicknameDuplication: () => void;
  SubmitSignup: () => void;
}

export const SignupModify = ({
  state,
  func,
  apiResponse,
}: {
  state: Istate;
  func: Ifunc;
  apiResponse: IapiResponse;
}) => {
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const passwordVerifyRef = useRef<HTMLInputElement>(null);
  const nicknameRef = useRef<HTMLInputElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);
  const telephoneRef = useRef<HTMLInputElement>(null);
  const companyRef = useRef<HTMLInputElement>(null);

  const cur_length = useRecoilValue(passwordLength);
  const cur_number = useRecoilValue(passwordNumber);
  const cur_special = useRecoilValue(passwordSpecial);
  const cur_same = useRecoilValue(passwordSame);

  // 비밀번호 유효성 검사
  const passwordValidation = usePasswordValidation(state.input);
  // 회원가입 성공 후 로그인 페이지 이동
  const goLoginPage = useGoLoginPage(apiResponse.signupStatus);

  const { emailDupStatus, setEmailDupStatus } = useEmailPass(
    apiResponse.emailDupSuccess,
    apiResponse.emailDupFail,
  );

  const { phoneDupStatus, setPhoneDupStatus } = usePhonePass(
    apiResponse.phoneDupSuccess,
    apiResponse.phoneDupFail,
  );

  const { nicknameDupStatus, setNicknameDupStatus } = useNicknamePass(
    apiResponse.nicknameDupSuccess,
    apiResponse.nicknameDupFail,
  );

  // input 유효성 state(border-red)
  const [emailCheck, setEmailCheck] = useState(false);
  const [passwordCheck, setPasswordCheck] = useState(false);
  const [passwordVerifyCheck, setPasswordVerifyCheck] = useState(false);
  const [nicknameCheck, setNicknameCheck] = useState(false);
  const [usernameCheck, setUsernameCheck] = useState(false);
  const [phoneNumberCheck, setPhoneNumberCheck] = useState(false);
  const [telephoneNumberCheck, setTelephoneNumberCheck] = useState(false);
  const [companyCheck, setCompanyCheck] = useState(false);

  // input 포커스 이벤트 함수
  const blurInput = (type: string) => {
    if (type === "email") {
      setEmailCheck(!HandleEmail(state.input.email));
      // 이메일 input 디폴트 초기화 상태 해체
      setEmailDupStatus({ ...emailDupStatus, init: true });
      // 이메일 중복 확인 실행 함수
      func.submitEmailDuplication();
    } else if (type === "password") {
      setPasswordCheck(!cur_length || !cur_number || !cur_special);
    } else if (type === "passwordVerify") {
      setPasswordVerifyCheck(!cur_same);
    } else if (type === "nickname") {
      setNicknameCheck(!HandleNickname(state.input.nickname));
      setNicknameDupStatus({ ...nicknameDupStatus, init: true });
      func.submitNicknameDuplication();
    } else if (type === "userName") {
      setUsernameCheck(state.input.userName.length <= 0);
    } else if (type === "phoneNumber") {
      setPhoneNumberCheck(!HandlePhoneNumber(state.input.phoneNumber));
      setPhoneDupStatus({ ...phoneDupStatus, init: true });
      func.submitPhoneDuplication();
    } else if (type === "telephoneNumber") {
      setTelephoneNumberCheck(
        state.input.telephoneNumber.length
          ? !HandleTelephoneNumber(state.input.telephoneNumber)
          : false,
      );
    } else {
      setCompanyCheck(state.input.company.length <= 0);
    }
  };

  // 마케팅 메인 체크박스 클릭 함수
  const handleToggleAll = () => {
    func.setBenefit(!state.benefit);

    return !state.benefit
      ? func.setReceiveCheck({
          email: true,
          kakao: true,
          sms: true,
        })
      : func.setReceiveCheck({
          email: false,
          kakao: false,
          sms: false,
        });
  };

  // 이벤트 체크박스 관리 기능
  const handleToggleSingle = (e: string) => {
    func.setReceiveCheck({
      ...state.receiveCheck,
      [e]: !state.receiveCheck[e],
    });

    if (!state.receiveCheck[e]) {
      func.setBenefit(true);
    } else if (handleCurrentToggle(e, !state.receiveCheck[e])) {
      func.setBenefit(false);
    }
  };

  const handleCurrentToggle = (type: string, bool: boolean) => {
    if (type === "email") {
      return !state.receiveCheck.sms && !state.receiveCheck.kakao && !bool;
    } else if (type === "sms") {
      return !state.receiveCheck.email && !state.receiveCheck.kakao && !bool;
    } else {
      return !state.receiveCheck.email && !state.receiveCheck.sms && !bool;
    }
  };

  const submitValidation = () => {
    inputTotalValidation();

    if (
      !state.input.email ||
      !HandleEmail(state.input.email) ||
      apiResponse.emailDupFail ||
      emailDupStatus.fail
    ) {
      return emailRef.current ? emailRef.current.focus() : null;
    } else if (!cur_length || !cur_number || !cur_special) {
      return passwordRef.current ? passwordRef.current.focus() : null;
    } else if (!cur_same) {
      return passwordVerifyRef.current
        ? passwordVerifyRef.current.focus()
        : null;
    } else if (
      !state.input.nickname ||
      apiResponse.nicknameDupFail ||
      nicknameDupStatus.fail ||
      !HandleNickname(state.input.nickname)
    ) {
      return nicknameRef.current ? nicknameRef.current.focus() : null;
    } else if (!state.input.userName) {
      return nameRef.current ? nameRef.current.focus() : null;
    } else if (
      !state.input.phoneNumber ||
      apiResponse.phoneDupFail ||
      phoneDupStatus.fail ||
      !HandlePhoneNumber(state.input.phoneNumber)
    ) {
      return phoneRef.current ? phoneRef.current.focus() : null;
    } else if (
      state.input.telephoneNumber.length > 0 &&
      !HandleTelephoneNumber(state.input.telephoneNumber)
    ) {
      return telephoneRef.current ? telephoneRef.current.focus() : null;
    } else if (!state.input.company) {
      return companyRef.current ? companyRef.current.focus() : null;
    } else if (!state.input.agreement) {
      return alert("이용약관 동의를 확인하여 체크 해주시길 바랍니다.");
    } else if (
      emailDupStatus.success &&
      nicknameDupStatus.success &&
      phoneDupStatus.success
    ) {
      func.SubmitSignup();
    }
  };

  const inputTotalValidation = () => {
    setEmailCheck(!HandleEmail(state.input.email));
    setPasswordCheck(!cur_length || !cur_number || !cur_special);
    setPasswordVerifyCheck(!cur_same);
    setNicknameCheck(!HandleNickname(state.input.nickname));
    setUsernameCheck(state.input.userName.length <= 0);
    setPhoneNumberCheck(!HandlePhoneNumber(state.input.phoneNumber));
    setTelephoneNumberCheck(
      state.input.telephoneNumber.length
        ? !HandleTelephoneNumber(state.input.telephoneNumber)
        : false,
    );
    setCompanyCheck(state.input.company.length <= 0);
  };

  return (
    <>
      <SignupPresenter
        state={{
          input: state.input,
          benefit: state.benefit,
          receiveCheck: state.receiveCheck,
          emailCheck: emailCheck,
          passwordCheck: passwordCheck,
          passwordVerifyCheck: passwordVerifyCheck,
          nicknameCheck: nicknameCheck,
          usernameCheck: usernameCheck,
          phoneNumberCheck: phoneNumberCheck,
          telephoneNumberCheck: telephoneNumberCheck,
          companyCheck: companyCheck,
        }}
        func={{
          setInput: func.setInput,
          submitValidation: submitValidation,
          blurInput: blurInput,
          handleToggleAll: handleToggleAll,
          handleToggleSingle: handleToggleSingle,
        }}
        apiResponse={{
          serverError: apiResponse.serverError,
          signupStatus: apiResponse.signupStatus,
          emailDupStatus: emailDupStatus,
          emailDupLoading: apiResponse.emailDupLoading,
          phoneDupStatus: phoneDupStatus,
          phoneDupLoading: apiResponse.phoneDupLoading,
          nicknameDupStatus: nicknameDupStatus,
          nicknameDupLoading: apiResponse.nicknameDupLoading,
        }}
        options={{
          emailRef: emailRef,
          passwordRef: passwordRef,
          passwordVerifyRef: passwordVerifyRef,
          nicknameRef: nicknameRef,
          nameRef: nameRef,
          phoneRef: phoneRef,
          telephoneRef: telephoneRef,
          companyRef: companyRef,
        }}
      />
    </>
  );
};
