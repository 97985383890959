import { DetailTalkModal } from "../../../components/Detail/Modal/DetailTalkModal";
// type
import { Italk } from "../types/state.type";
import { ItalkModalItem } from "../types/item.type";
import { useSubmitTalkSuccess } from "../hooks/useSubmitTalkSuccess";

type ImodalTalk = {
  open: boolean;
  type: string;
  userId: number;
  username: string;
};

interface Istate {
  modalTalk: ImodalTalk;
  talkText: Italk;
}

interface IapiResponse {
  success: boolean;
  loading: boolean;
  axiosCode: string;
  error: boolean;
  errorStatus: number;
  errorCode: string;
}

interface Ifunc {
  setModalTalk: (bool: ImodalTalk) => void;
  submitTalk: (type: string) => void;
  setTalkText: (talk: Italk) => void;
  setModalTalkSellList: (bool: boolean) => void;
  setModalTalkBuyList: (bool: boolean) => void;
}

interface Ioptions {
  sellerListCount: number;
  currentPagePositon: string;
}

export const DetailTalkModalModify = ({
  state,
  item,
  func,
  apiResponse,
  options,
}: {
  state: Istate;
  item: ItalkModalItem;
  func: Ifunc;
  apiResponse: IapiResponse;
  options: Ioptions;
}) => {
  // 모달 문의하기 내용 작성
  const onChangeTalk = (text: string) => {
    func.setTalkText({ ...state.talkText, content: text });
  };

  // 모달 닫기 함수
  const modalClose = () => {
    func.setModalTalk({ open: false, type: "", userId: 0, username: "" });
    func.setTalkText({ plant: 0, content: "" });
  };

  const submitTalkSuccess = useSubmitTalkSuccess(
    {
      success: apiResponse.success,
      modalTalk: state.modalTalk,
      owner: item.isOwner,
      talkText: state.talkText.plant,
    },
    func.setModalTalk,
  );

  return (
    <>
      <DetailTalkModal
        state={{
          modalTalk: state.modalTalk,
          talkText: state.talkText,
        }}
        item={{ ...item }}
        func={{
          modalClose: modalClose,
          submitTalk: func.submitTalk,
          onChangeTalk: onChangeTalk,
          setTalkText: func.setTalkText,
          setModalTalkSellList: func.setModalTalkSellList,
          setModalTalkBuyList: func.setModalTalkBuyList,
        }}
        options={{
          sellerListCount: options.sellerListCount,
          currentPagePositon: options.currentPagePositon,
        }}
        apiResponse={{ ...apiResponse }}
      />
    </>
  );
};
