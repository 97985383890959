import { MypageRegistration } from "../../../components/Mypage/MypageMyPlant/Registration/MypageRegistration";
import { Ifile, IplantInputInfo } from "../types/state.type";

interface Istate {
  plantInputInfo: IplantInputInfo;
  businessFile: Ifile;
  actionFile: Ifile;
}

interface Ifunc {
  setPlantInputInfo: (info: IplantInputInfo) => void;
  setBusinessFile: (file: Ifile) => void;
  setActionFile: (file: Ifile) => void;
}

export const MypageRegistrationModify = ({
  state,
  func,
}: {
  state: Istate;
  func: Ifunc;
}) => {
  const handleInput = (value: string, type: string) => {
    func.setPlantInputInfo({ ...state.plantInputInfo, [type]: value });
  };

  return (
    <MypageRegistration
      state={{
        plantInputInfo: state.plantInputInfo,
        businessFile: state.businessFile,
        actionFile: state.actionFile,
      }}
      func={{
        setPlantInputInfo: func.setPlantInputInfo,
        setBusinessFile: func.setBusinessFile,
        setActionFile: func.setActionFile,
        handleInput: handleInput,
      }}
    />
  );
};
