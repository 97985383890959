import { useNavigate } from "react-router-dom";
import { capacityUnit } from "../../../../utils_public/Unit";

import { IplantList } from "../../../../pages/Main/types/plantList.type";
import { nullToEmptyString } from "../../../../utils_public/nullToEmptyString";

export const MypageMyPlantSearchListItem = ({
  plantList,
}: {
  plantList: IplantList[];
}) => {
  const navigate = useNavigate();
  return (
    <>
      {
        <ul className="flex flex-col gap-y-[28px]  overflow-y-scroll px-[20px]">
          {!!plantList.length ? (
            plantList.map((el: IplantList, idx) => (
              <li key={idx} className="flex mt-[16px] items-center">
                <div className="max-w-[300px] w-full h-[20px] font-bold whitespace-nowrap overflow-hidden text-ellipsis pr-[24px]">
                  <span
                    className="max-w-[250px] w-full cursor-pointer border-b-[1px] border-b-text-color-primary hover:border-b-[1px] hover:border-b-text-color-primary "
                    onClick={() => navigate(`/detail/${el.id}`)}
                  >
                    {el.plant_name}
                  </span>
                </div>
                <div className="max-w-[200px] w-full">
                  <span>
                    {`${nullToEmptyString(el.addr_lv1)} 
                      ${nullToEmptyString(el.addr_lv2)} 
                      ${nullToEmptyString(el.addr_lv3)}`}
                  </span>
                </div>
                <div className="max-w-[140px] w-full text-main-color-primary">
                  <span>{capacityUnit(el.capacity)}</span>
                </div>
                <div
                  className="max-w-[60px] w-full bg-main-color-primary text-general-white py-[4px] rounded-lg text-center text-footer cursor-pointer"
                  onClick={() => navigate("submit")}
                >
                  선택하기
                </div>
              </li>
            ))
          ) : (
            <li className="text-center text-listTitle mt-[32px]">
              검색된 발전소 결과가 없습니다.
              <div className="mt-[8px]">
                신규 발전소라면
                <span
                  className="text-main-color-primary border-b-[1px] border-b-main-color-primary px-[4px] cursor-pointer"
                  onClick={() => navigate("/mypage/myPlant/registration")}
                >
                  직접 등록
                </span>
                해 보세요!
              </div>
            </li>
          )}
        </ul>
      }
    </>
  );
};
