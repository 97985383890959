import classNames from "classnames";

export const MypageDocuments = ({
  fileName,
  title,
}: {
  fileName: string;
  title: string;
}) => {
  const LIMIT_STRING = fileName.length >= 15;

  return (
    <section className="mt-[62px]">
      <div className="flex flex-col gap-y-4 mb-[62px]">
        <h1 className="text-listFirst font-bold">{title}</h1>
        <div className="h-[23px]">
          {LIMIT_STRING ? (
            <input
              className={`max-w-[150px] overflow-hidden scrollbar-hide ${FILE_COMMON}`}
              value={fileName}
              disabled
            />
          ) : (
            <span className={`w-full ${FILE_COMMON}`}>{fileName}</span>
          )}
        </div>
      </div>
    </section>
  );
};

const FILE_COMMON =
  "px-[16px] py-[3px] text-footer text-text-color-secondary border-[1px] border-border-color-dividers !outline-none cursor-default rounded-2xl";
