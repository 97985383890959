import { useEffect } from "react";

interface Ivalue {
  status: string;
  favoriteResponse: boolean;
}

export const useEarlyFavorite = (
  value: Ivalue,
  setFavoriteIcon: (bool: boolean) => void,
) => {
  useEffect(() => {
    if (value.status === "success") setFavoriteIcon(value.favoriteResponse);
  }, [value.status]);
};
