import { useState } from "react";
import { MypagePlanBuyMenu } from "./MypagePlanBuyMenu";
import { DetailTalkModalFetch } from "../../../../pages/Detail/module_fetch/DetailTalkModal.fetch";
import { Ibuylist } from "../../../../pages/Mypage/types/item.type";
import { DefaultLoading } from "../../../UI/Loading/Loading";
import { MypagePlantBuyItemModify } from "../../../../pages/Mypage/module_modify/MypagePlantBuyItem.modify";
import { MyPageRecordBuySellFetch } from "../../../../pages/Mypage/module_fetch/MyPageRecordBuySell.fetch";
import { priceBoxType } from "../../../../pages/Mypage/types/state.type";

interface Istate {
  selectPlantId: number;
}

interface Item {
  buyList: Ibuylist[];
}

interface IapiResponse {
  apiStatus: string;
  submitPlantId: number;
  submitSuccess: boolean;
  submitLoading: boolean;
  axiosCode: string;
  error: boolean;
  errorStatus: number;
}

interface Ifunc {
  setSelectPlantId: (num: number) => void;
  submitPrice: (num: number, price: number | string) => void;
}

export const MypagePlantBuy = ({
  state,
  item,
  apiResponse,
  func,
}: {
  state: Istate;
  item: Item;
  apiResponse: IapiResponse;
  func: Ifunc;
}) => {
  const [modalTalk, setModalTalk] = useState({
    type: "seller",
    open: false,
    userId: 0,
    username: "",
  });

  const [modalPlantList, setModalPlantList] = useState({
    type: "seller",
    open: false,
    userId: 0,
    username: "",
  });

  const [selectedPriceHistory, setSelectedPriceHistory] =
    useState<priceBoxType>({
      type: "buyer",
      plantId: 0,
    });

  const [currentMessagePlantId, setCurrentMessagePlantId] = useState(0);

  return (
    <section>
      <div className="flex items-center mb-4 gap-x-[20px]">
        <h1 className="text-listFirst font-bold max-sm:font-medium">
          구매 관리
        </h1>
        <p className="text-general-red">
          주의! 소유주 인증이 되지 않은 판매자와의 거래에 유의하시기 바랍니다.
        </p>
      </div>
      <MypagePlanBuyMenu />
      {apiResponse.apiStatus === "loading" ? (
        <div className="flex h-[100px] mt-10">
          <DefaultLoading />
        </div>
      ) : (
        <ul className="h-[600px] overflow-y-scroll">
          {item.buyList.length > 0 ? (
            item.buyList.map((el, idx) => (
              <MypagePlantBuyItemModify
                key={idx}
                item={{ ...el }}
                state={{
                  selectPlantId: state.selectPlantId,
                  selectedPriceHistory: selectedPriceHistory,
                }}
                func={{
                  setSelectPlantId: func.setSelectPlantId,
                  submitPrice: func.submitPrice,
                  setModalTalk: setModalTalk,
                  setCurrentMessagePlantId: setCurrentMessagePlantId,
                  setModalPlantList: setModalPlantList,
                  setSelectedPriceHistory: setSelectedPriceHistory,
                }}
                apiResponse={{
                  submitPlantId: apiResponse.submitPlantId,
                  submitSuccess: apiResponse.submitSuccess,
                  submitLoading: apiResponse.submitLoading,
                  axiosCode: apiResponse.axiosCode,
                  error: apiResponse.error,
                  errorStatus: apiResponse.errorStatus,
                }}
                options={{
                  id: idx,
                  length: item.buyList.length,
                }}
              />
            ))
          ) : (
            <div className="text-center mt-[30px] text-listTitle">
              구매 리스트가 비어있는 상태입니다.
            </div>
          )}
        </ul>
      )}

      {modalTalk.open && (
        <DetailTalkModalFetch
          state={{
            modalTalk: modalTalk,
          }}
          item={{
            plantId: item.buyList[currentMessagePlantId].plant_id,
            plantName: item.buyList[currentMessagePlantId].plant_name,
            isOwner: true,
            landName: item.buyList[currentMessagePlantId].plant_land_name,
            capacity: item.buyList[currentMessagePlantId].plant_capacity,
            addr_lv1: item.buyList[currentMessagePlantId].plant_addr_lv1,
            addr_lv2: item.buyList[currentMessagePlantId].plant_addr_lv2,
            addr_lv3: item.buyList[currentMessagePlantId].plant_addr_lv3,
          }}
          func={{
            setModalTalk: setModalTalk,
            setModalTalkSellList: () => {},
            setModalTalkBuyList: () => {},
          }}
          options={{
            sellerListCount: 1,
            currentPagePositon: "mypage",
          }}
        />
      )}
      {modalPlantList.open && (
        <MyPageRecordBuySellFetch
          modalPlantList={modalPlantList}
          setModalPlantList={setModalPlantList}
        />
      )}
    </section>
  );
};
