import { useCookies } from "react-cookie";
import { useAddFavorite } from "../../Detail/query/useChangeFavoriteQuery";
import { useRemoveFavorite } from "../../Detail/query/useChangeFavoriteQuery";
import { PlantListItemModify } from "../module_modify/PlantListItem.modify";
import { IplantList } from "../types/plantList.type";
import { useFavorite } from "../hooks/useFavorite";

type ImodalSellBuy = {
  open: boolean;
  type: string;
  plantId: number;
};

interface Ifunc {
  setModalFavorite: (bool: boolean) => void;
  setModalSellBuy: (object: ImodalSellBuy) => void;
  setBlurModal: (bool: boolean) => void;
}

export const PlantListItemFetch = ({
  item,
  func,
}: {
  item: IplantList;
  func: Ifunc;
}) => {
  const [cookie] = useCookies(["60hz-user"]);

  // React-Query 찜하기 추가 요청
  const POST_FAVORITE = useAddFavorite();

  // React-Query 찜하기 제거 요청
  const DELETE_FAVORITE = useRemoveFavorite();

  // Heart 아이콘 state
  const { favorite, setFavorite } = useFavorite(item.is_like);

  const likeSubmitFromServer = () => {
    setFavorite(!favorite);
    return !favorite
      ? POST_FAVORITE.mutate({
          apiName: "Favorite Add",
          url: `api/like/${item.id}`,
          token: cookie["60hz-user"],
        })
      : DELETE_FAVORITE.mutate({
          apiName: "Favorite Remove",
          url: `api/like/${item.id}`,
          token: cookie["60hz-user"],
        });
  };

  return (
    <PlantListItemModify
      item={item}
      state={{
        favorite: favorite,
      }}
      func={{
        likeSubmitFromServer: likeSubmitFromServer,
        setFavorite: setFavorite,
        setModalFavorite: func.setModalFavorite,
        setModalSellBuy: func.setModalSellBuy,
        setBlurModal: func.setBlurModal,
      }}
      apiResponse={{
        submitError:
          POST_FAVORITE.error &&
          POST_FAVORITE.error.response &&
          POST_FAVORITE.error.response.status,
      }}
    />
  );
};
