import { useState } from "react";
import { Toggle } from "../../UI/ToggleBtn/Toggle";
import { MypageUserCheckBox } from "./MypageUserCheckBox";
import { IreceiveCheck } from "../../../pages/SignUp/types/state.type";

interface Istate {
  toggle: boolean;
  receiveCheck: IreceiveCheck;
}

interface Ifunc {
  handleToggleSingle: (value: string) => void;
  handleToggleAll: () => void;
}

export const MypageUserBenefit = ({
  state,
  func,
}: {
  state: Istate;
  func: Ifunc;
}) => {
  return (
    <>
      <div className="max-w-[660px] w-full flex items-center mb-[16px] justify-between max-md:mt-[52px] max-md:mb-[24px] max-md:max-w-[523px]">
        <div className="flex items-center">
          <p className="text-listFirst font-bold">혜택 알림 받기</p>
          <span className="ml-[6px] text-smallExplanation text-text-color-secondary">
            선택
          </span>
        </div>
        <div>
          <Toggle
            state={{
              toggle: state.toggle,
            }}
            func={{
              handleToggleAll: func.handleToggleAll,
            }}
          />
        </div>
      </div>
      <article className="max-sm:text-default">
        <p className="mb-[6px]">
          신규기능 업데이트 등 꼭 필요한 정보만 알려드릴게요.
        </p>
        <a
          className="pb-1 border-b-[1px] cursor-pointer"
          target="_blank"
          href="/privacy/marketing"
        >
          마케팅수신 동의 약관
        </a>
      </article>
      <MypageUserCheckBox
        state={{
          receiveCheck: state.receiveCheck,
        }}
        func={{
          handleToggleSingle: func.handleToggleSingle,
        }}
      />
    </>
  );
};
