import { useNavigate } from "react-router-dom";
import { QnAInquiryCurrent } from "./QnAInquiryCurrent";
import { QnAInquiryListBox } from "./QnAInquiryListBox";
import { QnAInquiryText } from "./QnAInquiryText";

interface Ifunc {
  setToggle: (toggle: boolean) => void;
  setSelectInquiryList: (text: string) => void;
  setInquiryText: (text: string) => void;
  clickInquiryListItem: (selected: string) => void;
  submitInquiry: () => void;
}

interface Istate {
  toggle: boolean;
  inquiryText: string;
  selectInquiryList: string;
}

interface IapiResponse {
  success: boolean;
  loading: boolean;
  axiosCode: string;
  error: boolean;
  errorStatus: number;
}

export const QnAInquiry = ({
  state,
  func,
  apiResponse,
}: {
  state: Istate;
  func: Ifunc;
  apiResponse: IapiResponse;
}) => {
  const navigate = useNavigate();
  return apiResponse.success ? (
    <section className="max-w-[1630px] w-full h-[500px] mx-auto flex flex-col justify-center items-center px-[50px] max-md:px-[24px]">
      <div className="flex flex-col items-center">
        <img className="w-[130px]" src="/icon_remodel/inquiry_send.svg" />
        <h1 className="text-listTitle mt-[15px]">
          문의 내용을 관리자에게 전달 되었습니다.
        </h1>
      </div>
      <article className="text-text-color-secondary mt-[15px] text-center mb-[20px]">
        <p>
          작성하신 문의 내용은 이메일 혹은 마이페이지 문의 내역에 확인하실 수
          있습니다.
        </p>
      </article>
      <button
        className="w-[170px] py-[10px] bg-text-color-primary text-general-white rounded-md"
        onClick={() => navigate("/mypage/inquiry")}
      >
        문의 내역 확인하기
      </button>
    </section>
  ) : (
    <section className="max-w-[1630px] w-full mx-auto px-[50px] mt-[50px] max-md:px-[24px]">
      <div className="flex gap-x-[80px] max-md:flex-col max-md:gap-y-[30px]">
        <h1 className="text-listFirst">유형 선택</h1>
        <div className="relative">
          <div className="absolute border-[1px] text-default border-border-color-dividers rounded-lg">
            <QnAInquiryCurrent
              state={{
                toggle: state.toggle,
                selectInquiryList: state.selectInquiryList,
              }}
              func={{ setToggle: func.setToggle }}
            />
            <QnAInquiryListBox
              state={{ toggle: state.toggle }}
              func={{ clickInquiryListItem: func.clickInquiryListItem }}
            />
          </div>
        </div>
      </div>

      <QnAInquiryText
        state={{
          inquiryText: state.inquiryText,
          selectInquiryList: state.selectInquiryList,
        }}
        func={{
          setInquiryText: func.setInquiryText,
          submitInquiry: func.submitInquiry,
        }}
        apiResponse={{
          ...apiResponse,
        }}
      />
    </section>
  );
};
