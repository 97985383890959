import axios from "axios";

export async function axiosSendMessage(queryKey: any) {
  const response = await axios.post(
    process.env.REACT_APP_SERVER_URL + queryKey.url,
    {
      content: queryKey.content,
    },
    {
      withCredentials: true,
      headers: {
        Authorization: "Token " + queryKey.token,
      },
    },
  );

  return response.data;
}
