import { ChangeEvent } from "react";
import { passwordStateCheck } from "../../../pages/SignUp/utils/passwordStateCheck";

import { IsignupInfo, Ipass } from "../../../pages/SignUp/types/state.type";
import {
  HandleHyphen,
  HandleOnlyTextInpt,
} from "../../../pages/SignUp/utils/SignFunc";
import { SubmitLoading } from "../Loading/Loading";
import classNames from "classnames";

type passwordValidationType = {
  type: string;
  text: string;
};

interface signatureNumber {
  [key: string]: number;
}

interface IserverError {
  phone: string[];
  telephone: string[];
}

interface Istate {
  input: IsignupInfo;
  emailCheck: boolean;
  passwordCheck: boolean;
  passwordVerifyCheck: boolean;
  nicknameCheck: boolean;
  usernameCheck: boolean;
  phoneNumberCheck: boolean;
  telephoneNumberCheck: boolean;
  companyCheck: boolean;
}

interface Ifunc {
  setInput: (input: IsignupInfo) => void;
  blurInput: (type: string) => void;
}

interface IapiResponse {
  serverError?: IserverError;
  emailDupStatus: Ipass;
  emailDupLoading: boolean;
  phoneDupStatus: Ipass;
  phoneDupLoading: boolean;
  nicknameDupStatus: Ipass;
  nicknameDupLoading: boolean;
}

interface Ioptions {
  ref: React.RefObject<HTMLInputElement>;
}

export const SignupInput = ({
  name,
  inputInfo,
  type,
  placeHolder,
  passwordValidation,
  state,
  func,
  apiResponse,
  options,
}: {
  name: string;
  inputInfo: string;
  type: string;
  placeHolder: string;
  passwordValidation?: passwordValidationType[];
  state: Istate;
  func: Ifunc;
  apiResponse: IapiResponse;
  options: Ioptions;
}) => {
  // input 텍스트 최대 길이
  const MAX_LENGTH_TEXT: signatureNumber = {
    userName: 20,
    nickname: 8,
    phoneNumber: 13,
    telephoneNumber: 12,
    company: 20,
  };

  return (
    <>
      <div
        className={`${
          inputInfo === "email" ||
          inputInfo === "phoneNumber" ||
          inputInfo === "telephoneNumber" ||
          inputInfo === "nickname"
            ? "h-[75px] max-md:h-[95px] max-md:mb-[10px]"
            : "mb-[18px] max-md:h-[95px] "
        }`}
      >
        <div className="flex items-center max-md:flex-col max-md:items-start max-md:gap-y-[16px]">
          <span className="min-w-[90px] text-default relative max-md:min-w-[80px]">
            {name}
            {inputInfo === "phoneNumber" && (
              <div className="group pl-[6px] cursor-pointer mb-[1px] absolute top-[1px] right-[-7px]">
                <img src="/icon_remodel/information.svg" />
                <p
                  className="w-[300px] px-[16px] py-[10px] bg-main-color-sub absolute left-[-15px] top-7 hidden text-footer rounded-lg group-hover:block
            before:content-[''] before:absolute before:w-0 before:h-0 before:border-[8px] before:border-solid before:border-[transparent]
          before:border-b-main-color-sub before:top-[-15px] before:left-[21px] z-50 leading-[21px] shadow-xl"
                >
                  발전소 팔기/사기를 위한 필요한 정보수집을 위해, <br />
                  가급적 본인번호을 기입해주시길 바랍니다.
                </p>
              </div>
            )}
          </span>
          <div className="w-full ml-16 mr-4 relative max-md:ml-0">
            <input
              ref={options.ref}
              className={classNames(
                "max-w-[523px] w-full h-[43px] px-4 !outline-none border-[1px] border-border-color-dividers rounded-lg max-md:max-w-full",

                {
                  "border-general-red":
                    inputInfo === "email" &&
                    (state.emailCheck || apiResponse.emailDupStatus.fail),
                },
                {
                  "border-general-red":
                    inputInfo === "password" && state.passwordCheck,
                },
                {
                  "border-general-red":
                    inputInfo === "passwordVerify" && state.passwordVerifyCheck,
                },
                {
                  "border-general-red":
                    inputInfo === "nickname" &&
                    (state.nicknameCheck || apiResponse.nicknameDupStatus.fail),
                },
                {
                  "border-general-red":
                    inputInfo === "nickname" && state.nicknameCheck,
                },
                {
                  "border-general-red":
                    inputInfo === "userName" && state.usernameCheck,
                },
                {
                  "border-general-red":
                    inputInfo === "phoneNumber" &&
                    (state.phoneNumberCheck || apiResponse.phoneDupStatus.fail),
                },
                {
                  "border-general-red":
                    inputInfo === "telephoneNumber" &&
                    state.telephoneNumberCheck,
                },
                {
                  "border-general-red":
                    inputInfo === "company" && state.companyCheck,
                },
              )}
              type={type}
              placeholder={placeHolder}
              maxLength={MAX_LENGTH_TEXT[inputInfo]}
              autoComplete={"new-password"}
              autoCapitalize={"off"}
              onBlur={() => func.blurInput(inputInfo)}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                inputInfo === "phoneNumber" || inputInfo === "telephoneNumber"
                  ? HandleHyphen(e)
                  : inputInfo === "userName"
                  ? HandleOnlyTextInpt(e)
                  : null
              }
              onChange={(value: ChangeEvent<HTMLInputElement>) =>
                func.setInput({
                  ...state.input,
                  [inputInfo]: value.target.value,
                })
              }
            />
            {/* 이메일 중복 비동기 로딩 애니메이션 */}
            {inputInfo === "email" && apiResponse.emailDupLoading && (
              <div className="absolute top-[10px] right-5">
                <SubmitLoading width={23} height={23} />
              </div>
            )}
            {/* 휴대폰번호 중복 비동기 로딩 애니메이션 */}
            {inputInfo === "phoneNumber" && apiResponse.phoneDupLoading && (
              <div className="absolute top-[10px] right-5">
                <SubmitLoading width={23} height={23} />
              </div>
            )}
            {/* 휴대폰번호 중복 비동기 로딩 애니메이션 */}
            {inputInfo === "nickname" && apiResponse.nicknameDupLoading && (
              <div className="absolute top-[10px] right-5">
                <SubmitLoading width={23} height={23} />
              </div>
            )}
          </div>
        </div>

        {/* 유효성 및 중복 이메일 체크 */}
        {inputInfo === "email" &&
          (apiResponse.emailDupStatus.init && state.emailCheck ? (
            <h1 className="mt-[6px] ml-[155px] text-smallExplanation text-general-red max-md:ml-[10px]">
              이메일 형식이 올바르지 않습니다.
            </h1>
          ) : (
            (apiResponse.emailDupStatus.success && (
              <h1 className="mt-[6px] ml-[155px] text-smallExplanation text-main-color-primary max-md:ml-[10px]">
                사용 가능한 이메일입니다.
              </h1>
            )) ||
            (apiResponse.emailDupStatus.fail && (
              <h1 className="mt-[6px] ml-[155px] text-smallExplanation text-general-red max-md:ml-[10px] ">
                중복된 이메일입니다.
              </h1>
            ))
          ))}

        {/* 유효성 및 중복 휴대폰 번호 체크 */}
        {inputInfo === "phoneNumber" &&
          (apiResponse.serverError?.phone ? (
            <h1 className="mt-[6px] ml-[155px] text-smallExplanation text-general-red max-md:ml-[10px]">
              등록할 수 없는 휴대폰 번호 형식입니다.
            </h1>
          ) : apiResponse.phoneDupStatus.init && state.phoneNumberCheck ? (
            <h1 className="mt-[6px] ml-[155px] text-smallExplanation text-general-red max-md:ml-[10px]">
              휴대폰 번호 형식이 올바르지 않습니다.
            </h1>
          ) : (
            (apiResponse.phoneDupStatus.success && (
              <h1 className="mt-[6px] ml-[155px] text-smallExplanation text-main-color-primary max-md:ml-[10px]">
                사용 가능한 휴대폰 번호입니다.
              </h1>
            )) ||
            (apiResponse.phoneDupStatus.fail && (
              <h1 className="mt-[6px] ml-[155px] text-smallExplanation text-general-red max-md:ml-[10px]">
                중복된 휴대폰 번호입니다.
              </h1>
            ))
          ))}

        {/* 유효성 및 중복 닉네임 체크 */}
        {inputInfo === "nickname" &&
          (apiResponse.nicknameDupStatus.init && state.nicknameCheck ? (
            <h1 className="mt-[6px] ml-[155px] text-smallExplanation text-general-red max-md:ml-[10px]">
              닉네임 형식이 올바르지 않습니다. (공백, 특수문자 및 이모티콘 사용
              불가)
            </h1>
          ) : (
            (apiResponse.nicknameDupStatus.success && (
              <h1 className="mt-[6px] ml-[155px] text-smallExplanation text-main-color-primary max-md:ml-[10px]">
                사용 가능한 닉네임입니다.
              </h1>
            )) ||
            (apiResponse.nicknameDupStatus.fail && (
              <h1 className="mt-[6px] ml-[155px] text-smallExplanation text-general-red max-md:ml-[10px]">
                중복된 닉네임입니다.
              </h1>
            ))
          ))}

        {/* 유효성 전화번호 체크 */}
        {state.input.telephoneNumber.length > 0 &&
          inputInfo === "telephoneNumber" &&
          (apiResponse.serverError?.telephone ? (
            <h1 className="mt-[6px] ml-[155px] text-smallExplanation text-general-red max-md:ml-[10px]">
              등록할 수 없는 전화 번호 형식입니다.
            </h1>
          ) : (
            state.telephoneNumberCheck && (
              <h1 className="mt-[6px] ml-[155px] text-smallExplanation text-general-red max-md:ml-[10px]">
                전화번호 형식이 올바르지 않습니다.
              </h1>
            )
          ))}

        {passwordValidation && (
          <div className="flex mt-[6px] ml-[155px] max-md:ml-[10px]">
            {passwordValidation.map((el, idx) => (
              <span
                className={`flex text-smallExplanation ${
                  passwordStateCheck(el.type)
                    ? "text-main-color-primary"
                    : "text-general-red"
                }`}
                key={idx}
              >
                {el.text}
                <img
                  className="ml-[6px] mr-[17px] w-[10px]"
                  src={`${
                    passwordStateCheck(el.type)
                      ? "/icon_remodel/check_blue.svg"
                      : "/icon_remodel/check_red.svg"
                  }`}
                />
              </span>
            ))}
          </div>
        )}
      </div>
    </>
  );
};
