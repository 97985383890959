import { HandleComma } from "../../../utils_public/Comma";
import { moneyUnit, moneyUnit_Pure } from "../../../utils_public/Unit";

interface IdetailWorthTop {
  landSize: string | number;
  tradeInfoArea: number;
  tradeInforPrice: number;
}

export const DetailWorthTop = ({
  landSize,
  tradeInfoArea,
  tradeInforPrice,
}: IdetailWorthTop) => {
  // 토지평당가
  const landFlatlandPrice: number =
    tradeInforPrice / (tradeInfoArea / 3.305785);

  return (
    <div className="flex mb-10">
      <div className="flex flex-col items-center">
        <div className="w-[130px] max-sm:w-[118px]">
          <h1 className="text-subValue mb-[6px] max-md:text-listTitle">
            {moneyUnit(landFlatlandPrice)}
          </h1>
          <div className="flex items-center max-md:text-default">
            <span>토지평당가</span>
            <div className="group relative pl-[6px] cursor-pointer mb-[1px]">
              <img src="/icon_remodel/information.svg" />

              <p
                className="w-[279px] px-[16px] py-[10px] bg-main-color-sub absolute left-[-15px] top-7 hidden text-footer rounded-lg group-hover:block
              before:content-[''] before:absolute before:w-0 before:h-0 before:border-[8px] before:border-solid before:border-[transparent]
              before:border-b-main-color-sub before:top-[-15px] before:left-[21px] z-50 leading-[21px] shadow-xl max-md:text-[10px] max-md:w-[225px]
            "
              >
                ﹣평당가는 공공데이터 포털에서 제공하고 있는
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;토지 매매 정보를기반으로 제공하고
                있습니다.
                <br />
                ﹣평당가는 아래의 기준으로 표기하고 있습니다. <br />
                &nbsp;&nbsp;&nbsp;&nbsp;매매평당가: 매매가/(공급면적/3.305785m
                <sup>2</sup>) <br />
                ﹣금액 단위 이하 절사한 수치입니다.
              </p>
            </div>
          </div>
          <p className="mt-[6px] mb-[4px] text-smallExplanation text-text-color-secondary max-md:text-[11px]">
            출처: 국토교통부
          </p>
          <p className="text-smallExplanation text-text-color-secondary max-md:text-[11px]">
            최근 업데이트: 2023
          </p>
        </div>
      </div>
      <div className="text-[30px] ml-[44px] mr-[78px] mt-1 max-md:ml-[13px] max-md:mr-[30px] max-md:text-listTitle max-sm:ml-[7px] max-sm:mr-[25px]">
        ×
      </div>
      <div className="flex flex-col items-center">
        <div>
          <h1 className="text-subValue mb-[6px] max-md:text-listTitle">
            {landSize !== "-"
              ? HandleComma((landSize as number) * 0.3025)
              : "-"}{" "}
            평
          </h1>
          <span className="max-md:text-default">토지면적</span>
        </div>
      </div>
      <div className="text-[30px] mx-[78px] mt-[4px] max-md:mx-[28px] max-md:text-listTitle max-sm:ml-[20px] max-sm:mr-[25px]">
        =
      </div>
      <div>
        <h1 className="text-subValue mb-[6px] max-md:text-listTitle">
          {typeof landFlatlandPrice === "number" && typeof landSize === "number"
            ? moneyUnit(moneyUnit_Pure(landFlatlandPrice) * (landSize * 0.3025))
            : "-"}
        </h1>
        <span className="max-md:text-default">토지 가격</span>
      </div>
    </div>
  );
};
