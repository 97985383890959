import { IimageFile } from "../../../pages/Mypage/types/state.type";
import { DragDropMuchFileContainer } from "../../UI/DragAndDrop/DragDropMuchFile";

interface Istate {
  imageFile: IimageFile;
}

interface Ifunc {
  setImageFile: (file: IimageFile) => void;
}

interface Iitem {
  currntIdx: number;
}

export const MypagePlantImage = ({
  state,
  func,
  item,
}: {
  state: Istate;
  func: Ifunc;
  item: Iitem;
}) => {
  return (
    <section>
      <div className="flex gap-x-1 items-center mb-4">
        <h1 className="text-listFirst font-bold">발전소 사진</h1>
        <span className="font-normal">(최대 5장)</span>
      </div>
      <DragDropMuchFileContainer
        file={state.imageFile}
        setFile={func.setImageFile}
        title={"발전소 사진"}
        input_id={"input-file-upload-plantImg"}
        currntIdx={item.currntIdx}
      />
    </section>
  );
};
