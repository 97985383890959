import { useEffect } from "react";
import { emailCheck } from "../atoms/validation.atom";
import { IloginInput } from "../types/loginInput.type";
import { useSetRecoilState } from "recoil";
export const useEmailValidation = (input: IloginInput) => {
  const setValidation = useSetRecoilState(emailCheck);

  useEffect(() => {
    if (EMAIL_TEST.test(input.email)) {
      setValidation(true);
    } else {
      setValidation(false);
    }
  }, [input.email]);
};

const EMAIL_TEST = /^[a-z0-9A-Z_+.-]+@([a-z0-9A-Z]+\.)+[a-z0-9]{2,3}$/;
