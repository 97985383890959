import { SUBMIT_LOGIN_ERROR_TYPE } from "../../pages/Login/error/error.object";
import { ErrorHandling } from "../../utils_public/ErrorHandling";

import { LoginInput } from "../UI/Input_Text/LoginInput";

interface IapiResponse {
  axiosCode: string;
  error: boolean;
  errorStatus: number;
}

interface Ifunc {
  changeLoginInput: (type: string, text: string) => void;
  setCurrentModal: (value: string) => void;
}

export const LoginInputBox = ({
  apiResponse,
  func,
}: {
  apiResponse: IapiResponse;
  func: Ifunc;
}) => {
  return (
    <div className="max-w-[432px] w-full">
      <div className="flex flex-col gap-y-3 mb-3 justify-center max-sm:mb-[10px]">
        <LoginInput
          input_type="email"
          placeHolder="이메일을 입력하세요"
          changeLoginInput={func.changeLoginInput}
          status={status}
        />
        <LoginInput
          input_type="password"
          placeHolder="비밀번호를 입력하세요"
          changeLoginInput={func.changeLoginInput}
          status={status}
        />
      </div>
      <div className="flex justify-between items-center mb-[23px] max-sm:gap-y-[8px] max-sm:flex-col max-sm:mb-[28px]">
        <div className="h-[20px] text-smallExplanation text-general-red flex items-center">
          {ErrorHandling(
            {
              errorType: SUBMIT_LOGIN_ERROR_TYPE,
              errorCode: apiResponse.axiosCode,
              errorStatus: apiResponse.errorStatus,
              isError: apiResponse.error,
            },
            "",
          )}
        </div>
        <div className="flex">
          <span
            className="text-text-color-secondary text-default cursor-pointer whitespace-pre"
            onClick={() => func.setCurrentModal("email")}
          >
            이메일 찾기
          </span>
          <span className="px-3 text-text-color-secondary text-default max-md:px-2">
            |
          </span>
          <span
            className="text-text-color-secondary text-default cursor-pointer whitespace-pre"
            onClick={() => func.setCurrentModal("password")}
          >
            비밀번호 찾기
          </span>
        </div>
      </div>
    </div>
  );
};
