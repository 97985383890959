import classNames from "classnames";
import { SUBMIT_USER_PASSWORD_CHECK_ERROR_TYPE } from "../../../pages/Mypage/error/error.object";
import { errorHandling } from "../../../utils_public/ErrorHandle";
import { ErrorHandling } from "../../../utils_public/ErrorHandling";
import { DefaultLoading } from "../../UI/Loading/Loading";

interface Istate {
  password: string;
}

interface Ifunc {
  submitCheckPassword: () => void;
  setPassword: (password: string) => void;
}

interface IapiResponse {
  success: boolean;
  loading: boolean;
  axiosCode: string;
  error: boolean;
  errorStatus: number;
}

export const MypageUserCheck = ({
  state,
  func,
  apiResponse,
}: {
  state: Istate;
  func: Ifunc;
  apiResponse: IapiResponse;
}) => {
  return (
    <div className="max-xl:h-[150px]">
      <p className="mb-4 max-sm:text-default">
        회원님의 개인정보 보호를 위한 본인 확인 절차인 관계로,
        <br className="sm:hidden" /> 비밀번호를 입력해주시기 바랍니다.
      </p>
      <form
        className="max-w-[556px] w-full h-[43px] flex gap-x-2"
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
          e.preventDefault();
        }}
      >
        <div className="max-w-[432px] w-full relative">
          <input
            type="password"
            placeholder="비밀번호를 입력해주세요"
            className={`${classNames(
              "w-full h-full px-4 !outline-none border-[1px] rounded-lg",
              { "border-border-color-dividers": !apiResponse.error },
              { "border-general-red": apiResponse.error },
            )}`}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              func.setPassword(e.target.value)
            }
          />
          {apiResponse.loading && (
            <div className="absolute h-7 top-2 right-5">
              <DefaultLoading />
            </div>
          )}
        </div>
        <button
          className={`${classNames(
            "max-w-[92px] w-full rounded-lg",
            {
              "bg-main-color-primary text-general-white": state.password.length,
            },
            {
              "text-main-color-primary border-[1px] border-main-color-primary":
                !state.password.length,
            },
          )}`}
          onClick={() => func.submitCheckPassword()}
        >
          확인
        </button>
      </form>
      <h1 className="mt-[6px] text-general-red text-smallExplanation">
        {ErrorHandling(
          {
            errorType: SUBMIT_USER_PASSWORD_CHECK_ERROR_TYPE,
            errorCode: apiResponse.axiosCode,
            errorStatus: apiResponse.errorStatus,
            isError: apiResponse.error,
          },
          "",
        )}
      </h1>
    </div>
  );
};
