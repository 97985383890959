import { MypageMenu } from "../../components/Mypage/MypageMenu";
import { MypageMenuTablet } from "../../components/Mypage/MypageMenuTablet";
import { MypageRight } from "../../components/Mypage/MypageRight";
import { ImypageMainInfo, Irecently } from "../../pages/Mypage/types/item.type";

interface Iitem {
  userInfo: ImypageMainInfo;
  recently: Irecently;
}

interface IapiResponse {
  userInfoLoading: boolean;
}

export const MypagePresenter = ({
  item,
  apiResponse,
}: {
  item: Iitem;
  apiResponse: IapiResponse;
}) => {
  return (
    <div className="max-w-[1630px] flex my-[156px] mx-auto  px-[50px] max-lg:flex-col max-md:px-[24px] max-md:my-[50px]">
      <MypageMenuTablet item={{ ...item }} />
      <MypageMenu item={{ ...item }} apiResponse={{ ...apiResponse }} />
      <MypageRight item={{ username: item.userInfo.username }} />
    </div>
  );
};
