interface signature {
  [key: number]: any;
}

interface IapiResponse {
  errorType: signature;
  errorCode: string;
  errorStatus: number;
  isError: boolean;
}

export const ErrorHandling = (res: IapiResponse, custumCode: string) => {
  if (!res.isError) return;

  if (res.errorStatus >= 500) return "서버 에러가 발생하였습니다.";

  if (res.errorCode === "ERR_NETWORK") {
    return "네트워크 연결이 끊어졌습니다.";
  } else {
    return typeof res.errorType[res.errorStatus] === "object"
      ? res.errorType[res.errorStatus][custumCode]
      : res.errorType[res.errorStatus];
  }
};
