import { useQuery } from "react-query";
import { axiosHopeBuySellUserInfo } from "../api/axiosGetRequest";

interface IuseHopeUserInfo {
  apiName: string;
  authorization?: string;
}

export const useHopeUserInfoQuery = (url: string, info: IuseHopeUserInfo) =>
  useQuery(
    ["hopeUserInfoFromMain", url, info.authorization],
    axiosHopeBuySellUserInfo,
    {
      onError: (err) => {
        console.error(`from ${info.apiName} ERROR`, err);
      },
    },
  );
