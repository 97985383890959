import { useState } from "react";
import { useCookies } from "react-cookie";
import { ModalHopeSellBuy } from "../../../components/UI/Modal/ModalHopeSellBuy";
import { useSubmitPriceQuery } from "../../Main/query/useSubmitPriceQuery";
import { useSubmitPrice } from "../../Main/hooks/useSubmitPrice";
import { useGetHopeUserInfo } from "../../Main/hooks/useGetHopeUserInfo";
type ImodalSellBuy = {
  open: boolean;
  type: string;
  plantId: number;
};

interface Istate {
  type: string;
  plantId: number;
}

interface Ifunc {
  setModalSellBuy: (object: ImodalSellBuy) => void;
}

export const DetailModalHopeSellBuyFetch = ({
  state,
  func,
}: {
  state: Istate;
  func: Ifunc;
}) => {
  const [text, setText] = useState({
    username: "",
    phoneNumber: "",
    price: "",
    email: "",
  });

  const [cookie] = useCookies(["60hz-user"]);

  const POST_SUBMIT_PRICE = useSubmitPriceQuery();

  const { data, status } = useGetHopeUserInfo(cookie["60hz-user"]);

  const submitPrice = () => {
    POST_SUBMIT_PRICE.mutate({
      apiName: "PostSubmitPrice",
      url: `api/plant/${state.plantId}/desired-price/${state.type}/`,
      price: text.price,
      cookie: cookie["60hz-user"],
    });
  };

  const submit_price = useSubmitPrice(
    {
      success: POST_SUBMIT_PRICE.isSuccess,
      type: state.type,
    },
    func.setModalSellBuy,
  );

  return (
    <ModalHopeSellBuy
      state={{
        type: state.type,
        text: text,
      }}
      item={{
        userInfo: data,
      }}
      func={{
        setModalSellBuy: func.setModalSellBuy,
        setText: setText,
        submitPrice: submitPrice,
      }}
      apiResponse={{
        loading: status,
        submitLoading: POST_SUBMIT_PRICE.isLoading,
        axiosCode:
          POST_SUBMIT_PRICE.error && POST_SUBMIT_PRICE.error.code
            ? POST_SUBMIT_PRICE.error.code
            : "",
        error: POST_SUBMIT_PRICE.isError,
        errorStatus:
          POST_SUBMIT_PRICE.error && POST_SUBMIT_PRICE.error.response
            ? POST_SUBMIT_PRICE.error.response.status
            : 0,
      }}
    />
  );
};
