interface IsortList {
  name: string;
  text: string;
}

interface Istate {
  toggle: boolean;
  list: IsortList[];
}

interface Ifunc {
  selectFilter: (type: string, value: string) => void;
}

export const SortDropDownListBox = ({
  state,
  func,
}: {
  state: Istate;
  func: Ifunc;
}) => {
  return (
    <ul
      className={`w-[100%] z-10 absolute top-8 left-1 overflow-hidden transition-all border-main-color-primary bg-general-white rounded-xl ${
        state.toggle ? "max-h-screen border-[1.5px] py-0" : "max-h-0 border-0"
      }`}
    >
      {state.list.map((el, idx) => (
        <li
          className="relative text-center mx-[4px] my-[4px] py-[8px] cursor-pointer hover:bg-main-color-secondary rounded-[8px]"
          key={idx}
          onClick={() => func.selectFilter("sort", el.name)}
        >
          {el.text}
        </li>
      ))}
    </ul>
  );
};
