import { useRecoilValue } from "recoil";
import { usePlantListQuery } from "../query/usePlantListQuery";
import { filterCategory } from "../atoms/filter.atoms";

export const useGetPlantList = (token?: string) => {
  const filter = useRecoilValue(filterCategory);

  const { data, isLoading, isSuccess, isError, error } = usePlantListQuery(
    "api/plant/",
    filter,
    {
      apiName: "Search List Information",
      cookie: token,
    },
  );

  return {
    plantList: isSuccess ? data.results : [],
    plantListTotal: isSuccess ? data.count : 0,
    loading: isLoading,
    isError: isError,
    error: error,
  };
};
