import classNames from "classnames";
import { useLocation } from "react-router-dom";
import { Irecently } from "../../pages/Mypage/types/item.type";
import { buySellMenuPing } from "../../pages/Mypage/utils/buySellMenuPing.function";

interface Iitem {
  recently: Irecently;
}

export const MypageMenuList = ({
  item,
  moveMypageMenu,
}: {
  item: Iitem;
  moveMypageMenu: (url: string) => void;
}) => {
  const { pathname } = useLocation();
  const CURRENT_PATH_NAME = pathname.split("/");

  return (
    <ul className="max-xl:text-default">
      <li
        className={`mb-6 cursor-pointer ${
          CURRENT_PATH_NAME[2] === "talk" && "text-main-color-primary font-bold"
        }`}
        onClick={() => moveMypageMenu("/message")}
      >
        <span>메세지함</span>
      </li>
      <li
        className={classNames("mb-[24px] cursor-pointer", {
          "text-main-color-primary font-bold":
            CURRENT_PATH_NAME[2] === "myPlant" &&
            CURRENT_PATH_NAME[3] === "certification",
        })}
        onClick={() => {
          if (CURRENT_PATH_NAME[3] === "certification") return;
          moveMypageMenu("myPlant/certification");
        }}
      >
        <span>내 발전소</span>
      </li>
      <li className={`mb-6`}>
        <span
          className={classNames({
            "text-main-color-primary font-bold":
              CURRENT_PATH_NAME[2] === "trade",
          })}
        >
          거래 관리
        </span>
        <dl className="ml-[15px]">
          <li
            className={classNames("cursor-pointer relative mt-[12px]", {
              "font-bold":
                CURRENT_PATH_NAME[2] === "trade" &&
                CURRENT_PATH_NAME[3] === "plantSell",
            })}
            onClick={() => {
              if (CURRENT_PATH_NAME[3] === "plantSell") {
                return;
              }
              moveMypageMenu("trade/plantSell");
            }}
          >
            <span className="text-default">ㄴ</span> 판매 관리
            {buySellMenuPing(
              "60hz-sellerList-local",
              item.recently.sell_menu_recently_datetime,
            ) && (
              <div className="absolute top-[4px] left-[80px]">
                <span className="relative flex h-[10px] w-[10px]">
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-general-red opacity-75"></span>
                  <span className="relative inline-flex rounded-full h-[10px] w-[10px] bg-general-red"></span>
                </span>
              </div>
            )}
          </li>
          <li
            className={classNames("cursor-pointer mt-[12px] relative", {
              "font-bold":
                CURRENT_PATH_NAME[2] === "trade" &&
                CURRENT_PATH_NAME[3] === "plantBuy",
            })}
            onClick={() => {
              if (CURRENT_PATH_NAME[3] === "plantBuy") {
                return;
              }
              moveMypageMenu("trade/plantBuy");
            }}
          >
            <span className="text-default">ㄴ</span> 구매 관리
            {buySellMenuPing(
              "60hz-buyerList-local",
              item.recently.buy_menu_recently_datetime,
            ) && (
              <div className="absolute top-[4px] left-[80px]">
                <span className="relative flex h-[10px] w-[10px]">
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-general-red opacity-75"></span>
                  <span className="relative inline-flex rounded-full h-[10px] w-[10px] bg-general-red"></span>
                </span>
              </div>
            )}
          </li>
        </dl>
      </li>
      <li
        className={`mb-[24px] cursor-pointer ${
          CURRENT_PATH_NAME[2] === "favorite" &&
          "text-main-color-primary font-bold"
        }`}
        onClick={() => {
          if (CURRENT_PATH_NAME[2] === "favorite") return;
          moveMypageMenu("favorite");
        }}
      >
        <span>찜한 발전소</span>
      </li>
      <li
        className={`mb-12 cursor-pointer ${
          CURRENT_PATH_NAME[2] === "updateInfo" &&
          "text-main-color-primary font-bold"
        }`}
        onClick={() => {
          if (CURRENT_PATH_NAME[2] === "updateInfo") return;
          moveMypageMenu("updateInfo");
        }}
      >
        <span>내 정보 수정</span>
      </li>
      <li
        className={`cursor-pointer ${
          CURRENT_PATH_NAME[2] === "inquiry" &&
          "text-main-color-primary font-bold"
        }`}
        onClick={() => {
          if (CURRENT_PATH_NAME[2] === "inquiry") return;
          moveMypageMenu("inquiry");
        }}
      >
        <span>문의 내역</span>
      </li>
    </ul>
  );
};
