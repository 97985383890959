import ApexCharts from "react-apexcharts";
import { ApexOptions } from "apexcharts";

interface Igen {
  gen_hourly_today: number[];
  gen_hourly_tomorrow: number[];
  gen_monthly_last_year: number[];
  sum_gen_hour_daily: number;
}

type Iseries = {
  name: string;
  type: string;
  data: number[];
};

export const DetailCapacityGraph = ({
  dateCapacity,
}: {
  dateCapacity: Igen;
}) => {
  const YEAR_OPTIONS: ApexOptions = {
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "60%",
        borderRadius: 2,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 0,
      colors: ["transparent"],
    },

    colors: ["#4B77E6"],

    xaxis: {
      categories: MONTH_ARRAY.map((month) => month),
      labels: {
        style: {
          colors: "#111111",
          fontFamily: "PretendardVariable",
        },
      },
      tooltip: {
        enabled: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        formatter: function (value: number, opts: any) {
          if (!opts) {
            return "0";
          }
          return value >= 1
            ? Number(value).toFixed(1) + " MW"
            : Number(value * 1000).toFixed(0) + " kW";
        },
        style: {
          fontFamily: "PretendardVariable",
        },
      },
      show: true,
      opposite: true,
      tickAmount: 2,
    },

    tooltip: {
      custom: ({ series, seriesIndex, dataPointIndex }) => {
        return (
          '<main class="border-[1px] bg-general-white border-main-color-primary px-4 py-3 rounded-lg">' +
          '<div class="flex justify-center">' +
          '<span class="text-main-color-primary mr-2" >' +
          "발전량 :" +
          "</span>" +
          "<span>" +
          (series[0][dataPointIndex] >= 1
            ? Number(series[0][dataPointIndex]).toFixed(1) + " MW"
            : Number(series[0][dataPointIndex] * 1000).toFixed(0) + " kW") +
          "</span>" +
          "</div>" +
          "</main>"
        );
      },
    },
  };

  const CURRENT_SERIES: Iseries[] = [
    {
      name: "작년",
      type: "bar",
      data: dateCapacity.gen_monthly_last_year.map((el) => el),
    },
  ];

  return (
    <div className="DetailCapacityGraph h-[350px]  ">
      <ApexCharts
        options={YEAR_OPTIONS}
        series={CURRENT_SERIES}
        height={"100%"}
        width={"100%"}
      />
      <div className="mt-[8px] text-text-color-secondary text-footer">
        <span>최근 업데이트: 2023 </span>
      </div>
    </div>
  );
};

const MONTH_ARRAY = [
  "1월",
  "2월",
  "3월",
  "4월",
  "5월",
  "6월",
  "7월",
  "8월",
  "9월",
  "10월",
  "11월",
  "12월",
];

const HOUR_ARRAY = [
  "0시",
  "1시",
  "2시",
  "3시",
  "4시",
  "5시",
  "6시",
  "7시",
  "8시",
  "9시",
  "10시",
  "11시",
  "12시",
  "13시",
  "14시",
  "15시",
  "16시",
  "17시",
  "18시",
  "19시",
  "20시",
  "21시",
  "22시",
  "23시",
];
