import { useState } from "react";
import { IsignupInfo, IreceiveCheck } from "./types/state.type";
import { SignupModify } from "./Signup.modify";
import {
  useEmailDuplicationQuery,
  usePhoneNumberDuplicationQuery,
  useNicknameDuplicationQuery,
} from "./query/useDuplicationQuery";
import { useSignupQuery } from "./query/useSignupQuery";
import {
  HandleEmail,
  HandleNickname,
  HandlePhoneNumber,
} from "./utils/SignFunc";

export const SignupFetch = () => {
  const [input, setInput] = useState<IsignupInfo>({
    email: "",
    password: "",
    passwordVerify: "",
    nickname: "",
    userName: "",
    phoneNumber: "",
    telephoneNumber: "",
    company: "",
    company_type: "발전소 소유주(개인/법인)",
    agreement: false,
  });

  // 이벤트, 알림 전체 관리 체크박스 state
  const [benefit, setBenefit] = useState(false);
  // 이벤트, 알림 개별 체크박스 state
  const [receiveCheck, setReceiveCheck] = useState<IreceiveCheck>({
    email: false,
    kakao: false,
    sms: false,
  });

  const POST_EMAIL_DUPLICATION = useEmailDuplicationQuery();
  const POST_PHONE_DUPLICATION = usePhoneNumberDuplicationQuery();
  const POST_NICKNAME_DUPLICATION = useNicknameDuplicationQuery();
  const POST_SIGNUP = useSignupQuery();

  // 이메일 중복 검사 요청
  const submitEmailDuplication = () => {
    if (HandleEmail(input.email)) {
      POST_EMAIL_DUPLICATION.mutate({
        apiName: "Email Duplication",
        submitInfo: input.email,
        url: "email-check/",
        type: "email",
      });
    }
  };

  // 휴대폰 중복 검사 요청
  const submitPhoneDuplication = () => {
    if (HandlePhoneNumber(input.phoneNumber)) {
      POST_PHONE_DUPLICATION.mutate({
        apiName: "PhoneNumber Duplication",
        submitInfo: input.phoneNumber,
        url: "users/phone/exists/",
        type: "phone",
      });
    }
  };

  // 닉네임 중복 검사 요청
  const submitNicknameDuplication = () => {
    if (HandleNickname(input.nickname)) {
      POST_NICKNAME_DUPLICATION.mutate({
        apiName: "Nickname Duplication",
        submitInfo: input.nickname,
        url: "users/nickname/exists/",
        type: "nickname",
      });
    }
  };

  // 회원가입 요청
  const SubmitSignup = () => {
    POST_SIGNUP.mutate({
      apiName: "Email Duplication",
      submitInfo: input,
      benefit: {
        email: receiveCheck.email,
        sms: receiveCheck.sms,
        kakao: receiveCheck.kakao,
      },
      url: "v1/signup/",
    });
  };

  return (
    <>
      <SignupModify
        state={{
          input: input,
          benefit: benefit,
          receiveCheck: receiveCheck,
        }}
        func={{
          setInput: setInput,
          setBenefit: setBenefit,
          setReceiveCheck: setReceiveCheck,
          submitEmailDuplication: submitEmailDuplication,
          submitPhoneDuplication: submitPhoneDuplication,
          submitNicknameDuplication: submitNicknameDuplication,
          SubmitSignup: SubmitSignup,
        }}
        apiResponse={{
          serverError: POST_SIGNUP.error?.response?.data,
          signupStatus: POST_SIGNUP.status,
          emailDupSuccess: POST_EMAIL_DUPLICATION.isSuccess,
          emailDupFail: POST_EMAIL_DUPLICATION.isError,
          emailDupLoading: POST_EMAIL_DUPLICATION.isLoading,
          phoneDupSuccess: POST_PHONE_DUPLICATION.isSuccess,
          phoneDupFail: POST_PHONE_DUPLICATION.isError,
          phoneDupLoading: POST_PHONE_DUPLICATION.isLoading,
          nicknameDupSuccess: POST_NICKNAME_DUPLICATION.isSuccess,
          nicknameDupFail: POST_NICKNAME_DUPLICATION.isError,
          nicknameDupLoading: POST_NICKNAME_DUPLICATION.isLoading,
        }}
      />
    </>
  );
};
