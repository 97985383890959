import { useNavigate } from "react-router-dom";
import { ImyPlantStatusList } from "../../../../pages/Mypage/types/plantInfo.type";
import { capacityUnit } from "../../../../utils_public/Unit";
import { getDayIncodeDate } from "../../../../utils_public/dateFormat";
import classNames from "classnames";
import { MypageCertificationTabletItem } from "./MypageCertificationTabletItem";
import { DefaultLoading } from "../../../UI/Loading/Loading";

interface Iitem {
  plantList: ImyPlantStatusList[];
}

interface IapiResponse {
  status: string;
}

export const MypageCertificationListTablet = ({
  item,
  apiResponse,
}: {
  item: Iitem;
  apiResponse: IapiResponse;
}) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-y-[36px] lg:hidden">
      {apiResponse.status === "loading" ? (
        <div className="flex h-[100px] mt-10">
          <DefaultLoading />
        </div>
      ) : (
        <>
          <ul className="h-[550px] overflow-y-scroll">
            {item.plantList.map((el) => (
              <MypageCertificationTabletItem {...el} />
            ))}
          </ul>
          <div className="flex justify-center">
            <div
              className="flex gap-x-2 cursor-pointer text-main-color-primary"
              onClick={() => navigate("/mypage/myPlant/submit")}
            >
              <span>+</span>
              <button>새로 등록하기</button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
