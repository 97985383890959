export const FooterDefault = () => {
  return (
    <footer className="relative border-t-border-color-dividers border-t-[0.5px]">
      <div
        className="max-w-[1530px] mt-[56px] mb-[103px] mx-auto flex flex-col 
        justify-center max-2xl:px-[50px] max-md:px-[16px] max-sm:mt-[32px] max-sm:mb-[60px]"
      >
        <div>
          <img src="/image_remodel/company_logo.svg" />
        </div>
        <div className="flex justify-between mt-[13px] max-md:flex-col-reverse max-md:mt-[32px]">
          <section className="text-footer">
            <article className="sm:flex mb-[7px] ">
              식스티헤르츠 주식회사
              <span className="px-[18px] max-sm:px-[12px]"> | </span>
              대표 : 김종규
              <span className="px-[18px] max-sm:px-[12px]"> | </span>
              <br className="sm:hidden" />
              <div className="max-sm:mt-[7px]">사업자 : 878-88-01768</div>
            </article>
            <article className="flex mb-8 max-sm:flex-col max-md:mb-[42px]">
              <div>
                04538 서울시 중구 명동길 73 6층 602호
                <span className="px-4 max-sm:px-[12px]"> | </span>
              </div>
              <div className="max-sm:mt-[7px]">
                <a
                  className="cursor-pointer "
                  href="mailto:hello@60hz.io"
                  target="_blank"
                >
                  hello@60hz.io
                </a>
              </div>
            </article>
            <div>Copyright © 식스티헤르츠 주식회사. All Rights Reserved.</div>
          </section>
          <section className="text-footer">
            <div className="mb-[32px] max-sm:flex">
              <a
                className="cursor-pointer"
                target={"_blank"}
                href="https://60hz.io/"
              >
                회사소개
              </a>
              <span className="px-4 max-sm:px-[12px]"> | </span>
              <a
                className="cursor-pointer"
                href="/privacy/service"
                target={"_blank"}
              >
                서비스 이용 약관
              </a>
              <span className="px-4 max-sm:px-[12px]"> | </span>
              <a
                className="cursor-pointer"
                target={"_blank"}
                href="/privacy/policy"
              >
                개인정보처리방침
              </a>
            </div>
            <div className="max-md:mb-[40px]">
              <h1 className="font-bold mb-[6px] max-sm:font-medium">
                Family Service
              </h1>
              <a
                href="https://map.60hz.io/"
                target="_blank"
                className="cursor-pointer"
              >
                햇빛바람지도
              </a>
              <span className="px-4 max-sm:px-[12px]"> | </span>
              <a
                href="https://connect.60hz.io/"
                target="_blank"
                className="cursor-pointer"
              >
                월간햇빛바람
              </a>
            </div>
          </section>
        </div>
      </div>
    </footer>
  );
};
