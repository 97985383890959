import { AxiosError } from "axios";
import { useMutation } from "react-query";
import { axiosSignupDuplication } from "../api/axiosPostRequest";

interface errorData {
  is_user: boolean;
}

export const useEmailDuplicationQuery = () =>
  useMutation(axiosSignupDuplication, {
    onError: (err: AxiosError<errorData>, val) => {
      console.error(`from ${val.apiName} ERROR`, err);
    },
  });

export const usePhoneNumberDuplicationQuery = () =>
  useMutation(axiosSignupDuplication, {
    onError: (err: AxiosError, val) => {
      console.error(`from ${val.apiName} ERROR`, err);
    },
  });

export const useNicknameDuplicationQuery = () =>
  useMutation(axiosSignupDuplication, {
    onError: (err: AxiosError, val) => {
      console.error(`from ${val.apiName} ERROR`, err);
    },
  });
