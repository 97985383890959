import { DetailEnergeMain } from "./DetailEnergeMain";
import { DetailEnergeExpectation } from "./DetailEnergeExpectation";
import { IplantInfo } from "../../../pages/Detail/types/plantInfo.type";
import { DetailEnergeMarketFetch } from "../../../pages/Detail/module_fetch/DetailEnergeMarket.fetch";

export const DetailEnerge = ({ plantInfo }: { plantInfo: IplantInfo }) => {
  return (
    <section className="w-[47%] max-xl:w-full">
      <DetailEnergeMain
        capacity={plantInfo.capacity}
        local={plantInfo.addr_lv1}
        hourDaily={plantInfo.gen.sum_gen_hour_daily}
      />
      <DetailEnergeMarketFetch />
      <DetailEnergeExpectation
        capacity={plantInfo.capacity}
        hourDaily={plantInfo.gen.sum_gen_hour_daily}
      />
    </section>
  );
};
