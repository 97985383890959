import { useState, useEffect } from "react";

interface Ipass {
  init: boolean;
  success: boolean;
  fail: boolean;
}

export const useEmailPass = (successRes: boolean, failRes: boolean) => {
  // 이메일 중복 검사 결과 state
  const [emailPass, setEmailPass] = useState<Ipass>({
    init: false,
    success: false,
    fail: false,
  });

  useEffect(() => {
    // 서버에서의 결과
    if (successRes && emailPass.init) {
      setEmailPass({ ...emailPass, success: successRes, fail: false });
    } else if (failRes && emailPass.init) {
      setEmailPass({ ...emailPass, success: false, fail: failRes });
    } else {
      setEmailPass({ ...emailPass, success: false, fail: false });
    }
  }, [successRes, failRes]);

  return {
    emailDupStatus: emailPass as Ipass,
    setEmailDupStatus: setEmailPass as (value: Ipass) => void,
  };
};

export const usePhonePass = (successRes: boolean, failRes: boolean) => {
  // 이메일 중복 검사 결과 state
  const [phonePass, setPhonePass] = useState<Ipass>({
    init: false,
    success: false,
    fail: false,
  });

  useEffect(() => {
    // 서버에서의 결과
    if (successRes && phonePass.init) {
      setPhonePass({ ...phonePass, success: successRes, fail: false });
    } else if (failRes && phonePass.init) {
      setPhonePass({ ...phonePass, success: false, fail: failRes });
    } else {
      setPhonePass({ ...phonePass, success: false, fail: false });
    }
  }, [successRes, failRes]);

  return {
    phoneDupStatus: phonePass as Ipass,
    setPhoneDupStatus: setPhonePass as (value: Ipass) => void,
  };
};

export const useNicknamePass = (successRes: boolean, failRes: boolean) => {
  // 이메일 중복 검사 결과 state
  const [nicknamePass, setNicknamePass] = useState<Ipass>({
    init: false,
    success: false,
    fail: false,
  });

  useEffect(() => {
    // 서버에서의 결과
    if (successRes && nicknamePass.init) {
      setNicknamePass({ ...nicknamePass, success: successRes, fail: false });
    } else if (failRes && nicknamePass.init) {
      setNicknamePass({ ...nicknamePass, success: false, fail: failRes });
    } else {
      setNicknamePass({ ...nicknamePass, success: false, fail: false });
    }
  }, [successRes, failRes]);

  return {
    nicknameDupStatus: nicknamePass as Ipass,
    setNicknameDupStatus: setNicknamePass as (value: Ipass) => void,
  };
};
