import { FavoritePlantListItem } from "../../UI/PlantList/FavoritePlantListItem";
import { SkeletonListContainer } from "../../UI/PlantList/SkeletonList";
import { MypageFavoriteModal } from "./MypageFavoriteModal";
import { IplantList } from "../../../pages/Mypage/types/plantInfo.type";
import { IfavoriteModal } from "../../../pages/Mypage/types/state.type";
export const MypageFavorite = ({
  plantList,
  deleteList,
  status,
  deleteModal,
  setDeleteModal,
  modalOnclick,
  deleteFavoritePlant,
}: {
  plantList: IplantList[];
  deleteList: number[];
  status: string;
  deleteModal: IfavoriteModal;
  setDeleteModal: (bool: IfavoriteModal) => void;
  modalOnclick: (id?: number) => void;
  deleteFavoritePlant: () => void;
}) => {
  const EXTRACT_INQUIRY_LIST = plantList.filter(
    (x) => !deleteList.includes(x.id),
  );

  return (
    <>
      <section>
        <h1 className="text-listFirst font-bold mb-4 max-sm:font-medium">
          찜한 발전소
        </h1>

        {status === "loading" ? (
          <ul className="grid grid-cols-4 gap-8 max-xl:grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1">
            <SkeletonListContainer count={8} />
          </ul>
        ) : EXTRACT_INQUIRY_LIST.length ? (
          <ul className="grid grid-cols-4 gap-8 max-xl:grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1">
            {EXTRACT_INQUIRY_LIST.map((el: IplantList) => {
              return (
                <FavoritePlantListItem
                  item={{ ...el }}
                  state={{ currentFavorite: true }}
                  key={el.id}
                  func={{
                    likeSubmitFromServer: modalOnclick,
                  }}
                />
              );
            })}
          </ul>
        ) : (
          <div className="w-full flex justify-center mt-28 text-subValue">
            찜한 발전소가 없습니다.
          </div>
        )}
      </section>
      {deleteModal.modal && (
        <MypageFavoriteModal
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
          deleteFavoritePlant={deleteFavoritePlant}
        />
      )}
    </>
  );
};
