import {
  IinquiryList,
  IinquiryListDeleteModal,
  IinquiryListUpdateModal,
} from "../../../pages/Mypage/types/state.type";
import { MypageInquiryListItemModify } from "../../../pages/Mypage/module_modify/MypageInquiryListItem.modify";
import { useState } from "react";
import { MypageInquiryNoticeModal } from "./MypageInquiryModal";
import { errorHandling } from "../../../utils_public/ErrorHandle";

interface Istate {
  deleteInquiryList: number[];
  deleteModal: IinquiryListDeleteModal;
  updateModal: IinquiryListUpdateModal;
  alreadyInquiryDelete: boolean;
}

interface Iitem {
  inquiryList: IinquiryList[];
}

interface IapiResponse {
  updatedStatus: string;
  updatedError?: null | number;
  updatedId: number;
  deletedStatus: string;
  deletedError?: null | number;
}

interface Ifunc {
  HandleDeleteModal: (value: IinquiryListDeleteModal | string) => void;
  HandleUpdateModal: (value: IinquiryListUpdateModal | string) => void;
  setAlreadyInquiryDelete: (bool: boolean) => void;
}

export const MypageInquiryList = ({
  state,
  item,
  apiResponse,
  func,
}: {
  state: Istate;
  item: Iitem;
  apiResponse: IapiResponse;
  func: Ifunc;
}) => {
  // 답변 완료된 모달 상태값
  const [alreadyInquiryUpdate, setAlreadyInquiryUpdate] = useState(false);
  const [currentListItem, setCurrentListItem] = useState(0);
  const [clickTrigger, setClickTrigger] = useState(true);
  const [curretOrderId, setCurretOrderId] = useState(-1);

  const EXTRACT_INQUIRY_LIST = item.inquiryList.filter(
    (x) => !state.deleteInquiryList.includes(x.id),
  );

  return EXTRACT_INQUIRY_LIST.length ? (
    <>
      {EXTRACT_INQUIRY_LIST.map((el, idx) => (
        <MypageInquiryListItemModify
          key={el.id}
          item={{
            content: el.question,
            answer: el.answer,
            answerDate: el.answer_datetime,
            question_type: el.question_type,
            inquiry_id: el.id,
            plantOrder: idx,
            waiting: el.status,
            registerDatetime: el.register_datetime,
          }}
          state={{
            deleteModal: state.deleteModal,
            updateModal: state.updateModal,
            curretOrderId: curretOrderId,
            currentListItem: currentListItem,
            clickTrigger: clickTrigger,
          }}
          apiResponse={{
            updatedStatus: apiResponse.updatedStatus,
            updatedId: apiResponse.updatedId,
            deletedStatus: apiResponse.deletedStatus,
          }}
          func={{
            setCurretOrderId: setCurretOrderId,
            HandleDeleteModal: func.HandleDeleteModal,
            HandleUpdateModal: func.HandleUpdateModal,
            setAlreadyInquiryUpdate: setAlreadyInquiryUpdate,
            setCurrentListItem: setCurrentListItem,
            setClickTrigger: setClickTrigger,
          }}
        />
      ))}

      {alreadyInquiryUpdate && (
        <MypageInquiryNoticeModal
          contents={errorHandling(
            apiResponse.updatedError as number,
            "이미 답변 완료된 문의입니다.",
          )}
          setAlreadyInquiry={setAlreadyInquiryUpdate}
        />
      )}

      {state.alreadyInquiryDelete && (
        <MypageInquiryNoticeModal
          contents={errorHandling(
            apiResponse.deletedError as number,
            "이미 답변 완료된 문의입니다.",
          )}
          setAlreadyInquiry={func.setAlreadyInquiryDelete}
        />
      )}
    </>
  ) : (
    <div className="text-center mt-14">문의 내역이 없습니다.</div>
  );
};
