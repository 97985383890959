interface Istate {
  toggle: boolean;
  current: string;
}

interface Ifunc {
  setToggle: (value: boolean) => void;
}

export const SortDropDownCurrent = ({
  state,
  func,
}: {
  state: Istate;
  func: Ifunc;
}) => {
  return (
    <div
      className="flex gap-x-[8px] text-center relative cursor-pointer"
      onClick={() => func.setToggle(!state.toggle)}
    >
      <span>{state.current}</span>
      <img
        className={`${state.toggle ? "rotate-180" : "rotate-0"}`}
        src="/icon_remodel/arrow.svg"
      />
    </div>
  );
};
