import { MainPlantListFilter } from "../../components/Main/MainPlantListFilter";
import { MainPlantListHeader } from "../../components/Main/MainPlantListHeader";
import { MainPlantList } from "../../components/Main/MainPlantList";
import PageNation from "../../components/UI/Paging/SearchPageNation";
import { ModalBlur } from "../../components/UI/Modal/ModalBlur";
import classNames from "classnames";
import { useState } from "react";
import { MobileMainFilter } from "../../components/Main/Mobile/MobileMainFilter";
import { IplantList } from "../Main/types/plantList.type";
import { useRecoilValue } from "recoil";
import { filterCategory } from "./atoms/filter.atoms";

interface Istate {
  toggle: boolean;
  blurModal: boolean;
}

interface Ifunc {
  selectFilter: (type: string, value: string) => void;
  resetFilter: () => void;
  pageChangeHandler: (pageNum: number) => void;
  setToggle: (bool: boolean) => void;
  setBlurModal: (bool: boolean) => void;
  setPageNumberList: (value: number[]) => void;
}

interface Iitem {
  plantList: IplantList[];
  plantListTotal: number;
}

interface IapiResponse {
  loading: boolean;
  error: boolean;
  axiosCode: string;
  errorStatus: number;
}

export const SearchPresenter = ({
  state,
  func,
  item,
  apiResponse,
}: {
  state: Istate;
  func: Ifunc;
  item: Iitem;
  apiResponse: IapiResponse;
}) => {
  const filter = useRecoilValue(filterCategory);
  const [mobileFilterOpen, setMobileFilterOpen] = useState(false);

  return (
    <>
      <div className="pt-16 w-full">
        <MobileMainFilter
          state={{
            filter: filter,
            mobileFilterOpen: mobileFilterOpen,
          }}
          func={{
            selectFilter: func.selectFilter,
            resetFilter: func.resetFilter,
            setMobileFilterOpen: setMobileFilterOpen,
          }}
        />
        <div
          className={classNames(
            "max-w-[1630px] flex mt-20 mx-auto px-[50px] max-md:mt-0",
            {
              "blur-md": state.blurModal,
            },
            "max-md:px-[24px] max-sm:px-[16px]",
          )}
        >
          <MainPlantListFilter
            state={{
              filter: filter,
            }}
            func={{
              selectFilter: func.selectFilter,
              resetFilter: func.resetFilter,
            }}
          />
          <section className="w-[100%] z-[1]">
            <MainPlantListHeader
              state={{
                filter: filter,
                total: item.plantListTotal,
                toggle: state.toggle,
              }}
              func={{
                selectFilter: func.selectFilter,
                setToggle: func.setToggle,
                setMobileFilterOpen: setMobileFilterOpen,
              }}
              apiResponse={{
                loading: apiResponse.loading,
              }}
            />
            <MainPlantList
              state={{ plantList: item.plantList }}
              apiResponse={{
                isLoading: apiResponse.loading,
                error: apiResponse.error,
                axiosCode: apiResponse.axiosCode,
                errorStatus: apiResponse.errorStatus,
              }}
              func={{
                setBlurModal: func.setBlurModal,
              }}
            />
            <PageNation
              state={{
                total: item.plantListTotal,
                current: filter.page,
              }}
              func={{
                pageChangeHandler: func.pageChangeHandler,
              }}
            />
          </section>
        </div>
        {state.blurModal && (
          <ModalBlur
            state={{
              mobileFilterOpen: mobileFilterOpen,
            }}
            setBlurModal={func.setBlurModal}
          />
        )}
      </div>
    </>
  );
};
