import { useRecoilValue } from "recoil";

import { LoginPresenter } from "./Login.presenter";
import { emailCheck } from "./atoms/validation.atom";
import { useEmailValidation } from "../../pages/Login/hooks/useValidation";
import { IloginInput } from "../../pages/Login/types/loginInput.type";
import { useMakeCookie } from "./hooks/useMakeCookie";

interface Istate {
  loginInput: IloginInput;
}

interface Iresponse {
  Token: string;
}

interface Ifunc {
  submitLogin: () => void;
  setLoginInput: (loginInput: IloginInput) => void;
}

interface IapiResponse {
  loading: boolean;
  axiosCode: string;
  error: boolean;
  errorStatus: number;
}

interface Ioptions {
  token: Iresponse;
}

export const LoginModify = ({
  state,
  func,
  apiResponse,
  options,
}: {
  state: Istate;
  func: Ifunc;
  apiResponse: IapiResponse;
  options: Ioptions;
}) => {
  const emailResult = useRecoilValue(emailCheck);

  // 이메일 유효성 검사 hooks
  const loginValidation = useEmailValidation(state.loginInput);

  // 로그인 input onChange 이벤트 함수
  const changeLoginInput = (type: string, text: string) => {
    func.setLoginInput({ ...state.loginInput, [type]: text });
  };

  // 로그인 성공 시 웹 Cookie 토큰 등록
  const makeCookie = useMakeCookie(options.token);

  return (
    <>
      <LoginPresenter
        state={{
          loginInput: state.loginInput,
          emailResult: emailResult,
        }}
        apiResponse={{
          ...apiResponse,
        }}
        func={{
          submitLogin: func.submitLogin,
          changeLoginInput: changeLoginInput,
        }}
      />
    </>
  );
};
