import { ChatUserListItem } from "./ChatUserListItem";
import { Imessage } from "../../pages/Chat/types/item.type";
import {
  IpingList,
  IcurrentMessageList,
} from "../../pages/Chat/types/state.type";

type IselectOption = {
  plant: string;
  currentMessageId: number;
  user: string;
};

interface Istate {
  selectOption: IselectOption;
  selectPlant: Imessage[];
  userPingList: IpingList;
  curretMessageList: IcurrentMessageList;
}

interface Ifunc {
  setSelectOption: (value: IselectOption) => void;
  setUserPingList: (value: IpingList) => void;
  setSendMessageError: (error: boolean) => void;
}

export const ChatUserList = ({
  state,
  func,
}: {
  state: Istate;
  func: Ifunc;
}) => {
  return (
    <section className="max-w-[320px] w-full px-[20px] transition-all duration-200  max-xl:max-w-[270px] max-xl:px-[10px] max-lg:hidden">
      {state.selectOption.plant === "" ? (
        <div className="max-w-full w-full h-full bg-general-lightGray flex justify-center pt-[50px] text-listTitle whitespace-pre max-xl:text-listSecond">
          발전소를 선택해주세요!
        </div>
      ) : (
        <>
          <ul className="h-[680px] flex flex-col overflow-y-scroll scrollbar-hide">
            {state.selectPlant
              .sort(
                (a, b) =>
                  new Date(
                    b.recent_message_content_info.send_datetime,
                  ).getTime() -
                  new Date(
                    a.recent_message_content_info.send_datetime,
                  ).getTime(),
              )
              .map((el, idx) => (
                <ChatUserListItem
                  item={{ ...el }}
                  key={idx}
                  state={{
                    selectOption: state.selectOption,
                    userPingList: state.userPingList,
                    curretMessageList: state.curretMessageList,
                  }}
                  func={{
                    setSelectOption: func.setSelectOption,
                    setUserPingList: func.setUserPingList,
                    setSendMessageError: func.setSendMessageError,
                  }}
                />
              ))}
          </ul>
        </>
      )}
    </section>
  );
};
