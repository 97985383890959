import { useCookies } from "react-cookie";
import { MainModify } from "./Main.modify";
import { useGetPlantList } from "./hooks/useGetPlantList";

export const MainFetch = () => {
  const [cookie] = useCookies(["60hz-user"]);

  // 변경된 필터들 get 요청
  const { plantList, loading, plantListTotal, isError, error } =
    useGetPlantList(cookie["60hz-user"]);

  return (
    <>
      <MainModify
        item={{
          plantList: plantList,
          plantListTotal: plantListTotal,
        }}
        apiResponse={{
          loading: loading,
          error: isError,
          axiosCode: error && error.code ? error.code : "",
          errorStatus:
            error && error.response && error.response.status
              ? error.response.status
              : 0,
        }}
      />
    </>
  );
};
