import classNames from "classnames";
import {
  IinquiryListUpdateModal,
  IinquiryListDeleteModal,
  IplantInfo,
} from "../../../pages/Mypage/types/state.type";
import { capacityUnit } from "../../../utils_public/Unit";
import { DefaultLoading } from "../../UI/Loading/Loading";

interface Istate {
  inquiryContent: string;
  modify: boolean;
  contentCopy: string;
}

interface Iitem {
  waiting: string;
  inquiry_id: number;
}

interface Ifunc {
  setInquiryContent: (text: string) => void;
  closeModifyBox: (bool: boolean) => void;
  HandleDeleteModal: (value: IinquiryListDeleteModal) => void;
  HandleUpdateModal: (value: IinquiryListUpdateModal) => void;
}

interface IapiResponse {
  updatedStatus: string;
}

export const InquiryText = ({
  state,
  func,
  item,
  apiResponse,
}: {
  state: Istate;
  func: Ifunc;
  item: Iitem;
  apiResponse: IapiResponse;
}) => {
  return (
    <div className="pt-[24px] pb-8 px-[30px] bg-general-lightGray">
      <div className="flex items-center justify-between mb-4">
        <div className="flex gap-x-4">
          <span className="w-[40px] font-bold text-main-color-primary">
            문의
          </span>
          <p className="text-main-color-primary max-sm:text-default">
            {state.contentCopy
              .split("")
              .filter((_, idx) => idx < 27)
              .join("")}
          </p>
        </div>
        {item.waiting === "답변 대기" && (
          <div className="flex gap-x-4 text-text-color-disabled">
            <span
              className="cursor-pointer border-b-[1px] border-text-color-disabled"
              onClick={() => func.closeModifyBox(false)}
            >
              수정
            </span>
            <span
              className="cursor-pointer border-b-[1px] border-text-color-disabled"
              onClick={() =>
                func.HandleDeleteModal({
                  modal: true,
                  inquiry_id: item.inquiry_id,
                })
              }
            >
              삭제
            </span>
          </div>
        )}
      </div>

      <div>
        {state.modify ? (
          <div className="w-full bg-general-lightGray rounded-lg whitespace-pre max-xl:whitespace-pre-wrap max-sm:text-default">
            {state.contentCopy}
          </div>
        ) : (
          <div className="relative">
            <textarea
              className={`w-full h-[150px] py-[24px] px-[24px] rounded-lg !outline-none `}
              placeholder="최소 5자 이상 문의를 남겨주세요."
              value={state.inquiryContent}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                func.setInquiryContent(e.target.value)
              }
            />
            {apiResponse.updatedStatus === "loading" && (
              <div className="w-10 absolute right-[30px] bottom-[20px]">
                <DefaultLoading />
              </div>
            )}
          </div>
        )}
      </div>
      {!state.modify && item.waiting && (
        <div className="h-[43px] flex justify-end gap-x-2 mt-[22px]">
          <button
            className="border-[1px] border-border-color-dividers px-[30px] py-3 rounded-lg"
            onClick={() => func.closeModifyBox(true)}
          >
            닫기
          </button>
          <button
            className={`${classNames(
              " px-[30px] py-3 rounded-lg bg-main-color-primary text-general-white",
              {
                "bg-text-color-disabled text-text-color-primary cursor-not-allowed":
                  state.inquiryContent.trim().length < 5,
              },
            )} `}
            onClick={() => {
              if (state.inquiryContent.trim().length < 5) return;
              func.HandleUpdateModal({
                modal: true,
                inquiry_id: item.inquiry_id,
                contents: state.inquiryContent.trim(),
              });
            }}
          >
            수정
          </button>
        </div>
      )}
    </div>
  );
};
