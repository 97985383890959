import classnames from "classnames";

interface signature {
  [key: string]: string;
}

interface Ifunc {
  handleSubmitModal: (bool: boolean) => void;
}

interface Ioptions {
  contents: string;
  paddingX: string;
}

export const MypageOwnerModal = ({
  func,
  options,
}: {
  func: Ifunc;
  options: Ioptions;
}) => {
  const CLASS_OBJECT: signature = {
    submit: "px-[30px]",
    information: "px-[90px]",
  };

  return (
    <>
      <div className="w-full h-full fixed bottom-0 left-0 top-0 right-0 z-[100]">
        <div className="w-full h-full absolute  opacity-50 bg-text-color-primary"></div>

        <div className="flex relative min-h-full justify-center items-center">
          <div
            className="relative flex flex-col justify-end items-center bg-general-white
          rounded-xl border-main-color-primary border-[2px] drop-shadow-[2px_2px_4px_rgba(0,0,0,0.25)]"
          >
            <h1
              className={classnames(
                CLASS_OBJECT[options.paddingX],
                "whitespace-pre-line text-center py-[38px]",
              )}
            >
              {options.contents}
            </h1>
            <div className="w-full border-t-[2px] border-t-main-color-primary">
              <button
                className="w-1/2 py-4"
                onClick={() => func.handleSubmitModal(false)}
              >
                취소
              </button>
              <button
                className="w-1/2 py-4 bg-main-color-primary text-general-white"
                onClick={() => func.handleSubmitModal(true)}
              >
                확인
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
