import axios from "axios";
import { HandleFormPlantInfo } from "../utils/handleFormData.function";

export async function axiosAddMyPlantInfo(queryKey: any) {
  const formData = HandleFormPlantInfo(queryKey.plantInfo, {
    rpsFile: queryKey.file.rpsFile,
    energeFile: queryKey.file.energeFile,
    imageFile: queryKey.file.imageFile,
  });

  const response = await axios.put(
    process.env.REACT_APP_SERVER_URL + queryKey.url,
    formData,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Token " + queryKey.cookie,
      },
    },
  );

  return response.data;
}
