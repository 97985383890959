export const LoginInput = ({
  input_type,
  placeHolder,
  changeLoginInput,
  status,
}: {
  input_type: string;
  placeHolder: string;
  changeLoginInput: (type: string, text: string) => void;
  status: string;
}) => {
  return (
    <input
      className={`h-[43px] px-4 !outline-none border-[1px] border-main-color-primary
       ${status === "error" && "border-general-red"} rounded-lg`}
      type={input_type}
      placeholder={placeHolder}
      autoComplete={"on"}
      autoCapitalize={"off"}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        changeLoginInput(input_type, e.target.value)
      }
    />
  );
};
