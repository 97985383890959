import classNames from "classnames";
import { DropDownListBox } from "../../../UI/DropDown/DropDownListBox";

export const MypageMyPlantSearchDropDownBox = ({
  isDropdown,
  setIsDropdown,
  currentDropdownValue,
  setCurrentDropdownValue,
  dropDownValueList,
}: {
  isDropdown: boolean;
  setIsDropdown: (toggle: boolean) => void;
  currentDropdownValue: string;
  setCurrentDropdownValue: (value: string) => void;
  dropDownValueList: string[];
}) => {
  return (
    <div className=" flex items-center gap-x-[10px] max-md:flex-col max-md:items-start max-md:gap-y-[8px]">
      <div className="w-[200px] h-[35px] z-[2]">
        <div className="relative border-[1px] py-[6px] text-default border-border-color-dividers rounded-lg">
          <div
            className=" text-listSecond pl-[14px] relative cursor-pointer"
            onClick={() => setIsDropdown(!isDropdown)}
          >
            {currentDropdownValue}
            <img
              className={classNames(
                "absolute top-[8px] right-[8px]",
                {
                  "rotate-180": isDropdown,
                },
                {
                  "rotate-0": !isDropdown,
                },
              )}
              src="/icon_remodel/arrow.svg"
            />
          </div>
          <DropDownListBox
            state={{
              toggle: isDropdown,
              current: currentDropdownValue,
            }}
            item={{
              selectList: dropDownValueList,
            }}
            func={{
              setToggle: setIsDropdown,
              setCurrent: setCurrentDropdownValue,
            }}
            options={{
              overflow: true,
            }}
          />
        </div>
      </div>
    </div>
  );
};
