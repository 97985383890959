import { MypageOwnerInfo } from "../../../components/Mypage/MypageMyPlant/Owner/MypageOwnerInfo";
import { useSavePlantTotalInfo } from "../hooks/useSavePlantTotalInfo";
import {
  IplantTotalInfo,
  Iinverter,
  Imodule,
} from "../types/plantTotalInfo.type";
import { Ifile, IimageFile, IthumbnailList } from "../types/state.type";

interface Istate {
  inverter: Iinverter;
  module: Imodule;
  rpsFile: Ifile;
  energeFile: Ifile;
  imageFile: IimageFile;
  thumbnail: IthumbnailList;
  haveRpsFile: boolean;
  haveEnergeFile: boolean;
  modal: boolean;
}

interface Ifunc {
  setInverter: (inverter: Iinverter) => void;
  setModule: (module: Imodule) => void;
  setRpsFile: (file: Ifile) => void;
  setEnergeFile: (file: Ifile) => void;
  setImageFile: (file: IimageFile) => void;
  setThumbnail: (file: IthumbnailList) => void;
  setHaveRpsFile: (bool: boolean) => void;
  setHaveEnergeFile: (bool: boolean) => void;
  setModal: (bool: boolean) => void;
  submitMyPlantInfo: () => void;
}

interface Iitem {
  plantTotalInfo: IplantTotalInfo;
}

interface IapiResponse {
  status: string;
  submitStatus: string;
  submitError?: number | null;
}

export const MypageOwnerInfoModify = ({
  item,
  state,
  func,
  apiResponse,
}: {
  item: Iitem;
  state: Istate;
  func: Ifunc;
  apiResponse: IapiResponse;
}) => {
  // 내 발전소 용량 신청
  const savePlantTotalInfo = useSavePlantTotalInfo(
    apiResponse.status,
    item.plantTotalInfo,
    {
      setInverter: func.setInverter,
      setModule: func.setModule,
      setRpsFile: func.setRpsFile,
      setEnergeFile: func.setEnergeFile,
      setImageFile: func.setImageFile,
      setHaveRpsFile: func.setHaveRpsFile,
      setHaveEnergeFile: func.setHaveEnergeFile,
      setThumbnail: func.setThumbnail,
    },
  );

  // 수정 모달 관리 함수
  const handleSubmitModal = (bool: boolean) => {
    if (!bool) {
      func.setModal(bool);
    } else {
      // 전송 함수
      func.submitMyPlantInfo();
    }
  };

  return (
    <MypageOwnerInfo
      state={{
        inverter: state.inverter,
        module: state.module,
        rpsFile: state.rpsFile,
        energeFile: state.energeFile,
        imageFile: state.imageFile,
        thumbnail: state.thumbnail,
        haveRpsFile: state.haveRpsFile,
        haveEnergeFile: state.haveEnergeFile,
        modal: state.modal,
      }}
      func={{
        setInverter: func.setInverter,
        setModule: func.setModule,
        setRpsFile: func.setRpsFile,
        setEnergeFile: func.setEnergeFile,
        setImageFile: func.setImageFile,
        setThumbnail: func.setThumbnail,
        setHaveRpsFile: func.setHaveRpsFile,
        setHaveEnergeFile: func.setHaveEnergeFile,
        setModal: func.setModal,
        handleSubmitModal: handleSubmitModal,
      }}
      item={{
        plantTotalInfo: item.plantTotalInfo,
      }}
      apiResponse={{
        status: apiResponse.status,
        submitStatus: apiResponse.submitStatus,
        submitError: apiResponse.submitError,
      }}
    />
  );
};
