import { useNavermaps } from "react-naver-maps";

interface InaverMaps_TypeID {
  typeId: string;
  text: string;
}

interface IdetailVariousMapSelect {
  changeNaverMap: (curretNaverValue: string) => void;
  curretNaverValue: string;
}

export const DetailVariousMapSelect = ({
  changeNaverMap,
  curretNaverValue,
}: IdetailVariousMapSelect) => {
  const navermaps = useNavermaps();

  const buttons: InaverMaps_TypeID[] = [
    {
      typeId: navermaps.MapTypeId.NORMAL,
      text: "일반지도",
    },
    {
      typeId: navermaps.MapTypeId.SATELLITE,
      text: "위성지도",
    },
  ];

  return (
    <div className="w-[140px] h-[23px] flex justify-center py-[2px] px-[2px] bg-border-color-dividers text-[11px] rounded-lg">
      <span
        className={` flex items-center px-[10px] leading-[100%] cursor-pointer rounded-[6px] ${
          curretNaverValue === "normal" ? "bg-general-white" : ""
        }`}
        onClick={() => changeNaverMap(buttons[0].typeId)}
      >
        일반
      </span>
      <span
        className={` flex items-center px-[10px] leading-[100%] cursor-pointer rounded-[6px] ${
          curretNaverValue === "satellite" ? "bg-general-white" : ""
        } `}
        onClick={() => changeNaverMap(buttons[1].typeId)}
      >
        위성
      </span>
      <span
        className={` flex items-center justify-center pl-[6px] pr-2 leading-[100%] cursor-pointer rounded-[6px] ${
          curretNaverValue === "pano" ? "bg-general-white " : ""
        } `}
        onClick={() => changeNaverMap("pano")}
      >
        로드뷰
      </span>
    </div>
  );
};
