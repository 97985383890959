import { useRef, useState } from "react";
import { MypageUserChange } from "../../../components/Mypage/MypageUserInfo/MypageUserChange";
import { useNicknamePass, usePhonePass } from "../hooks/useDuplicationPass";
import {
  HandleNickname,
  HandlePhoneNumber,
  HandleTelephoneNumber,
} from "../../SignUp/utils/SignFunc";
import { usePasswordValidation } from "../hooks/usePasswordValidation";
import { useSaveUserInfo } from "../hooks/useSaveUserInfo";
import { IreceiveCheck, IgetUserInfo } from "../types/state.type";

interface IserverError {
  code: string;
  message: string;
}

interface signature {
  [key: string]: string;
}

interface Istate {
  toggle: boolean;
  pwButton: boolean;
  receiveCheck: IreceiveCheck;
  userModify: signature;
}

interface Ifunc {
  setToggle: (bool: boolean) => void;
  setPwButton: (bool: boolean) => void;
  setReceiveCheck: (receiveCheck: IreceiveCheck) => void;
  setUserModify: (userModify: signature) => void;
  submitUserInfo: () => void;
  submitNicknameDuplication: () => void;
  submitPhoneDuplication: () => void;
}

interface Iitem {
  userInfo: IgetUserInfo;
  nicknameOriginal: string;
  phoneNumberOriginal: string;
}

interface IapiResponse {
  serverError?: IserverError;
  userInfoStatus: string;
  submitLoading: boolean;
  submitError: boolean;
  submiteCode: string;
  submiteStatus: number;
  phoneDupSuccess: boolean;
  phoneDupFail: boolean;
  phoneDupLoading: boolean;
  nicknameDupSuccess: boolean;
  nicknameDupFail: boolean;
  nicknameDupLoading: boolean;
}

export const MypageUserChangeModify = ({
  state,
  func,
  item,
  apiResponse,
}: {
  state: Istate;
  func: Ifunc;
  item: Iitem;
  apiResponse: IapiResponse;
}) => {
  const passwordRef = useRef<HTMLInputElement>(null);
  const passwordVerifyRef = useRef<HTMLInputElement>(null);
  const nicknameRef = useRef<HTMLInputElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);
  const telephoneRef = useRef<HTMLInputElement>(null);
  const companyRef = useRef<HTMLInputElement>(null);

  const [passwordLength, setPasswordLength] = useState(false);
  const [passwordSpecial, setPasswordSpecial] = useState(false);
  const [passwordSame, setPasswordSame] = useState(false);
  const [passwordNumber, setPasswordNumber] = useState(false);

  const [passwordCheck, setPasswordCheck] = useState(false);
  const [passwordVerifyCheck, setPasswordVerifyCheck] = useState(false);
  const [nicknameCheck, setNicknameCheck] = useState(false);
  const [usernameCheck, setUsernameCheck] = useState(false);
  const [phoneNumberCheck, setPhoneNumberCheck] = useState(false);
  const [telephoneNumberCheck, setTelephoneNumberCheck] = useState(false);
  const [companyCheck, setCompanyCheck] = useState(false);

  // 회원 비밀번호 유효성 검사 hooks
  const passwordValidation = usePasswordValidation(
    {
      passwordLength: passwordLength,
      passwordSpecial: passwordSpecial,
      passwordSame: passwordSame,
      passwordNumber: passwordNumber,
      passwordChange: state.userModify.password,
      passwordVerify: state.userModify.passwordVerify,
    },
    {
      setLength: setPasswordLength,
      setSpecial: setPasswordSpecial,
      setSame: setPasswordSame,
      setNumber: setPasswordNumber,
    },
  );

  // 회원 정보 저장 hooks
  const saveUserInformation = useSaveUserInfo(
    apiResponse.userInfoStatus,
    item.userInfo,
    {
      setUserModify: func.setUserModify,
      setReceiveCheck: func.setReceiveCheck,
      setToggle: func.setToggle,
    },
  );

  const { phoneDupStatus, setPhoneDupStatus } = usePhonePass(
    apiResponse.phoneDupSuccess,
    apiResponse.phoneDupFail,
    {
      original: item.phoneNumberOriginal,
      newText: state.userModify.phoneNumber,
    },
  );

  const { nicknameDupStatus, setNicknameDupStatus } = useNicknamePass(
    apiResponse.nicknameDupSuccess,
    apiResponse.nicknameDupFail,
    {
      original: item.nicknameOriginal,
      newText: state.userModify.nickname,
    },
  );

  const blurInput = (type: string) => {
    if (type === "password") {
      setPasswordCheck(!passwordLength || !passwordNumber || !passwordSpecial);
    } else if (type === "passwordVerify") {
      setPasswordVerifyCheck(!passwordSame);
    } else if (type === "nickname") {
      setNicknameCheck(!HandleNickname(state.userModify.nickname));
      setNicknameDupStatus({ ...nicknameDupStatus, init: true });
      func.submitNicknameDuplication();
    } else if (type === "userName") {
      setUsernameCheck(state.userModify.userName.length <= 0);
    } else if (type === "phoneNumber") {
      setPhoneNumberCheck(!HandlePhoneNumber(state.userModify.phoneNumber));
      setPhoneDupStatus({ ...phoneDupStatus, init: true });
      func.submitPhoneDuplication();
    } else if (type === "telephoneNumber") {
      setTelephoneNumberCheck(
        state.userModify.telephoneNumber.length
          ? !HandleTelephoneNumber(state.userModify.telephoneNumber)
          : false,
      );
    } else {
      setCompanyCheck(state.userModify.company.length <= 0);
    }
  };

  // 마케팅 메인 체크박스 클릭 함수
  const handleToggleAll = () => {
    func.setToggle(!state.toggle);

    return state.toggle
      ? func.setReceiveCheck({
          email: true,
          kakao: true,
          sms: true,
        })
      : func.setReceiveCheck({
          email: false,
          kakao: false,
          sms: false,
        });
  };

  // 이벤트 체크박스 관리 기능
  const handleToggleSingle = (e: string) => {
    func.setReceiveCheck({
      ...state.receiveCheck,
      [e]: !state.receiveCheck[e],
    });

    if (!state.receiveCheck[e]) {
      func.setToggle(false);
    } else if (handleCurrentToggle(e, !state.receiveCheck[e])) {
      func.setToggle(true);
    }
  };

  const handleCurrentToggle = (type: string, bool: boolean) => {
    if (type === "email") {
      return !state.receiveCheck.sms && !state.receiveCheck.kakao && !bool;
    } else if (type === "sms") {
      return !state.receiveCheck.email && !state.receiveCheck.kakao && !bool;
    } else {
      return !state.receiveCheck.email && !state.receiveCheck.sms && !bool;
    }
  };

  const submitValidation = () => {
    inputTotalValidation();

    if (state.pwButton) {
      if (!passwordLength || !passwordNumber || !passwordSpecial) {
        return passwordRef.current ? passwordRef.current.focus() : null;
      } else if (!passwordSame) {
        return passwordVerifyRef.current
          ? passwordVerifyRef.current.focus()
          : null;
      }
    }

    if (!state.userModify.userName) {
      return nameRef.current ? nameRef.current.focus() : null;
    } else if (
      !state.userModify.nickname ||
      (state.userModify.nickname !== item.nicknameOriginal
        ? apiResponse.nicknameDupFail || nicknameDupStatus.fail
        : false) ||
      !HandleNickname(state.userModify.nickname)
    ) {
      return nicknameRef.current ? nicknameRef.current.focus() : null;
    } else if (
      !state.userModify.phoneNumber ||
      (state.userModify.phoneNumber !== item.phoneNumberOriginal
        ? apiResponse.phoneDupFail || phoneDupStatus.fail
        : false) ||
      !HandlePhoneNumber(state.userModify.phoneNumber)
    ) {
      return phoneRef.current ? phoneRef.current.focus() : null;
    } else if (
      state.userModify.telephoneNumber.length > 0 &&
      !HandleTelephoneNumber(state.userModify.telephoneNumber)
    ) {
      return telephoneRef.current ? telephoneRef.current.focus() : null;
    } else if (!state.userModify.company) {
      return companyRef.current ? companyRef.current.focus() : null;
    } else if (
      (state.userModify.nickname !== item.nicknameOriginal
        ? nicknameDupStatus.success
        : true) &&
      (state.userModify.phoneNumber !== item.phoneNumberOriginal
        ? phoneDupStatus.success
        : true)
    ) {
      func.submitUserInfo();
    }
  };

  const inputTotalValidation = () => {
    setPasswordCheck(!passwordLength || !passwordNumber || !passwordSpecial);
    setPasswordVerifyCheck(!passwordSame);
    setNicknameCheck(!HandleNickname(state.userModify.nickname));
    setUsernameCheck(state.userModify.userName.length <= 0);
    setPhoneNumberCheck(!HandlePhoneNumber(state.userModify.phoneNumber));
    setTelephoneNumberCheck(
      state.userModify.telephoneNumber.length
        ? !HandleTelephoneNumber(state.userModify.telephoneNumber)
        : false,
    );
    setCompanyCheck(state.userModify.company.length <= 0);
  };

  return (
    <>
      <MypageUserChange
        state={{
          toggle: state.toggle,
          pwButton: state.pwButton,
          receiveCheck: state.receiveCheck,
          userModify: state.userModify,
          passwordValidation: {
            passwordLength: passwordLength,
            passwordSpecial: passwordSpecial,
            passwordSame: passwordSame,
            passwordNumber: passwordNumber,
          },

          passwordCheck: passwordCheck,
          passwordVerifyCheck: passwordVerifyCheck,
          nicknameCheck: nicknameCheck,
          usernameCheck: usernameCheck,
          phoneNumberCheck: phoneNumberCheck,
          telephoneNumberCheck: telephoneNumberCheck,
          companyCheck: companyCheck,
        }}
        func={{
          setPwButton: func.setPwButton,
          setUserModify: func.setUserModify,
          submitUserInfo: func.submitUserInfo,
          handleToggleSingle: handleToggleSingle,
          handleToggleAll: handleToggleAll,
          blurInput: blurInput,
          submitValidation: submitValidation,
        }}
        item={{
          nicknameOriginal: item.nicknameOriginal,
          phoneNumberOriginal: item.phoneNumberOriginal,
        }}
        apiResponse={{
          serverError: apiResponse.serverError,
          submitLoading: apiResponse.submitLoading,
          submitError: apiResponse.submitError,
          submiteCode: apiResponse.submiteCode,
          submiteStatus: apiResponse.submiteStatus,
          phoneDupStatus: phoneDupStatus,
          phoneDupLoading: apiResponse.phoneDupLoading,
          nicknameDupStatus: nicknameDupStatus,
          nicknameDupLoading: apiResponse.nicknameDupLoading,
        }}
        options={{
          passwordRef: passwordRef,
          passwordVerifyRef: passwordVerifyRef,
          nicknameRef: nicknameRef,
          nameRef: nameRef,
          phoneRef: phoneRef,
          telephoneRef: telephoneRef,
          companyRef: companyRef,
        }}
      />
    </>
  );
};
