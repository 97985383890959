import { MypagePresenter } from "./Mypage.presenter";
import { ImypageMainInfo, Irecently } from "../../pages/Mypage/types/item.type";

interface Iitem {
  userInfo: ImypageMainInfo;
  recently: Irecently;
}

interface IapiResponse {
  userInfoLoading: boolean;
}

export const MypageModify = ({
  item,
  apiResponse,
}: {
  item: Iitem;
  apiResponse: IapiResponse;
}) => {
  return (
    <MypagePresenter item={{ ...item }} apiResponse={{ ...apiResponse }} />
  );
};
