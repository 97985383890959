interface signature {
  [key: string]: string;
}

interface Istate {
  input: signature;
}

interface Ifunc {
  setInput: (input: signature) => void;
}

interface Iitem {
  inputName: string;
}

export const MypageUserRadioItem = ({
  state,
  func,
  item,
}: {
  state: Istate;
  func: Ifunc;
  item: Iitem;
}) => {
  return (
    <li className="min-w-[210px] w-full flex items-center radio-buttons max-sm:min-w-[150px] whitespace-nowrap">
      <label className="radio-button max-sm:text-default">
        {item.inputName}
        <input
          className="mr-2"
          value={item.inputName}
          type="radio"
          checked={item.inputName === state.input.companyType}
          onChange={() => {
            func.setInput({ ...state.input, companyType: item.inputName });
          }}
        />
        <span className="custom-radio"></span>
      </label>
    </li>
  );
};
