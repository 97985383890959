import { useRECQuery } from "../query/useRECQuery";
import { IREC } from "../types/RECorSMP.type";

export const useGetREC = () => {
  const { data } = useRECQuery("api/rec/", {
    apiName: "REC Information",
  });

  return data ? data : INITIAL_DATA;
};

const INITIAL_OBJETC: IREC = {
  id: 0,
  bz_dd: "",
  cls_prc: 0,
  jeju_avg_prc: 0,
  jeju_hg_prc: 0,
  jeju_lw_prc: 0,
  jeju_trd_rec_value: 0,
  land_avg_prc: 0,
  land_hg_prc: 0,
  land_lw_prc: 0,
  land_trd_rec_value: 0,
};

const INITIAL_DATA: IREC[] = Array.from({ length: 7 }, () => INITIAL_OBJETC);
