import classNames from "classnames";

export const QnAKeyword = ({
  selectKeyword,
  QnAKeywordClick,
}: {
  selectKeyword: string[];
  QnAKeywordClick: (target: string[]) => void;
}) => {
  return (
    <div className="max-w-[1630px] w-full mx-auto my-[50px] px-[50px] flex justify-center gap-x-[15px] max-md:px-[24px]">
      <ul className="flex gap-x-[20px] gap-y-[20px] flex-wrap">
        {INITAIL_KEYWORD.map((text, idx) => (
          <li
            key={idx}
            className={classNames(
              "border-[1px] border-main-color-primary rounded-lg px-[15px] py-[5px] cursor-pointer",
              {
                "bg-main-color-primary text-general-white": selectKeyword.some(
                  (el) => el === text,
                ),
              },
            )}
            onClick={() => {
              selectKeyword.some((el) => el === text)
                ? QnAKeywordClick(selectKeyword.filter((el) => el !== text))
                : QnAKeywordClick([...selectKeyword, text]);
            }}
          >
            {text}
          </li>
        ))}
      </ul>
    </div>
  );
};

const INITAIL_KEYWORD = [
  "거래",
  "발전소",
  "발전량",
  "판매",
  "구매",
  "인증",
  "소유주",
  "중개",
];
