import { MypageMenuList } from "../../../components/Mypage/MypageMenuList";
import { useNavigate } from "react-router-dom";
import { Irecently } from "../types/item.type";

interface Iitem {
  recently: Irecently;
}

export const MypageMenuListModify = ({ item }: { item: Iitem }) => {
  const navigate = useNavigate();

  const moveMypageMenu = (url: string) => {
    navigate(url);
  };
  return (
    <>
      <MypageMenuList
        item={{ recently: item.recently }}
        moveMypageMenu={moveMypageMenu}
      />
    </>
  );
};
