import { inquiryList } from "../../pages/QnA/constants/inquiryList.constant";

interface Istate {
  toggle: boolean;
}

interface Ifunc {
  clickInquiryListItem: (select: string) => void;
}

export const QnAInquiryListBox = ({
  state,
  func,
}: {
  state: Istate;
  func: Ifunc;
}) => {
  return (
    <ul
      className={`w-full bg-general-white overflow-hidden transition-all duration-100 rounded-lg ${
        state.toggle ? "max-h-screen" : "max-h-0 border-[0px]"
      }`}
    >
      {inquiryList.map((el, idx) => (
        <li
          key={idx}
          className="h-[35px] mx-[1px] mb-1  px-4 py-1  flex items-center cursor-pointer hover:bg-main-color-secondary rounded-md"
          onClick={() => func.clickInquiryListItem(el.name)}
        >
          {el.name}
        </li>
      ))}
    </ul>
  );
};
