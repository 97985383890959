type signature = {
  [key: string]: number | null;
};

export const handleCapacity__gte = (capacity: string) => {
  const capacityType: signature = {
    "0~30kW": 0,
    "30~100kW": 30 * 0.001,
    "100~150kW": 100 * 0.001,
    "150~200kW": 150 * 0.001,
    "200~500kW": 200 * 0.001,
    "500~1MW": 500 * 0.001,
    "1MW": 1000 * 0.001,
  };

  return capacityType[capacity];
};

export const handleCapacity__lt = (capacity: string) => {
  const capacityType: signature = {
    "0~30kW": 30 * 0.001,
    "30~100kW": 100 * 0.001,
    "100~150kW": 150 * 0.001,
    "150~200kW": 200 * 0.001,
    "200~500kW": 500 * 0.001,
    "500~1MW": 1000 * 0.001,
    "1MW": null,
  };

  return capacityType[capacity];
};
