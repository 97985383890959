import { useRecordBuySell } from "../query/useRecordBuySell";

export const useGetRecordBuySell = (
  cookie: string,
  userId: number,
  type: string,
) => {
  const { data, isSuccess, isLoading } = useRecordBuySell(
    `api/v1/desired-price/users/${userId}/?type=${type}`,
    {
      cookie: cookie,
      apiName: "BuySellRecord",
    },
  );

  return { registrationList: data, isSuccess, isLoading };
};
