export const MypageCertificationMenu = () => {
  return (
    <nav className="flex px-5 text-default text-text-color-secondary py-2 border-y-[1px] border-y-text-color-disabled">
      <div className="max-w-[294px] w-full">
        <span>발전소명</span>
      </div>
      <div className="max-w-[159px] w-full">
        <span>용량</span>
      </div>
      <div className="max-w-[360px] w-full">
        <span>주소</span>
      </div>
      <div className="max-w-[205px] w-full">
        <span>상태</span>
      </div>
      <div className="max-w-[164px] w-full">
        <span>최종 수정일</span>
      </div>
    </nav>
  );
};
