import { AxiosError } from "axios";
import { useQuery, useMutation } from "react-query";
import {
  axiosMyPlantMainCount,
  axiosMyPlantMainProfile,
  axiosMyPlantList,
  axiosMyPlantInfo,
} from "../api/axiosGetRequest";
import { axiosAddMyPlantInfo } from "../api/axiosPutRequest";

export const useMyPlantMainCountQuery = (
  url: string,
  info: { cookie: string; apiName: string },
) =>
  useQuery([info.apiName, url, info.cookie], axiosMyPlantMainCount, {
    onError: (err) => {
      console.error(`from ${info.apiName} ERROR`, err);
    },
    cacheTime: 0,
    retry: false,
  });

export const useMyPlantMainProfileQuery = (
  url: string,
  info: { cookie: string; apiName: string },
) =>
  useQuery([info.apiName, url, info.cookie], axiosMyPlantMainProfile, {
    onError: (err: AxiosError) => {
      console.error(`from ${info.apiName} ERROR`, err);
    },
    cacheTime: 0,
    retry: false,
  });

export const useMyPlantMainListQuery = (
  url: string,
  info: { cookie: string; apiName: string },
) =>
  useQuery([info.apiName, url, info.cookie], axiosMyPlantList, {
    onError: (err) => {
      console.error(`from ${info.apiName} ERROR`, err);
    },
    cacheTime: 0,
    retry: false,
  });

export const useMyPlantInfoQuery = (
  url: string,
  info: { cookie: string; apiName: string },
) =>
  useQuery([info.apiName, url, info.cookie], axiosMyPlantInfo, {
    onError: (err) => {
      console.error(`from ${info.apiName} ERROR`, err);
    },
    cacheTime: 0,
    retry: false,
  });

export const useAddMyPlantInfoQuery = () =>
  useMutation(axiosAddMyPlantInfo, {
    onSuccess: () => {
      window.location.reload();
    },
    onError: (err: AxiosError, val) => {
      console.error(`from ${val.apiName} ERROR`, err);
    },
  });
