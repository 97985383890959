export const LOCAL_LIST = [
  "전국",
  "서울",
  "경기",
  "인천",
  "강원",
  "경북",
  "경남",
  "대구",
  "부산",
  "울산",
  "충남",
  "충북",
  "대전",
  "세종",
  "제주",
  "전남",
  "전북",
  "광주",
];

export const CAPACITY_LIST = [
  "전체",
  "0~30kW",
  "30~100kW",
  "100~150kW",
  "150~200kW",
  "200~500kW",
  "500~1MW",
  "1MW",
];
