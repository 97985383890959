import { useEffect, useCallback } from "react";
import { useRecoilState } from "recoil";
import { pageNumberList } from "../../../pages/Main/atoms/pageNumberList.atom";

interface Istate {
  total: number;
  current: string | number;
}

interface Ifunc {
  pageChangeHandler: (pageNum: number) => void;
}

function MainPageNation({ state, func }: { state: Istate; func: Ifunc }) {
  const [pageList, setPageList] = useRecoilState(pageNumberList);

  const PageListDefault = useCallback(
    (arr: number[] = []) => {
      // 필터의 리스트 총합이 6개 페이지 초과된 상태
      if (state.total / 28 >= 6) {
        for (let i = 1; i < 6; i++) {
          arr.push(i);
        }
      }
      // 필터의 리스트 총합이 6개 페이지(280개 미만일 경우 페이지네이션 처리, 무조건 280개 미만일 경우에만 적용되는 파트)
      else {
        for (let i = 1; i <= Math.ceil(state.total / 28); i++) {
          arr.push(i);
        }
      }
      return arr;
    },
    [state.total],
  );

  // 페이지네이션 첫번째 자리 번호 클릭 함수
  const firstNumberMove = () => {
    func.pageChangeHandler(PageListDefault()[0]);
    setPageList(PageListDefault());
  };

  const isStack = (arr: number[], type: any) => {
    for (type.current; type.current < type.limit; type.current++) {
      arr.push(type.current);
    }

    type.isPrevious
      ? func.pageChangeHandler(arr[arr.length - 1])
      : func.pageChangeHandler(arr[0]);

    return arr;
  };

  // 다음 버튼 클릭
  const nextPageMove = () => {
    // 현재 페이지 넘버의 리스트 총합이 전체보다 클 경우 다음 페이지 차단
    if ((state.current as number) * 28 > state.total) return;

    return pageList.find((el: number) => (state.current as number) + 1 === el)
      ? func.pageChangeHandler((state.current as number) + 1)
      : setPageList(nextPageList([]));
  };

  const nextPageList = (arr = []) => {
    return ((state.current as number) + 6) * 28 < state.total
      ? isStack(arr, {
          current: (state.current as number) + 1,
          limit: (state.current as number) + 7,
        })
      : isStack(arr, {
          current: (state.current as number) + 1,
          limit: Math.ceil(state.total / 28) + 1,
        });
  };

  // 이전 버튼 클릭
  const previousPageMove = () => {
    return pageList.find((el: number) => (state.current as number) - 1 === el)
      ? func.pageChangeHandler((state.current as number) - 1)
      : setPageList(previousPageList());
  };

  const previousPageList = (arr = []) => {
    if (state.current === 1) return PageListDefault();

    return isStack(arr, {
      current: (state.current as number) - 6,
      limit: state.current,
      isPrevious: true,
    });
  };

  useEffect(() => {
    if (state.current < 6) {
      setPageList(PageListDefault());
    }
  }, [PageListDefault]);

  return (
    <section className="max-w-full w-full h-48 flex justify-center">
      <div className="flex items-center justify-between text-default">
        {state.current > 6 && (
          <div
            className="w-[15px] cursor-pointer mr-[8px]"
            onClick={() => previousPageMove()}
          >
            <img
              className="rotate-180"
              src="/icon_remodel/arrow_pagenation.svg"
            />
          </div>
        )}

        {pageList[0] !== 1 && pageList.length !== 0 ? (
          <span className="cursor-pointer" onClick={() => firstNumberMove()}>
            1
          </span>
        ) : null}

        {pageList[0] !== 1 && pageList.length !== 0 ? (
          <span className="ml-1">...</span>
        ) : null}
        <ul className="flex">
          {pageList.map((el: number) => (
            <li
              className="cursor-pointer mx-[7px] text-center max-md:mx-[3px]"
              data-testid="currentNumber-click"
              onClick={() => {
                return el === state.current ? null : func.pageChangeHandler(el);
              }}
              key={el}
            >
              <p
                className={`px-[10px] max-sm:px-[6px] py-[3px] rounded-full ${
                  el === state.current ? null : "hover:bg-main-color-sub"
                } ${el === state.current ? "bg-main-color-secondary" : null}`}
              >
                {el}
              </p>
            </li>
          ))}
        </ul>

        {pageList.length !== 0 &&
          state.total > 28 &&
          pageList[pageList.length - 1] * 28 < state.total && (
            <div
              className="w-[15px] cursor-pointer ml-[8px]"
              onClick={() => nextPageMove()}
            >
              <img src="/icon_remodel/arrow_pagenation.svg" />
            </div>
          )}
      </div>
    </section>
  );
}

export default MainPageNation;
