import { useEffect, useState } from "react";
import {
  useMyPlantMainCountQuery,
  useMyPlantMainProfileQuery,
  useMyPlantMainListQuery,
  useMyPlantInfoQuery,
} from "../query/useGetMyPlantQuery";
import { useOwnerInfoQuery } from "../query/useOwnerInfoQuery";
import { IcurrentPlant } from "../types/main.typs";
import { IownerFile } from "../types/ownerFile.type";
import { ImyPlantStatusList } from "../types/plantInfo.type";
import { ImuchFile } from "../types/state.type";
import { imageURLtoFile } from "../utils/imageURLtoFile.function";

export const useGetMyPlantMainCount = (cookie: string) => {
  const { data, status } = useMyPlantMainCountQuery("api/v1/my-plant/count/", {
    apiName: "myPlantCountFromMypage",
    cookie: cookie,
  });

  return {
    count: data
      ? {
          ownerComplete: data.verified_success_count
            ? data.verified_success_count
            : 0,
          forSailComplete: data.listing_plant_success_count
            ? data.listing_plant_success_count
            : 0,
          favoriteCount: data.liked_plant_count ? data.liked_plant_count : 0,
        }
      : INITIAL_MY_PLANT_COUNT_DATA,
    countStatus: status,
  };
};

const INITIAL_MY_PLANT_COUNT_DATA: IcurrentPlant = {
  ownerComplete: 0,
  forSailComplete: 0,
  favoriteCount: 0,
};

export const useGetMyPlantProfile = (cookie: string) => {
  const { data, status, isError } = useMyPlantMainProfileQuery(
    "api/v1/my-plant/profile/",
    {
      apiName: "myPlanProfileFromMypage",
      cookie: cookie,
    },
  );

  return {
    years: data
      ? {
          id: data.id ? data.id : 0,
          completionDate: data.completion_date ? data.completion_date : "2000",
        }
      : INITIAL_MY_PLANT_PROFILE,
    yearsStatus: status,
    yearsIsError: isError,
  };
};

const INITIAL_MY_PLANT_PROFILE = {
  id: 0,
  completionDate: "2000",
};

export const useGetMyPlantList = (cookie: string) => {
  const { data, status } = useMyPlantMainListQuery("api/v1/my-plant/", {
    apiName: "myPlantListFromMypage",
    cookie: cookie,
  });

  return {
    data: data ? data : INITIAL_MY_PLANT_LIST_DATA,
    status: status,
  };
};

const INITIAL_MY_PLANT_LIST_DATA: ImyPlantStatusList[] = [
  {
    id: 0,
    plant: {
      plant_name: "",
      capacity: 0,
      updated_address: "",
    },
    status: "",
    status_id: 0,
    updated_datetime: "",
  },
];

export const useGetOwnerInfo = (cookie: string, id?: string) => {
  const [plantLicense, setPlantLicense] = useState<File | null>(null);
  const [commercialLicense, setCommercialLicense] = useState<File | null>(null);
  const [response, setResponse] = useState("loading");

  const { data, status } = useOwnerInfoQuery(`api/v1/license/${id}/`, {
    apiName: "OwnerInfoFromMypage",
    cookie: cookie,
  });

  useEffect(() => {
    // image URL => File Object 변환 함수
    const ayncFile = async () => {
      const resultPlantLicence = await imageURLtoFile(
        data.plant_license,
        data.plant_license_origin_name,
      );
      const resultCommercialLicence = await imageURLtoFile(
        data.commercial_operation_license,
        data.commercial_operation_license_origin_name,
      );
      setPlantLicense(resultPlantLicence);
      setCommercialLicense(resultCommercialLicence);
      setResponse("success");
    };
    if (status === "success") {
      ayncFile();
    }
  }, [data]);

  return {
    data: data
      ? {
          plantLicense: plantLicense,
          plantLicenseName: data.plant_license_origin_name,
          commercialLicense: commercialLicense,
          commercialLicenseName: data.commercial_operation_license_origin_name,
        }
      : INITIAL_OWNER_INFO_LIST_DATA,
    status: response,
  };
};

const INITIAL_OWNER_INFO_LIST_DATA: IownerFile = {
  plantLicense: null,
  plantLicenseName: "스크린샷 2023-03-20 16.56.03.png",
  commercialLicense: null,
  commercialLicenseName: "",
};

export const useGetMyPlantInfo = (cookie: string, id?: string) => {
  const [response, setResponse] = useState("loading");
  const [rpsFile, setRpsFile] = useState<File | null>(null);
  const [energeFile, setEnergeFile] = useState<File | null>(null);
  const [imageFile, setImageFile] = useState<ImuchFile[] | null>(null);

  const { data, status } = useMyPlantInfoQuery(`api/v1/my-plant/${id}/`, {
    apiName: "myPlantInfoFromMypage",
    cookie: cookie,
  });

  useEffect(() => {
    // image URL => File Object 변환 함수
    const ayncFile = async () => {
      const rpsConverter = await imageURLtoFile(
        data.rps_power_gen_image,
        data.rps_power_gen_image_origin_name,
      );
      const energeConverter = await imageURLtoFile(
        data.renewable_certificate_image,
        data.renewable_certificate_image_origin_name,
      );
      // await Promise.all(
      //   data.images.map(
      //     async (
      //       el: { file: string; file_origin_name: string },
      //       idx: number,
      //     ) => {
      //       const coverterItem = await imageURLtoFile(
      //         el.file,
      //         el.file_origin_name,
      //       );

      //       imagesConverter.push({
      //         id: idx,
      //         name: el.file_origin_name,
      //         file: coverterItem,
      //         change: true,
      //       });
      //     },
      //   ),
      // );

      const orderConverterPromiseList = data.images.map(
        async (el: { file: string; file_origin_name: string }, idx: number) => {
          const coverterItem = await imageURLtoFile(
            el.file,
            el.file_origin_name,
          );
          return {
            id: idx,
            name: el.file_origin_name,
            file: coverterItem,
            change: true,
          };
        },
      );

      const imagesConverterList: ImuchFile[] = await Promise.all(
        orderConverterPromiseList,
      );

      setRpsFile(rpsConverter);
      setEnergeFile(energeConverter);
      setImageFile(imagesConverterList);

      setResponse("success");
    };
    if (status === "success") {
      ayncFile();
    }
  }, [data]);

  return {
    data: data
      ? {
          id: data.id,
          images: data.images ? data.images : [],
          imagesConverter: imageFile,
          imagesCurrentId: data.images.length ? data.images.length - 1 : 0,
          inverterCapacity: data.inverter_capacity,
          inverterManufactureCompany: data.inverter_manufacture_company,
          inverterName: data.inverter_name,
          inverterNums: data.inverter_nums,
          moduleCapacity: data.module_capacity,
          moduleManufactureCompany: data.module_manufacture_company,
          moduleName: data.module_name,
          moduleNums: data.module_nums,
          plant: {
            capacity: data.plant.capacity,
            memberType: data.plant.member_type,
            plantName: data.plant.plant_name,
            updatedAddress: data.plant.updated_address,
          },
          renewableCertificateImage: energeFile,
          renewableCertificateImageName:
            data.renewable_certificate_image_origin_name,
          rpsPowerGenImage: rpsFile,
          rpsPowerGenImageName: data.rps_power_gen_image_origin_name,
          verifyLicense: {
            commercialOperationLicense:
              data.verify_license.commercial_operation_license,
            commercialOperationLicenseName:
              data.verify_license.commercial_operation_license_origin_name,
            plantLicense: data.verify_license.plant_license,
            plantLicenseName: data.verify_license.plant_license_origin_name,
          },
        }
      : INITIAL_MY_PLANT_INFO_DATA,
    status: response,
  };
};

const INITIAL_MY_PLANT_INFO_DATA = {
  id: 0,
  images: [],
  imagesConverter: [],
  imagesCurrentId: 0,
  inverterCapacity: "",
  inverterManufactureCompany: "",
  inverterName: "",
  inverterNums: "",
  moduleCapacity: "",
  moduleManufactureCompany: "",
  moduleName: "",
  moduleNums: "",
  plant: {
    capacity: "",
    memberType: "",
    plantName: "",
    updatedAddress: "",
  },
  renewableCertificateImage: null,
  renewableCertificateImageName: "",
  rpsPowerGenImage: null,
  rpsPowerGenImageName: "",
  verifyLicense: {
    commercialOperationLicense: null,
    commercialOperationLicenseName: "",
    plantLicense: null,
    plantLicenseName: "",
  },
};
