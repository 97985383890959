import axios from "axios";

export async function axiosSubmitPrice(queryKey: any) {
  const removeComma = queryKey.price.replace(/,/g, "");

  const response = await axios.post(
    process.env.REACT_APP_SERVER_URL + queryKey.url,
    {
      price: Number(removeComma),
    },
    {
      withCredentials: true,
      headers: {
        Authorization: "Token " + queryKey.cookie,
      },
    },
  );
  return response.data;
}
