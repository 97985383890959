export const buySellMenuPing = (type: string, currentTime: string | null) => {
  if (currentTime === null) return false;

  const LOCAL_STORAGE_VALUE = localStorage.getItem(type);
  const LOCAL_STORAGE_TIME = new Date(LOCAL_STORAGE_VALUE as string).getTime();
  const CURRENT_GET_UTC = new Date(currentTime);
  const CURRENT_TIME = new Date(CURRENT_GET_UTC).getTime();

  if (LOCAL_STORAGE_TIME <= CURRENT_TIME) {
    return true;
  } else {
    return false;
  }
};
