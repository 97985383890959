import { DetailVariousMapNaver } from "./DetailVariousMapNaver";
import { DetailVariousMapSentinel } from "./DetailVariousMapSentinel";

interface Istate {
  latitude: number;
  longitude: number;
  curretNaverValue: string;
  notFound: boolean;
  typeNaverMap: string;
}

interface IapiResponse {
  status: string;
}

interface Ifunc {
  changeNaverMap: (currentMap: string) => void;
}

export const DetailVariousMap = ({
  state,
  apiResponse,
  func,
}: {
  state: Istate;
  apiResponse: IapiResponse;
  func: Ifunc;
}) => {
  return (
    <section className="w-[47%] mt-28 z-0 max-xl:w-full">
      <DetailVariousMapNaver
        state={{
          latitude: state.latitude,
          longitude: state.longitude,
          curretNaverValue: state.curretNaverValue,
          notFound: state.notFound,
          typeNaverMap: state.typeNaverMap,
        }}
        apiResponse={{
          status: apiResponse.status,
        }}
        func={{
          changeNaverMap: func.changeNaverMap,
        }}
      />
      <DetailVariousMapSentinel />
    </section>
  );
};
