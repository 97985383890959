import { useRecoilValue } from "recoil";
import { MainBanner } from "../../components/Main/MainBanner";
import { MainPlantListFilter } from "../../components/Main/MainPlantListFilter";
import { MainPlantListHeader } from "../../components/Main/MainPlantListHeader";
import { MainPlantList } from "../../components/Main/MainPlantList";
import MainPageNation from "../../components/UI/Paging/MainPageNation";
// atmos
import { filterCategory } from "./atoms/filter.atoms";
import { ModalBlur } from "../../components/UI/Modal/ModalBlur";
import classNames from "classnames";
import { MobileMainFilter } from "../../components/Main/Mobile/MobileMainFilter";
import { useState } from "react";
import { IplantList } from "./types/plantList.type";

interface Istate {
  toggle: boolean;
  blurModal: boolean;
}

interface Ifunc {
  selectFilter: (type: string, value: string) => void;
  resetFilter: () => void;
  pageChangeHandler: (pageNum: number) => void;
  setToggle: (bool: boolean) => void;
  setBlurModal: (bool: boolean) => void;
  setPageNumberList: (value: number[]) => void;
}

interface Iitem {
  plantList: IplantList[];
  plantListTotal: number;
}

interface IapiResponse {
  loading: boolean;
  error: boolean;
  axiosCode: string;
  errorStatus: number;
}

export const MainPresenter = ({
  state,
  func,
  item,
  apiResponse,
}: {
  state: Istate;
  func: Ifunc;
  item: Iitem;
  apiResponse: IapiResponse;
}) => {
  const filter = useRecoilValue(filterCategory);

  const [mobileFilterOpen, setMobileFilterOpen] = useState(false);

  return (
    <div className="w-full pt-16">
      <MainBanner />

      <MobileMainFilter
        state={{
          filter: filter,
          mobileFilterOpen: mobileFilterOpen,
        }}
        func={{
          selectFilter: func.selectFilter,
          resetFilter: func.resetFilter,
          setMobileFilterOpen: setMobileFilterOpen,
        }}
      />

      <div
        className={classNames(
          "max-w-[1630px] flex mt-20 mx-auto px-[50px] relative max-md:mt-0",
          { "blur-md": state.blurModal },
          "max-md:px-[24px] max-sm:px-[16px]",
        )}
      >
        <MainPlantListFilter
          state={{
            filter: filter,
          }}
          func={{
            selectFilter: func.selectFilter,
            resetFilter: func.resetFilter,
          }}
        />
        <section className="w-full">
          <MainPlantListHeader
            state={{
              filter: filter,
              total: item.plantListTotal,
              toggle: state.toggle,
            }}
            func={{
              selectFilter: func.selectFilter,
              setToggle: func.setToggle,
              setMobileFilterOpen: setMobileFilterOpen,
            }}
            apiResponse={{
              loading: apiResponse.loading,
            }}
          />
          <MainPlantList
            state={{ plantList: item.plantList }}
            func={{ setBlurModal: func.setBlurModal }}
            apiResponse={{
              isLoading: apiResponse.loading,
              error: apiResponse.error,
              axiosCode: apiResponse.axiosCode,
              errorStatus: apiResponse.errorStatus,
            }}
          />
          {!apiResponse.error && (
            <MainPageNation
              state={{
                total: item.plantListTotal,
                current: filter.page,
              }}
              func={{
                pageChangeHandler: func.pageChangeHandler,
              }}
            />
          )}
        </section>
      </div>

      {state.blurModal && (
        <ModalBlur
          state={{
            mobileFilterOpen: mobileFilterOpen,
          }}
          setBlurModal={func.setBlurModal}
        />
      )}
    </div>
  );
};
