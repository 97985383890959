import { useRecoilValue } from "recoil";
import { DetailVariousMapSentinelItem } from "./DetailVariousMapSentinelItem";
import { sentinelList } from "../../../pages/Detail/atoms/sentinelList";

interface IdetailVariousMapSentinel {
  date: string;
  url: string;
}

export const DetailVariousMapSentinel = () => {
  const sentinelObject = useRecoilValue(sentinelList);

  return (
    <div className="mt-4">
      <ul className="flex justify-between gap-x-[16px] max-md:gap-x-[12px] max-sm:gap-x-[8px]">
        {sentinelObject.map((el: IdetailVariousMapSentinel[], idx: number) => (
          <DetailVariousMapSentinelItem
            date={el[0].date}
            url={el[0].url}
            key={idx}
          />
        ))}
      </ul>
      <p className="mt-2 text-footer text-text-color-secondary text-right max-md:text-left">
        sentinel 2에서 촬영한 위성사진으로 해외 서비스 제공시 해당 국가의 태양광
        발전소 위성사진 제공이 가능합니다.
      </p>
    </div>
  );
};
