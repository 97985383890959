import { ReactElement } from "react";
import { IfilterItem } from "../../../pages/Mypage/types/state.type";

type signature = {
  [key: string]: string | number;
};

interface Istate {
  toggle: boolean;
  filter: IfilterItem;
}

interface Ifunc {
  setToggle: (toggle: boolean) => void;
}

interface Ioptions {
  filterType: string;
  component: ReactElement;
}

export const MainPlantListBox = ({
  state,
  func,
  options,
}: {
  state: Istate;
  func: Ifunc;
  options: Ioptions;
}) => {
  const CURRET_FILTER: signature = {
    지역: state.filter.local,
    용량: state.filter.capacity,
    지목: state.filter.land,
    준공년도: "전체",
  };

  return (
    <div className="mb-7">
      <div className="flex items-center justify-between text-base mb-[20px]">
        <h1 className="text-main-color-primary font-semibold text-listFirst max-sm:font-medium">
          {options.filterType}
        </h1>
        <div className="flex items-center gap-x-3 mr-[25px]">
          <p className="text-text-color-secondary text-footer">
            {CURRET_FILTER[options.filterType]}
          </p>

          <img
            className="cursor-pointer"
            src={`${
              state.toggle
                ? "/icon_remodel/minus.svg"
                : "/icon_remodel/plus.svg"
            }`}
            onClick={() => func.setToggle(!state.toggle)}
          />
        </div>
      </div>
      {options.component}
    </div>
  );
};
