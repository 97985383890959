import { IfavoriteModal } from "../../../pages/Mypage/types/state.type";

export const MypageFavoriteModal = ({
  deleteModal,
  setDeleteModal,
  deleteFavoritePlant,
}: {
  deleteModal: IfavoriteModal;
  setDeleteModal: (bool: IfavoriteModal) => void;
  deleteFavoritePlant: () => void;
}) => {
  return (
    <>
      <div className="w-full h-full fixed bottom-0 left-0 top-0 right-0 z-[100]">
        <div className="w-full h-full absolute   opacity-50 bg-text-color-primary"></div>

        <div className="flex relative min-h-full justify-center items-center">
          <div
            className="relative flex flex-col justify-end items-center bg-general-white
          rounded-lg border-main-color-primary border-[2px]"
          >
            <h1 className="py-[38px] px-[89px]">찜하기를 삭제합니다.</h1>
            <div className="w-full border-t-[2px] border-t-main-color-primary">
              <button
                className="w-1/2 py-4"
                onClick={() => setDeleteModal({ ...deleteModal, modal: false })}
              >
                취소
              </button>
              <button
                className="w-1/2 py-4 bg-main-color-primary text-general-white"
                onClick={deleteFavoritePlant}
              >
                확인
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
