import { useEffect, useState } from "react";

declare const window: typeof globalThis & {
  naver: any;
};

export const useRoadView = (
  latitude: number,
  longitude: number,
  curretNaverValue: string,
  setNotFound: (bool: boolean) => void,
) => {
  const { naver } = window;

  // 네이버 로드뷰 중복 렌더 차단
  const [mapBrake, setMapBrake] = useState(false);
  useEffect(() => {
    function panoSync() {
      // 로드뷰 중복 렌더 방지(탈출문)
      if (!latitude || !longitude || mapBrake) {
        return;
      }

      // Panorama 기본 설정
      const pano = new naver.maps.Panorama("pano", {
        position: new naver.maps.LatLng(latitude, longitude),
        flightSpot: true, // 항공 아이콘 표시 여부, default: true
      });

      // 마커 설정
      const marker = new naver.maps.Marker({
        position: new naver.maps.LatLng(latitude, longitude),
        map: pano,
      });

      // Panorama 초기화 후 셋팅
      naver.maps.Event.addListener(pano, "init", function () {
        marker.setMap(pano);
        var proj = pano.getProjection();
        var lookAtPov = proj.fromCoordToPov(marker.getPosition());
        if (lookAtPov) {
          pano.setPov(lookAtPov);
        }
      });

      // Panorama 상태 체크
      naver.maps.Event.addListener(
        pano,
        "pano_status",
        function (status: string) {
          pano.setVisible(true);
          if (status === "ERROR") {
            pano.setVisible(false);
            setNotFound(true);
          } else {
            setNotFound(false);
          }
        },
      );
    }

    // pano ID 파싱 전에 사전 호출 차단
    if (
      latitude &&
      longitude &&
      naver?.maps?.Panorama &&
      curretNaverValue === "pano"
    ) {
      panoSync();
      setMapBrake(true);
    }
  }, [naver?.maps?.Panorama, mapBrake, curretNaverValue]);
};
