import { useState } from "react";
import { MypageInquiry } from "../../../components/Mypage/MypageInquiry/MypageInquiry";
import { useInquiryDeleteResult } from "../hooks/useInquiryStatusResult";
import {
  IinquiryList,
  IinquiryListDeleteModal,
  IinquiryListUpdateModal,
} from "../types/state.type";

interface Istate {
  deleteInquiryList: number[];
  deleteModal: IinquiryListDeleteModal;
  updateModal: IinquiryListUpdateModal;
  selectInquiryList: string;
  inquiryText: string;
}

interface IapiResponse {
  status: string;
  updatedStatus: string;
  updatedError?: null | number;
  updatedId: number;
  deletedStatus: string;
  deletedError?: null | number;
}

interface Ifunc {
  setDeleteInquiryList: (array: number[]) => void;
  deleteInquiryPlant: () => void;
  updateInquiryPlant: () => void;
  setDeleteModal: (value: IinquiryListDeleteModal) => void;
  setUpdateModal: (value: IinquiryListUpdateModal) => void;
  submitInquiry: () => void;
  setSelectInquiryList: (text: string) => void;
  setInquiryText: (text: string) => void;
}

interface Iitem {
  inquiryList: IinquiryList[];
}

export const MypageInquiryModify = ({
  state,
  item,
  apiResponse,
  func,
}: {
  state: Istate;
  item: Iitem;
  apiResponse: IapiResponse;
  func: Ifunc;
}) => {
  // 답변 완료 관련 모달 상태값
  const [alreadyInquiryDelete, setAlreadyInquiryDelete] = useState(false);

  const [toggle, setToggle] = useState(false);

  const clickInquiryListItem = (selected: string) => {
    func.setSelectInquiryList(selected);
    setToggle(false);
  };

  // 삭제 모달 관리 함수
  const HandleDeleteModal = (bool: IinquiryListDeleteModal | string) => {
    if (typeof bool === "string") return func.deleteInquiryPlant();
    func.setDeleteModal(bool);
  };

  // 수정 모달 관리 함수
  const HandleUpdateModal = (bool: IinquiryListUpdateModal | string) => {
    if (typeof bool === "string") return func.updateInquiryPlant();
    func.setUpdateModal(bool);
  };

  // 문의 삭제 비동기 과정 관리 hooks
  const deleteResult = useInquiryDeleteResult(
    {
      status: apiResponse.deletedStatus,
      deleteInquiryList: state.deleteInquiryList,
      deleteModal: state.deleteModal,
    },
    {
      setAlreadyInquiry: setAlreadyInquiryDelete,
      setDeleteInquiryList: func.setDeleteInquiryList,
      setDeleteModal: func.setDeleteModal,
    },
  );

  return (
    <MypageInquiry
      state={{
        deleteInquiryList: state.deleteInquiryList,
        deleteModal: state.deleteModal,
        updateModal: state.updateModal,
        alreadyInquiryDelete: alreadyInquiryDelete,
        selectInquiryList: state.selectInquiryList,
        toggle: toggle,
        inquiryText: state.inquiryText,
      }}
      item={{
        inquiryList: item.inquiryList,
      }}
      apiResponse={{
        status: apiResponse.status,
        updatedStatus: apiResponse.updatedStatus,
        updatedError: apiResponse.updatedError,
        updatedId: apiResponse.updatedId,
        deletedStatus: apiResponse.deletedStatus,
        deletedError: apiResponse.deletedError,
      }}
      func={{
        HandleDeleteModal: HandleDeleteModal,
        setAlreadyInquiryDelete: setAlreadyInquiryDelete,
        HandleUpdateModal: HandleUpdateModal,
        submitInquiry: func.submitInquiry,
        setToggle: setToggle,
        clickInquiryListItem: clickInquiryListItem,
        setInquiryText: func.setInquiryText,
      }}
    />
  );
};
