import { AxiosError } from "axios";
import { useQuery } from "react-query";
import { axiosUserMessageRecord } from "../api/axiosGetRequest";

interface Iaccess {
  apiName: string;
  authorization?: string;
}

export const useUserMessageRecordQuery = (url: string, info: Iaccess) =>
  useQuery(
    ["userMessageRecordFromMessage", url, info.authorization],
    axiosUserMessageRecord,
    {
      onError: (err: AxiosError) => {
        console.error(`from ${info.apiName} ERROR`, err);
      },
      cacheTime: 0,
      retry: false,
    },
  );
