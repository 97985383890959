import { MypageUserCheck } from "./MypageUserCheck";
import { MypageUserChangeFetch } from "../../../pages/Mypage/module_fetch/MypageUserChange.fetch";

interface Istate {
  password: string;
}

interface Ifunc {
  submitCheckPassword: () => void;
  setPassword: (password: string) => void;
}

interface IapiResponse {
  success: boolean;
  loading: boolean;
  axiosCode: string;
  error: boolean;
  errorStatus: number;
}

export const MypageUserInfo = ({
  state,
  func,
  apiResponse,
}: {
  state: Istate;
  func: Ifunc;
  apiResponse: IapiResponse;
}) => {
  return (
    <section>
      <h1 className="text-listFirst font-bold mb-4 max-sm:font-medium">
        내 정보 수정
      </h1>
      {apiResponse.success ? (
        <MypageUserChangeFetch />
      ) : (
        <MypageUserCheck
          state={{
            password: state.password,
          }}
          func={{
            submitCheckPassword: func.submitCheckPassword,
            setPassword: func.setPassword,
          }}
          apiResponse={{
            success: apiResponse.success,
            loading: apiResponse.loading,
            axiosCode: apiResponse.axiosCode,
            error: apiResponse.error,
            errorStatus: apiResponse.errorStatus,
          }}
        />
      )}
    </section>
  );
};
