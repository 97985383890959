import { ISMP } from "../../../pages/Detail/types/RECorSMP.type";

export const DetailEnergeSMP = ({ smpData }: { smpData: ISMP[] }) => {
  return (
    <div className="flex justify-between mt-4 mb-[58px] leading-[100%]">
      <div>
        <h1 className="text-listSecond mb-1">SMP (오늘 최고가)</h1>
        <span className="text-smallExplanation">매일 업데이트</span>
      </div>

      <article>{currentSMP(smpData)}</article>
    </div>
  );
};

// 이전값과 비교 후 css 및 이미지 결정 함수
function colorResult(value: number) {
  if (value > 0) {
    return {
      color: "text-general-red",
      img: "/icon_remodel/plus_triangle.svg",
    };
  } else if (value === 0) {
    return {
      color: "text-text-color-primary",
      img: "/icon_remodel/same_line.svg",
    };
  } else {
    return {
      color: "text-general-blue",
      img: "/icon_remodel/minus_triangle.svg",
    };
  }
}

const currentSMP = (smpData: ISMP[]) => {
  const getSmpData =
    smpData[0] &&
    smpData[1] &&
    smpData[0].land_smp &&
    smpData[0].jeju_smp &&
    smpData[1].land_smp &&
    smpData[1].jeju_smp;

  return getSmpData ? <SuccessSMP smpData={smpData} /> : <ServerErrorSMP />;
};

const ServerErrorSMP = () => {
  return (
    <>
      <div className="flex justify-between items-center mb-6">
        <span className="text-listSecond">육지</span>
        <h1 className={`w-[100px] text-subValue text-center`}>-</h1>
        <div className="w-[11px] ml-3">-</div>
      </div>

      <div className="flex justify-between items-center">
        <span className="text-listSecond">제주</span>
        <h1 className={`w-[100px] text-subValue text-center`}>-</h1>
        <div className="w-[11px] ml-3">-</div>
      </div>
    </>
  );
};

const SuccessSMP = ({ smpData }: { smpData: ISMP[] }) => {
  const DIFFERENCE_JEJU_SMP = smpData[1].jeju_smp - smpData[0].jeju_smp;
  const DIFFERENCE_LAND_SMP =
    smpData[0] && smpData[1] && smpData[1].land_smp - smpData[0].land_smp;

  return (
    <>
      <div className="flex justify-between items-center mb-5">
        <span className="text-listSecond">육지</span>
        <h1
          className={`text-subValue text-right pl-[16px] ${
            colorResult(DIFFERENCE_LAND_SMP).color
          } whitespace-nowrap overflow-hidden text-ellipsis leading-[24px]`}
        >
          {smpData[1].land_smp} 원
        </h1>
        <div className="w-[11px] ml-3">
          <img src={colorResult(DIFFERENCE_LAND_SMP).img} />
        </div>
      </div>

      <div className="flex justify-between items-center">
        <span className="text-listSecond">제주</span>
        <h1
          className={`text-subValue text-right pl-[16px] ${
            colorResult(DIFFERENCE_JEJU_SMP).color
          } whitespace-nowrap overflow-hidden text-ellipsis leading-[24px]`}
        >
          {smpData[1].jeju_smp} 원
        </h1>
        <div className="w-[11px] ml-3">
          <img src={colorResult(DIFFERENCE_JEJU_SMP).img} />
        </div>
      </div>
    </>
  );
};
