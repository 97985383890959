import {
  IinquiryListUpdateModal,
  IinquiryListDeleteModal,
} from "../../../pages/Mypage/types/state.type";

interface Ifunc {
  deleteModalFunc: (bool: IinquiryListDeleteModal | string) => void;
  updateModalFunc: (bool: IinquiryListUpdateModal | string) => void;
}

interface Ioptions {
  contents: string;
  modalType: string;
}

export const MypageInquirySelectModal = ({
  func,
  options,
}: {
  func: Ifunc;
  options: Ioptions;
}) => {
  return (
    <>
      <div className="w-full h-full fixed bottom-0 left-0 top-0 right-0 z-[100]">
        <div className="w-full h-full absolute  opacity-50 bg-text-color-primary"></div>
        <div className="flex relative min-h-full justify-center items-center">
          <div
            className="relative flex flex-col justify-end items-center bg-general-white
          rounded-xl border-main-color-primary border-[2px] drop-shadow-[2px_2px_4px_rgba(0,0,0,0.25)]"
          >
            <h1 className="py-[38px] px-[96px]">{options.contents}</h1>
            <div className="w-full border-t-[2px] border-t-main-color-primary">
              <button
                className="w-1/2 py-4"
                onClick={() => {
                  if (options.modalType === "delete") {
                    return func.deleteModalFunc({
                      modal: false,
                      inquiry_id: null,
                    });
                  } else {
                    return func.updateModalFunc({
                      modal: false,
                      inquiry_id: null,
                      contents: "",
                    });
                  }
                }}
              >
                취소
              </button>
              <button
                className="w-1/2 py-4 bg-main-color-primary text-general-white"
                onClick={() => {
                  {
                    if (options.modalType === "delete") {
                      return func.deleteModalFunc(options.modalType);
                    } else {
                      return func.updateModalFunc(options.modalType);
                    }
                  }
                }}
              >
                확인
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const MypageInquiryNoticeModal = ({
  contents,
  setAlreadyInquiry,
}: {
  contents: string;
  setAlreadyInquiry: (bool: boolean) => void;
}) => {
  return (
    <>
      <div className="w-full h-full fixed bottom-0 left-0 top-0 right-0 z-[100]">
        <div className="w-full h-full absolute  opacity-50 bg-text-color-primary"></div>
        <div className="flex relative min-h-full justify-center items-center">
          <div
            className="relative flex flex-col justify-end items-center bg-general-white
          rounded-xl border-main-color-primary border-[2px] drop-shadow-[2px_2px_4px_rgba(0,0,0,0.25)]"
          >
            <h1 className="py-[38px] px-[50px]">{contents}</h1>
            <div
              className="w-full text-center  py-4 bg-main-color-primary text-general-white cursor-pointer"
              onClick={() => setAlreadyInquiry(false)}
            >
              확인
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
