import { useCookies } from "react-cookie";
import { MypageMainModify } from "../module_modify/MypageMain.modify";
import {
  useGetMyPlantMainCount,
  useGetMyPlantProfile,
} from "../hooks/useGetMyPlant";

export const MypageMainFetch = () => {
  const [cookie] = useCookies(["60hz-user"]);

  const { count, countStatus } = useGetMyPlantMainCount(cookie["60hz-user"]);

  const { years, yearsStatus, yearsIsError } = useGetMyPlantProfile(
    cookie["60hz-user"],
  );

  return (
    <MypageMainModify
      item={{
        currentPlant: count,
        yearsPlant: years,
      }}
      apiResponse={{
        countStatus: countStatus,
        yearsStatus: yearsStatus,
        yearsIsError: yearsIsError,
      }}
    />
  );
};
