import { useEffect } from "react";

function useScroll(updateScroll: () => void) {
  const throttle = (callback: () => void, delay: number) => {
    let timer: NodeJS.Timeout | null = null;

    // 클로저 함수 발동
    // 외부 함수의 변수인 callback 함수를 접근하여 사용
    // 우려되는 점은 timer가 계속 저장되어 메모리 누수 발생()
    return () => {
      // 중복 방지 (두번 셋타임 들어가 콜백 부르기에 비효율적 이므로)
      if (timer) return;
      timer = setTimeout(() => {
        callback();
        timer = null;
      }, delay);
    };
  };

  const handleScroll = throttle(updateScroll, 100);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
}

export default useScroll;
