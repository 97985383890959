import classNames from "classnames";

interface Istate {
  toggle: boolean;
  selectInquiryList: string;
}

interface Ifunc {
  setToggle: (toggle: boolean) => void;
}

export const QnAInquiryCurrent = ({
  state,
  func,
}: {
  state: Istate;
  func: Ifunc;
}) => {
  return (
    <>
      <div
        className="w-[300px] h-[47px] px-4 flex justify-between items-center cursor-pointer"
        onClick={() => func.setToggle(!state.toggle)}
      >
        <span
          className={classNames({
            "text-text-color-disabled": state.selectInquiryList === "",
          })}
        >
          {state.selectInquiryList === ""
            ? "문의 유형을 선택해주세요"
            : state.selectInquiryList}
        </span>
        <img
          className={`${state.toggle ? "rotate-180" : "rotate-0"}`}
          src="/icon_remodel/arrow.svg"
        />
      </div>
    </>
  );
};
