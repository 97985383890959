interface signature {
  [key: string | number]: string | object;
}

export const SUBMIT_LOGIN_ERROR_TYPE: signature = {
  400: "아이디 혹은 비밀번호를 잘못 작성하셨습니다.",
  404: "잘못된 주소를 요청하셨습니다.",
};

export const SUBMIT_FIND_USER_INFO_ERROR_TYPE: signature = {
  400: "잘못된 정보를 입력하셨습니다.",
  404: "잘못된 주소를 요청하셨습니다.",
};
