import { MypageFavorite } from "../../../components/Mypage/MypageFavorite/MypageFavorite";
import { IplantList } from "../types/plantInfo.type";
import { IfavoriteModal } from "../types/state.type";

export const MypageFavoriteModify = ({
  plantList,
  deleteList,
  status,
  deleteModal,
  setDeleteModal,
  deleteFavoritePlant,
}: {
  plantList: IplantList[];
  deleteList: number[];
  status: string;
  deleteModal: IfavoriteModal;
  setDeleteModal: (bool: IfavoriteModal) => void;
  deleteFavoritePlant: () => void;
}) => {
  const modalOnclick = (id?: number) => {
    setDeleteModal({
      modal: true,
      plant_id: id,
    });
  };

  return (
    <>
      <MypageFavorite
        plantList={plantList}
        deleteList={deleteList}
        status={status}
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        modalOnclick={modalOnclick}
        deleteFavoritePlant={deleteFavoritePlant}
      />
    </>
  );
};
