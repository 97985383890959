import { PlantListItem } from "../../../components/UI/PlantList/PlantListItem";
import { IplantList } from "../types/plantList.type";
import { useFavoriteError } from "../hooks/useFavorite";

type ImodalSellBuy = {
  open: boolean;
  type: string;
  plantId: number;
};

interface Ifunc {
  likeSubmitFromServer: (id?: number) => void;
  setFavorite: (bool: boolean) => void;
  setModalFavorite: (bool: boolean) => void;
  setModalSellBuy: (object: ImodalSellBuy) => void;
  setBlurModal: (bool: boolean) => void;
}

interface Istate {
  favorite: boolean;
}

interface IapiResponse {
  submitError?: null | number;
}

export const PlantListItemModify = ({
  item,
  func,
  state,
  apiResponse,
}: {
  item: IplantList;
  func: Ifunc;
  state: Istate;
  apiResponse: IapiResponse;
}) => {
  const likeError = useFavoriteError(
    { setFavorite: func.setFavorite, setModal: func.setModalFavorite },
    apiResponse.submitError,
  );

  return (
    <PlantListItem
      item={item}
      state={{ currentFavorite: state.favorite }}
      func={{
        likeSubmitFromServer: func.likeSubmitFromServer,
        setModalSellBuy: func.setModalSellBuy,
        setBlurModal: func.setBlurModal,
      }}
    />
  );
};
