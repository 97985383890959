import { useBodyScrollLock } from "../../../utils_public/useBodyScrollLock";

export const ModalConfirmation = ({
  contentsTop,
  contentsBottom,
  setModal,
}: {
  contentsTop: string;
  contentsBottom: string;
  setModal: (bool: boolean) => void;
}) => {
  useBodyScrollLock();

  return (
    <>
      <div className="w-full h-full fixed bottom-0 left-0 top-0 right-0 z-[100]">
        <div className="w-full h-full absolute  bg-opacity-40 bg-text-color-primary"></div>

        <div className="flex relative min-h-full justify-center items-center">
          <div
            className="relative flex flex-col justify-end items-center bg-general-white
          rounded-xl border-main-color-primary border-[2px] drop-shadow-[2px_2px_4px_rgba(0,0,0,0.25)]"
          >
            <div className="py-[35px] px-[50px]">
              <h1 className="whitespace-pre-line text-center leading-[100%]">
                {contentsTop}
              </h1>
              <h1 className="pt-[8px] whitespace-pre-line text-center leading-[100%]">
                {contentsBottom}
              </h1>
            </div>
            <button
              className="w-full py-4 bg-main-color-primary text-general-white rounded-b-[5px]"
              onClick={() => {
                setModal(false);
              }}
            >
              확인
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
