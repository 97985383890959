import { atom } from "recoil";

interface IsentinelList {
  date: string;
  url: string;
}

export const sentinelList = atom<IsentinelList[][]>({
  key: "sentinelList",
  default: [],
});

// date: string; url: string
