import { MypageUserInfo } from "../../../components/Mypage/MypageUserInfo/MypageUserInfo";

interface Istate {
  password: string;
}

interface Ifunc {
  submitCheckPassword: () => void;
  setPassword: (password: string) => void;
}

interface IapiResponse {
  success: boolean;
  loading: boolean;
  axiosCode: string;
  error: boolean;
  errorStatus: number;
}

export const MypageUserInfoModify = ({
  state,
  func,
  apiResponse,
}: {
  state: Istate;
  func: Ifunc;
  apiResponse: IapiResponse;
}) => {
  return (
    <MypageUserInfo
      state={{
        password: state.password,
      }}
      func={{
        submitCheckPassword: func.submitCheckPassword,
        setPassword: func.setPassword,
      }}
      apiResponse={{
        success: apiResponse.success,
        loading: apiResponse.loading,
        axiosCode: apiResponse.axiosCode,
        error: apiResponse.error,
        errorStatus: apiResponse.errorStatus,
      }}
    />
  );
};
