import axios from "axios";

export const axiosDeleteFavorite = async (queryKey: any) => {
  const response = await axios.delete(
    process.env.REACT_APP_SERVER_URL + queryKey.url,
    {
      withCredentials: true,
      headers: {
        Authorization: " Token " + queryKey.cookie,
      },
    },
  );

  return response.data;
};

export const axiosDeleteInquiryList = async (queryKey: any) => {
  const response = await axios.delete(
    process.env.REACT_APP_SERVER_URL + queryKey.url,
    {
      withCredentials: true,
      headers: {
        Authorization: " Token " + queryKey.cookie,
      },
    },
  );

  return response.data;
};
