import { DefaultLoading } from "../UI/Loading/Loading";
import { ChatPlantListItem } from "./ChatPlantListItem";
import { IplantRoomList, Imessage } from "../../pages/Chat/types/item.type";
import { IpingList } from "../../pages/Chat/types/state.type";
import { useState } from "react";

type IselectOption = {
  plant: string;
  currentMessageId: number;
  user: string;
};

interface Ifunc {
  setSelectOption: (value: IselectOption) => void;
  setSelectPlant: (value: Imessage[]) => void;
  setPlantPingList: (value: IpingList) => void;
  searchPlantName: (text: string) => void;
  searchPlantReset: () => void;
}

interface Istate {
  selectOption: IselectOption;
  plantPingList: IpingList;
}

interface IapiResponse {
  plantRoomStatus: string;
}

interface Iitem {
  plantRoomList: IplantRoomList[];
}

export const ChatPlantList = ({
  state,
  func,
  apiResponse,
  item,
}: {
  state: Istate;
  func: Ifunc;
  apiResponse: IapiResponse;
  item: Iitem;
}) => {
  const [plantName, setPlantName] = useState("");
  return (
    <section className="max-w-[250px] w-full border-r-[1px] border-r-border-color-dividers transition-all duration-200 max-xl:max-w-[190px] max-lg:hidden">
      <div
        className="bg-main-color-primary text-general-white text-center py-[7px] mb-[5px] cursor-pointer max-xl:text-default"
        onClick={() => {
          func.searchPlantReset();
          setPlantName("");
          func.setSelectOption({ plant: "", currentMessageId: 0, user: "" });
        }}
      >
        발전소 검색 초기화
      </div>
      <form
        className="relative h-[40px]"
        onSubmit={(e) => {
          e.preventDefault();
          if (plantName === "") return alert("발전소 이름을 작성해주세요.");
          func.searchPlantName(plantName);
          func.setSelectOption({ plant: "", currentMessageId: 0, user: "" });
        }}
      >
        <input
          className="max-w-full w-full py-[10px] pl-[10px] pr-[30px]
          border-border-color-dividers outline-none text-default bg-general-lightGray focus:bg-general-white focus:border-[1px] max-xl:text-footer"
          placeholder="발전소를 검색해주세요."
          value={plantName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPlantName(e.target.value)
          }
        />

        <img
          className="absolute top-[12px] right-[10px] cursor-pointer"
          src="/icon_remodel/search.svg"
          onClick={() => {
            if (plantName === "") return alert("발전소 이름을 작성해주세요.");
            func.searchPlantName(plantName);
            func.setSelectOption({ plant: "", currentMessageId: 0, user: "" });
          }}
        />
      </form>
      <ul className="h-[605px] flex flex-col gap-y-[5px] overflow-y-scroll scrollbar-hide">
        {apiResponse.plantRoomStatus === "loading" ? (
          <div className="w-[70%] mt-[40px] mx-auto">
            <DefaultLoading />
          </div>
        ) : item.plantRoomList.length > 0 ? (
          item.plantRoomList.map((el, idx) => (
            <ChatPlantListItem
              item={{ ...el }}
              key={idx}
              state={{
                selectOption: state.selectOption,
                plantPingList: state.plantPingList,
              }}
              func={{
                setSelectOption: func.setSelectOption,
                setSelectPlant: func.setSelectPlant,
                setPlantPingList: func.setPlantPingList,
              }}
            />
          ))
        ) : (
          <h1 className="mt-[50px]">해당 발전소는 검색 내역이 없습니다.</h1>
        )}
      </ul>
    </section>
  );
};
