import { IfilterItem } from "../../../pages/Mypage/types/state.type";

interface Istate {
  toggle: boolean;
  filter: IfilterItem;
}

interface Ifunc {
  selectFilter: (type: string, value: string) => void;
}

export const CapacityRadioList = ({
  state,
  func,
}: {
  state: Istate;
  func: Ifunc;
}) => {
  return (
    <div
      className={`flex ${
        state.toggle ? "mb-12" : ""
      }  flex-col text-default overflow-hidden transition-all duration-100  ${
        state.toggle ? "max-h-screen " : "max-h-0"
      }`}
    >
      {RADIO_LIST.map((el: string, idx: number) => (
        <div className="flex items-center mb-[16px] radio-buttons" key={idx}>
          <label className="radio-button">
            {el}
            <input
              type="radio"
              onChange={() => func.selectFilter("capacity", el)}
              checked={state.filter["capacity"] === el}
            />
            <span className="custom-radio"></span>
          </label>
        </div>
      ))}
    </div>
  );
};

export const RADIO_LIST: string[] = [
  "전체",
  "0~30kW",
  "30~100kW",
  "100~150kW",
  "150~200kW",
  "200~500kW",
  "500~1MW",
  "1MW",
];
