import { useNavigate } from "react-router-dom";

export const MypageOwnerSubmitSuccess = ({
  username,
}: {
  username: string;
}) => {
  const navigate = useNavigate();

  return (
    <section className="max-w-[552px] w-full">
      <h1 className="flex text-listFirst font-bold mb-4 max-sm:font-medium">
        내 발전소 <img className="px-4" src="/icon_remodel/bird_arrow.svg" />
        소유주 등록하기
      </h1>
      <article className=" mt-8 mb-12">
        <h1 className="text-subValue text-main-color-primary mb-4">
          {username}님, 축하합니다!
        </h1>

        <p className="mb-[6px]">
          {username}님의 발전소와 햇빛바람거래소가 연결된 것을 진심으로
          환영합니다.
        </p>
        <p>
          태양광발전소 거래 시장 활성화를 통해 재생에너지 확산하는 데
          동참해주셔서 감사합니다.
        </p>
      </article>
      <button
        className="w-full py-3 bg-main-color-primary text-general-white rounded-lg"
        onClick={() => navigate("/mypage/myPlant")}
      >
        시작하기
      </button>
    </section>
  );
};
