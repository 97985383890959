import axios from "axios";

export const axiosRemoveFavorite = async (queryKey: any) => {
  const response = await axios.delete(
    process.env.REACT_APP_SERVER_URL + queryKey.url,
    {
      withCredentials: true,
      headers: {
        Authorization: " Token " + queryKey.token,
      },
    },
  );

  return response.data;
};
