import { Navigate } from "react-router-dom";
import { MypageFetch } from "./Mypage.fetch";
import { isLogin } from "../../utils_public/isLogin";

export const MypageContainer = () => {
  return isLogin() ? (
    <>
      <MypageFetch />
    </>
  ) : (
    <Navigate to={"/login?access"} />
  );
};
