import { useEffect } from "react";
import { IgetUserInfo, IreceiveCheck } from "../types/state.type";

interface signature {
  [key: string]: string;
}

interface Ifunc {
  setUserModify: (value: signature) => void;
  setReceiveCheck: (value: IreceiveCheck) => void;
  setToggle: (value: boolean) => void;
}

export const useSaveUserInfo = (
  status: string,
  userInformation: IgetUserInfo,
  func: Ifunc,
) => {
  useEffect(() => {
    if (status === "success") {
      func.setUserModify({
        userName: userInformation.userName,
        email: userInformation.email,
        nickname: userInformation.nickname,
        phoneNumber: userInformation.phoneNumber,
        telephoneNumber: userInformation.telephone
          ? userInformation.telephone
          : "",
        company: userInformation.company,
        companyType: userInformation.companyType,
        passwordOriginal: "",
        password: "",
        passwordVerify: "",
      });
      func.setReceiveCheck({
        email: userInformation.receiveEmail,
        kakao: userInformation.receiveKakao,
        sms: userInformation.receiveSms,
      });

      if (
        userInformation.receiveEmail ||
        userInformation.receiveKakao ||
        userInformation.receiveSms
      ) {
        func.setToggle(false);
      }
    }
  }, [status]);
};
