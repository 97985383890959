import { useQuery } from "react-query";
import { axiosMessageNotify } from "../api/axiosGetRequest";

interface ImessageNotifyQuery {
  apiName: string;
  authorization?: string;
}

export const useMessageNotifyQuery = (url: string, info: ImessageNotifyQuery) =>
  useQuery(
    ["messageNotifyFromHeader", url, info.authorization],
    axiosMessageNotify,
    {
      onError: (err) => {
        console.error(`from ${info.apiName} ERROR`, err);
      },
      refetchInterval: 1000 * 60 * 5,
    },
  );
