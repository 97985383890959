import classNames from "classnames";
import { useState } from "react";
import { DropDownListBox } from "../../../UI/DropDown/DropDownListBox";

interface Ioptions {
  name: string;
  list: string[];
}

export const MypageRegistrationDropDown = ({
  options,
}: {
  options: Ioptions;
}) => {
  const [toggle, setToggle] = useState(false);
  const [current, setCurrent] = useState("-");

  return (
    <div
      className={classNames(
        "relative w-[210px] h-[35px]",
        {
          "z-[2]": options.name === "지목",
        },
        { "z-[1]": options.name === "착공단계" },
      )}
    >
      <div className="absolute border-[1px] py-[3px] text-default border-border-color-dividers rounded-lg">
        <div
          className="w-[210px] pl-[14px] relative cursor-pointer text-listSecond"
          onClick={() => setToggle(!toggle)}
        >
          {current}
          <img
            className={classNames(
              "absolute top-[8px] right-[10px]",
              {
                "rotate-180": toggle,
              },
              {
                "rotate-0": !toggle,
              },
            )}
            src="/icon_remodel/arrow.svg"
          />
        </div>
        <DropDownListBox
          state={{
            toggle: toggle,
            current: current,
          }}
          item={{
            selectList: options.list,
          }}
          func={{
            setToggle: setToggle,
            setCurrent: setCurrent,
          }}
          options={{
            overflow: false,
          }}
        />
      </div>
    </div>
  );
};
