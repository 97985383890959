import { useState } from "react";
import { LoginModify } from "./Login.modify";
import { IloginInput } from "../../pages/Login/types/loginInput.type";
import { useLoginQuery } from "../../pages/Login/query/useLoginQuery";

export const LoginFetch = () => {
  const [loginInput, setLoginInput] = useState<IloginInput>({
    email: "",
    password: "",
  });

  // 로그인 React-Query POST 설정
  const POST_LOGIN = useLoginQuery();

  // 로그인 React-Query POST 요청 후 응답값
  // const { status, data, error } = POST_LOGIN;

  // 로그인 요청
  const submitLogin = () => {
    POST_LOGIN.mutate({
      apiName: "Login",
      submitInfo: loginInput,
      url: "login/",
    });
  };

  return (
    <>
      <LoginModify
        state={{
          loginInput: loginInput,
        }}
        func={{
          submitLogin: submitLogin,
          setLoginInput: setLoginInput,
        }}
        apiResponse={{
          loading: POST_LOGIN.isLoading,
          axiosCode:
            POST_LOGIN.error && POST_LOGIN.error.code
              ? POST_LOGIN.error.code
              : "",
          error: POST_LOGIN.isError,
          errorStatus:
            POST_LOGIN.error && POST_LOGIN.error.response
              ? POST_LOGIN.error.response.status
              : 0,
        }}
        options={{
          token: POST_LOGIN.data,
        }}
      />
    </>
  );
};
