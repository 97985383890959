import { AxiosError } from "axios";
import { useQuery } from "react-query";
import { axiosPlantRoom } from "../api/axiosGetRequest";

interface Iaccess {
  apiName: string;
  authorization?: string;
}

export const usePlantRoomListQuery = (
  url: string,
  plantName: string,
  info: Iaccess,
) =>
  useQuery(
    ["plantRoomListFromMessage", url, plantName, info.authorization],
    axiosPlantRoom,
    {
      onError: (err: AxiosError) => {
        console.error(`from ${info.apiName} ERROR`, err);
      },
      cacheTime: 0,
      retry: false,
    },
  );
