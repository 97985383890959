import {
  ImodalPlantList,
  ImodalSellBuy,
} from "../../../pages/Mypage/types/state.type";

interface Ifunc {
  setModalTalk: (value: ImodalSellBuy) => void;
  setModalPlantList: (plantLIst: ImodalPlantList) => void;
}

interface Ioption {
  type: string;
  userId: number;
  username: string;
}

export const UserBox = ({ func, option }: { func: Ifunc; option: Ioption }) => {
  return (
    <ul className="p-[8px] bg-general-white shadow-lg border-[1px] border-border-color-dividers rounded-md text-text-color-primary text-default">
      <li
        className="p-[6px] rounded-md hover:bg-main-color-primary hover:text-general-white"
        onClick={() => {
          func.setModalTalk({
            open: true,
            type: option.type,
            userId: option.userId,
            username: option.username,
          });
        }}
      >
        쪽지 보내기
      </li>
      <li
        className="p-[6px] rounded-md hover:bg-main-color-primary hover:text-general-white"
        onClick={() => {
          func.setModalPlantList({
            open: true,
            type: "seller",
            userId: option.userId,
            username: option.username,
          });
        }}
      >
        판매 등록 이력
      </li>
      <li
        className="p-[6px] rounded-md hover:bg-main-color-primary hover:text-general-white"
        onClick={() => {
          func.setModalPlantList({
            open: true,
            type: "buyer",
            userId: option.userId,
            username: option.username,
          });
        }}
      >
        구매 등록 이력
      </li>
    </ul>
  );
};
