import { QnAList } from "../../components/QnA/QnAList";
import { MainBanner } from "../../components/Main/MainBanner";
import { QnAMenu } from "../../components/QnA/QnAMenu";
import { QnAInquiryFetch } from "./module_fetch/QnAInquiry.Fetch";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { QnAKeyword } from "../../components/QnA/QnAKeyword";

interface Istate {
  current: number;
  selectKeyword: string[];
}

interface Ifunc {
  setCurrent: (count: number) => void;
  setSelectKeyword: (selectKeyword: string[]) => void;
  QnAKeywordClick: (target: string[]) => void;
}

export const QnAPresenter = ({
  state,
  func,
}: {
  state: Istate;
  func: Ifunc;
}) => {
  const navigate = useLocation();
  const [selectMenu, setSelectMenu] = useState(navigate.search.slice(1));

  return (
    <div className="w-full mx-auto h-full  pt-16">
      <MainBanner />
      <QnAMenu selectMenu={selectMenu} setSelectMenu={setSelectMenu} />

      {selectMenu === "questions" ? (
        <>
          <QnAKeyword
            selectKeyword={state.selectKeyword}
            QnAKeywordClick={func.QnAKeywordClick}
          />
          <QnAList
            state={{
              selectKeyword: state.selectKeyword,
              current: state.current,
            }}
            func={{ setCurrent: func.setCurrent }}
          />
        </>
      ) : (
        <QnAInquiryFetch />
      )}
    </div>
  );
};
