interface IdetailVariousMapSentinel {
  date: string;
  url: string;
}

export const DetailVariousMapSentinelItem = ({
  date,
  url,
}: IdetailVariousMapSentinel) => {
  return (
    <li className="max-w-[167px] w-full h-[120px] relative max-2xl:h-[100px] max-xl:max-w-[250px] max-xl:h-[150px] max-lg:h-[120px] max-md:max-w-[150px] max-md:h-[80px]">
      <img
        className="w-full h-full rounded-lg grayscale"
        src={process.env.REACT_APP_SENTINEL + url}
      />
      <img
        className="w-[20px] absolute top-[35%] left-[45%] max-md:top-[30%] max-md:left-[40%]"
        src="/icon_remodel/map-marker.png"
      />
      <span className="bg-text-color-primary p-[2px] text-footer text-general-white absolute bottom-0 right-2 max-md:text-[10px] max-md:bottom-[3px]">
        촬영일: {date}
      </span>
    </li>
  );
};
