import { AxiosError } from "axios";
import { useMutation } from "react-query";
import { axiosInquiry } from "../api/axiosPostRequest";

export const useInquiryQuery = () =>
  useMutation(axiosInquiry, {
    onError: (err: AxiosError, val) => {
      console.error(`from ${val.apiName} ERROR`, err);
    },
  });
