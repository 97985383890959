import { useEffect } from "react";
import { ClipBoardCopy } from "../utils/ClipBoardCopy.function.ts";

interface IfavoriteFunc {
  setZindex: (bool: boolean) => void;
  setFavoriteToast: (bool: boolean) => void;
}

interface IfavoriteValue {
  favoriteToast: boolean;
  clipBoardToast: boolean;
}

export const useClipBoardToastTimeOut = (
  clipBoardToast: boolean,
  setClipBoardToast: (bool: boolean) => void,
) => {
  // 토스트 UI on상태 3초 딜레이
  useEffect(() => {
    if (clipBoardToast === true) {
      // 현재 링크 저장하는 함수
      ClipBoardCopy(document.location.href);
    }

    let timer: ReturnType<typeof setTimeout> = setTimeout(() => {
      setClipBoardToast(false);
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [clipBoardToast]);
};

export const useFavoriteToastTimeOut = (
  value: IfavoriteValue,
  func: IfavoriteFunc,
) => {
  // 토스트 UI on 순서에 따른 z-index 적용
  useEffect(() => {
    if (value.favoriteToast) {
      func.setZindex(false);
    } else {
      func.setZindex(true);
    }
  }, [value.clipBoardToast]);

  // 토스트 UI on상태 3초 딜레이
  useEffect(() => {
    func.setZindex(true);
    let timer: ReturnType<typeof setTimeout> = setTimeout(() => {
      func.setFavoriteToast(false);
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [value.favoriteToast]);
};
