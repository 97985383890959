import { IplantRoomList, Imessage } from "../../../pages/Chat/types/item.type";
import {
  IpingList,
  IcurrentMessageList,
} from "../../../pages/Chat/types/state.type";
import { ChatTabletPlantList } from "./ChatTabletPlantList";
import { ChatTabletUserList } from "./ChatTabletUserList";

type IselectOption = {
  plant: string;
  currentMessageId: number;
  user: string;
};

interface Ifunc {
  setSelectOption: (value: IselectOption) => void;
  setSelectPlant: (value: Imessage[]) => void;
  setPlantPingList: (value: IpingList) => void;
  searchPlantName: (text: string) => void;
  searchPlantReset: () => void;
  setUserPingList: (value: IpingList) => void;
  setSendMessageError: (error: boolean) => void;
}

interface Istate {
  selectOption: IselectOption;
  selectPlant: Imessage[];
  plantPingList: IpingList;
  userPingList: IpingList;
  curretMessageList: IcurrentMessageList;
}

interface IapiResponse {
  plantRoomStatus: string;
}

interface Iitem {
  plantRoomList: IplantRoomList[];
}

export const ChatTabletMenu = ({
  state,
  func,
  item,
  apiResponse,
}: {
  state: Istate;
  func: Ifunc;
  apiResponse: IapiResponse;
  item: Iitem;
}) => {
  return (
    <div className="w-full h-full fixed bg-general-white z-[1] flex lg:hidden top-0 right-0 bottom-0 left-0 pt-[100px] px-[50px] justify-center max-md:px-[24px] max-md:pt-[50px]">
      <ChatTabletPlantList
        state={{
          selectOption: state.selectOption,
          plantPingList: state.plantPingList,
        }}
        func={{
          setSelectOption: func.setSelectOption,
          setSelectPlant: func.setSelectPlant,
          setPlantPingList: func.setPlantPingList,
          searchPlantName: func.searchPlantName,
          searchPlantReset: func.searchPlantReset,
        }}
        apiResponse={{ plantRoomStatus: apiResponse.plantRoomStatus }}
        item={{
          plantRoomList: item.plantRoomList,
        }}
      />
      <ChatTabletUserList
        state={{
          selectOption: state.selectOption,
          selectPlant: state.selectPlant,
          userPingList: state.userPingList,
          curretMessageList: state.curretMessageList,
        }}
        func={{
          setSelectOption: func.setSelectOption,
          setUserPingList: func.setUserPingList,
          setSendMessageError: func.setSendMessageError,
        }}
      />
    </div>
  );
};
