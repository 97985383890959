import { useCookies } from "react-cookie";
import { useGetFavorite } from "../hooks/useGetFavorite";
import { useParams } from "react-router-dom";
import {
  useAddFavorite,
  useRemoveFavorite,
} from "../query/useChangeFavoriteQuery";
import { DetailInformationButtonModify } from "../module_modify/DetailInformationButton.modify";
import { useState } from "react";

type ImodalSellBuy = {
  open: boolean;
  type: string;
  plantId: number;
};

interface Ifunc {
  setModalTalkChoice: (bool: boolean) => void;
  setModalSellBuy: (value: ImodalSellBuy) => void;
}

interface Iitem {
  favoriteCount: number;
}

export const DetailInformationButtonFetch = ({
  func,
  item,
}: {
  func: Ifunc;
  item: Iitem;
}) => {
  const [cookie] = useCookies(["60hz-user"]);
  const params = useParams();

  const [zIndex, setZindex] = useState(false);
  const [clipBoardToast, setClipBoardToast] = useState(false);
  const [favoriteToast, setFavoriteToast] = useState(false);
  const [favoriteIcon, setFavoriteIcon] = useState(false);

  const [modalConfirmation, setModalConfirmation] = useState(false);

  // React-Query 찜하기 조회 요청
  const getFavoriteInfo = useGetFavorite(params.id, cookie["60hz-user"]);

  // React-Query 찜하기 추가 요청
  const POST_FAVORITE = useAddFavorite();

  // React-Query 찜하기 제거 요청
  const DELETE_FAVORITE = useRemoveFavorite();

  const likeSubmitFromServer = () => {
    return !favoriteIcon
      ? POST_FAVORITE.mutate({
          apiName: "Favorite Add",
          url: `api/like/${params.id}`,
          token: cookie["60hz-user"],
        })
      : DELETE_FAVORITE.mutate({
          apiName: "Favorite Remove",
          url: `api/like/${params.id}`,
          token: cookie["60hz-user"],
        });
  };

  return (
    <DetailInformationButtonModify
      state={{
        zIndex: zIndex,
        favoriteIcon: favoriteIcon,
        favoriteToast: favoriteToast,
        clipBoardToast: clipBoardToast,
        modalConfirmation: modalConfirmation,
      }}
      item={{
        favoriteCount: item.favoriteCount,
      }}
      func={{
        setModalTalkChoice: func.setModalTalkChoice,
        setModalConfirmation: setModalConfirmation,
        setModalSellBuy: func.setModalSellBuy,
        setFavoriteIcon: setFavoriteIcon,
        setFavoriteToast: setFavoriteToast,
        setClipBoardToast: setClipBoardToast,
        setZindex: setZindex,
        likeSubmitFromServer: likeSubmitFromServer,
      }}
      apiResponse={{
        favoriteResponse: getFavoriteInfo.data,
        currentFavoriteStatus: getFavoriteInfo.status,
      }}
    />
  );
};
