import classNames from "classnames";
import { Imessage } from "../../../pages/Chat/types/item.type";
import { getRecordDate } from "../../../utils_public/dateFormat";
import { useSetRecoilState } from "recoil";
import { notifyMessage } from "../../UI/Header/atoms/notify.atoms";
import {
  IpingList,
  IcurrentMessageList,
} from "../../../pages/Chat/types/state.type";

type IselectOption = {
  plant: string;
  currentMessageId: number;
  user: string;
};

interface Ifunc {
  setSelectOption: (value: IselectOption) => void;
  setUserPingList: (value: IpingList) => void;
  setSendMessageError: (error: boolean) => void;
}

interface Istate {
  selectOption: IselectOption;
  userPingList: IpingList;
  curretMessageList: IcurrentMessageList;
}

export const ChatTabletUserListItem = ({
  item,
  state,
  func,
}: {
  item: Imessage;
  state: Istate;
  func: Ifunc;
}) => {
  const setNotifyMessage = useSetRecoilState(notifyMessage);

  return (
    <li
      className={classNames(
        "py-[20px] px-[20px] flex items-center gap-x-[15px] cursor-pointer hover:bg-general-lightGray border-b-[1px] border-border-color-dividers max-md:px-[10px]",
        {
          "bg-main-color-sub": item.view_user === state.selectOption.user,
        },
      )}
      onClick={() => {
        func.setSelectOption({
          ...state.selectOption,
          user: item.view_user,
          currentMessageId: item.id,
        });
        func.setUserPingList({
          ...state.userPingList,
          [item.id]: { ping: false },
        });
        func.setSendMessageError(false);
        setNotifyMessage(false);
      }}
    >
      <aside>
        <div className="w-[50px] h-[50px] relative border-[1px] border-border-color-dividers rounded-[100%] flex justify-center items-center max-md:w-[35px] max-md:h-[35px]">
          <img className="w-[70%] h-[70%]" src="/icon_remodel/profile.svg" />
          {state.userPingList[item.id] && state.userPingList[item.id].ping && (
            <p className="absolute top-[0px] right-[-7px] text-smallExplanation bg-general-red text-general-white rounded-md px-[5px] flex items-center">
              {item.not_read_count}
            </p>
          )}
        </div>

        {item.is_owner && (
          <span className="flex justify-center mt-[3px] items-center text-smallExplanation text-main-color-primary border-[1px] px-[5px] border-main-color-primary rounded-lg max-md:text-[10px] max-md:px-[3px]">
            소유주
          </span>
        )}
      </aside>
      <div className="w-full flex flex-col gap-y-[3px]">
        <div className="flex items-center gap-x-[10px] justify-between">
          <h1 className="max-w-[100px] w-full relative font-bold whitespace-nowrap overflow-hidden text-ellipsis max-md:text-default max-md:max-w-[85px] max-sm:font-medium">
            {item.view_user}
          </h1>

          <span className="font-normal text-default text-text-color-disabled max-md:text-smallExplanation max-sm:hidden">
            {getRecordDate(
              state.curretMessageList[item.id] &&
                state.curretMessageList[item.id].date,
            )}
          </span>
        </div>
        <p className="max-w-[170px] w-full text-default whitespace-nowrap overflow-hidden text-ellipsis max-md:text-smallExplanation max-md:max-w-[100px] max-sm:max-w-[90px]">
          {state.curretMessageList[item.id] &&
            state.curretMessageList[item.id].message}
        </p>

        <span className="font-normal text-default text-text-color-disabled max-md:text-smallExplanation">
          {getRecordDate(
            state.curretMessageList[item.id] &&
              state.curretMessageList[item.id].date,
          )}
        </span>
      </div>
    </li>
  );
};
