import axios from "axios";
import {
  emailModal,
  passwordModal,
  verificationdModal,
} from "../types/modal.type";
import { IloginInput } from "../types/loginInput.type";

interface IloginQuery {
  submitInfo: IloginInput;
  url: string;
  apiName: string;
}

interface IFindUserInfoQuery {
  submitInfo: emailModal | passwordModal | verificationdModal;
  url: string;
  apiName: string;
}

export async function axiosLogin(queryKey: IloginQuery) {
  const response = await axios.post(
    process.env.REACT_APP_SERVER_URL + queryKey.url,
    queryKey.submitInfo,
    {
      withCredentials: true,
    },
  );

  return response.data;
}

export async function axiosFindUserInfo(queryKey: IFindUserInfoQuery) {
  const response = await axios.post(
    process.env.REACT_APP_SERVER_URL + queryKey.url,
    queryKey.submitInfo,
    {
      withCredentials: true,
    },
  );
  return response.data;
}
