import classNames from "classnames";

type ImodalSellBuy = {
  open: boolean;
  type: string;
  plantId: number;
};

interface Ifunc {
  setModalTalkChoice: (bool: boolean) => void;
  setClipBoardToast: (bool: boolean) => void;
  clickFavoriteIcon: () => void;
  setModalSellBuy: (value: ImodalSellBuy) => void;
}

interface Istate {
  favoriteIcon: boolean;
}

export const DetailInformationButton = ({
  state,
  func,
}: {
  state: Istate;
  func: Ifunc;
}) => {
  // const { lockScroll } = useBodyScrollLock();
  return (
    <ul className="fixed right-[0px] bottom-[180px] border-[1px] border-border-color-dividers bg-general-white text-smallExplanation max-md:text-[10px]">
      <li
        className={classNames(
          "w-[50px] h-[50px] border-b-[1px] border-b-border-color-dividers flex flex-col items-center justify-center gap-y-[3px] cursor-pointer max-md:w-[40px] max-md:h-[40px]",
        )}
        onClick={() => {
          func.setModalTalkChoice(true);
        }}
      >
        <img className="w-[45%]" src={"/icon_remodel/message.svg"} />
        메세지
      </li>
      <li
        className={classNames(
          "w-[50px] h-[50px] border-b-[1px] border-b-border-color-dividers flex flex-col items-center justify-center gap-y-[3px] cursor-pointer max-md:w-[40px] max-md:h-[40px]",
        )}
        onClick={() => func.setClipBoardToast(true)}
      >
        <img className="w-[34%]" src="/icon_remodel/clip.svg" />
        링크 복사
      </li>

      <li
        className={classNames(
          "w-[50px] h-[50px] flex flex-col items-center justify-center gap-y-[3px] cursor-pointer max-md:w-[40px] max-md:h-[40px]",
        )}
        onClick={() => func.clickFavoriteIcon()}
      >
        <img
          className="w-[40%]"
          src={` ${
            state.favoriteIcon
              ? "/icon_remodel/heart_full.svg"
              : "/icon_remodel/heart.svg"
          } `}
        />
        찜하기
      </li>
    </ul>
  );
};
