import { IplantInputInfo } from "../../../../pages/Mypage/types/state.type";

interface Istate {
  plantInputInfo: IplantInputInfo;
}

interface Ifunc {
  setPlantInputInfo: (capacity: IplantInputInfo) => void;
}

interface Ioptions {
  type: string;
}

export const MypageRegistrationRadioItem = ({
  state,
  func,
  options,
}: {
  state: Istate;
  func: Ifunc;
  options: Ioptions;
}) => {
  return (
    <div className="w-full h-[50px] flex items-center radio-buttons">
      <label className="radio-button">
        {options.type}
        <input
          className="mr-2"
          type="radio"
          name="check-signup"
          checked={options.type === state.plantInputInfo.capacityType}
          onChange={() =>
            func.setPlantInputInfo({
              ...state.plantInputInfo,
              capacityType: options.type,
            })
          }
        />
        <span className="custom-radio"></span>
      </label>
    </div>
  );
};
