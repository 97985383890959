import { capacityUnit } from "../../../utils_public/Unit";
import { DetailInformationSupport } from "./DetailInformationSupport";

interface Iitem {
  title: string;
  address: string;
  capacity: number | string;
}

interface Ifunc {
  setModalTalkChoice: (bool: boolean) => void;
}

export const DetailInformationTitle = ({
  item,
  func,
}: {
  item: Iitem;
  func: Ifunc;
}) => {
  return (
    <div className="relative">
      <div className="flex justify-between">
        <h1 className="font-bold text-mainTitle max-sm:font-medium max-sm:text-subTitle">
          {item.title}
        </h1>
      </div>
      <div className="mt-[6px] flex items-center text-default justify-between max-sm:text-footer">
        <div className="flex">
          <h1 className="flex">
            <img className="mr-1" src="/icon_remodel/marker.svg" />
            {item.address}
          </h1>
          <h1 className="px-4 max-sm:px-2">|</h1>
          <h1 className="whitespace-nowrap">
            용량 {capacityUnit(item.capacity)}
          </h1>
        </div>

        <div className="flex items-center">
          <DetailInformationSupport
            func={{
              setModalTalkChoice: func.setModalTalkChoice,
            }}
          />
        </div>
      </div>
    </div>
  );
};
