import { useQuery } from "react-query";
import { axiosMenuUserInfo } from "../api/axiosGetRequest";

export const useMenuUserInfoQuery = (
  url: string,
  info: { cookie: string; apiName: string },
) =>
  useQuery([info.apiName, url, info.cookie], axiosMenuUserInfo, {
    onError: (err) => {
      console.error(`from ${info.apiName} ERROR`, err);
    },
    cacheTime: 0,
    retry: false,
  });
