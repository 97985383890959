import { AxiosError } from "axios";
import { useQuery } from "react-query";
import { axiosPlantFilterList } from "../../Mypage/api/axiosGetRequest";
import { IfilterItem } from "../../Mypage/types/state.type";

interface IusePlantList {
  apiName: string;
  cookie?: string;
}

export const usePlantListQuery = (
  url: string,
  filter: IfilterItem,
  pathName: string,
  info: IusePlantList,
) =>
  useQuery(
    ["plantListFromSearch", url, filter, pathName, info.cookie],
    axiosPlantFilterList,
    {
      onError: (err: AxiosError) => {
        console.error(`from ${info.apiName} ERROR`, err);
      },
      cacheTime: 0,
      retry: false,
    },
  );
