import "./App.css";
import "./style/reset.css";
import "./style/fonts/font.css";
import "./style/share.css";
import { Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

// Components
import { Header } from "./components/UI/Header/Header.container";
import { Footer } from "./components/UI/Footer/Footer";

// Page
import Terms from "./components/UI/Terms/Terms";
import { LoginContainer } from "./pages/Login/Login.container";
import { SignupContainer } from "./pages/SignUp/Signup.container";
import { MainContainer } from "./pages/Main/Main.container";
import { DetailContainer } from "./pages/Detail/Detail.container";
import { SearchContainer } from "./pages/Search/Search.container";
import { MypageContainer } from "./pages/Mypage/Mypage.container";
import { ChatContainer } from "./pages/Chat/Chat.container";
import { QnAConatiner } from "./pages/QnA/QnA.container";
import { ScrollControlButton } from "./components/UI/ScrollControlButton/ScrollControlButton";

// Router
import ScrollToTop from "./router/ScrollTop";
import { NotFound } from "./pages/NotFound/NotFound";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });
  return (
    <QueryClientProvider client={queryClient}>
      <Header />
      <Routes>
        <Route path="/" element={MainContainer()} />
        <Route path="/search" element={SearchContainer()} />
        <Route path="/detail/:id" element={DetailContainer()} />
        <Route path="/login" element={LoginContainer()} />
        <Route path="/signup" element={SignupContainer()} />
        <Route path="/message" element={ChatContainer()} />
        <Route path="/mypage/*" element={MypageContainer()} />
        <Route path="qna" element={QnAConatiner()} />
        <Route path="/privacy/*" element={<Terms />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
      <Footer />
      <ScrollControlButton />
      <ScrollToTop />
    </QueryClientProvider>
  );
}

export default App;
