import { MypageCertification } from "../../../components/Mypage/MypageMyPlant/Certification/MypageCertification";
import { ImyPlantStatusList } from "../types/plantInfo.type";

export const MypageCertificationModify = ({
  plantList,
  status,
}: {
  plantList: ImyPlantStatusList[];
  status: string;
}) => {
  return <MypageCertification plantList={plantList} status={status} />;
};
