import { IREC } from "../../../pages/Detail/types/RECorSMP.type";
import ApexCharts from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { HandleComma } from "../../../utils_public/Comma";
export const DetailEnergeWeeklyGraph = ({ recData }: { recData: IREC[] }) => {
  const OPTIONS: ApexOptions = {
    chart: {
      type: "line",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "40%",
        borderRadius: 2,
      },
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    tooltip: {
      custom: ({ series, seriesIndex, dataPointIndex }) => {
        return (
          '<div class="border-[1px] bg-general-white border-main-color-primary px-4 py-3 rounded-lg">' +
          '<div class="flex justify-center mb-2">' +
          '<span class="text-main-color-primary mr-2" >' +
          "체결량 :" +
          "</span>" +
          "<span>" +
          HandleComma(series[0][dataPointIndex]) +
          "</span>" +
          "</div>" +
          '<div class="flex justify-center">' +
          '<span class="text-general-red mr-2">' +
          "종가 :" +
          "</span>" +
          "<span>" +
          HandleComma(series[1][dataPointIndex]) +
          "</span>" +
          "</div>" +
          "</div>"
        );
      },
    },

    colors: ["#4B77E6", "#D7260D"],
    stroke: {
      width: [0, 3],
    },
    xaxis: {
      categories: recData.map((day) => day.bz_dd.replace(/\-/g, ".").slice(5)),
      labels: {
        rotate: -45,
        style: {
          colors: "#111111",
          fontSize: "12px",
          fontFamily: "PretendardVariable",
        },
      },
      tooltip: {
        enabled: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: [
      {
        tickAmount: 5,
        show: true,
        labels: {
          formatter: (val: number, opts: any) => {
            if (!opts) {
              return "0";
            } else if (opts !== 5) {
              return HandleComma(val) as string;
            } else {
              return "";
            }
          },
          style: {
            colors: "#4B77E6",
            fontSize: "14px",
            fontFamily: "PretendardVariable",
          },
        },
      },
      {
        tickAmount: 5,
        opposite: true,
        labels: {
          show: true,
          style: {
            colors: "#D7260D",
            fontSize: "14px",
            fontFamily: "PretendardVariable",
          },
          formatter: (val: number, opts: any) => {
            if (!opts) {
              return "0";
            } else if (opts !== 5) {
              return HandleComma(val) as string;
            } else {
              return "";
            }
          },
        },
      },
    ],
    legend: {
      show: false,
    },
    states: {
      active: {
        filter: {
          type: "none",
        },
      },
    },

    responsive: [
      {
        breakpoint: 769,
        options: {
          plotOptions: {
            bar: {
              columnWidth: "57%",
              borderRadius: 2,
            },
          },
          yaxis: [
            {
              tickAmount: 5,
              show: true,
              labels: {
                formatter: (val: number, opts: any) => {
                  if (!opts) {
                    return "0";
                  } else if (opts !== 5) {
                    return HandleComma(val) as string;
                  } else {
                    return "";
                  }
                },
                style: {
                  colors: "#4B77E6",
                  fontSize: "10px",
                  fontFamily: "PretendardVariable",
                },
              },
            },
            {
              tickAmount: 5,
              opposite: true,
              labels: {
                show: true,
                style: {
                  colors: "#D7260D",
                  fontSize: "10px",
                  fontFamily: "PretendardVariable",
                },
                formatter: (val: number, opts: any) => {
                  if (!opts) {
                    return "0";
                  } else if (opts !== 5) {
                    return HandleComma(val) as string;
                  } else {
                    return "";
                  }
                },
              },
            },
          ],
        },
      },
    ],
  };

  const SERIES = [
    {
      name: "체결량",
      type: "column",
      data: recData?.map((el) => el.land_trd_rec_value),
    },
    {
      name: "종가",
      type: "line",
      data: recData?.map((el) => el.cls_prc),
    },
  ];

  return (
    <div className="relative pb-12 ">
      <div className="mb-3">
        <h1 className="text-listSecond">최근 4주간 REC</h1>
        <div className="flex justify-center my-4">
          <div className="flex items-center">
            <img src="/icon_remodel/blue_squre.svg" />
            <span className="text-default ml-2 mr-4 max-sm:text-footer">
              체결량
            </span>
            <img src="/icon_remodel/red_line.svg" />
            <span className="text-default ml-2 max-sm:text-footer">
              REC 현물시장(종가)
            </span>
          </div>
        </div>
      </div>

      <div className="w-[104%] relative DetailCapacityGraph max-sm:w-[108%] mt-[40px]">
        <span className="absolute text-default text-main-color-primary font-bold left-[46px] top-[-15px] max-md:left-[38px] max-md:text-smallExplanation max-sm:font-medium">
          REC
        </span>
        <span className="absolute text-default text-general-red font-bold right-[33px] top-[-15px] max-md:right-[25px] max-md:text-smallExplanation max-sm:font-medium">
          원/REC
        </span>
        <div className="w-full h-[330px] max-sm:h-[230px]">
          <ApexCharts
            options={OPTIONS}
            series={SERIES}
            height={"100%"}
            width={"100%"}
          />
        </div>
      </div>
      <div className="flex justify-end mr-14 mt-3 max-sm:mt-[5px] max-sm:mr-[10px]">
        <span className="text-smallExplanation text-text-color-secondary ">
          출처: 전력거래소
        </span>
      </div>
    </div>
  );
};
