import classNames from "classnames";
import { DefaultLoading } from "../../UI/Loading/Loading";
import { IsellerList } from "../../../pages/Detail/types/item.type";
import { useBodyScrollLock } from "../../../utils_public/useBodyScrollLock";

type ImodalTalk = {
  open: boolean;
  type: string;
  userId: number;
  username: string;
};

interface Iitem {
  sellerList: IsellerList[];
}

interface Ifunc {
  setModalBuy: (bool: boolean) => void;
  setModalTalk: (bool: ImodalTalk) => void;
}

interface IapiResponse {
  status: string;
}

export const DetailBuyListModal = ({
  item,
  func,
  apiResponse,
}: {
  item: Iitem;
  func: Ifunc;
  apiResponse: IapiResponse;
}) => {
  const isLoading = apiResponse.status === "loading";

  useBodyScrollLock();

  return (
    <div className="relative z-[999]">
      <div className="fixed inset-0 bg-[#272727] bg-opacity-40 transition-opacity"></div>
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full justify-center items-center">
          <div
            className="w-[707px] h-[350px] pt-[30px] relative bg-general-white p-8
            rounded-2xl border-main-color-primary border-[1px] drop-shadow-[2px_2px_4px_rgba(0,0,0,0.25)] max-md:w-[450px] max-sm:w-[340px] max-sm:p-5 max-sm:h-[300px]"
          >
            <img
              className="absolute top-[15px] right-[35px] cursor-pointer"
              src="/icon_remodel/close.svg"
              onClick={() => {
                func.setModalBuy(false);
              }}
            />
            <h1 className="text-subValue text-main-color-primary mb-[20px] max-sm:text-listSecond">
              판매 관계자 리스트
            </h1>
            <dl className="flex justify-end">
              <dt className="py-[8px] w-[50%] text-center border-b-[1px] border-t-[1px] border-b-border-color-dividers border-t-border-color-dividers max-sm:text-default max-sm:pr-[30px]">
                등록 일자
              </dt>
              <dt className="py-[8px] w-[50%] text-center border-b-[1px] border-t-[1px] border-b-border-color-dividers border-t-border-color-dividers max-sm:text-default">
                아이디(닉네임)
              </dt>
            </dl>
            <ul className="h-[150px] mt-[10px] overflow-y-auto max-sm:h-[100px]">
              {/* 발전소 소유주가 있는 경우 */}
              {isLoading && (
                <div className="max-w-[12%] w-full mx-auto mt-[50px]">
                  <DefaultLoading />
                </div>
              )}

              {/* 발전소 소유주가 있는 경우 */}
              {!isLoading &&
                !!item.sellerList.length &&
                item.sellerList.map((el, idx) => (
                  <li
                    className={classNames("flex", { "pt-[8px]": idx })}
                    key={el.id}
                  >
                    <div className="w-[50%] text-center max-sm:text-footer max-sm:w-[40%]">
                      {el.created_datetime.slice(0, 10)}
                    </div>
                    <div className="w-[50%] flex gap-x-[10px] max-sm:text-footer">
                      <div className="relative pl-[115px] max-md:pl-[50px] max-sm:pl-[65px]">
                        {el.is_owner && (
                          <span className="w-[45px] absolute right-[68px] top-[0px] py-[2px] text-center text-footer text-general-white bg-main-color-primary rounded-lg max-sm:text-smallExplanation max-sm:w-[40px] max-sm:right-[55px]">
                            소유주
                          </span>
                        )}
                        <div
                          className="flex gap-x-[10px] border-b-[1px] cursor-pointer border-b-main-color-primary text-main-color-primary hover:border-b-[#b1159f] hover:text-[#b1159f]"
                          title={el.username}
                          onClick={() => {
                            func.setModalBuy(false);
                            func.setModalTalk({
                              open: true,
                              type: "buyer",
                              userId: el.user_id,
                              username: el.username,
                            });
                          }}
                        >
                          <p className="max-w-[100px] whitespace-nowrap overflow-hidden text-ellipsis max-sm:max-w-[80px]">
                            {el.username}
                          </p>
                          <img
                            className="w-[15px] max-sm:w-[10px]"
                            src="/icon_remodel/mail.svg"
                          />
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              {/* 발전소 소유주가 없는 경우 */}
              {!isLoading && !item.sellerList.length && (
                <div className="flex justify-center pt-[20px] text-listTitle max-md:text-listSecond max-sm:text-default">
                  해당 발전소의 등록된 판매자가 현재 없는 상태입니다.
                </div>
              )}
            </ul>

            <div className="flex justify-end items-center gap-x-[50px] mt-[15px] max-md:gap-x-[16px] max-sm:justify-between">
              <p className="text-text-color-secondary mr-[40px]  max-md:text-footer max-sm:text-smallExplanation max-md:mr-0">
                * 소유주 인증이 되지 않은 경우, 관계자로{" "}
                <br className="sm:hidden" />
                표시됩니다.
              </p>
              <button
                className="w-[88px] py-[12px] bg-main-color-primary text-general-white rounded-lg max-sm:w-[70px] max-sm:text-default"
                onClick={() => {
                  func.setModalBuy(false);
                }}
              >
                닫기
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
