import { SearchModify } from "./Search.modify";
import { useGetPlantList } from "./hooks/useGetPlantList";

export const SearchFetch = () => {
  // 변경된 필터들 get 요청
  const { plantList, loading, plantListTotal, isError, error } =
    useGetPlantList();

  return (
    <>
      <SearchModify
        item={{
          plantList: plantList,
          plantListTotal: plantListTotal,
        }}
        apiResponse={{
          loading: loading,
          error: isError,
          axiosCode: error && error.code ? error.code : "",
          errorStatus:
            error && error.response && error.response.status
              ? error.response.status
              : 0,
        }}
      />
    </>
  );
};
