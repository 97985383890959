import {
  HandlePhoneNumber,
  HandleTelephoneNumber,
} from "../../SignUp/utils/SignFunc";
import { Ifile, IimageFile } from "../types/state.type";
import { Iinverter, Imodule } from "../types/plantTotalInfo.type";

interface Iinput {
  inverter: Iinverter;
  module: Imodule;
}

interface IsoloFile {
  rpsFile: Ifile;
  energeFile: Ifile;
  imageFile: IimageFile;
}

export const ownerSubmitCheck = (
  businessFile: File | null,
  commerceFile: File | null,
) => {
  if (businessFile === null || commerceFile === null) return false;

  if (businessFile.size <= 2000000 && commerceFile.size <= 2000000) {
    return true;
  } else {
    return false;
  }
};

export const myPlantSubmitCheck = (input: Iinput, soloFile: IsoloFile) => {
  const imageSize = soloFile.imageFile.image
    .map((el) => el.file?.size)
    .every((el) => (el as number) <= 2000000);
  const imageLimit = soloFile.imageFile.image.length > 0 ? imageSize : true;

  const rpsLimit = soloFile.rpsFile.file
    ? soloFile.rpsFile.file.size <= 2000000
    : true;

  const energeLimit = soloFile.energeFile.file
    ? soloFile.energeFile.file.size <= 2000000
    : true;

  if (input.inverter.change || input.module.change) {
    if (
      soloFile.imageFile.change ||
      soloFile.rpsFile.change ||
      soloFile.energeFile.change
    ) {
      if (!imageLimit || !rpsLimit || !energeLimit) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  } else {
    if (
      soloFile.imageFile.change ||
      soloFile.rpsFile.change ||
      soloFile.energeFile.change
    ) {
      if (!imageLimit || !rpsLimit || !energeLimit) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
};
