import { useParams } from "react-router-dom";
import { DetailModify } from "./Detail.modify";
// hooks
import { useGetPlantInfo } from "./hooks/useGetPlantInfo";
import { useGetFavoriteLimitCount } from "./hooks/useGetFavorite";
import { useCookies } from "react-cookie";
import { useGetSellerList } from "./hooks/useGetBuyerList";
import actualData from "../../data/price_by_sido_capgroup.json";

export const DetailFetch = () => {
  const [cookie] = useCookies(["60hz-user"]);
  const params = useParams();

  // React-Query 발전소 상세 정보 요청
  const { plantData, plantStatus } = useGetPlantInfo(
    params.id,
    cookie["60hz-user"],
  );

  const { favoriteCountData, favoriteCountStatus } = useGetFavoriteLimitCount(
    cookie["60hz-user"],
  );

  const { sellerList, sellerListStatus } = useGetSellerList(
    params.id,
    cookie["60hz-user"],
  );

  return (
    <>
      <DetailModify
        item={{
          plantInfo: plantData,
          favoriteCount: favoriteCountData,
          sellerList: sellerList,
          actualPriceList: actualData,
        }}
        apiResponse={{
          plantLoading: plantStatus,
          favoriteCountLoading: favoriteCountStatus,
          sellerListLoading: sellerListStatus,
        }}
      />
    </>
  );
};
