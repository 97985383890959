import { useEffect, useState } from "react";
import { MypagePlantBuyItem } from "../../../components/Mypage/MypageMyPlant/PlantBuy/MypagePlantBuyItem";
import { getDayIncodeDate } from "../../../utils_public/dateFormat";
import { Ibuylist } from "../types/item.type";
import {
  ImodalPlantList,
  ImodalSellBuy,
  priceBoxType,
} from "../types/state.type";

interface Istate {
  selectPlantId: number;
  selectedPriceHistory: priceBoxType;
}

interface Ifunc {
  setSelectPlantId: (num: number) => void;
  submitPrice: (num: number, price: number | string) => void;
  setModalTalk: (value: ImodalSellBuy) => void;
  setCurrentMessagePlantId: (value: number) => void;
  setModalPlantList: (plantList: ImodalPlantList) => void;
  setSelectedPriceHistory: (priceHistory: priceBoxType) => void;
}

interface IapiResponse {
  submitPlantId: number;
  submitSuccess: boolean;
  submitLoading: boolean;
  axiosCode: string;
  error: boolean;
  errorStatus: number;
}

interface Ioptions {
  id: number;
  length: number;
}

export const MypagePlantBuyItemModify = ({
  state,
  item,
  func,
  apiResponse,
  options,
}: {
  state: Istate;
  item: Ibuylist;
  func: Ifunc;
  apiResponse: IapiResponse;
  options: Ioptions;
}) => {
  const [copyPrice, setCopyPrice] = useState(String(item.buy_price));
  const [savePrice, setSavePrice] = useState(String(item.buy_price));
  const [saveCreateDate, setSaveCreateDate] = useState(
    getDayIncodeDate(item.buy_created_datetime),
  );

  const [updateInput, setUpdateInput] = useState(true);

  const onClickPriceHistory = (plantId: number) => {
    func.setSelectedPriceHistory({
      type: "buyer",
      plantId: plantId === state.selectedPriceHistory.plantId ? 0 : plantId,
    });
  };

  useEffect(() => {
    if (state.selectPlantId !== 0 && state.selectPlantId !== item.plant_id) {
      setUpdateInput(true);
      setCopyPrice(savePrice);
    }
  }, [state.selectPlantId]);

  useEffect(() => {
    if (apiResponse.submitSuccess) {
      setUpdateInput(true);
      setSavePrice(copyPrice);
    }
  }, [apiResponse.submitSuccess]);

  useEffect(() => {
    if (item.plant_id === apiResponse.submitPlantId) {
      setSaveCreateDate(getDayIncodeDate(new Date().toString()));
    }
  }, [apiResponse.submitPlantId]);

  useEffect(() => {
    if (options.id + 1 === options.length) {
      item.seller_list.length
        ? item.seller_list.map((id, idx) => {
            if (idx + 1 === item.seller_list.length) {
              localStorage.setItem(
                "60hz-buyerList-local",
                new Date().toString(),
              );
            }
          })
        : localStorage.setItem("60hz-buyerList-local", new Date().toString());
    }
  }, []);

  return (
    <MypagePlantBuyItem
      state={{
        selectPlantId: state.selectPlantId,
        copyPrice: copyPrice,
        saveCreateDate: saveCreateDate,
        updateInput: updateInput,
        selectedPriceHistory: state.selectedPriceHistory,
      }}
      item={{ ...item }}
      func={{
        setSelectPlantId: func.setSelectPlantId,
        submitPrice: func.submitPrice,
        setModalTalk: func.setModalTalk,
        setCurrentMessagePlantId: func.setCurrentMessagePlantId,
        setModalPlantList: func.setModalPlantList,
        setCopyPrice: setCopyPrice,
        setUpdateInput: setUpdateInput,
        onClickPriceHistory: onClickPriceHistory,
      }}
      apiResponse={{
        submitLoading: apiResponse.submitLoading,
      }}
      options={{
        id: options.id,
        length: options.length,
      }}
    />
  );
};
