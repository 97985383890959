import classNames from "classnames";
import { ButtonLoading } from "../UI/Loading/Loading";
import { SUBMIT_INQUIRY_ERROR_TYPE } from "../../pages/QnA/error/error.object";
import { ErrorHandling } from "../../utils_public/ErrorHandling";

interface Istate {
  inquiryText: string;
  selectInquiryList: string;
}

interface Ifunc {
  setInquiryText: (text: string) => void;
  submitInquiry: () => void;
}

interface Ioptions {
  inquiryTextTitle: boolean;
}

interface IapiResponse {
  success: boolean;
  loading: boolean;
  axiosCode: string;
  error: boolean;
  errorStatus: number;
}

export const QnAInquiryText = ({
  state,
  func,
  apiResponse,
}: // options,
{
  state: Istate;
  func: Ifunc;
  apiResponse: IapiResponse;
  // options: Ioptions;
}) => {
  return (
    <div
      className={classNames(
        "flex gap-x-[80px] max-md:flex-col max-md:gap-y-[30px] mt-[120px] mb-[50px]",
      )}
    >
      <h1 className="text-listFirst">문의 내용</h1>
      <div
        className={classNames("max-w-[800px] w-full flex flex-col items-end")}
      >
        <textarea
          className="w-full h-[200px] border-[1px] border-border-color-dividers rounded-lg resize-none outline-none px-[16px] py-[20px]"
          placeholder="문의 내용을 입력해주세요. (최소 5자 이상)"
          onChange={(e) => func.setInquiryText(e.target.value)}
        />
        <div className="flex items-center mt-[25px] gap-x-[50px]">
          <p className=" text-general-red">
            {ErrorHandling(
              {
                errorType: SUBMIT_INQUIRY_ERROR_TYPE,
                errorCode: apiResponse.axiosCode,
                errorStatus: apiResponse.errorStatus,
                isError: apiResponse.error,
              },
              "",
            )}
          </p>
          <button
            className={classNames(
              "w-[120px] h-[45px] border-[1px] border-border-color-dividers rounded-lg",
              {
                "bg-main-color-primary text-general-white border-none":
                  state.inquiryText.length > 4 &&
                  state.selectInquiryList !== "",
              },
            )}
            onClick={() => {
              state.inquiryText.length > 4 && state.selectInquiryList !== ""
                ? func.submitInquiry()
                : alert(
                    "유형 선택, 문의 내용(최소 5자 이상)을 작성해주시길 바랍니다.",
                  );
            }}
          >
            {apiResponse.loading ? (
              <div className="w-[30px] mx-auto">
                <ButtonLoading />
              </div>
            ) : (
              "등록"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
