import axios from "axios";
import { ObjReverse } from "../utils/ObjReverse.function";

export const axiosPlantInfo = async ({ queryKey }: any) => {
  const response = await axios.get(
    process.env.REACT_APP_SERVER_URL + queryKey[1],
    {
      withCredentials: true,
      headers: {
        Authorization: " Token " + queryKey[2],
      },
    },
  );

  return response.data;
};

export const axiosDailyGen = async ({ queryKey }: any) => {
  const response = await axios.get(
    process.env.REACT_APP_SERVER_URL + queryKey[1],
    {
      withCredentials: true,
    },
  );

  return response.data;
};

interface IsentinelList {
  date: string;
  url: string;
}

export const axiosSentinelMap = async ({ queryKey }: any) => {
  const response = await axios.get(
    process.env.REACT_APP_SENTINEL + queryKey[1],
    {
      withCredentials: false,
    },
  );

  const newObj: IsentinelList[][] = ObjReverse(response.data[0]);

  return newObj;
};

export const axiosSMP = async ({ queryKey }: any) => {
  const response = await axios.get(
    process.env.REACT_APP_SERVER_URL + queryKey[1],
    {
      withCredentials: true,
    },
  );

  return response.data.results;
};

export const axiosREC = async ({ queryKey }: any) => {
  const response = await axios.get(
    process.env.REACT_APP_SERVER_URL + queryKey[1],
    {
      withCredentials: true,
    },
  );

  return response.data.results;
};

export const axiosFavorite = async ({ queryKey }: any) => {
  if (!queryKey[1]) return;
  const response = await axios.get(
    process.env.REACT_APP_SERVER_URL + queryKey[1],
    {
      withCredentials: true,
      headers: {
        Authorization: " Token " + queryKey[2],
      },
    },
  );

  return response.data.message;
};

export const axiosFavoriteLimitCount = async ({ queryKey }: any) => {
  const response = await axios.get(
    process.env.REACT_APP_SERVER_URL + queryKey[1],
    {
      withCredentials: true,
      headers: {
        Authorization: " Token " + queryKey[2],
      },
    },
  );

  return response.data.count;
};

export const axiosBuyerSellerList = async ({ queryKey }: any) => {
  const response = await axios.get(
    process.env.REACT_APP_SERVER_URL + queryKey[1],
    {
      withCredentials: true,
      headers: {
        Authorization: " Token " + queryKey[2],
      },
    },
  );

  return response.data;
};
