import { useMessageNotifyQuery } from "../query/useMessageNotifyQuery";

export const useGetMessageNotify = (token?: string) => {
  const { data, status } = useMessageNotifyQuery("message/notify/", {
    apiName: "Message notify Information",
    authorization: token,
  });

  // 새 매세지가 없을 경우 data.is_read는 true
  return { notify: data ? data.is_read : true, status: status };
};
