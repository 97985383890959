interface ItypeInfo {
  type: string;
  currentTime: string;
}

interface IindexInfo {
  parentIndex: number;
  parentLenght: number;
  childIndex: number;
  childLength: number;
}

interface ItimeInfo {
  localStorageName: string;
  currentTime: string;
}

export const newBadgeSetting = (typeInfo: ItypeInfo, indexInfo: IindexInfo) => {
  if (typeInfo.type === "buyerList") {
    return settingLocal(
      {
        localStorageName: "60hz-buyerList-local",
        currentTime: typeInfo.currentTime,
      },
      {
        parentIndex: indexInfo.parentIndex,
        parentLenght: indexInfo.parentLenght,
        childIndex: indexInfo.childIndex,
        childLength: indexInfo.childLength,
      },
    );
  } else {
    return settingLocal(
      {
        localStorageName: "60hz-sellerList-local",
        currentTime: typeInfo.currentTime,
      },
      {
        parentIndex: indexInfo.parentIndex,
        parentLenght: indexInfo.parentLenght,
        childIndex: indexInfo.childIndex,
        childLength: indexInfo.childLength,
      },
    );
  }
};

const settingLocal = (timeInfo: ItimeInfo, indexInfo: IindexInfo) => {
  const LOCAL_STORAGE_VALUE = localStorage.getItem(timeInfo.localStorageName);
  const LOCAL_STORAGE_TIME = new Date(LOCAL_STORAGE_VALUE as string).getTime();

  const CURRENT_GET_UTC = new Date(timeInfo.currentTime);
  const CURRENT_TIME = new Date(CURRENT_GET_UTC).getTime();

  if (!LOCAL_STORAGE_VALUE) {
    return true;
  } else if (LOCAL_STORAGE_TIME <= CURRENT_TIME) {
    return true;
  } else {
    return false;
  }
};
