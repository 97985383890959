interface Iitem {
  fileName: string;
}

interface Ifunc {
  changeFile: (bool: boolean) => void;
}

interface Ioptions {
  title: string;
  type: string;
}

export const MypageDocumentsModify = ({
  item,
  func,
  options,
}: {
  item: Iitem;
  func: Ifunc;
  options: Ioptions;
}) => {
  return (
    <section className="mt-[62px]">
      <div className="flex flex-col gap-y-4 mb-[62px]">
        <div className="flex items-center gap-x-[8px]">
          <h1 className="text-listFirst font-bold">{options.title}</h1>
          <span
            className="text-smallExplanation text-text-color-secondary border-b-[1px] 
          border-b-text-color-secondary cursor-pointer"
            onClick={() => {
              if (options.type === "rpsFile") {
                func.changeFile(false);
              } else {
                func.changeFile(false);
              }
            }}
          >
            첨부파일 수정하기
          </span>
        </div>
        <input
          value={item.fileName}
          className="max-w-[130px] w-full pr-[30px] pl-[10px] py-[3px] text-footer text-text-color-secondary border-[1px] 
          border-border-color-dividers !outline-none cursor-default rounded-2xl"
          readOnly
        />
      </div>
    </section>
  );
};
