import { IactualPriceList } from "../types/item.type";

type Itype = {
  startDate: Date;
  endDate: Date;
  local: string;
  capacity: string;
};

interface IgraphData {
  x: number;
  y: number | null;
  capacity: number;
}

export const makeTimeSumTotal = (original: IactualPriceList[], type: Itype) => {
  const timestampArray: IgraphData[] = [];

  for (
    let date = new Date(type.startDate);
    date <= type.endDate;
    date.setMonth(date.getMonth() + 1)
  ) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;

    const timestamp = new Date(`${year}-${month}`).getTime();

    const filterLocalCapacity = original.filter(
      (parent: { capacity_group: string; sido: string }) =>
        parent.capacity_group === type.capacity && parent.sido === type.local,
    );

    const addTimestamp = filterLocalCapacity.filter(
      (el) => generateDayWiseTimeSeries(el.time, el["price"])[0] === timestamp,
    );

    // 그래프 데이터가 없을 경우 length 0 처리
    if (!filterLocalCapacity.length && type.local !== "전국") {
      return [];
    }

    const copy =
      type.local === "전국"
        ? original
            .filter((parent) => parent.capacity_group === type.capacity)
            .filter(
              (el) =>
                generateDayWiseTimeSeries(el.time, el["price"])[0] ===
                timestamp,
            )
            .map((el) =>
              timestampArray.push({
                x: timestamp,
                y: el["price"],
                capacity: el["cap"],
              }),
            )
        : addTimestamp.map((el) =>
            timestampArray.push({
              x: timestamp,
              y: el["price"],
              capacity: el["cap"],
            }),
          );

    // 해당 년도,월 기준 데이터 판별 후 push
    copy.length === 0
      ? timestampArray.push({ x: timestamp, y: null, capacity: 0 })
      : null;
  }

  const PRICE_SUM = timestampArray.reduce((acc, cur) => acc + Number(cur.y), 0);

  return PRICE_SUM ? timestampArray : [];
};

export const generateDayWiseTimeSeries = (time: string, price: number) => {
  const conversion = time.split("-");

  const timeConversion = conversion[1]
    ? new Date(`${Number(conversion[0])}-${Number(conversion[1])}`).getTime()
    : new Date(`${Number(conversion[0]) - 1}-${12}`).getTime();

  return [timeConversion, price];
};
