import { MypageOwnerSubmit } from "../../../components/Mypage/MypageMyPlant/Owner/MypageOwnerSubmit";
import { MypageOwnerSubmitSuccess } from "../../../components/Mypage/MypageMyPlant/Owner/MypageOwnerSubmitSuccess";
import { Ifile } from "../types/state.type";

interface Istate {
  businessFile: Ifile;
  commerceFile: Ifile;
  submitModal: boolean;
}

interface Ifunc {
  setBusinessFile: (file: Ifile) => void;
  setCommerceFile: (file: Ifile) => void;
  setSubmitModal: (bool: boolean) => void;
  submitOwnerFile: () => void;
}

interface Iitem {
  username: string;
}

interface IapiResponse {
  status: string;
}

export const MypageOwnerSubmitModify = ({
  state,
  func,
  item,
  apiResponse,
}: {
  state: Istate;
  func: Ifunc;
  item: Iitem;
  apiResponse: IapiResponse;
}) => {
  const handleSubmitModal = (bool: boolean) => {
    if (!bool) {
      func.setSubmitModal(bool);
    } else {
      // 전송 함수
      func.submitOwnerFile();
    }
  };

  return apiResponse.status === "success" ? (
    <MypageOwnerSubmitSuccess username={item.username} />
  ) : (
    <MypageOwnerSubmit
      state={{
        businessFile: state.businessFile,
        commerceFile: state.commerceFile,
        submitModal: state.submitModal,
      }}
      func={{
        setBusinessFile: func.setBusinessFile,
        setCommerceFile: func.setCommerceFile,
        setSubmitModal: func.setSubmitModal,
        handleSubmitModal: handleSubmitModal,
      }}
      apiResponse={{
        status: apiResponse.status,
      }}
    />
  );
};
