import { useNavigate } from "react-router-dom";
import { useBodyScrollLock } from "../../../utils_public/useBodyScrollLock";

interface Istate {
  mobileFilterOpen: boolean;
}

export const ModalBlur = ({
  state,
  setBlurModal,
}: {
  state: Istate;
  setBlurModal: (bool: boolean) => void;
}) => {
  const navigate = useNavigate();

  useBodyScrollLock();

  return (
    <>
      <div className="w-full h-full fixed bottom-0 left-0 top-0 right-0 z-[120]">
        <div className="w-full h-full absolute"></div>

        <div className="flex relative min-h-full justify-center items-center">
          <div
            className="relative flex flex-col justify-end items-center bg-general-white
          rounded-xl border-main-color-primary border-[1px] drop-shadow-[2px_2px_4px_rgba(0,0,0,0.25)]"
          >
            <img
              className="w-[15px] h-[15px] cursor-pointer absolute top-[15px] right-[15px]"
              src="/icon_remodel/close.svg"
              onClick={() => {
                setBlurModal(false);
              }}
            />
            <div className="w-[475px] py-[35px] max-md:w-[400px]">
              <article className="text-center flex flex-col gap-y-[5px] mb-[30px]">
                <p>햇빛바람거래소의 상세한 이용을 희망하시면</p>
                <p>아래의 로그인 및 회원가입을 진행해주세요.</p>
              </article>
              <div className="flex flex-col gap-y-[15px] justify-center items-center">
                <button
                  className="max-w-full w-[200px] py-3 bg-main-color-primary text-general-white hover:bg-main-color-secondary"
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  로그인 하기
                </button>
                <button
                  className="max-w-full w-[200px] py-3 bg-main-color-primary text-general-white hover:bg-main-color-secondary"
                  onClick={() => {
                    navigate("/signup");
                  }}
                >
                  회원가입 하기
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
