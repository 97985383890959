import { DetailTalkModalTop } from "./DetailTalkModalTop";
import { DetailModalInformation } from "./DetailModalInformation";
import { DetailModalContents } from "./DetailModalContents";
import { DetailTalkModalRegist } from "./DetailTalkModalRegist";
import { Italk } from "../../../pages/Detail/types/state.type";
import { ItalkModalItem } from "../../../pages/Detail/types/item.type";
import { useBodyScrollLock } from "../../../utils_public/useBodyScrollLock";

type ImodalTalk = {
  open: boolean;
  type: string;
  userId: number;
  username: string;
};

interface Istate {
  modalTalk: ImodalTalk;
  talkText: Italk;
}

interface Ifunc {
  modalClose: () => void;
  submitTalk: (type: string) => void;
  onChangeTalk: (text: string) => void;
  setTalkText: (talk: Italk) => void;
  setModalTalkSellList: (bool: boolean) => void;
  setModalTalkBuyList: (bool: boolean) => void;
}

interface Ioptions {
  sellerListCount: number;
  currentPagePositon: string;
}

interface IapiResponse {
  loading: boolean;
  axiosCode: string;
  error: boolean;
  errorStatus: number;
  errorCode: string;
}

export const DetailTalkModal = ({
  state,
  item,
  func,
  options,
  apiResponse,
}: {
  state: Istate;
  item: ItalkModalItem;
  func: Ifunc;
  options: Ioptions;
  apiResponse: IapiResponse;
}) => {
  useBodyScrollLock();
  return (
    <div className="relative z-[999]">
      <div className="fixed inset-0 bg-[#272727] bg-opacity-40 transition-opacity"></div>

      <div className="fixed inset-0 overflow-y-auto ">
        <div className="flex min-h-full justify-center items-center">
          <div
            className="w-[707px] bg-general-white p-8
            rounded-2xl border-main-color-primary border-[1px] drop-shadow-[2px_2px_4px_rgba(0,0,0,0.25)] max-md:w-[450px] max-sm:w-[340px] max-sm:p-5 max-sm:h-[400px]"
          >
            <DetailTalkModalTop
              state={{
                modalTalk: state.modalTalk,
              }}
              func={{
                modalClose: func.modalClose,
              }}
              options={{
                type: state.modalTalk.type,
              }}
            />
            <DetailModalInformation item={{ ...item }} />

            <DetailModalContents onChangeText={func.onChangeTalk} />
            <DetailTalkModalRegist
              func={{
                modalClose: func.modalClose,
                submitTalk: func.submitTalk,
                setModalTalkSellList: func.setModalTalkSellList,
                setModalTalkBuyList: func.setModalTalkBuyList,
              }}
              state={{
                talkText: state.talkText,
              }}
              options={{
                type: state.modalTalk.type,
                sellerListCount: options.sellerListCount,
                currentPagePositon: options.currentPagePositon,
                ownerVerification: item.isOwner,
              }}
              apiResponse={{ ...apiResponse }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
