import { useState } from "react";
import { useCookies } from "react-cookie";
import { useRecoilState, useSetRecoilState } from "recoil";
import { filterCategory } from "../../pages/Main/atoms/filter.atoms";
import { pageNumberList } from "../../pages/Main/atoms/pageNumberList.atom";
import { MainPresenter } from "./Main.presenter";
import { IplantList } from "./types/plantList.type";

interface Iitem {
  plantList: IplantList[];
  plantListTotal: number;
}

interface IapiResponse {
  loading: boolean;
  error: boolean;
  axiosCode: string;
  errorStatus: number;
}

export const MainModify = ({
  item,
  apiResponse,
}: {
  item: Iitem;
  apiResponse: IapiResponse;
}) => {
  // const { lockScroll } = useBodyScrollLock();
  const [filter, setFilter] = useRecoilState(filterCategory);

  const setPageNumberList = useSetRecoilState(pageNumberList);

  // const [pageNumberList, setPageNumberList] = useState<number[]>([]);

  const [toggle, setToggle] = useState(false);
  const [blurModal, setBlurModal] = useState(false);

  const [cookie] = useCookies(["60hz-user"]);

  // 선택된 필터 적용 함수
  const selectFilter = (type: string, value: string) => {
    if (!cookie["60hz-user"]) {
      return setBlurModal(true);
    }
    window.scrollTo(0, 0);
    setFilter({ ...filter, [type]: value, page: 1 });
    setToggle(false);
  };

  // 필터 초기화 함수
  const resetFilter = () => {
    if (!cookie["60hz-user"]) {
      return setBlurModal(true);
    }
    window.scrollTo(0, 0);
    setFilter({
      local: "전국",
      land: "전체",
      capacity: "전체",
      sort: "-capacity",
      page: 1,
    });
    setPageNumberList([1, 2, 3, 4, 5]);
  };

  // 페이지네이션 기능 함수
  const pageChangeHandler = (pageNum: number) => {
    if (!cookie["60hz-user"]) {
      return setBlurModal(true);
    }
    window.scrollTo(0, 0);
    setFilter({ ...filter, page: pageNum });
  };

  return (
    <>
      <MainPresenter
        state={{
          toggle: toggle,
          blurModal: blurModal,
        }}
        func={{
          selectFilter: selectFilter,
          resetFilter: resetFilter,
          pageChangeHandler: pageChangeHandler,
          setToggle: setToggle,
          setBlurModal: setBlurModal,
          setPageNumberList: setPageNumberList,
        }}
        item={{
          plantList: item.plantList,
          plantListTotal: item.plantListTotal,
        }}
        apiResponse={{
          loading: apiResponse.loading,
          error: apiResponse.error,
          axiosCode: apiResponse.axiosCode,
          errorStatus: apiResponse.errorStatus,
        }}
      />
    </>
  );
};
