import { useState } from "react";
import { IplantInfo } from "./types/plantInfo.type";
import { IsellerList, IactualPriceList } from "./types/item.type";
// components
import { DetailInformationFetch } from "./module_fetch/DetailInformation.fetch";
import { DetailVariousMapFetch } from "./module_fetch/DetailVariousMap.fetch";
import { DetailEnerge } from "../../components/Detail/Energe/DetailEnerge";
import { DetailCapacityModify } from "./module_modify/DetailCapacity.modify";
import { DetailWorth } from "../../components/Detail/Worth/DetailWorth";
import { DetailCadastralMap } from "../../components/Detail/DetailCadastralMap";
import { DetailTalkModalFetch } from "./module_fetch/DetailTalkModal.fetch";
import { ModalTalkTypeChoice } from "../../components/UI/Modal/ModalTalkTypeChoice";
import { DetailLoading } from "../../components/Detail/DetailLoading";
import { DetailModalHopeSellBuyFetch } from "./module_fetch/DetailModalHopeSellBuy.fetch";
import { DetailSellListModalFetch } from "./module_fetch/DetailSellListModal.fetch";
import { DetailBuyListModal } from "../../components/Detail/Modal/DetailBuyListModal";
import { DetailActualPrice } from "../../components/Detail/ActualPrice/DetailActualPrice";
import { DetailInformationButtonFetch } from "./module_fetch/DetailInformationButton.fetch";
import { DetailAskingPriceModify } from "./module_modify/DetailAskingPrice.modify";

interface Iitem {
  plantInfo: IplantInfo;
  favoriteCount: number;
  sellerList: IsellerList[];
  actualPriceList: IactualPriceList[];
}

interface IapiResponse {
  plantLoading: string;
  favoriteCountLoading: string;
  sellerListLoading: string;
}

export const DetailPresnter = ({
  item,
  apiResponse,
}: {
  item: Iitem;
  apiResponse: IapiResponse;
}) => {
  const [modalTalk, setModalTalk] = useState({
    open: false,
    type: "",
    userId: 0,
    username: "",
  });
  const [modalTalkChoice, setModalTalkChoice] = useState(false);
  const [modalTalkSellList, setModalTalkSellList] = useState(false);
  const [modalTalkBuyList, setModalTalkBuyList] = useState(false);

  const [modalSellBuy, setModalSellBuy] = useState({
    open: false,
    type: "",
    plantId: 0,
  });

  return (
    <>
      <div className="max-w-[1630px] flex mx-auto mt-0 mb-[140px] pt-[108px] px-[50px] justify-between max-xl:flex-col max-xl:items-center max-md:px-[24px] max-md:pt-[50px] max-sm:px-[16px]">
        <DetailInformationFetch
          item={{
            plantInfo: item.plantInfo,
          }}
          func={{
            setModalTalkChoice: setModalTalkChoice,

            setModalSellBuy: setModalSellBuy,
          }}
        />
        <DetailVariousMapFetch
          state={{
            latitude: item.plantInfo.latitude,
            longitude: item.plantInfo.longitude,
          }}
          apiResponse={{
            status: apiResponse.plantLoading,
          }}
        />
      </div>
      <div className="max-w-[1630px] mx-auto flex mb-[140px] px-[50px] justify-between max-xl:flex-col max-xl:items-center max-md:px-[24px] max-sm:px-[16px]">
        <DetailActualPrice
          item={{
            local: item.plantInfo.addr_lv1,
            sellInfo: item.plantInfo.sell_info,
            capacity: item.plantInfo.capacity,
            actualPriceList: item.actualPriceList,
          }}
        />
        <DetailAskingPriceModify
          item={{
            sellerList: item.sellerList,
          }}
        />
      </div>
      <div className="max-w-[1630px] mx-auto flex mt-0 mb-[140px] px-[50px] justify-between max-xl:flex-col max-xl:items-center max-md:px-[24px] max-sm:px-[16px]">
        <DetailEnerge plantInfo={item.plantInfo} />
        <DetailCapacityModify
          state={{
            plantInfo: item.plantInfo,
          }}
        />
      </div>
      <div className="max-w-[1630px] mx-auto flex mt-0 mb-[140px] px-[50px] justify-between max-xl:flex-col max-xl:items-center max-md:px-[24px] max-sm:px-[16px]">
        <DetailWorth plantInfo={item.plantInfo} />
        <DetailCadastralMap />
      </div>
      {modalTalk.open && (
        <DetailTalkModalFetch
          state={{
            modalTalk: modalTalk,
          }}
          item={{
            plantId: item.plantInfo.id,
            plantName: item.plantInfo.plant_name,
            isOwner: item.plantInfo.is_owner,
            landName: item.plantInfo.land_name,
            capacity: item.plantInfo.capacity,
            addr_lv1: item.plantInfo.addr_lv1,
            addr_lv2: item.plantInfo.addr_lv2,
            addr_lv3: item.plantInfo.addr_lv3,
          }}
          func={{
            setModalTalk: setModalTalk,
            setModalTalkSellList: setModalTalkSellList,
            setModalTalkBuyList: setModalTalkBuyList,
          }}
          options={{
            sellerListCount: item.sellerList.length,
            currentPagePositon: "detail",
          }}
        />
      )}

      {modalTalkChoice && (
        <ModalTalkTypeChoice
          func={{
            setModalTalkChoice: setModalTalkChoice,
            setModalSell: setModalTalkSellList,
            setModalBuy: setModalTalkBuyList,
          }}
        />
      )}

      {modalTalkBuyList && (
        <DetailBuyListModal
          item={{ sellerList: item.sellerList }}
          apiResponse={{
            status: apiResponse.sellerListLoading,
          }}
          func={{
            setModalBuy: setModalTalkBuyList,
            setModalTalk: setModalTalk,
          }}
        />
      )}

      {modalTalkSellList && (
        <DetailSellListModalFetch
          item={{ plantId: item.plantInfo.id }}
          func={{
            setModalSell: setModalTalkSellList,
            setModalTalk: setModalTalk,
          }}
        />
      )}

      {(apiResponse.plantLoading !== "success" ||
        apiResponse.favoriteCountLoading !== "success" ||
        apiResponse.sellerListLoading !== "success") && (
        <DetailLoading apiResponse={{ status: apiResponse.plantLoading }} />
      )}

      {modalSellBuy.open && (
        <DetailModalHopeSellBuyFetch
          state={{
            type: modalSellBuy.type,
            plantId: modalSellBuy.plantId,
          }}
          func={{
            setModalSellBuy: setModalSellBuy,
          }}
        />
      )}

      <DetailInformationButtonFetch
        func={{
          setModalTalkChoice: setModalTalkChoice,
          setModalSellBuy: setModalSellBuy,
        }}
        item={{ favoriteCount: item.favoriteCount }}
      />
    </>
  );
};
