type detailValue = {
  date: string;
  url: string;
};

interface InewArrayValue {
  key: string;
}

interface IobjValue {
  [key: string]: detailValue[];
}

export function ObjReverse(obj: IobjValue) {
  let newArray: InewArrayValue[] = [];
  let newObj: IobjValue = {};

  Object.keys(obj)
    .sort()
    .reverse()
    .forEach((key) => {
      newArray.push({
        key: key,
      });
    });

  newArray
    .slice(0, 4)
    .map((el: InewArrayValue) => (newObj[el.key] = obj[el.key]));

  const objectKey = Object.keys(newObj);

  const result = objectKey.map((el) => newObj[el]);

  return result;
}
