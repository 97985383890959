import { useQuery, useMutation } from "react-query";
import { axiosFavoritePlant } from "../api/axiosGetRequest";
import { axiosDeleteFavorite } from "../api/axiosDeleteRequest";

export const useFavoritePlantQuery = (
  url: string,
  info: { cookie: string; apiName: string },
) =>
  useQuery(["favoriteList", url, info.cookie], axiosFavoritePlant, {
    onError: (err) => {
      console.error(`from ${info.apiName} ERROR`, err);
    },
    cacheTime: 0,
    retry: false,
  });

export const useRemoveFavoritePlantQuery = () =>
  useMutation(axiosDeleteFavorite, {
    onError: (err, val) => {
      console.error(`from ${val.apiName} ERROR`, err);
    },
  });
