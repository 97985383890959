import classNames from "classnames";
import { capacityUnit } from "../../../utils_public/Unit";
import { IplantRoomList, Imessage } from "../../../pages/Chat/types/item.type";
import { IpingList } from "../../../pages/Chat/types/state.type";

type IselectOption = {
  plant: string;
  currentMessageId: number;
  user: string;
};

interface Ifunc {
  setSelectOption: (value: IselectOption) => void;
  setSelectPlant: (vlaue: Imessage[]) => void;
  setPlantPingList: (value: IpingList) => void;
}

interface Istate {
  selectOption: IselectOption;
  plantPingList: IpingList;
}

export const ChatTabletPlantListItem = ({
  item,
  state,
  func,
}: {
  item: IplantRoomList;
  state: Istate;
  func: Ifunc;
}) => {
  return (
    <li
      className={classNames(
        "py-[15px] px-[20px] flex flex-col gap-x-[15px] cursor-pointer hover:bg-general-lightGray",
        { "bg-main-color-sub": item.plant_name === state.selectOption.plant },
      )}
      onClick={() => {
        func.setSelectOption({
          plant: item.plant_name,
          user: "",
          currentMessageId: 0,
        });
        func.setSelectPlant(item.message);
        func.setPlantPingList({
          ...state.plantPingList,
          [item.plant]: { ping: false },
        });
      }}
    >
      <div className="flex gap-x-[20px] items-center">
        <h1 className="w-full whitespace-nowrap overflow-hidden text-ellipsis max-md:text-default">
          {item.plant_name}
        </h1>
        {state.plantPingList[item.plant] &&
          state.plantPingList[item.plant].ping && (
            <span className="relative flex h-[10px] w-[10px]">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-general-red opacity-75"></span>
              <span className="relative inline-flex rounded-full h-[10px] w-[10px] bg-general-red"></span>
            </span>
          )}
      </div>
      <div className="flex items-center justify-between gap-x-[15px] mt-[8px] max-md:flex-col max-md:items-start max-md:gap-y-[4px]">
        <p className="w-full text-footer text-text-color-secondary whitespace-nowrap overflow-hidden text-ellipsis max-md:text-smallExplanation">
          {item.addr_lv1 + " " + item.addr_lv2 + " " + item.addr_lv3}
        </p>
        <span className="whitespace-nowrap text-main-color-primary text-default max-md:text-smallExplanation">
          {capacityUnit(item.capacity)}
        </span>
      </div>
    </li>
  );
};
