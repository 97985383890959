import { HeaderModify } from "./Header.modify";
import { useCookies } from "react-cookie";
import { useGetMessageNotify } from "./hooks/useGetMessageNotify";

export const HeaderFetch = () => {
  const [cookie] = useCookies(["60hz-user"]);

  const { notify, status } = useGetMessageNotify(cookie["60hz-user"]);

  return (
    <>
      <HeaderModify
        item={{
          notify: notify,
        }}
        apiResponse={{
          status: status,
        }}
      />
    </>
  );
};
