import { AxiosError } from "axios";
import { useQuery } from "react-query";
import { axiosBuyerSellerList } from "../api/axiosGetRequest";

interface Iaccess {
  apiName: string;
  authorization?: string;
}

export const useBuyerListQuery = (url: string, info: Iaccess) =>
  useQuery(["buyerList", url, info.authorization], axiosBuyerSellerList, {
    onError: (err: AxiosError) => {
      console.error(`from ${info.apiName} ERROR`, err);
    },
  });

export const useSellerListQuery = (url: string, info: Iaccess) =>
  useQuery(["sellerList", url, info.authorization], axiosBuyerSellerList, {
    onError: (err: AxiosError) => {
      console.error(`from ${info.apiName} ERROR`, err);
    },
  });
