import { ChatModify } from "./Chat.modify";
import { useState } from "react";
import { usePlantRoomList } from "./hooks/useGetPlantRoomList";
import { useCookies } from "react-cookie";

export const ChatFetch = () => {
  const [cookie] = useCookies(["60hz-user"]);

  const [plantSearchTrigger, setPlantSearchTrigger] = useState("");

  const { plantRoomListData, plantRoomStatus } = usePlantRoomList(
    plantSearchTrigger,
    cookie["60hz-user"],
  );

  const searchPlantName = (plantName: string) => {
    setPlantSearchTrigger(plantName);
  };

  const searchPlantReset = () => {
    setPlantSearchTrigger("");
  };

  return (
    <ChatModify
      func={{
        searchPlantName: searchPlantName,
        searchPlantReset: searchPlantReset,
      }}
      apiResponse={{
        plantRoomStatus: plantRoomStatus,
      }}
      item={{
        plantRoomList: plantRoomListData,
      }}
    />
  );
};
