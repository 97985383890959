import { useCookies } from "react-cookie";
import { MypageInquiryModify } from "../module_modify/MypageInquiryModify";
import { useGetInquiryList } from "../hooks/useGetInquiryList";
import {
  useDeleteInquiryListQuery,
  useUpdateInquiryListQuery,
} from "../query/useInquiryListQuery";
import {
  IinquiryListDeleteModal,
  IinquiryListUpdateModal,
} from "../types/state.type";
import { useState } from "react";

export const MypageInquiryFetch = () => {
  const [cookie] = useCookies(["60hz-user"]);

  const [deleteModal, setDeleteModal] = useState<IinquiryListDeleteModal>({
    modal: false,
    inquiry_id: null,
  });

  const [updateModal, setUpdateModal] = useState<IinquiryListUpdateModal>({
    modal: false,
    inquiry_id: null,
    contents: "",
  });

  const [inquiryText, setInquiryText] = useState("");
  const [selectInquiryList, setSelectInquiryList] = useState("");

  // 삭제된 문의 저장 state
  const [deleteInquiryList, setDeleteInquiryList] = useState<number[]>([]);

  const DELETE_INQUIRY_LIST = useDeleteInquiryListQuery();

  const UPDATE_INQUIRY_LIST = useUpdateInquiryListQuery();

  const { data, status } = useGetInquiryList(cookie["60hz-user"]);

  const submitInquiry = () => {};

  // 문의 내역 삭제 요청 함수
  const deleteInquiryPlant = () => {
    if (!deleteModal.inquiry_id) {
      return;
    }
    DELETE_INQUIRY_LIST.mutate({
      apiName: "InquiryList REMOVE",
      url: `api/ask/${deleteModal.inquiry_id}/`,
      cookie: cookie["60hz-user"],
    });
    // 삭제 모달 초기화
    // setDeleteModal({ modal: false, inquiry_id: null });
  };

  // 문의 내역 업데이트 요청 함수
  const updateInquiryPlant = () => {
    UPDATE_INQUIRY_LIST.mutate({
      apiName: "InquiryList UPDATTE",
      url: `api/ask/${updateModal.inquiry_id}/`,
      cookie: cookie["60hz-user"],
      contents: updateModal.contents,
    });
    // 수정 모달 초기화
    setUpdateModal({ modal: false, inquiry_id: null, contents: "" });
  };

  return (
    <MypageInquiryModify
      state={{
        deleteInquiryList: deleteInquiryList,
        deleteModal: deleteModal,
        updateModal: updateModal,
        selectInquiryList: selectInquiryList,
        inquiryText: inquiryText,
      }}
      item={{
        inquiryList: data,
      }}
      apiResponse={{
        status: status,
        updatedStatus: UPDATE_INQUIRY_LIST.status,
        updatedError:
          UPDATE_INQUIRY_LIST.error &&
          UPDATE_INQUIRY_LIST.error.response &&
          UPDATE_INQUIRY_LIST.error.response.status,
        updatedId: UPDATE_INQUIRY_LIST.data && UPDATE_INQUIRY_LIST.data.id,
        deletedStatus: DELETE_INQUIRY_LIST.status,
        deletedError:
          DELETE_INQUIRY_LIST.error &&
          DELETE_INQUIRY_LIST.error.response &&
          DELETE_INQUIRY_LIST.error.response.status,
      }}
      func={{
        setDeleteInquiryList: setDeleteInquiryList,
        deleteInquiryPlant: deleteInquiryPlant,
        updateInquiryPlant: updateInquiryPlant,
        setDeleteModal: setDeleteModal,
        setUpdateModal: setUpdateModal,
        submitInquiry: submitInquiry,
        setSelectInquiryList: setSelectInquiryList,
        setInquiryText: setInquiryText,
      }}
    />
  );
};
