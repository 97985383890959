import { ChatPresenter } from "./Chat.presenter";
import { IplantRoomList, Imessage } from "./types/item.type";
import { IpingList, IcurrentMessageList } from "./types/state.type";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

interface IapiResponse {
  plantRoomStatus: string;
}

interface Iitem {
  plantRoomList: IplantRoomList[];
}

interface Ifunc {
  searchPlantName: (plantName: string) => void;
  searchPlantReset: () => void;
}

export const ChatModify = ({
  func,
  apiResponse,
  item,
}: {
  func: Ifunc;
  apiResponse: IapiResponse;
  item: Iitem;
}) => {
  const [plantPingList, setPlantPingList] = useState<IpingList>({});
  const [userPingList, setUserPingList] = useState<IpingList>({});

  const [selectPlant, setSelectPlant] = useState<Imessage[]>([]);

  const [selectOption, setSelectOption] = useState({
    plant: "",
    currentMessageId: 0,
    user: "",
  });

  const { search } = useLocation();

  const [curretMessageList, setCurretMessageList] =
    useState<IcurrentMessageList>({});

  useEffect(() => {
    const copyPlantArr: IpingList = {};
    const copyUserArr: IpingList = {};
    const copyUserMessageArr: IcurrentMessageList = {};
    if (apiResponse.plantRoomStatus === "success") {
      item.plantRoomList.map((el: IplantRoomList) => {
        return (copyPlantArr[el.plant] = { ping: !el.is_read });
      });

      item.plantRoomList.map((el) =>
        el.message.map((ms) => {
          copyUserArr[ms.id] = { ping: ms.not_read_count !== 0 };
          return (copyUserMessageArr[ms.id] = {
            message: ms.recent_message_content_info.content,
            date: ms.recent_message_content_info.send_datetime,
          });
        }),
      );

      setPlantPingList({ ...copyPlantArr });
      setUserPingList({ ...copyUserArr });
      setCurretMessageList({ ...copyUserMessageArr });
    }
  }, [apiResponse.plantRoomStatus]);

  useEffect(() => {
    if (search.slice(7).length) {
      const FIND_PLANT_NAME = item.plantRoomList.find(
        (el) => String(el.plant) === search.slice(7),
      )?.plant_name;
      const SAVE_MESSAGE_LIST = item.plantRoomList.find(
        (el) => String(el.plant) === search.slice(7),
      )?.message;

      setSelectOption({
        ...selectOption,
        plant: FIND_PLANT_NAME ? FIND_PLANT_NAME : "",
      });
      setSelectPlant(SAVE_MESSAGE_LIST ? SAVE_MESSAGE_LIST : []);
      setPlantPingList({ ...plantPingList, [search.slice(7)]: false });
    }
  }, [apiResponse.plantRoomStatus]);

  return (
    <ChatPresenter
      state={{
        plantPingList: plantPingList,
        userPingList: userPingList,
        curretMessageList: curretMessageList,
        selectOption: selectOption,
        selectPlant: selectPlant,
      }}
      func={{
        setPlantPingList: setPlantPingList,
        setUserPingList: setUserPingList,
        setCurretMessageList: setCurretMessageList,
        setSelectOption: setSelectOption,
        setSelectPlant: setSelectPlant,
        searchPlantName: func.searchPlantName,
        searchPlantReset: func.searchPlantReset,
      }}
      apiResponse={{ ...apiResponse }}
      item={{
        ...item,
      }}
    />
  );
};
