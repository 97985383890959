import { DetailAskingPrice } from "../../../components/Detail/AskingPrice/DetailAskingPrice";
import { IsellerList } from "../types/item.type";

interface Iitem {
  sellerList: IsellerList[];
}

export const DetailAskingPriceModify = ({ item }: { item: Iitem }) => {
  return (
    <DetailAskingPrice
      item={{
        sellerList: item.sellerList,
      }}
    />
  );
};
