import { useGetUserInfoQuery } from "../query/useUserInfoQuery";

export const useGetUserinfo = (cookie: string) => {
  const { data, status } = useGetUserInfoQuery("v1/info/", {
    apiName: "userInfomationFromMypage",
    cookie: cookie,
  });

  return {
    userInfo: data
      ? {
          userName: data.username,
          email: data.email,
          nickname: data.nickname,
          company: data.company_name,
          companyType: data.company_type,
          phoneNumber: data.phone,
          telephone: data.telephone,
          receiveEmail: data.receive_email,
          receiveKakao: data.receive_kakaotalk,
          receiveSms: data.receive_sms,
        }
      : INITIAL_USER_INFORMATION,
    userInfoStatus: status,
  };
};

const INITIAL_USER_INFORMATION = {
  userName: "",
  email: "",
  nickname: "",
  phoneNumber: "",
  telephone: "",
  company: "",
  companyType: "발전소 소유주(개인/법인)",
  receiveEmail: false,
  receiveKakao: false,
  receiveSms: false,
};
