import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { sentinelList } from "../atoms/sentinelList";
import { useSentinelMapQuery } from "../query/useSentinelMapQuery";

export const useGetSentinelMap = (id?: string) => {
  const setImage = useSetRecoilState(sentinelList);

  const { data } = useSentinelMapQuery(
    `satellite_image/plant_id/${id}/metadata.json`,
    {
      apiName: "SentinelMap Information",
    },
  );

  useEffect(() => {
    if (!data) return;
    setImage(data);
  }, [data]);
};
