import { useState } from "react";
import { useCookies } from "react-cookie";
import { useGetUserinfo } from "../hooks/useGetUserInfo";
import { usePatchUserInfoQuery } from "../query/useUserInfoQuery";
import { MypageUserChangeModify } from "../module_modify/MypageUserChange.modify";
import { IreceiveCheck } from "../types/state.type";
import {
  useNicknameDuplicationQuery,
  usePhoneNumberDuplicationQuery,
} from "../../SignUp/query/useDuplicationQuery";
import { HandleNickname, HandlePhoneNumber } from "../../SignUp/utils/SignFunc";

interface signature {
  [key: string]: string;
}

export const MypageUserChangeFetch = () => {
  const [userModify, setUserModify] = useState<signature>({
    userName: "",
    email: "",
    password: "",
    passwordVerify: "",
    nickname: "",
    phoneNumber: "",
    telephoneNumber: "",
    company: "",
    companyType: "발전소 소유주(개인/법인)",
  });
  const [cookie] = useCookies(["60hz-user"]);

  const [toggle, setToggle] = useState(true);
  const [pwButton, setPwButton] = useState(false);

  const [receiveCheck, setReceiveCheck] = useState<IreceiveCheck>({
    email: false,
    kakao: false,
    sms: false,
  });

  const { userInfo, userInfoStatus } = useGetUserinfo(cookie["60hz-user"]);

  const PATCH_USER_INFORMATION = usePatchUserInfoQuery();
  const POST_PHONE_DUPLICATION = usePhoneNumberDuplicationQuery();
  const POST_NICKNAME_DUPLICATION = useNicknameDuplicationQuery();

  // 휴대폰 중복 검사 요청
  const submitPhoneDuplication = () => {
    if (
      HandlePhoneNumber(userModify.phoneNumber) &&
      userModify.phoneNumber !== userInfo.phoneNumber
    ) {
      POST_PHONE_DUPLICATION.mutate({
        apiName: "PhoneNumber Duplication",
        submitInfo: userModify.phoneNumber,
        url: "users/phone/exists/",
        type: "phone",
      });
    }
  };

  // 닉네임 중복 검사 요청
  const submitNicknameDuplication = () => {
    if (
      HandleNickname(userModify.nickname) &&
      userModify.nickname !== userInfo.nickname
    ) {
      POST_NICKNAME_DUPLICATION.mutate({
        apiName: "Nickname Duplication",
        submitInfo: userModify.nickname,
        url: "users/nickname/exists/",
        type: "nickname",
      });
    }
  };

  const submitUserInfo = () => {
    PATCH_USER_INFORMATION.mutate({
      apiName: "userInformation PATCH",
      url: "v1/info/",
      cookie: cookie["60hz-user"],
      userInfo: userModify,
      receive: receiveCheck,
      pwButton: pwButton,
    });
  };

  return (
    <MypageUserChangeModify
      state={{
        toggle: toggle,
        pwButton: pwButton,
        receiveCheck: receiveCheck,
        userModify: userModify,
      }}
      func={{
        setToggle: setToggle,
        setPwButton: setPwButton,
        setReceiveCheck: setReceiveCheck,
        setUserModify: setUserModify,
        submitUserInfo: submitUserInfo,
        submitPhoneDuplication: submitPhoneDuplication,
        submitNicknameDuplication: submitNicknameDuplication,
      }}
      item={{
        userInfo: userInfo,
        nicknameOriginal: userInfo.nickname,
        phoneNumberOriginal: userInfo.phoneNumber,
      }}
      apiResponse={{
        serverError: PATCH_USER_INFORMATION.error?.response?.data,
        userInfoStatus: userInfoStatus,
        submitLoading: PATCH_USER_INFORMATION.isLoading,
        submitError: PATCH_USER_INFORMATION.isError,
        submiteCode:
          PATCH_USER_INFORMATION.error && PATCH_USER_INFORMATION.error.code
            ? PATCH_USER_INFORMATION.error.code
            : "",
        submiteStatus:
          PATCH_USER_INFORMATION.error && PATCH_USER_INFORMATION.error.response
            ? PATCH_USER_INFORMATION.error.response.status
            : 0,

        phoneDupSuccess: POST_PHONE_DUPLICATION.isSuccess,
        phoneDupFail: POST_PHONE_DUPLICATION.isError,
        phoneDupLoading: POST_PHONE_DUPLICATION.isLoading,
        nicknameDupSuccess: POST_NICKNAME_DUPLICATION.isSuccess,
        nicknameDupFail: POST_NICKNAME_DUPLICATION.isError,
        nicknameDupLoading: POST_NICKNAME_DUPLICATION.isLoading,
      }}
    />
  );
};
