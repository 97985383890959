import { SubmitLoading } from "../UI/Loading/Loading";

interface IapiResponse {
  status: string;
}

export const DetailLoading = ({
  apiResponse,
}: {
  apiResponse: IapiResponse;
}) => {
  return (
    <>
      <div className="w-full h-full fixed top-[0.5px] z-[99] opacity-50 bg-text-color-primary"></div>
      <div className="w-full fixed bottom-[50%] z-[100]">
        <div className="flex min-h-full justify-center items-center">
          <div
            className="w-[300px] h-[100px] flex flex-col justify-center items-center bg-general-white
          rounded-lg border-main-color-primary border-[1px]"
          >
            {apiResponse.status === "loading" ? (
              <>
                <SubmitLoading height={30} width={30} />
                <span className="mt-3 font-light">로딩 중입니다.</span>
              </>
            ) : (
              <>
                <span className="text-subValue">⛔️</span>
                <h1 className="text-general-red">
                  네트워크 에러가 발생하였습니다.
                </h1>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
