import { ReactElement } from "react";
import { DetailInformationTable } from "../../../Detail/Information/DetailInformationTable";
import { MypageDocuments } from "../MypageDocuments";
import { MypageEquipment } from "../MypageEquipment";
import { MypageSubmit } from "../MypageSubmit";
import { MypagePlantImage } from "../MypagePlantImage";
import { MypageRpsSystem } from "../MypageRpsSystem";
import {
  IplantTotalInfo,
  Iinverter,
  Imodule,
} from "../../../../pages/Mypage/types/plantTotalInfo.type";
import {
  Ifile,
  IimageFile,
  IthumbnailList,
} from "../../../../pages/Mypage/types/state.type";
import { useNavigate } from "react-router-dom";
import { MypageOwnerModal } from "./MypageOwnerModal";
import { MypageDocumentsModify } from "../MypageDocumentsModify";
import { myPlantSubmitCheck } from "../../../../pages/Mypage/utils/submitCheck";
import { MypageThumbnail } from "../MypageThumbnail";
import { DefaultLoading } from "../../../UI/Loading/Loading";
import { errorHandling } from "../../../../utils_public/ErrorHandle";

interface signature {
  [key: string]:
    | ReactElement<HTMLHeadingElement | HTMLDivElement>
    | ReactElement;
}

interface Istate {
  inverter: Iinverter;
  module: Imodule;
  rpsFile: Ifile;
  energeFile: Ifile;
  imageFile: IimageFile;
  thumbnail: IthumbnailList;
  haveRpsFile: boolean;
  haveEnergeFile: boolean;
  modal: boolean;
}

interface Ifunc {
  setInverter: (inverter: Iinverter) => void;
  setModule: (module: Imodule) => void;
  setRpsFile: (file: Ifile) => void;
  setEnergeFile: (file: Ifile) => void;
  setImageFile: (file: IimageFile) => void;
  setThumbnail: (file: IthumbnailList) => void;
  setHaveRpsFile: (bool: boolean) => void;
  setHaveEnergeFile: (bool: boolean) => void;
  setModal: (bool: boolean) => void;
  handleSubmitModal: (bool: boolean) => void;
}

interface Iitem {
  plantTotalInfo: IplantTotalInfo;
}

interface IapiResponse {
  status: string;
  submitStatus: string;
  submitError?: number | null;
}

export const MypageOwnerInfo = ({
  state,
  func,
  item,
  apiResponse,
}: {
  state: Istate;
  func: Ifunc;
  item: Iitem;
  apiResponse: IapiResponse;
}) => {
  const loading = apiResponse.status === "loading";
  const navigate = useNavigate();

  const submitCheck = myPlantSubmitCheck(
    {
      inverter: state.inverter,
      module: state.module,
    },
    {
      rpsFile: state.rpsFile,
      energeFile: state.energeFile,
      imageFile: state.imageFile,
    },
  );

  const submitEvent: signature = {
    loading: (
      <div className="w-full h-[50%]">
        <DefaultLoading />
      </div>
    ),
    error: (
      <h1 className="text-general-red">
        {errorHandling(
          apiResponse.submitError as number,
          "작성하신 발전소 정보가 등록되지 못하였습니다.",
        )}
      </h1>
    ),
  };

  return (
    <section>
      <h1 className="flex text-listFirst font-bold max-sm:font-medium">
        내 발전소 <img className="px-4" src="/icon_remodel/bird_arrow.svg" /> 내
        발전소 수정
      </h1>
      <div className="flex gap-x-10 mt-4 mb-12">
        {loading ? (
          <div className="animate-pulse">
            <div className="w-[200px] h-[52.5px] bg-[#D9D9D9] rounded-xl"></div>
          </div>
        ) : (
          <h1 className="font-bold text-mainTitle max-sm:font-medium max-sm:text-subTitle">
            {item.plantTotalInfo.plant.plantName}
          </h1>
        )}
      </div>

      <div className="max-w-[740px] w-full max-lg:max-w-full">
        <DetailInformationTable
          item={{
            plantName: item.plantTotalInfo.plant.plantName,
            landCategory: "-",
            landSize: "-",
            memberType: item.plantTotalInfo.plant.memberType,
            address: item.plantTotalInfo.plant.updatedAddress,
          }}
          options={{
            title: "발전소 기본 정보",
            loading: loading,
          }}
        />
        <MypageDocuments
          fileName={item.plantTotalInfo.verifyLicense.plantLicenseName}
          title={"발전사업등록증"}
        />
        <MypageDocuments
          fileName={
            item.plantTotalInfo.verifyLicense.commercialOperationLicenseName
          }
          title={"상업운전개시 확인서"}
        />

        {state.haveRpsFile ? (
          <MypageDocumentsModify
            item={{
              fileName: item.plantTotalInfo.rpsPowerGenImageName,
            }}
            func={{
              changeFile: func.setHaveRpsFile,
            }}
            options={{
              title: "RPS 종합시스템상 발전량 화면 캡쳐본",
              type: "rpsFile",
            }}
          />
        ) : (
          <MypageRpsSystem
            rpsFile={state.rpsFile}
            setRpsFile={func.setRpsFile}
          />
        )}

        <MypageEquipment
          state={{
            inverter: state.inverter,
            module: state.module,
          }}
          func={{
            setInverter: func.setInverter,
            setModule: func.setModule,
          }}
          options={{
            title: "모듈",
          }}
        />
        <MypageEquipment
          state={{
            inverter: state.inverter,
            module: state.module,
          }}
          func={{
            setInverter: func.setInverter,
            setModule: func.setModule,
          }}
          options={{
            title: "인버터",
          }}
        />
        {state.haveEnergeFile ? (
          <MypageDocumentsModify
            item={{
              fileName: item.plantTotalInfo.renewableCertificateImageName,
            }}
            func={{
              changeFile: func.setHaveEnergeFile,
            }}
            options={{
              title: "신재생에너지설비 인증서",
              type: "energeFile",
            }}
          />
        ) : (
          <MypageSubmit
            energeFile={state.energeFile}
            setEnergeFile={func.setEnergeFile}
          />
        )}
        {state.thumbnail.default ? (
          <MypageThumbnail
            thumbnail={state.thumbnail}
            setThumbnail={func.setThumbnail}
          />
        ) : (
          <MypagePlantImage
            state={{
              imageFile: state.imageFile,
            }}
            func={{
              setImageFile: func.setImageFile,
            }}
            item={{
              currntIdx: item.plantTotalInfo.imagesCurrentId,
            }}
          />
        )}

        <div className="h-[60px] flex items-center justify-center">
          {submitEvent[apiResponse.submitStatus]}
        </div>
        <section className="flex gap-x-4">
          <button
            className="w-full py-[12px] leading-[100%] text-text-color-disabled border-[1px]
           border-text-color-disabled rounded-lg"
            onClick={() => navigate(-1)}
          >
            이전
          </button>
          <button
            className={`w-full py-[12px] leading-[100%] ${
              submitCheck
                ? " bg-main-color-primary "
                : "bg-border-color-dividers"
            } text-general-white  rounded-lg`}
            onClick={() => {
              if (submitCheck) {
                func.setModal(true);
              }
            }}
          >
            저장
          </button>
        </section>
      </div>
      {state.modal && (
        <MypageOwnerModal
          func={{
            handleSubmitModal: func.handleSubmitModal,
          }}
          options={{
            contents: "저장하시겠습니까?",
            paddingX: "information",
          }}
        />
      )}
    </section>
  );
};
