import { useEffect } from "react";
import { IuserPasswordValidation } from "../types/state.type";

interface Ivalue extends IuserPasswordValidation {
  passwordChange: string;
  passwordVerify: string;
}

interface Ifunc {
  setLength: (bool: boolean) => void;
  setSpecial: (bool: boolean) => void;
  setSame: (bool: boolean) => void;
  setNumber: (bool: boolean) => void;
}

export const usePasswordValidation = (value: Ivalue, func: Ifunc) => {
  useEffect(() => {
    if (value.passwordChange.length >= 8 && value.passwordChange.length <= 30) {
      func.setLength(true);
    } else {
      func.setLength(false);
    }

    if (NUMBER_TEST.test(value.passwordChange)) {
      func.setNumber(true);
    } else {
      func.setNumber(false);
    }

    if (SPECIAL_TEST.test(value.passwordChange)) {
      func.setSpecial(true);
    } else {
      func.setSpecial(false);
    }
  }, [value.passwordChange]);

  useEffect(() => {
    if (
      value.passwordVerify === value.passwordChange &&
      value.passwordVerify !== ""
    ) {
      func.setSame(true);
    } else {
      func.setSame(false);
    }
  }, [value.passwordVerify, value.passwordChange]);
};

const NUMBER_TEST = /[0-9]/;
const SPECIAL_TEST = /[$&+,:;=?@#|`'<>.^*()%!-]/;
