import "./Terms.css";
import { Route, Routes } from "react-router-dom";

function ConsentToReceiveMarketing() {
  return (
    <div>
      <div className={"privacy-title"}>마케팅 수신 동의</div>
      <div className={"privacy-sub-title"}>1. 광고성 정보의 이용목적</div>
      <div className={"privacy-content"}>
        식스티헤르츠가 제공하는 서비스 관련 정보 및 광고 수신, 고객 만족도 조사,
        제휴 혜택 등의 광고성 정보를 전자우편이나 서신우편, 문자(SMS 또는 카카오
        알림톡), 푸시, 전화 등을 통해 이용자에게 제공합니다.
        <br />
        <br />
        마케팅 수신 동의는 거부하실 수 있으며 동의 이후에라도 고객의 의사에 따라
        동의를 철회할 수 있습니다. 동의를 거부하시더라도 식스티헤르츠가 제공하는
        서비스의 이용에 제한이 되지 않습니다. 단, 할인, 이벤트 및 이용자 맞춤형
        상품 추천 등의 마케팅 정보 안내 서비스가 제한됩니다.
      </div>
      <div className={"privacy-sub-title"}>2. 미동의 시 불이익 사항</div>
      <div className={"privacy-content"}>
        개인정보보호법 제22조 제5항에 의해 선택정보 사항에 대해서는 동의
        거부하시더라도 서비스 이용에 제한되지 않습니다. 단, 할인, 이벤트 및
        이용자 맞춤형 상품 추천 등의 마케팅 정보 안내 서비스가 제한됩니다.
      </div>
      <div className={"privacy-sub-title"}>3. 서비스 정보 수신 동의 철회</div>
      <div className={"privacy-content"}>
        식스티헤르츠에서 제공하는 마케팅 정보를 원하지 않을 경우 철회를 요청할
        수 있습니다.
      </div>
      <div className={"privacy-content"}>시행일자 : 2021년 4월 14일</div>
    </div>
  );
}

function PrivacyPolicy() {
  return (
    <div>
      <div className={"privacy-title"}>개인정보 처리방침</div>
      <div className={"privacy-content"}>
        식스티헤르츠 주식회사(이하 ‘회사’)는 서비스 기획부터 종료까지
        개인정보보호법 등 국내의 개인정보 보호 법령을 철저히 준수합니다.
      </div>

      <div className={"privacy-sub-title"}>1. 개인정보처리방침의 의의</div>
      <div className={"privacy-content"}>
        개인정보처리방침은 다음과 같은 중요한 의미를 가지고 있습니다.
        <br />
        회사가 어떤 정보를 수집하고, 수집한 정보를 어떻게 사용하며, 필요에 따라
        누구와 이를 공유(‘위탁 또는 제공’)하며, 이용목적을 달성한 정보를
        언제·어떻게 파기하는지 등 ‘개인정보의 한살이’와 관련한 정보를 투명하게
        제공합니다.
        <br />
        개인정보 침해사고가 발생하는 경우, 추가적인 피해를 예방하고 이미 발생한
        피해를 복구하기 위해 누구에게 연락하여 어떤 도움을 받을 수 있는지
        알려드립니다.
        <br />그 무엇보다도, 개인정보와 관련하여 회사와 이용자간의 권리 및 의무
        관계를 규정하여 이용자의 ‘개인정보자기결정권’을 보장하는 수단이 됩니다.
      </div>

      <div className={"privacy-sub-title"}>2. 수집하는 개인정보</div>
      <div className={"privacy-content"}>
        이용자가 서비스를 이용하기 위해 회원가입을 할 경우, 회사는 서비스 이용을
        위해 필요한 최소한의 개인정보를 수집합니다. 회원가입 시점에 회사가
        이용자로부터 수집하는 개인정보는 아래와 같습니다.
        <br />
        회원 가입 시에 ‘아이디, 비밀번호, 이름, 전화번호, 소속, 분류’를
        수집합니다.
        <br />
        서비스 이용 과정에서 이용자로부터 수집하는 개인정보는 아래와 같습니다.
        <br />
        햇빛바람 거래소 서비스의 발전량 예측 정보 확인, 발전소 검색 등 일련의
        서비스 이용 과정에서 해당 서비스의 이용자에 한해 추가 개인정보 수집이
        발생할 수 있습니다. 추가로 개인정보를 수집할 경우에는 해당 개인정보 수집
        시점에서 이용자에게 ‘수집하는 개인정보 항목, 개인정보의 수집 및
        이용목적, 개인정보의 보관기간’에 대해 안내 드리고 동의를 받습니다.
        <br />
        서비스 이용 과정에서 IP 주소, 쿠키, 서비스 이용 기록, 기기정보,
        위치정보가 생성되어 수집될 수 있습니다. 또한 발전소 정보를 제공 받는
        과정에서 이미지가 수집될 수 있습니다.
        <br />
        구체적으로 1) 서비스 이용 과정에서 이용자에 관한 정보를 자동화된
        방법으로 생성하여 이를 저장(수집)하거나, 2) 이용자 기기의 고유한 정보를
        원래의 값을 확인하지 못 하도록 안전하게 변환하여 수집합니다. 이와 같이
        수집된 정보는 개인정보와의 연계 여부 등에 따라 개인정보에 해당할 수
        있고, 개인정보에 해당하지 않을 수도 있습니다.
        <br />
        회사는 아래의 방법을 통해 개인정보를 수집합니다.
        <br />
        회원가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에 대해 동의를
        하고 직접 정보를 입력하는 경우, 해당 개인정보를 수집합니다.
        <br />
        오프라인에서 진행되는 이벤트, 세미나 등에서 서면을 통해 개인정보가
        수집될 수 있습니다.
        <br />
        제휴 기업이나 단체로부터 개인정보를 제공받을 수 있으며, 이러한 경우에는
        개인정보보호법에 따라 제휴사에서 이용자에게 개인정보 제공 동의 등을 받은
        후에 회사에 제공합니다.
        <br />
        기기정보와 같은 생성정보는 PC웹, 모바일 웹/앱 이용 과정에서 자동으로
        생성되어 수집될 수 있습니다.
        <br />
      </div>

      <div className={"privacy-sub-title"}>3. 수집한 개인정보의 이용</div>
      <div className={"privacy-content"}>
        햇빛바람 거래소 서비스(모바일 웹/앱 포함)의 회원관리, 서비스 개발·제공
        및 향상, 안전한 인터넷 이용환경 구축 등 아래의 목적으로만 개인정보를
        이용합니다.
        <br />
        회원 가입 의사의 확인, 연령 확인 및 법정대리인 동의 진행, 이용자 및
        법정대리인의 본인 확인, 이용자 식별, 회원탈퇴 의사의 확인 등 회원관리를
        위하여 개인정보를 이용합니다.
        <br />
        기존 서비스 제공(광고 포함)에 더하여, 인구통계학적 분석, 서비스 방문 및
        이용기록의 분석, 개인정보 및 관심에 기반한 이용자간 관계의 형성, 지인 및
        관심사 등에 기반한 맞춤형 서비스 제공 등 신규 서비스 요소의 발굴 및 기존
        서비스 개선 등을 위하여 개인정보를 이용합니다.
        <br />
        법령 및 식스티헤르츠 이용약관을 위반하는 회원에 대한 이용 제한 조치,
        부정 이용 행위를 포함하여 서비스의 원활한 운영에 지장을 주는 행위에 대한
        방지 및 제재, 계정도용 및 부정거래 방지, 약관 개정 등의 고지사항 전달,
        분쟁조정을 위한 기록 보존, 민원처리 등 이용자 보호 및 서비스 운영을
        위하여 개인정보를 이용합니다.
        <br />
        이벤트 정보 및 참여기회 제공, 광고성 정보 제공 등 마케팅 및 프로모션
        목적으로 개인정보를 이용합니다.
        <br />
        서비스 이용기록과 접속 빈도 분석, 서비스 이용에 대한 통계, 서비스 분석
        및 통계에 따른 맞춤 서비스 제공 및 광고 게재 등에 개인정보를 이용합니다.
      </div>

      <div className={"privacy-sub-title"}>4. 개인정보의 제공 및 위탁</div>
      <div className={"privacy-content"}>
        회사는 원칙적으로 이용자 동의 없이 개인정보를 외부에 제공하지 않습니다.
        <br />
        회사는 이용자의 사전 동의 없이 개인정보를 외부에 제공하지 않습니다. 단,
        이용자가 외부 제휴사의 서비스를 이용하기 위하여 개인정보 제공에 직접
        동의를 한 경우, 그리고 관련 법령에 의거해 회사에 개인정보 제출 의무가
        발생한 경우, 이용자의 생명이나 안전에 급박한 위험이 확인되어 이를
        해소하기 위한 경우에 한하여 개인정보를 제공하고 있습니다.
      </div>

      <div className={"privacy-sub-title"}>5. 개인정보의 파기</div>
      <div className={"privacy-content"}>
        회사는 원칙적으로 이용자의 개인정보를 회원 탈퇴 시 지체없이 파기하고
        있습니다.
        <br />
        단, 이용자에게 개인정보 보관기간에 대해 별도의 동의를 얻은 경우, 또는
        법령에서 일정 기간 정보보관 의무를 부과하는 경우에는 해당 기간 동안
        개인정보를 안전하게 보관합니다.
        <br />
        전자상거래 등에서의 소비자 보호에 관한 법률, 전자문서 및 전자거래
        기본법, 통신비밀보호법 등 법령에서 일정기간 정보의 보관을 규정하는
        경우는 아래와 같습니다. 회사는 이 기간 동안 법령의 규정에 따라
        개인정보를 보관하며, 본 정보를 다른 목적으로는 절대 이용하지 않습니다.
        <br />
        <br />
        전자상거래 등에서 소비자 보호에 관한 법률
        <br />
        계약 또는 청약철회 등에 관한 기록 : 5년 보관
        <br />
        대금결제 및 재화 등의 공급에 관한 기록 : 5년 보관
        <br />
        소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 보관
        <br />
        전자문서 및 전자거래 기본법
        <br />
        공인전자주소를 통한 전자문서 유통에 관한 기록 : 10년 보관
        <br />
        통신비밀보호법
        <br />
        로그인 기록 : 3개월
        <br />
        <br />
        회원탈퇴, 서비스 종료, 이용자에게 동의 받은 개인정보 보유기간의 도래와
        같이 개인정보의 수집 및 이용목적이 달성된 개인정보는 재생이 불가능한
        방법으로 파기하고 있습니다.
        <br />
        법령에서 보존의무를 부과한 정보에 대해서도 해당 기간 경과 후 지체없이
        재생이 불가능한 방법으로 파기합니다.
        <br />
        전자적 파일 형태의 경우 복구 및 재생이 되지 않도록 기술적인 방법을
        이용하여 안전하게 삭제하며, 출력물 등은 분쇄하거나 소각하는 방식 등으로
        파기합니다.
        <br />
      </div>

      <div className={"privacy-sub-title"}>
        6. 이용자 및 법정대리인의 권리와 행사 방법
      </div>
      <div className={"privacy-content"}>
        이용자는 언제든지 자신의 개인정보에 대한 열람을 요청할 수 있습니다.
        <br />
        이용자는 언제든지 개인정보 처리의 정지를 요청할 수 있으며, 법률에 특별한
        규정이 있는 등의 경우에는 처리정지 요청을 거부할 수 있습니다.
        <br />
        이용자는 언제든지 ‘회원탈퇴’ 등을 통해 개인정보의 수집 및 이용 동의를
        철회할 수 있습니다.
        <br />
        만 14세 미만 아동의 경우, 법정대리인이 아동의 개인정보를 조회하거나 수정
        및 삭제, 처리정지, 수집 및 이용 동의를 철회할 권리를 가집니다.
        <br />
        이용자가 개인정보의 오류에 대한 정정을 요청한 경우, 정정을 완료하기
        전까지 해당 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된
        개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체
        없이 통지하여 정정이 이루어지도록 하겠습니다.
      </div>

      <div className={"privacy-sub-title"}>
        7. 개인정보 보호책임자 및 담당자 안내
      </div>
      <div className={"privacy-content"}>
        회사는 이용자의 개인정보 관련 문의사항 및 불만 처리 등을 위하여 아래와
        같이 개인정보 보호책임자를 지정하고 있습니다.
        <br />
        <br />
        개인정보 보호책임자
        <br />
        이 름 : 김종규
        <br />
        직 위 : CEO/대표이사
        <br />
        메 일 : privacy@60hz.io
        <br />
        <br />
        기타 개인정보 침해에 대한 신고나 상담이 필요한 경우에 아래 기관에 문의
        가능합니다.
        <br />
        개인정보침해신고센터 (privacy.kisa.or.kr / 국번없이 118)
        <br />
        대검찰청 사이버수사과 (www.spo.go.kr / 국번없이 1301)
        <br />
        경찰청 사이버수사국 (police.go.kr / 국번없이 182)
        <br />
      </div>

      <div className={"privacy-sub-title"}>
        9. 본 개인정보처리방침의 적용 범위
      </div>
      <div className={"privacy-content"}>
        본 개인정보처리방침은 회사의 서비스인 햇빛바람 거래소 및 관련 제반
        서비스(모바일 웹/앱 포함)에 적용됩니다.
      </div>

      <div className={"privacy-sub-title"}>10. 개정 전 고지 의무</div>
      <div className={"privacy-content"}>
        본 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 경우 개정 최소 7일
        전에 ‘공지사항’을 통해 사전 공지를 할 것입니다.
        <br />
        다만, 수집하는 개인정보의 항목, 이용목적의 변경 등과 같이 이용자 권리의
        중대한 변경이 발생할 때에는 최소 30일 전에 공지하며, 필요 시 이용자
        동의를 다시 받을 수도 있습니다.
        <br />
        <br />
        공고일자 : 2021년 4월 14일
      </div>
    </div>
  );
}

function TermsOfService() {
  return (
    <div>
      <div className={"privacy-title"}>이용약관</div>
      <div className={"privacy-sub-title"}>제 1 조 목적</div>
      <div className={"privacy-content"}>
        본 약관은 식스티헤르츠 주식회사(이하 ‘회사’)가 운영하는 ‘햇빛바람
        거래소’ 서비스(이하 ‘서비스’)를 이용하는 ‘회원’의 서비스 이용 조건 및
        절차에 관한 사항과 기타 이용에 필요한 사항을 정함을 목적으로 합니다.
      </div>
      <div className={"privacy-sub-title"}>제 2 조 용어의 정의</div>
      <div className={"privacy-content"}>
        1. 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
        <br />
        ① ‘햇빛바람 거래소’란 재생에너지 발전자원의 정보와 오늘, 내일, 연간
        발전량 예측 값을 온라인 공간에서 제공하고, 나아가 가상발전소 업무를
        지원하는 소프트웨어 일체 (이하 ‘서비스’)를 말합니다.
        <br />
        ② ‘회원’이란 본 약관에 동의하고 ‘서비스’를 이용하는 이용자를 말합니다.
        <br />
        ③ ‘사이트’란 ‘서비스’를 제공하기 위해 ‘회사’가 운영하는
        웹사이트(map.60hz.io)를 말합니다.
        <br />
        2. 본 약관에서 사용하는 용어의 정의는 본 조 제1항에서 정하는 것을
        제외하고는 관계 법령, 회사 이용약관 및 ‘서비스’ 운영정책에서 정하는 바에
        따르며, 그 외에는 일반 상관례에 따릅니다.
      </div>
      <div className={"privacy-sub-title"}>제 3 조 약관의 효력 및 개정</div>
      <div className={"privacy-content"}>
        1. 본 약관의 내용은 ‘사이트’에 게시하거나 기타의 방법으로 ‘회원’에게
        공지하고, ‘회원’이 이에 동의함으로써 효력이 발생합니다. 2. ‘회사’는
        ‘약관의 규제에 관한 법률’, ‘정보통신망 이용 촉진 및 정보 보호 등에 관한
        법률’ 등 관련법령을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.
        <br />
        3. ‘회사’가 약관을 개정할 경우에는 적용 일자 및 개정 사유를 명시하여
        현행 약관과 함께 제1항의 방식에 따라 그 개정 약관의 적용 일자 10일
        이전부터 공지합니다. 다만, ‘회원’에게 불리하게 변경되거나 중요한 내용인
        경우에는 30일 이전부터 상기 방식으로 공지하며, 공지 외에 전자 우편 등의
        전자적 수단을 통해 따로 명확히 통지하도록 합니다.
        <br />
        4. ‘회사’가 전항에 따라 개정 약관을 공지 또는 통지하면서, ‘회원’이 약관
        변경 적용일까지 거부 의사를 표시하지 아니하는 경우에는 해당 변경 약관에
        동의한 것으로 간주한다는 내용을 함께 공지 또는 통지하였음에도, ‘회원’이
        명시적으로 변경된 약관의 효력 발생일까지 약관 변경에 대한 거부의
        의사표시를 하지 아니한 경우에는 개정 약관에 동의한 것으로 봅니다.
        <br />
        5. ‘회원’이 변경된 약관에 동의하지 않을 경우 ‘회사’는 변경된 약관의
        내용을 적용할 수 없으며, 이 경우 ‘회원’은 본 ‘서비스’ 이용을 중단하고
        이용계약을 해지할 수 있습니다.
        <br />
        6. ‘회사’가 약관의 변경 시 본 조에 따른 조치를 하였음에도 불구하고
        ‘회원’이 개정 약관을 인지하지 못하여 발생하는 손해에 대해 ‘회사’는
        책임을 지지 않습니다.
      </div>
      <div className={"privacy-sub-title"}>제 4 조 약관 외 준칙</div>
      <div className={"privacy-content"}>
        1. ‘회사’는 ‘서비스’ 유지 및 ‘회원’ 보호 등을 위해 필요한 경우 본
        ‘서비스’ 이용과 관련된 별도의 운영정책을 정하여 이를 제3조 제1항의
        방법에 의하여 공지할 수 있으며, ‘회원’은 ‘회사’가 정한 운영정책을 반드시
        확인하고 준수하여야 합니다.
        <br />
        2. 본 약관에 명시되지 아니한 사항이나 해석에 대하여는 정보통신망
        이용촉진 및 정보보호 등에 관한 법률 등 관계법령 및 식스티헤르츠
        주식회사의 이용약관과 ‘회사’가 정한 ‘서비스’의 운영정책 등에서 정하는
        바에 따릅니다.
      </div>
      <div className={"privacy-sub-title"}>제 5 조 통지 또는 정보의 제공</div>
      <div className={"privacy-content"}>
        1. ‘회사’가 ‘회원’에 대한 통지를 하는 경우 본 약관에 별도 규정이 없는 한
        전자우편주소, SMS 등으로 할 수 있습니다.
        <br />
        2. ‘회사’는 ‘회원’ 전체에 대한 통지의 경우 7일 이상 ‘회사’의 게시판에 그
        내용을 게시함으로써 전항의 통지에 갈음할 수 있습니다.
        <br />
        3. ‘회사’는 ‘회원’에게 ‘서비스’ 이용에 필요가 있다고 인정되는 각종
        정보에 대해서 사전 동의를 받은 후 전자 우편이나 서신, SMS 등의 방법으로
        제공할 수 있으며 ‘회원’은 원하지 않을 경우 수신을 거부할 수 있습니다.
        단, ‘서비스’ 이용 관련 결제 안내, 정책 변경, 장애, 공지사항 등 필수 주요
        내용은 수신 거부 여부와 무관하게 발송됩니다.
      </div>

      <div className={"privacy-sub-title"}>제 6 조 제한적 사용권의 부여</div>
      <div className={"privacy-content"}>
        ‘회원’이 본 약관에 동의한 경우에 한하여, ‘회사’는 해당 ‘회원’에게
        ‘서비스’를 지역적 제한 없이 이용할 수 있도록 하는 양도 불가능한 개인적인
        사용권을 무상으로 부여합니다.
      </div>

      <div className={"privacy-sub-title"}>제 7 조 ‘서비스’ 내용</div>
      <div className={"privacy-content"}>
        1. ‘회원’은 ‘서비스’를 재생에너지 발전자원의 예측 값을 확인할 수
        있습니다.
        <br />
        2. ‘회사’는 ‘회원’에게 일정 수준의 발전량 예측 결과를 무료로 제공하며,
        ‘회사’의 사업 상황, ‘회원’의 상태 변경 등의 사정으로 무료로 제공되는
        서비스의 범위는 변경될 수 있습니다.
        <br />
        3. ‘회사’는 ‘회원’에게 ‘서비스’에서 발전량 예측 기술을 제공하기 위해서
        발전소 정보, 발전량 예측 값의 제공을 요청할 수 있습니다.
        <br />
        4. ‘회사’는 ‘회원’에게 다음 각 호의 경우를 포함하여 ‘서비스’ 내 일부
        기능을 제공하기 위하여, 기계적인 알고리듬으로 ‘회원’의 데이터를 활용할
        수 있습니다.
        <br />
        5. ‘회사’는 ‘회원’에게 전항의 일부 기능을 제공하기 위하여 각 기능이
        제공되는 시점 또는 제공되기 이전에 ‘회원’의 개별 동의를 얻어야 합니다.
      </div>

      <div className={"privacy-sub-title"}>제 8 조 ‘서비스’ 이용 시간</div>
      <div className={"privacy-content"}>
        1. ‘서비스’ 이용은 ‘회사’의 업무상 또는 기술상 특별한 지장이 없는 한
        연중 무휴, 1일 24시간 운영을 원칙으로 합니다. 단, ‘회사’는 시스템 정기
        점검, 임시 점검, 증설 및 교체를 위해 ‘회사’가 정한 날이나 시간에는
        그러하지 않습니다.
        <br />
        2. ‘회사’는 ‘서비스’ 제공에 필요한 경우 정기점검을 실시할 수 있으며,
        정기 점검시간은 사전에 ‘사이트’를 통해 공지합니다.
        <br />
        3. ‘회사’는 ‘서비스’를 특정 범위로 분할하여 각 범위 별로 이용 가능
        시간을 별도로 지정할 수 있습니다. 다만 이 경우, 그 내용을 사전에
        공지합니다.
        <br />
        4. ‘회사’는 다음 각 호의 어느 하나에 해당하는 경우 ‘서비스’ 제공을
        일시적으로 중지할 수 있습니다. 이 경우 ‘회사’는 본 약관 제5조에 정한
        방법으로 ‘회원’에게 통지합니다. 다만, ‘회사’가 사전에 통지할 수 없는
        부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
        <br />
        ① 긴급한 시스템 점검, 증설 및 교체 등 부득이한 경우
        <br />
        ② 전기통신사업법에 규정된 기간통신사업자가 전기 통신 서비스를 중지했을
        경우
        <br />
        ③ 국가 비상사태, 정전, 서비스 설비의 장애 또는 ‘서비스’ 이용의 폭주
        등으로 정상적인 ‘서비스’ 제공이 불가능할 경우
        <br />④ 기타 불가항력적 사유가 있는 경우
      </div>

      <div className={"privacy-sub-title"}>
        제 9 조 ‘서비스’ 이용 데이터의 저장 및 활용
      </div>
      <div className={"privacy-content"}>
        ‘회사’는 ‘회원’에게 좀 더 좋은 ‘서비스’를 제공하기 위하여 ‘회원’이
        ‘서비스’를 이용하는 과정에서 입력하는 데이터(검색어, 장소 등, 이하 ‘이용
        데이터’)를 저장하여 ‘서비스’의 품질 개선 및 성능 향상, ‘회원’에 대한
        ‘서비스’의 고도화 및 최적화 등의 목적으로 활용할 수 있습니다.
      </div>

      <div className={"privacy-sub-title"}>
        제 10 조 ‘서비스’의 중단 및 변경
      </div>
      <div className={"privacy-content"}>
        1. '회사'는 '서비스' 운영 정책상 또는 '회사'가 운영하는 사이트 간 통합
        등을 하는 경우 콘텐츠의 내용을 변경하지 않고 콘텐츠의 위치를
        변경ㆍ이전하거나 사이트 간 공유를 통해 콘텐츠를 제공할 수 있으며,
        콘텐츠를 이전ㆍ변경하거나 공유할 때에는 미리 공지합니다.
        <br />
        2. ‘서비스’에 대한 변경이 있는 경우, ‘회사’는 ‘회원’에게 제5조에서 정한
        방법으로 통지합니다. 이때 변경 내용에 동의하지 않는 ‘회원’은 ‘서비스’
        이용계약을 해지할 수 있습니다.
        <br />
        3. ‘회사’의 사정으로 인하여 ‘서비스’를 제공할 수 없게 되는 경우에
        ‘회사’는 제5조에 정한 방법으로 ‘회원’에게 통지합니다.
      </div>

      <div className={"privacy-sub-title"}>
        제 11 조 ‘서비스’ 관련 권리 및 관리 등
      </div>
      <div className={"privacy-content"}>
        ‘서비스’의 저작권은 ‘회사’에게 있으며, ‘회원’은 본 약관에서 정한 바에
        따른 제한적 사용권만을 취득할 뿐, 그 외 ‘서비스’에 대한 어떠한 권리도
        취득하지 않습니다.
      </div>

      <div className={"privacy-sub-title"}>제 12 조 ‘회사’의 의무</div>
      <div className={"privacy-content"}>
        1. ‘회사’는 특별한 사정이 없는 한 ‘회원’이 희망한 ‘서비스’를 이용할 수
        있도록 합니다.
        <br />
        2. ‘회사’는 계속적이고 안정적인 ‘서비스’의 제공을 위하여 설비에 장애가
        생기거나 멸실된 때에는 부득이한 사유가 없는 한 지체 없이 이를 수리 또는
        복구합니다.
        <br />
        3. ‘회사’는 ‘회원’으로부터 제기되는 의견이나 불만이 정당하다고
        객관적으로 인정될 경우에는 적절한 절차를 거쳐 처리하여야 합니다. 다만,
        즉시 처리가 곤란한 경우는 ‘회원’에게 그 사유와 처리 일정을 통보하여야
        합니다.
      </div>

      <div className={"privacy-sub-title"}>제 13 조 ‘회원’의 의무</div>
      <div className={"privacy-content"}>
        1. ‘회원’은 본 약관에 동의하는 경우에 한하여 ‘서비스’를 이용할 수
        있으며, ‘회원’이 본 약관에 동의하지 않거나 동의를 철회할 경우 즉시
        ‘서비스’의 이용이 중단됩니다.
        <br />
        2. ‘회원’은 ‘서비스’를 이용함에 있어서 본 약관에서 규정하는 사항과 기타
        ‘회사’가 정한 제반 규정 및 공지사항을 준수하여야 하며, 특히 ‘서비스’와
        관련하여 다음과 같은 행위를 해서는 안됩니다.
        <br />
        ① ‘서비스’ 계정 공유를 위해 아이디 또는 비밀번호를 타인과 공유하는 행위
        <br />
        ② ‘서비스’ 이용을 위해 타인의 아이디 또는 비밀번호를 부정하게 사용하는
        행위
        <br />
        ③ ‘회사’의 운영진이나 ‘회사’를 사칭하는 행위
        <br />
        ④ 타인의 명예를 손상시키거나 불이익을 주는 행위
        <br />
        ⑤ 범죄적 행위와 결부된다고 판단되는 행위
        <br />
        ⑥ ‘회사’ 및 제3자의 지적재산권을 침해하는 행위
        <br />
        ⑦ 악성 프로그램 및 버그를 이용하거나 시스템 취약점을 악용하는 등 부정한
        방법을 ‘서비스’에 사용하는 행위
        <br />
        ⑧ 컴퓨터 바이러스 감염 자료를 등록하는 행위
        <br />
        ⑨ 관계 법령에 위배되는 행위
        <br />
        ⑩ 기타 ‘서비스’의 정상적 운영 유지 등을 방해하거나 지연시키는 행위
        <br />
        3. ‘회원’은 ‘서비스’의 이용 권한, 기타 이용 계약상 지위를 타인에게 양도,
        증여할 수 없으며, 이를 담보로 제공할 수 없습니다.
        <br />
        4. ‘회원’은 ‘회원’ 정보에 변경이 발생하면 즉시 그 내용을 ‘회사’에
        통보하여야 하며, 통보 지연으로 발생한 문제에 대해서는 ‘회사’에서 책임을
        지지 않습니다. 또한 ‘회사’는 ‘회원’의 귀책 사유로 인해 노출된 정보에
        대해서 책임을 지지 않습니다
        <br />
        5. ‘회원’이 ‘회사’의 설비를 분실 또는 훼손하였거나 장애를 초래하게
        하였을 경우에는 그 보충, 수선 또는 기타 공사에 소요되는 비용과 이로 인해
        발생되는 ‘회사’의 손해를 전액 부담하여야 합니다.
        <br />
        6. ‘회원’이 제공하는 모든 정보의 저작권과 관련하여 발생하는 문제는
        ‘회원’의 책임입니다.
        <br />
        7. ‘회원’은 본 약관에서 규정하는 사항, 이용안내 및 ‘서비스’상에 공지한
        사항과 기타 ‘회사’가 정한 제반 규정 및 관계 법령을 준수하여야 하며, 기타
        ‘회사’의 업무에 방해가 되는 행위, ‘회사’의 명예를 손상시키는 행위를
        해서는 안됩니다.
      </div>

      <div className={"privacy-sub-title"}>제 14 조 손해배상</div>
      <div className={"privacy-content"}>
        1. ‘회원’이 본 약관의 규정을 위반함으로 인하여 ‘회사’에 손해가 발생하게
        되는 경우, 본 약관을 위반한 ‘회원’은 ‘회사’에 발생하는 모든 손해를
        배상할 책임이 있습니다.
        <br />
        2. ‘회원’이 ‘서비스’를 이용함에 있어 행한 불법행위나 본 약관 위반 행위로
        인하여 ‘회사’가 당해 ‘회원’ 이외의 제3자로부터 손해배상 청구 또는 소송을
        비롯한 각종 이의 제기를 받는 경우 당해 ‘회원’은 자신의 책임과 비용으로
        ‘회사’를 면책시켜야 하며, ‘회사’가 면책되지 못한 경우 당해 ‘회원’은 그로
        인하여 ‘회사’에 발생한 모든 손해를 배상하여야 합니다.
      </div>

      <div className={"privacy-sub-title"}>제 15 조 면책</div>
      <div className={"privacy-content"}>
        ‘회사’는 각 호의 1에 해당하는 사유로 인하여 ‘회원’에게 발생한 손해에
        대하여는 그 책임을 지지 아니합니다.
        <br />
        ① 전시, 사변, 천재지변 또는 이에 준하는 국가 비상사태 등 불가항력으로
        파일이 손상되었거나 ‘서비스’를 제공할 수 없는 경우
        <br />
        ② 전기통신서비스 특성상 불가피한 사유로 ‘서비스’ 제공이 불가능하거나
        관련 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 일시적으로
        ‘서비스’ 제공을 중단하는 경우
        <br />
        ③ 회원’이 개인 정보를 부실하게 기재하여 손해가 발생한 경우
        <br />
        ④ ‘회원’이 ‘서비스’를 이용하여 기대하는 손익이나 ‘서비스’를 통해 얻은
        정보나 자료 등으로 인해 발생한 손익의 경우
        <br />
        ⑤ ‘회원’ 상호간 및 ‘회원’과 제3자 상호 간에 ‘서비스’를 매개로 분쟁이
        발생한 경우
        <br />
        ⑥ ‘회원’이 자료의 게시로 인해 법적인 책임이 발생한 경우
        <br />
        ⑦ ‘회원’의 디바이스 오류, 인터넷 접속 장애, 휴대 기기 단말 및 운영 체제
        자체의 오류 혹은 한계로 ‘서비스’가 지연되거나 수행되지 않은 결과에
        대해서 ‘회원’에게 발생한 손해의 경우
        <br />
        ⑧ 외부 바이러스나 해킹 시도에 의해 ‘회원’의 이용이 불가한 경우
        <br />⑨ ‘회원’이 자신의 개인정보를 타인에게 유출 또는 제공하거나, 자신의
        아이디, 비밀번호, ‘서비스’ 이용 중에 ‘회원’이 직접 생성한 암호,
        자동로그인 설정된 디바이스 등을 분실 및 도용, 관리 소홀 등의 사유로
        ‘회원’에게 어떠한 손해가 발생한 경우
      </div>

      <div className={"privacy-sub-title"}>제 16 조 계약의 해지 등</div>
      <div className={"privacy-content"}>
        1. ‘회원’은 언제든지 ‘서비스’의 사용을 중단하고, 본 약관에 따른 이용
        계약을 해지할 수 있습니다. ‘회원’이 이용 계약을 해지하는 경우 ‘회사’는
        보관 중인 ‘회원’의 콘텐츠를 모두 삭제하며 삭제된 콘텐츠는 복원되지
        않습니다.
        <br />
        2. ‘회원’이 관련 법령을 위반하거나 본 이용약관을 준수하지 않는 경우,
        ‘회사’는 임의로 본 이용 계약을 해지하거나 ‘회원’의 ‘서비스’ 사용을
        일시적 또는 영구적으로 제한할 수 있습니다.
        <br />
        3. ‘회원’이 ‘서비스’를 장기간 사용하지 않는 경우 휴면 정책이 적용됩니다.
        휴면 정책이 적용되면 ‘회사’는 보관 중인 해당 ‘회원’의 컨텐츠를 모두
        삭제하며 삭제된 컨텐츠는 복원되지 않습니다.
      </div>

      <div className={"privacy-sub-title"}>제 17 조 재판권 및 준거법</div>
      <div className={"privacy-content"}>
        본 이용약관의 준거법은 대한민국 법으로 하며, ‘서비스’와 관련하여 소송이
        제기되는 경우 민사소송법 상의 관할 법원을 재판 관할을 갖는 법원으로
        합니다.
      </div>

      <div className={"privacy-sub-title"}>부칙</div>
      <div className={"privacy-content"}>
        본 약관은 2021년 4월 14일부터 적용됩니다.
      </div>
    </div>
  );
}

export default function Terms() {
  return (
    <div className={"terms-area"}>
      <pre className={"terms-body"}>
        <Routes>
          <Route path="/service" element={<TermsOfService />} />
          <Route path="/policy" element={<PrivacyPolicy />} />
          <Route path="/marketing" element={<ConsentToReceiveMarketing />} />
        </Routes>
      </pre>
    </div>
  );
}
