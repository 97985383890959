import { useNavigate } from "react-router-dom";
import { IloginInput } from "../../pages/Login/types/loginInput.type";
import { ButtonLoading } from "../UI/Loading/Loading";

interface Istate {
  loginInput: IloginInput;
  emailResult: boolean;
}

interface Ifunc {
  submitLogin: () => void;
}

interface IapiResponse {
  loading: boolean;
}

export const LoginSelect = ({
  state,
  func,
  apiResponse,
}: {
  state: Istate;
  func: Ifunc;
  apiResponse: IapiResponse;
}) => {
  const navigate = useNavigate();
  return (
    <div className="max-w-[432px] w-full flex flex-col gap-y-3 mb-[40px]">
      <button
        className={`h-[43px] ${
          state.emailResult && state.loginInput.password !== ""
            ? "cursor-pointer bg-main-color-primary text-general-white"
            : "cursor-not-allowed bg-border-color-dividers "
        }  rounded-lg`}
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          // 유효성 검사 성립 안될 경우 클릭 방지
          e.preventDefault();
          if (!state.emailResult || state.loginInput.password === "") return;
          func.submitLogin();
        }}
      >
        {apiResponse.loading ? (
          <div className="w-[30px] mx-auto">
            <ButtonLoading />
          </div>
        ) : (
          "로그인"
        )}
      </button>
      <button
        className="h-[43px] border-[1px] border-main-color-secondary rounded-lg"
        onClick={() => navigate("/signup")}
      >
        회원가입
      </button>
    </div>
  );
};
