import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { DetailCapacity } from "../../../components/Detail/Capacity/DetailCapacity";
import { IplantInfo } from "../types/plantInfo.type";
import { Iinquiry } from "../types/state.type";

interface Istate {
  plantInfo: IplantInfo;
}

export const DetailCapacityModify = ({ state }: { state: Istate }) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const [cookie] = useCookies(["60hz-user"]);

  // 비로그인 접근 방지 함수
  const preventNotLogin = () => {
    return window.confirm(
      "로그인이 필요합니다. 로그인 페이지로 이동하겠습니까?",
    )
      ? navigate("/login", {
          state: { previousPath: pathname, queryStringPath: search },
        })
      : null;
  };

  return (
    <>
      <DetailCapacity plantInfo={state.plantInfo} />
    </>
  );
};
