import { MainPlantResetFilter } from "./FilterCategory/MainPlantResetFilter";
import { MainPlantListLocalFilter } from "./FilterCategory/MainPlantListLocalFilter";
import { MainPlantListCapacityFilter } from "./FilterCategory/MainPlantListCapacityFilter";
import { MainPlantListLandCategoryFilter } from "./FilterCategory/MainPlantListLandCategoryFilter";
import { IfilterItem } from "../../pages/Mypage/types/state.type";

interface Istate {
  filter: IfilterItem;
}

interface Ifunc {
  selectFilter: (type: string, value: string) => void;
  resetFilter: () => void;
}

export const MainPlantListFilter = ({
  state,
  func,
}: {
  state: Istate;
  func: Ifunc;
}) => {
  return (
    <section className="mb-5 mr-[35px] max-lg:hidden">
      <main
        id={"filter-scrollbar"}
        style={{ overflow: "overlay" }}
        className="h-[calc(100vh-100px)] sticky top-20 overflow-y-auto w-[255px]"
      >
        <div>
          <MainPlantResetFilter resetFilter={func.resetFilter} />
          <MainPlantListLocalFilter
            filter={state.filter}
            filterType={"지역"}
            selectFilter={func.selectFilter}
          />
          <MainPlantListCapacityFilter
            filter={state.filter}
            filterType={"용량"}
            selectFilter={func.selectFilter}
          />
          <MainPlantListLandCategoryFilter
            filter={state.filter}
            filterType={"지목"}
            selectFilter={func.selectFilter}
          />
        </div>
      </main>
    </section>
  );
};
