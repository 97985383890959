export const FooterLogin = ({ current }: { current: string }) => {
  return (
    <footer
      className={`w-full h-[150px] relative text-smallExplanation text-text-color-disabled ${
        current.slice(1, current.length) === "success" && "fixed bottom-[-15px]"
      }`}
    >
      <div className="max-w-[738px]  flex flex-col justify-center mx-auto">
        <div className="flex justify-center max-sm:flex-col items-center ">
          <div>
            <a
              className="cursor-pointer"
              target={"_blank"}
              href="https://60hz.io/"
            >
              회사소개
            </a>
            <span className="mx-5">|</span>
            <a
              href="/privacy/service"
              className="cursor-pointer"
              target={"_blank"}
            >
              서비스 이용 약관
            </a>
            <span className="mx-5">|</span>
          </div>
          <div className="max-sm:mt-[8px]">
            <a
              className="cursor-pointer"
              href="/privacy/policy"
              target={"_blank"}
            >
              개인정보처리방침
            </a>
            <span className="mx-5">|</span>
            <a
              className="cursor-pointer"
              href="mailto:hello@60hz.io"
              target="_blank"
            >
              hello@60hz.io
            </a>
          </div>
        </div>
        <h1 className="text-center mt-2 max-sm:mt-[16px]">
          Copyright © 식스티헤르츠 주식회사. All Rights Reserved.
        </h1>
      </div>
    </footer>
  );
};
