import { DragDropFileContainer } from "../../../UI/DragAndDrop/DragDropFile";
import { MypageOwnerModal } from "./MypageOwnerModal";
import { Ifile } from "../../../../pages/Mypage/types/state.type";
import { ownerSubmitCheck } from "../../../../pages/Mypage/utils/submitCheck";
import { DefaultLoading } from "../../../UI/Loading/Loading";

interface Istate {
  businessFile: Ifile;
  commerceFile: Ifile;
  submitModal: boolean;
}

interface Ifunc {
  setBusinessFile: (file: Ifile) => void;
  setCommerceFile: (file: Ifile) => void;
  setSubmitModal: (bool: boolean) => void;
  handleSubmitModal: (bool: boolean) => void;
}

interface IapiResponse {
  status: string;
}

export const MypageOwnerSubmit = ({
  state,
  func,
  apiResponse,
}: {
  state: Istate;
  func: Ifunc;
  apiResponse: IapiResponse;
}) => {
  // 완료 버튼 활성화 여부 검사 함수
  const submitCheckValue = ownerSubmitCheck(
    state.businessFile.file,
    state.commerceFile.file,
  );
  // 파일 하나만 더 업로드하면 될 경우 true 발생 변수
  const compareFile =
    (state.businessFile.file !== null && state.commerceFile.file === null) ||
    (state.businessFile.file === null && state.commerceFile.file !== null);

  return (
    <section>
      <h1 className="flex text-listFirst font-bold mb-4 max-sm:font-medium">
        내 발전소 <img className="px-4" src="/icon_remodel/bird_arrow.svg" />
        소유주 등록하기
      </h1>
      <div className="flex items-center gap-x-[12px] text-subValue mt-4 mb-8 max-sm:text-listTitle">
        <p>발전소 정보를 알려주세요</p>

        {compareFile && (
          <span className="text-default text-general-red">
            하나만 더 추가하고 등록을 완료하세요!
          </span>
        )}
      </div>
      <main className="max-w-[768px] w-full">
        <div className="mb-4 flex items-center text-listSecond relative">
          <h1 className="font-bold max-sm:font-medium">발전사업허가증</h1>
          <span className="ml-1 text-smallExplanation text-general-red">
            필수
          </span>
        </div>
        <div className="max-w-full w-full h-[178px]">
          <DragDropFileContainer
            file={state.businessFile}
            setFile={func.setBusinessFile}
            title={"발전사업등록증"}
            input_id="input-file-upload_A"
            // status={apiResponse.status}
            content={""}
          />
        </div>
        <div className="mt-[60px] mb-4 flex items-center text-listSecond relative">
          <h1 className="font-bold max-sm:font-medium">상업운전개시 확인서</h1>
          <span className="ml-1 text-smallExplanation text-general-red">
            필수
          </span>
        </div>
        <div className="max-w-full w-full h-[178px]">
          <DragDropFileContainer
            file={state.commerceFile}
            setFile={func.setCommerceFile}
            title={"상업운전개시 확인서"}
            input_id="input-file-upload_B"
            content={""}
          />
        </div>
        <div className="flex justify-center items-center h-[50px] relative">
          <div className="h-[30px]">
            {apiResponse.status === "loading" && <DefaultLoading />}
          </div>
        </div>

        <div className="flex gap-x-4">
          <button
            className={`w-full ${
              submitCheckValue
                ? "bg-main-color-primary text-general-white"
                : "bg-border-color-dividers cursor-not-allowed"
            }  rounded-lg py-3`}
            onClick={() => {
              if (submitCheckValue) func.setSubmitModal(true);
            }}
          >
            완료
          </button>
        </div>
      </main>
      {state.submitModal && (
        <MypageOwnerModal
          func={{
            handleSubmitModal: func.handleSubmitModal,
          }}
          options={{
            contents: `정확한 파일인지 확인하셨나요? \n 업로드 후 검토 단계에서는 수정이 어렵습니다.`,
            paddingX: "submit",
          }}
        />
      )}
    </section>
  );
};
