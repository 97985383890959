import classNames from "classnames";
import { IcopyMessageList } from "../../pages/Chat/types/state.type";

interface Ioptions {
  order: number;
}

export const ChatMessageItem = ({
  item,
  options,
}: {
  item: IcopyMessageList;
  options: Ioptions;
}) => {
  return (
    <div className={classNames({ "mt-[50px]": options.order })}>
      <aside
        className={classNames(
          "flex gap-x-[10px] items-center mb-[20px]",
          {
            "justify-start": !item.is_me,
          },
          {
            "justify-end": item.is_me,
          },
        )}
      >
        <div className="w-[40px] h-[40px] border-[1px] bg-general-white border-border-color-dividers rounded-[100%] flex justify-center items-center">
          <img className="w-[70%] h-[70%]" src="/icon_remodel/profile.svg" />
        </div>
        <div>
          <span className="text-footer">{item.sender_name}</span>
        </div>
      </aside>
      <div className="overflow-auto ml-[25px] max-md:text-default">
        <div
          className={classNames(
            "flex flex-col float-right max-w-[95%] min-w-[25%] px-[30px] py-[30px] max-sm:px-[15px] max-sm:py-[15px]",
            {
              "bg-main-color-sub rounded-r-xl rounded-b-xl float-left":
                !item.is_me,
            },
            {
              "bg-general-white rounded-l-xl rounded-b-xl float-right":
                item.is_me,
            },
          )}
        >
          {item.content.split("\n").map((line, idx) => {
            return (
              <p className="break-all" key={idx}>
                {line}
                <br />
              </p>
            );
          })}

          <span
            className={classNames(
              "text-text-color-disabled mt-[10px] max-md:text-smallExplanation",
              { "text-left": !item.is_me },
              { "text-right": item.is_me },
            )}
          >
            {item.send_datetime}
          </span>
        </div>
      </div>
    </div>
  );
};
