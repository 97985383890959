export const MypagePlanBuyMenu = () => {
  return (
    <nav className="flex px-5 text-default text-text-color-secondary py-2 border-y-[1px] border-y-text-color-disabled">
      <div className="max-w-[280px] w-full">
        <span>발전소명</span>
      </div>
      <div className="max-w-[250px] w-full text-right">
        <span>나의 구매 희망가</span>
      </div>
      <div className="max-w-[280px] w-full text-right">
        <span>판매자의 희망가</span>
      </div>
      <div className="max-w-[240px] w-full text-right relative">
        <span>판매자 닉네임</span>

        <div className="group pl-[6px] cursor-pointer mb-[1px] absolute top-[1px] right-[-18px]">
          <img src="/icon_remodel/information.svg" />
          <p
            className="w-[185px] px-[16px] py-[10px] text-text-color-primary text-left bg-main-color-sub absolute left-[-150px] top-7 hidden text-footer rounded-lg group-hover:block
            before:content-[''] before:absolute before:w-0 before:h-0 before:border-[8px] before:border-solid before:border-[transparent]
          before:border-b-main-color-sub before:top-[-15px] before:left-[156px] z-50 leading-[21px] shadow-xl"
          >
            소유주 인증이 되지 않은 경우,
            <br /> 모두 관계자로 표시됩니다.
          </p>
        </div>
      </div>
    </nav>
  );
};
